import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory, useParams } from "react-router";
import { Badge } from 'rsuite';

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} className="link-group">
        {/* <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Hierarchy</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-sitemap"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-user-edit"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
            </Whisper>
        </Link>
        <Toggle className="table_toggle" size="sm"></Toggle> */}
      </Table.Cell>
    );
};

export default function Notes() {

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);
    const [notes, setNotes] = useState([]);

    const GetNotes = () => {
        axios.get(window.__APP__.api+"/operators/notes/list/"+id+"?platform="+platforms.platform.id+"&limit="+limit+"&page="+page, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setNotes(response.data.notes);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetNotes();
        } 
    }, [platforms.platform, limit, page]);

    return (
        <>
            <div className="nav-overview">
                {/* <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <InputPicker />
                    </div>
                </Panel> */}
                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={notes}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Creator</Table.HeaderCell>
                            <Table.Cell dataKey="creator" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Priority</Table.HeaderCell>
                            <Table.Cell>{rowData => <Badge style={{ background: rowData.priority == 0 ? "#27ae60" : rowData.priority == 1 ? "#e67e22" : rowData.priority == 2 ? "#e74c3c" : ""  }} content={rowData.priority == 0 ? "NORMAL" : rowData.priority == 1 ? "HIGH" : rowData.priority == 2 ? "IMPORTANT" : "N/A"} />}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Note</Table.HeaderCell>
                            <Table.Cell dataKey="note" />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>
        </>
    )
}