import {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {Panel, InputPicker, Divider} from "rsuite";

import RegionsTable from "./RegionsTable";
import PartnersTable from "./PartnersTable";
import ShopsTable from "./ShopsTable";
import PlayersTable from "./PlayersTable";
import ProvidersTable from "./ProvidersTable";
import GamesTable from "./GamesTable";
import JackpotsPanel from "./JackpotsPanel";
import CashbacksPanel from "./Cashbacks";
import TestingGGR from "./TestingGGR";

import axios from "axios";

export default function Detailed() {
    const dispatch = useDispatch();
    const reports = useSelector(state => state.reports);
    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const [partner, setPartner] = useState(null);
    const [shop, setShop] = useState(null);
    const [provider, setProvider] = useState(null);
    const [regions, setRegions] = useState([]);
    const [partners, setPartners] = useState([]);
    const [shops, setShops] = useState([]);
    const [providers, setProviders] = useState([]);

    const GetRegions = () => {
        axios.get(window.__APP__.api+"/platform/regions/"+reports.country+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setRegions(response.data.regions);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }
    const GetPartners = () => {
        axios.get(window.__APP__.api+"/platform/partners/"+reports.region+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPartners(response.data.partners);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }
    const GetShops = () => {
        axios.get(window.__APP__.api+"/platform/shops/"+reports.partner+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShops(response.data.shops);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }
    const GetProviders = () => {
        axios.get(window.__APP__.api+"/platform/providers?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setProviders(response.data.providers);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(reports.region != null)
            GetPartners();
    }, [reports.region]);
    useEffect(() => {
        if(reports.partner != null)
            GetShops()
    }, [reports.partner]);

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetProviders();
            GetRegions();
        }
    }, [platforms.platform]);

    const SetRegion = (region) => {
        dispatch({
            type : "SET_REPORTS_REGION",
            data : {
                region : region
            }
        })
    }
    const SetPartner = (partner) => {
        dispatch({
            type : "SET_REPORTS_PARTNER",
            data : {
                partner : partner
            }
        })
    }
    const SetShop = (shop) => {
        dispatch({
            type : "SET_REPORTS_SHOP",
            data : {
                shop : shop
            }
        })
    }
    const SetProvider = (provider) => {
        dispatch({
            type : "SET_REPORTS_PROVIDER",
            data : {
                provider : provider
            }
        })
    }

    return (
        <>
            <Panel style={{marginTop : "15px"}} className="report-panel" header="Regions" collapsible bordered>
                <RegionsTable />
            </Panel>

            {
                platforms.platform.type == 0 && (
                    <>
                    <Panel className="report-panel" header="Partners" collapsible bordered>
                        <div className="report-filters-form">
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Region</label>
                                <InputPicker data={regions} onChange={SetRegion} />
                            </div>
                        </div>
                        {
                            reports.region != null && (
                                <PartnersTable style={{marginTop : "15px"}} />
                            )
                        }
                    </Panel>
                    <Panel className="report-panel" header="Shops" collapsible bordered>
                        <div className="report-filters-form">
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Region</label>
                                <InputPicker value={reports.region} data={regions} onChange={SetRegion} />
                            </div>
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Partner</label>
                                <InputPicker value={reports.partner} data={partners} onChange={SetPartner} />
                            </div>
                        </div>
                        {
                            (reports.region != null && reports.partner != null) && (
                                <ShopsTable value={reports.region} style={{marginTop : "15px"}} />
                            )
                        }
                    </Panel>
                    <Panel className="report-panel" header="Players" collapsible bordered>
                        <div className="report-filters-form">
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Region</label>
                                <InputPicker value={reports.region} data={regions} onChange={SetRegion} />
                            </div>
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Partner</label>
                                <InputPicker value={reports.partner} data={partners} onChange={SetPartner} />
                            </div>
                            <div className="form-element">
                                <label style={{display : "block", marginBottom : "10px"}}>Shop</label>
                                <InputPicker value={reports.shop} data={shops} onChange={SetShop} />
                            </div>
                        </div>
                        {
                            (reports.region != null && reports.partner != null && reports.shop != null) && (
                                <PlayersTable value={reports.region} style={{marginTop : "15px"}} />
                            )
                        }
                    </Panel>
                    </>
                )
            }
            

            <Divider />

            <Panel className="report-panel" header="Providers" collapsible bordered>
                <ProvidersTable />
            </Panel>

            <Panel className="report-panel" header="Games" collapsible bordered>
                <div className="report-filters-form">
                    <div className="form-element">
                        <label style={{display : "block", marginBottom : "10px"}}>Providers</label>
                        <InputPicker value={reports.provider} data={providers} onChange={SetProvider} />
                    </div>
                </div>
                {
                    reports.provider != null ? <GamesTable /> : <></>
                }
            </Panel>
            
            {
                platforms.platform.type != 3 && (
                    <>
                        <Panel className="report-panel" header="Jackpots" collapsible bordered>
                            <JackpotsPanel />
                        </Panel>
                        <Panel className="report-panel" header="Cashbacks" collapsible bordered>
                            <CashbacksPanel />
                        </Panel>
                        <Panel className="report-panel" header="Testing GGR" collapsible bordered>
                            <TestingGGR />
                        </Panel>
                    </>
                )
            }
        </>
    )
}