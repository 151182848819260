import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {Button, Modal} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const userx = useSelector(state => state.user);

    const toggleBannerDeleteModal = (id) => {
        dispatch({
            type : "SET_LANGUAGE_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_LANGUAGE_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("languages_content_list"))) >= 0 ? (
                <Link to={"/cms/language/"+rowData['id']} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Content</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i class="fas fa-language"></i></IconButton>
                    </Whisper>
                </Link>
            ) : (
                <></>
            )
        }
        {
            userx.user.type != 4 && (
                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("languages_edit"))) >= 0 ? (
                    <Link to={"/cms/languages/edit/"+rowData['id']} style={{marginRight : "10px"}}>
                        <Whisper
                            placement={"top"}
                            speaker={
                                <Tooltip>Edit</Tooltip>
                            }
                        >
                            <IconButton className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
                        </Whisper>
                    </Link>
                ) : (
                    <></>
                )
            )
        }
        {
            (rowData['id'] != 1 && userx.user.type != 4) && (
                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("languages_delete"))) >= 0 ? (
                    <a onClick={() => toggleBannerDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
                        <Whisper
                            placement={"top"}
                            speaker={
                                <Tooltip>Delete</Tooltip>
                            }
                        >
                            <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
                        </Whisper>
                    </a>
                ) : (
                    <></>
                )
            )
        }
        
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_LANGUAGE_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeleteLanguage = () => {
        axios.post(window.__APP__.api+"/cms/languages/delete/"+modals.languageId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Language #{modals.languageId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this language ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeleteLanguage} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function LanguagesTable() {
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);


    const GetLanguages = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/cms/languages/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.languages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetLanguages();
    }, [platforms.platform, activePage, activeLimit, modals.languageDeleteModal]);


    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={70} align={"center"}>
                    <Table.HeaderCell>Image</Table.HeaderCell>
                    <Table.Cell>{rowData => <img style={{width : "30px"}} src={rowData.image} />}</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Language</Table.HeaderCell>
                    <Table.Cell dataKey="language" />
                </Table.Column>

                <Table.Column width={170} align={"center"}>
                    <Table.HeaderCell>Date Added</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            {
                modals.languageDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
        </>
        
    )
}