import {useState, useEffect} from "react";

import axios from "axios";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, InputPicker, Input, Table, Whisper, Tooltip, IconButton, Toggle, Pagination, Modal } from 'rsuite';

import {Button} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {FaFilter} from "react-icons/fa";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [checkedState, setCheckedState] = useState(rowData['Active']);

    const selectProvider = (id) => {
        dispatch({
            type : "SET_SETTINGS_PROVIDER",
            data : {
                provider : id
            }
        })
        dispatch({
            type : "SET_SETTINGS_PROVIDER_SHARE",
            data : {
                share : rowData['loyalty']
            }
        })
    }

    const toggleGame = (id) => {
        axios.post(window.__APP__.api+"/content/game/toggle/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                // dispatch({
                //     type : "SET_GAMES_RANDOM_ID",
                //     data : {
                //         random : Math.random()
                //     }
                // })
                setCheckedState(response.data.State);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const ChangePriority = () => {
        dispatch({
            type : "SET_GAMES_PRIORITY_MODAL",
            data : {
                modal : true,
                id : rowData['Id']
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group" style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("content_game_priority"))) >= 0 ? (
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Change Priority</Tooltip>
                    }
                >
                    <IconButton onClick={ChangePriority} style={{ position : "relative", top : "3px"}} className="table-button no-icon"><i className="fas fa-list-ol"></i></IconButton>
                </Whisper>
            ) : (
                <></>
            )
        }
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("content_toggle_game"))) >= 0 ? (
                <Toggle style={{marginLeft : "5px"}} onChange={() => toggleGame(rowData['Id'])} checked={checkedState == 0} className="table_toggle" size="sm"></Toggle>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

const PriorityModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closePriorityModal = () => {
        dispatch({
            type : "SET_GAMES_PRIORITY_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const [priority, setPriority] = useState("");
    const [loading, setLoading] = useState(false);

    const ChangePriority = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/content/game/priority/set/"+modals.gamesPriorityId+"?platform="+platforms.platform.id+"&priority="+priority, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.Error == null) {
            dispatch({
                type : "SET_GAMES_PRIORITY_MODAL",
                data : {
                    modal : false,
                    id : null
                }
            })
        }
    }

    return (
        <Modal loading={loading} open={modals.gamesPriorityModal} onClose={closePriorityModal}>
            <Modal.Header>
                <Modal.Title>Change Priority</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input type="number" value={priority} onChange={value => setPriority(value)} placeholder="Priority" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closePriorityModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ChangePriority} appearance="primary">
                    Update Priority
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function ContentGames() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [providers, setProviders] = useState([]);
    const [provider, setProvider] = useState("");
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);

    const FetchProviders = async () => {
        const response = await axios.get(window.__APP__.api+"/platform/providers?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.Error == null) {
            setProviders([
                {
                    label : "All",
                    value : ""
                },
                ...response.data.providers
            ])
        }
    }
    const FetchCategories = async () => {
        const response = await axios.get(window.__APP__.api+"/content/categories/list"+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.Error == null) {
            setCategories(response.data.Categories)
        }
    }

    const GetAllGames = async (m_page, m_limit, m_category, m_name, m_provider) => {
        setLoading(true);

        const response = await axios.post(window.__APP__.api+"/content/games/list"+"?platform="+platforms.platform.id+"&page="+m_page+"&limit="+m_limit, {
            category : m_category,
            provider : m_provider,
            name : m_name
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.Error == null) {
            setGames(response.data.Games);
            setPages(response.data.Pages);
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            FetchProviders();
            FetchCategories();
        }
    }, [platforms]);

    useEffect(() => {
        GetAllGames(page, limit, category, name, provider);
    }, [category, name, limit, page, provider, modals.gamesPriorityModal, modals.gamesRandomId]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"content-games"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content games-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Content Management</Breadcrumb.Item>
                            <Breadcrumb.Item active>Games</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Games</h1>
                        </div>

                        <Panel style={{marginTop : "20px", display : "flex", alignItems : "center", flexDirection : "row", width : "100%"}}>
                            <div className="report-filters-form" style={{ width : "100%" }}>
                                <div className="form-element" style={{ width : "100%"}}>
                                    <label style={{display : "block", marginBottom : "10px"}}>Game Name</label>
                                    <Input style={{ width : "100%"}} value={name} onChange={setName} placeholder="Game Name" />
                                </div>
                                <div className="form-element" style={{ width : "100%"}}>
                                    <label style={{display : "block", marginBottom : "10px"}}>Providers</label>
                                    <InputPicker style={{ width : "100%"}} value={provider} data={providers} onChange={setProvider} />
                                </div>
                                <div className="form-element" style={{ width : "100%"}}>
                                    <label style={{display : "block", marginBottom : "10px"}}>Category</label>
                                    <InputPicker style={{ width : "100%"}} value={category} data={categories} onChange={setCategory} />
                                </div>
                            </div>
                        </Panel>

                        <div style={{ marginTop : "20px" }}>
                            <Table
                                autoHeight
                                data={games}
                                loading={loading}
                            >
                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>Game Name</Table.HeaderCell>
                                    <Table.Cell dataKey="Name" />
                                </Table.Column>

                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>Provider</Table.HeaderCell>
                                    <Table.Cell dataKey="Provider" />
                                </Table.Column>

                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>Priority</Table.HeaderCell>
                                    <Table.Cell dataKey="Priority" />
                                </Table.Column>

                                <Table.Column flexGrow={1} align="center">
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                    <ActionCell />
                                </Table.Column>
                            </Table>

                            <div className="pagination-box">
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <PriorityModal />
        </>
    )
}