import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Panel } from 'rsuite';

import { Button, Input, InputPicker, Form, Message } from "rsuite";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaFilter, FaRegQuestionCircle } from "react-icons/fa";

import Rulesets from "./Rulesets";

import { useHistory } from "react-router-dom";

import axios from "axios";

export default function EPAddSelector() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [sports, setSports] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tournaments, setTournaments] = useState([]);

    const [activeKey, setActiveKey] = useState("sp-rulesets");
    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");
    const [advantages, setAdvantages] = useState({});

    const selectors = [
        {
            label: "Sport",
            value: "sport"
        },
        {
            label: "Category",
            value: "category"
        },
        {
            label: "Tournament",
            value: "tournament"
        }
    ]

    const [form, setForm] = useState({
        selector: "",
        selectorId: "",
        customad: {}
    })

    const FetchSports = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/sports?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setSports(response.data.sports);
        }
    }
    const FetchCategories = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/categories?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setCategories(response.data.categories);
        }
    }
    const FetchTournaments = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/tournaments?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setTournaments(response.data.tournaments);
        }
    }

    const CreateSelector = async () => {
        setLoading(true);

        const response = await axios.post(window.__APP__.api + "/sportsbook/CreateEPSelector?platform=" + platforms.platform.id, form, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if (response.data.Error == null) {
            history.push("/sportsbook/earlypayout/selectors");
        } else {
            setFormMessage(response.data.Error);
            setFormMessageType("error");
        }
    }

    useEffect(() => {
        FetchSports();
        FetchCategories();
        FetchTournaments();
    }, [])

    const GetEPTournamentMarkets = async (id) => {
        const response = await axios.post(window.__APP__.api + "/sportsbook/GetEPSelectorTournamentMarkets/"+id+"?platform=" + platforms.platform.id, form, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if(response.data.Error == null && response.data.advantages != null) {
            setAdvantages(response.data.advantages);
        }
    }

    useEffect(() => {
        if(form.selector == "tournament" && form.selectorId != "") {
            GetEPTournamentMarkets(form.selectorId)
        }
    }, [form.selectorId, form.selector])

    const ChangeCustomAdvantage = (eid, value) => {
        var f = Object.assign({}, form);

        f.customad[eid.toString()] = value;

        console.log(f)

        setForm(f);
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"sports-earlypayout"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Sportsbook Early Payout</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Create Early Payout Selector</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            {
                                formMessage != "" && (
                                    <Message showIcon type={formMessageType}>
                                        {formMessage}
                                    </Message>
                                )
                            }

                            <Form className="form-box">
                                <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Selector</Form.ControlLabel>
                                        <InputPicker onChange={value => setForm({ ...form, selector: value })} value={form.sport} placeholder="Selector" data={selectors} />
                                    </Form.Group>
                                </div>

                                {
                                    (form.selector != "" && form.selector != null) ? (
                                        <>
                                            {
                                                form.selector == "sport" ? (
                                                    <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                                        <Form.Group controlId="rulename">
                                                            <Form.ControlLabel>Sport</Form.ControlLabel>
                                                            <InputPicker onChange={value => setForm({ ...form, selectorId: value })} value={form.selectorId} placeholder="Sport" data={sports} />
                                                        </Form.Group>
                                                    </div>
                                                ) : form.selector == "category" ? (
                                                    <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                                        <Form.Group controlId="rulename">
                                                            <Form.ControlLabel>Category</Form.ControlLabel>
                                                            <InputPicker onChange={value => setForm({ ...form, selectorId: value })} value={form.selectorId} placeholder="Category" data={categories} />
                                                        </Form.Group>
                                                    </div>
                                                ) : form.selector == "tournament" ? (
                                                    <>
                                                        <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                                            <Form.Group controlId="rulename">
                                                                <Form.ControlLabel>Tournament</Form.ControlLabel>
                                                                <InputPicker onChange={value => setForm({ ...form, selectorId: value })} value={form.selectorId} placeholder="Tournament" data={tournaments} />
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            Object.entries(advantages).map(entry => {
                                                                return (
                                                                    <div className="form-row" style={{ marginTop: "20px" }}>
                                                                        <Form.Group controlId="rulename">
                                                                            <Form.ControlLabel>Custom Advantage (Market EID : {entry[0]})</Form.ControlLabel>
                                                                            <Form.Control onChange={value => ChangeCustomAdvantage(entry[0], value)} value={form.customad[entry[0].toString()] != undefined ? form.customad[entry[0].toString()] : ""} name="advantage" placeholder="Custom Advantage" />
                                                                        </Form.Group>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button onClick={CreateSelector} loading={loading} type="button" style={{ height: "100%" }} color="primary">Create Selector</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div >
        </>
    )
}