import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import PartnersTable from "./AgentsTable";

import {useHistory} from "react-router-dom";

import {useMediaQuery} from "react-responsive";
import { Whisper, Tooltip } from "rsuite";


import axios from "axios";

export default function KPartners() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const isSmall = useMediaQuery({ query: '(max-width: 400px)' })

    const [player, setPlayer] = useState("");
    const [cin, setCin] = useState("");
    const [shops, setShops] = useState([]);
    const [partners, setPartners] = useState([]);
    const [regions, setRegions] = useState([]);
    const [shop, setShop] = useState("");
    const [partner, setPartner] = useState("");
    const [region, setRegion] = useState("");

    const GetShops = () => {
        axios.get(window.__APP__.api+"/platform/shops?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setShops(response.data.shops);
        });
    }
    const GetPartners = () => {
        axios.get(window.__APP__.api+"/platform/partners?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setPartners(response.data.partners);
        });
    }
    const GetRegions = () => {
        axios.get(window.__APP__.api+"/platform/regions?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setRegions(response.data.regions);
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetShops();
            GetPartners();
            GetRegions();
        }
    }, [platforms]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"kagents"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Agents</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Agents</h1>
                            {
                                isSmall ? (
                                    <Whisper
                                        placement="topCenter"
                                        speaker={
                                            <Tooltip>Create Agent</Tooltip>
                                        }
                                    >
                                        <Button style={{marginTop : "7px"}} onClick={() => history.push("/kagent/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /></Button>
                                    </Whisper>
                                ) : (
                                    <Button onClick={() => history.push("/kagent/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Agent</Button>
                                )
                            }
                        </div>

                        <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                    <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                    <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <Input value={player} onChange={setPlayer} style={{flex : 1}} placeholder="Username" />
                            </div>
                        </Panel>

                        <Panel style={{marginTop : "20px"}}>
                            <PartnersTable partner={partner} shop={shop} player={player} cin={cin} region={region}></PartnersTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}