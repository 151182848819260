import {useState, useEffect} from "react";

import {Table, IconButton, CheckPicker, Input, Radio} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import TagPicker from 'rsuite/TagPicker';
import {Link} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import axios from "axios";

import qs from "querystring";

const ActionCell = ({ rowData, dataKey, setToggleModal, setToggleModalStatus, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    return (
      <Table.Cell {...props} className="link-group">
        <Link to={"/online/player/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link to={"/online/players/edit/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
            </Whisper>
        </Link>
        <Toggle defaultChecked={!rowData['status']} onChange={() => { setToggleModal(rowData['id']); setToggleModalStatus(rowData['status']) }} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/online/agents/player/toggle/"+props.id+"?platform="+platforms.platform.id, { }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                props.setToggleModal(null);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={() => props.setToggleModal(null)} open={props.show}>
            <Modal.Header>
                <Modal.Title>{props.toggleModalStatus == 1 ? "Disable" : "Enable"} Player</Modal.Title>
            </Modal.Header>
            <Modal.Body></Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setToggleModal(null)} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {props.toggleModalStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Players(props) {

    const {agent} = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const platforms = useSelector(state => state.platforms);

    const [toggleModal, setToggleModal] = useState(null);
    const [toggleModalStatus, setToggleModalStatus] = useState(0);

    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([
        {
            label : "Direct",
            value : 0
        },
        {
            label : "Whole Network",
            value : 1
        }
    ]);
    const [filters, setFilters] = useState({
        type : 0,
        username : ""
    });
    const [players, setPlayers] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetPlayers = () => {
        setPlayers([]);
        setLoading(true);

        axios.get(window.__APP__.api+"/online/agents/agent/players/"+id, {
            params : {
                platform : platforms.platform.id,
                filters : {
                    type : filters.type,
                    username : filters.username
                },
                page : page,
                limit : limit
            },
            headers : {
                "Authorization" : "Beaer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setPlayers(response.data.players);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        GetPlayers();
    }, [filters, toggleModal, page, limit]);

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body panel-body-filters">
                        <div className="filter-element" style={{flex : 1}}>
                            <Input placeholder="Username" value={filters.username} onChange={v => setFilters({...filters, username : v})} />
                        </div>
                        <div className="filter-element" style={{flex : 1}}>
                            <InputPicker placeholder="Type" onChange={v => setFilters({ ...filters, type : v})} data={types} value={filters.type} style={{minWidth : "100%"}} />
                        </div>
                    </div>
                </Panel>
                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={players}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        loading={loading}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.Cell>{rowData => <><Link to={"/online/player/"+rowData.id}>{rowData.username}</Link></>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                            <Table.Cell dataKey="balance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Parent</Table.HeaderCell>
                            <Table.Cell>{ data => <Link to={"/online/agent/"+data.parentId}>{data.parent}</Link>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date Created</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell>{ data => data.status == 0 ? "Disabled" : "Enabled"}</Table.Cell>
                        </Table.Column>
                        
                        <Table.Column minWidth={120} flexGrow={1} align="left">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell setToggleModal={setToggleModal} setToggleModalStatus={setToggleModalStatus} />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            pages={pages}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>

            <ToggleModal show={toggleModal != null} id={toggleModal} setToggleModal={setToggleModal} toggleModalStatus={toggleModalStatus} />
        </>
    )
}