import 'rsuite/dist/rsuite.min.css';

import { Switch, Redirect, BrowserRouter as Router, Route } from "react-router-dom";

import { Doughnut, PolarArea } from "react-chartjs-2";

import { Modal, Input, Button } from "rsuite";

import "./Dashboard.css";

import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Partners from "./components/Partners/Partners";
import CreatePartner from "./components/Partners/CreatePartner";
import EditPartner from "./components/Partners/EditPartner";
import Partner from "./components/Partner/Partner";
import Shops from "./components/Shops/Shops";
import Shop from "./components/Shop/Shop";
import CreateShop from "./components/Shops/CreateShop";
import CreateAgent from "./components/Agents/CreateAgent";
import EditShop from "./components/Shops/EditShop";
import EditAgent from "./components/Agents/EditAgent";
import Agents from "./components/Agents/Agents";
import Agent from "./components/Agent/Agent";
import Players from "./components/Players/Players";
import Player from "./components/Player/Player";
import Vouchers from "./components/Vouchers/Vouchers";
import Voucher from "./components/Voucher/Voucher";
import CreateVoucher from "./components/Vouchers/CreateVoucher";
import EditVoucher from './components/Vouchers/EditVoucher';
import CmsBanners from "./components/cms/banners/banners";
import CmsCreateBanner from "./components/cms/banners/CreateBanner";
import CmsEditBanner from "./components/cms/banners/EditBanner";
import CmsLanguages from "./components/cms/languages/languages";
import CmsAddLanguage from "./components/cms/languages/AddLanguage";
import CmsEditLanguage from "./components/cms/languages/EditLanguage";
import CmsLanguageContent from "./components/cms/languages/LanguageContent";
import CmsPages from "./components/cms/pages/pages";
import CmsCreatePage from "./components/cms/pages/CreatePage";
import CmsEditPage from "./components/cms/pages/EditPage";
import CmsPromotions from "./components/cms/promotions/promotions";
import CmsCreatePromotion from "./components/cms/promotions/CreatePromotion";
import CmsEditPromotion from "./components/cms/promotions/EditPromotion";
import CmsSocials from "./components/cms/socials/Socials";
import StatsProviders from "./components/Statistics/Providers";
import StatsGames from "./components/Statistics/Games";
import StatsPartners from "./components/Statistics/Partners";
import StatsShops from "./components/Statistics/Shops";
import StatsRegion from "./components/Statistics/Region";
import StatsPlayers from "./components/Statistics/Players";
import Reports from "./components/Reports/Reports";
import SettingsProviders from "./components/Settings/Providers";
import SettingsGames from "./components/Settings/Games";
import SettingsGamesAPI from "./components/Settings/APIGames";
import EditAccount from "./components/Account/Edit";
import TransactionsHistory from "./components/Transactions/History";
import Withdrawals from "./components/Withdrawals/Withdrawals";
import JackpotsSettings from "./components/Jackpots/Jackpots";
import JackpotSetting from "./components/Jackpots/Settings";
import JackpotWinners from "./components/Jackpots/Winners";
import SiteSettings from "./components/Settings/SiteSettings";
import ShopReport from "./components/Shop/PrintReport";
import Financials from "./components/Financials/Financials";
import Partnerships from "./components/Partnerships/Partnerships";
import KPartners from "./components/Kpartners/KPartners";
import CreateKPartners from "./components/Kpartners/CreateKPartners";
import KPartner from "./components/Kpartner/Kpartner";
import KAgents from "./components/KAgents/Agents";
import KAgent from "./components/KAgent/Agent";
import KShops from "./components/KShops/KShops";
import KShop from "./components/KShop/KShop";
import KPlayers from "./components/KPlayers/KPlayers";
import KPlayer from "./components/Kplayer/KPlayer";
import KEditPlayer from "./components/KPlayers/EditPlayer";
import KCreateAgent from "./components/KAgents/CreateAgent"
import KCreateShop from "./components/KShops/CreateShop"
import KEditPartner from "./components/Kpartners/EditPartner";
import KEditAgent from "./components/KAgents/EditAgent";
import KEditShop from "./components/KShops/EditShop";
import Logs from "./components/Logs/Logs";
import OnlinePlayers from "./components/Online/Players/Players";
import OnlinePlayer from "./components/Online/Player/Player";
import OnlineStatsPlayers from "./components/Online/Statistics/Players";
import OnlineStatsGames from "./components/Online/Statistics/Games";
import OnlineWithdrawals from "./components/Online/Withdrawals/Withdrawals";
import OnlineTransactions from "./components/Online/Transactions/Transactions";
import OnlineEditPlayer from "./components/Online/Player/Edit";
import OnlineAgents from "./components/Online/Agents/Agents";
import OnlineCreateAgent from "./components/Online/Agents/CreateAgent";
import OnlineEditAgent from "./components/Online/Agents/EditAgent";
import OnlinePreviewAgent from "./components/Online/Agent/Agent";
import SettingsCurrencies from "./components/Settings/Currencies";
import SettingsCountries from "./components/Settings/Countries";
import SettingsAddCountry from "./components/Settings/AddCountry";
import SettingsStates from "./components/Settings/States";
import SettingsAddState from "./components/Settings/AddState";
import SettingsAdminUsersList from "./components/Settings/AdminUsers";
import SettingsAdminRolesList from "./components/Settings/AdminRoles";
import SettingsAddAdminRole from "./components/Settings/AddAdminRole";
import SettingsEditAdminRole from "./components/Settings/EditAdminRole";
import SettingsAddAdminUser from "./components/Settings/AddAdminUser";
import SettingsEditAdminUser from "./components/Settings/EditAdminUser";
import TransferPage from "./components/Transfer/TransferPage";
import ListDepositMethods from "./components/Online/Paymentmethods/Deposits";
import ListWithdrawMethods from "./components/Online/Paymentmethods/Withdrawals";
// REST
import PartnerDebts from './components/PartnerDebts/Debts';
import ShopsBook from './components/Shopsbook/Shopsbook';
import Bonuses from "./components/Online/Bonuses/List";
import Bonus from "./components/Online/Bonuses/Bonus";
import ContentGames from "./components/Content/Games";
import ContentProviders from "./components/Content/Providers";
import SportsbookContent from "./components/Sportsbook/Content/Content";
import SportsbookEarlyPayout from "./components/Sportsbook/EarlyPayout/EarlyPayout";
import SportsbookCreateEPRuleset from "./components/Sportsbook/EarlyPayout/EPCreateRuleset";
import SportsbookMultibonus from "./components/Sportsbook/Multibonus/Multibonus";

import Transfer from "./components/Transfer/Transfer";

import { Notification, toaster } from 'rsuite';
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMediaQuery } from 'react-responsive'
import EPEditRuleset from './components/Sportsbook/EarlyPayout/EPEditRuleset';
import EPAddSelector from './components/Sportsbook/EarlyPayout/EPSelectorsAdd';


export default function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const platforms = useSelector(state => state.platforms);
  const message = useSelector(state => state.message);
  const modals = useSelector(state => state.modals);

  const history = useHistory();

  const isSidebarClosed = useMediaQuery({ query: '(max-width: 1160px)' })

  const [loaded, setLoaded] = useState(false);

  const NotificationBox = (
    <Notification type="success" header={"Notification"} closable>
      pppp
    </Notification>
  )


  useEffect(() => {
    if (localStorage.getItem("jwt") != undefined) {
      axios.get(window.__APP__.api + "/user/Check", {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      }).then(response => {
        if (response.data.error == null) {
          dispatch({
            type: "SET_LOGGED",
            data: {
              logged: true
            }
          });

          dispatch({
            type: "SET_USER",
            data: {
              user: response.data.user
            }
          })

          dispatch({
            type: "SET_PLATFORMS",
            data: {
              platforms: response.data.platforms
            }
          });

          if (response.data.platforms.length > 0) {
            if (localStorage.getItem("platform") != undefined) {
              var checkPlatform = response.data.platforms.find(plat => {
                return plat.platform_id == localStorage.getItem("platform")
              })

              if (checkPlatform != undefined) {

                dispatch({
                  type: "SET_PLATFORM",
                  data: {
                    platform: {
                      id: checkPlatform.platform_id,
                      name: checkPlatform.platform_name,
                      type: checkPlatform.platform_type,
                      credits_type: checkPlatform.platform_credits_type,
                      credits: checkPlatform.platform_credits,
                      is_limited: checkPlatform.is_limited,
                      is_mc: checkPlatform.is_mc,
                      agent_enabled: checkPlatform.agent_enabled,
                      version : checkPlatform.version
                    }
                  }
                })

                setLoaded(true);
              }
            } else {
              var platform = response.data.platforms[0];

              dispatch({
                type: "SET_PLATFORM",
                data: {
                  platform: {
                    id: platform.platform_id,
                    name: platform.platform_name,
                    type: platform.platform_type,
                    credits_type: platform.platform_credits_type,
                    credits: platform.platform_credits,
                    is_limited: platform.is_limited,
                    is_mc: platform.is_mc,
                    agent_enabled: platform.agent_enabled,
                    version : platform.version
                  }
                }
              })

              setLoaded(true);
            }
          } else {
            setLoaded(true);
          }
        } else {
          dispatch({
            type: "SET_LOGGED",
            data: {
              logged: false
            }
          });

          localStorage.removeItem("jwt");
          setLoaded(true);
        }
      });
    } else {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isSidebarClosed) {
      dispatch({
        type: "TOGGLE_SIDEBAR",
        data: {
          show: true
        }
      })
    }
  }, [isSidebarClosed]);

  const BalanceModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const platforms = useSelector(state => state.platforms)

    const closeBalanceModal = () => {
      dispatch({
        type: "SHOW_BALANCE_MODAL",
        data: {
          modal: false
        }
      })
    }

    const [balances, setBalances] = useState([0, 0]);

    const GetBalance = async () => {
      var response = await axios.get(window.__APP__.api + "/statistics/balance/partitions?platform=" + platforms.platform.id, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      });

      if (response.data.error == null) {
        setBalances([response.data.balance.paid, response.data.balance.unpaid, response.data.balance.total]);
      }
    }

    useEffect(() => {
      GetBalance();
    }, [platforms.platform])

    return (
      <Modal open={modals.balanceModal} onClose={closeBalanceModal}>
        <Modal.Header>
          <Modal.Title>Overall Balance partition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
            <Doughnut data={{ labels: ["Paid Balance", "Unpaid Balance", "Users' Balance"], datasets: [{ data: balances, backgroundColor: ["#71BA51", "#E3000E", "#E75926"] }] }} options={[]} />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const DepositModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const platforms = useSelector(state => state.platforms)

    const closeDepositModal = () => {
      dispatch({
        type: "SET_DEPOSIT_MODAL",
        data: {
          modal: false
        }
      })
    }

    const [amount, setAmount] = useState("");

    const [quote, setQuote] = useState(null);
    const [quoteloading, setQuoteLoading] = useState(false);
    const [depositloading, setDepositLoading] = useState(false);
    const [changedMessage, setChangedMessage] = useState("");

    const GetQuote = async () => {
      setChangedMessage("");
      setQuoteLoading(true);

      var response = await axios.post(window.__APP__.api + "/transactions/deposit/quote?platform=" + platforms.platform.id, {
        amount: amount
      }, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      })

      setQuoteLoading(false);

      if (response.data.error == null) {
        setQuote(response.data.rate);
      } else {
        setChangedMessage(response.data.error);
      }
    }

    const DepositQuote = async () => {

      setChangedMessage("");

      if (quote == null) {
        return;
      }

      setDepositLoading(true);

      var response = await axios.post(window.__APP__.api + "/transactions/deposit/usdt?platform=" + platforms.platform.id, {
        amount: amount,
        credits: quote.credits
      }, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      })

      setDepositLoading(false);

      if (response.data.error == null) {
        if (response.data.rate == null) {
          window.location = response.data.url;
        } else {
          setChangedMessage("Quote changed, please confirm new price.");
          setQuote(response.data.rate);
        }
      } else {
        setChangedMessage(response.data.error);
      }
    }


    return (
      <Modal open={modals.depositModal} onClose={closeDepositModal}>
        <Modal.Header>
          <Modal.Title>Deposit Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
            <div className="amount">
              <label>USDT Amount</label>
              <Input onChange={v => setAmount(v)} value={amount} placeholder="USDT Amount"></Input>
            </div>
            <div className='calculator' style={{ width: "100%" }}>
              <Button onClick={GetQuote} loading={quoteloading} color="primary">Calculate Credits</Button>
            </div>

            <p style={{ color: "red" }}>{changedMessage}</p>

            {
              quote != null && (
                <>
                  <div className='calculator-result'>
                    <label>USDT/TND Rate : {quote.usdt}</label>
                    <label>Buy Rate : {quote.quote}</label>
                    <label>Total Credits : {quote.credits} TND</label>
                  </div>
                  <div className='calculator' style={{ width: "100%" }}>
                    <Button onClick={DepositQuote} loading={depositloading} color="secondary" style={{ backgroundColor: "rgba(39, 174, 96,1.0)", color: "#FFF" }}>Deposit USDT</Button>
                  </div>
                </>
              )
            }
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <div className="app">
        {
          loaded && (
            <>
              <Router>
                <Switch>
                  <Route path="/" exact={true}>
                    {
                      !user.logged ? (
                        <Login></Login>
                      ) : (
                        <Redirect to="/dashboard" />
                      )
                    }
                  </Route>
                  <Route path="/dashboard" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Dashboard />
                      )
                    }
                  </Route>
                  <Route path="/partners" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Partners />
                      )
                    }
                  </Route>
                  <Route path="/partner/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <CreatePartner />
                      )
                    }
                  </Route>
                  <Route path="/partner/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EditPartner />
                      )
                    }
                  </Route>
                  <Route path="/partner/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Partner nav="home" />
                      )
                    }
                  </Route>
                  <Route path="/partner/hierarchy/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Partner nav="hierarchy" />
                      )
                    }
                  </Route>
                  <Route path="/agents" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Agents />
                      )
                    }
                  </Route>
                  <Route path="/shops" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Shops />
                      )
                    }
                  </Route>
                  <Route path="/kagent/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KCreateAgent />
                      )
                    }
                  </Route>
                  <Route path="/kshop/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KCreateShop />
                      )
                    }
                  </Route>
                  <Route path="/shop/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <CreateShop />
                      )
                    }
                  </Route>
                  <Route path="/agents/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <CreateAgent />
                      )
                    }
                  </Route>
                  <Route path="/shop/report/print/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ShopReport />
                      )
                    }
                  </Route>
                  <Route path="/shop/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EditShop />
                      )
                    }
                  </Route>
                  <Route path="/agent/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EditAgent />
                      )
                    }
                  </Route>
                  <Route path="/shop/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Shop nav="home" />
                      )
                    }
                  </Route>
                  <Route path="/agent/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Agent nav="home" />
                      )
                    }
                  </Route>
                  <Route path="/shop/hierarchy/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Shop nav="hierarchy" />
                      )
                    }
                  </Route>
                  <Route path="/agent/hierarchy/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Agent nav="hierarchy" />
                      )
                    }
                  </Route>

                  <Route path="/vouchers" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Vouchers />
                      )
                    }
                  </Route>

                  <Route path="/voucher/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <CreateVoucher />
                      )
                    }
                  </Route>

                  <Route path="/voucher/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EditVoucher />
                      )
                    }
                  </Route>

                  <Route path="/voucher/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Voucher nav="home" />
                      )
                    }
                  </Route>


                  <Route path="/players" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Players />
                      )
                    }
                  </Route>

                  <Route path="/bonuses/list" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Bonuses />
                      )
                    }
                  </Route>

                  <Route path="/online/bonus/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Bonus />
                      )
                    }
                  </Route>

                  <Route path="/kpartners" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KPartners />
                      )
                    }
                  </Route>

                  <Route path="/kagents" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KAgents />
                      )
                    }
                  </Route>

                  <Route path="/kshops" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KShops />
                      )
                    }
                  </Route>

                  <Route path="/kplayers" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KPlayers />
                      )
                    }
                  </Route>

                  <Route path="/kplayer/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KPlayer nav="home" />
                      )
                    }
                  </Route>

                  <Route path="/kplayer/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KEditPlayer />
                      )
                    }
                  </Route>

                  <Route path="/kshop/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KShop nav="home" />
                      )
                    }
                  </Route>

                  <Route path="/kagent/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KAgent nav="home" />
                      )
                    }
                  </Route>

                  <Route path="/kpartners/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <CreateKPartners />
                      )
                    }
                  </Route>

                  <Route path="/kpartner/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KEditPartner />
                      )
                    }
                  </Route>

                  <Route path="/kagent/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KEditAgent />
                      )
                    }
                  </Route>

                  <Route path="/kshop/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KEditShop />
                      )
                    }
                  </Route>

                  <Route path="/kpartner/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <KPartner nav="home" />
                      )
                    }
                  </Route>

                  <Route path="/agents" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Agent />
                      )
                    }
                  </Route>

                  <Route path="/logs" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Logs />
                      )
                    }
                  </Route>

                  <Route path="/player/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Player nav="home" />
                      )
                    }
                  </Route>
                  <Route path="/cms/banners" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsBanners />
                      )
                    }
                  </Route>
                  <Route path="/cms/banner/create" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsCreateBanner />
                      )
                    }
                  </Route>
                  <Route path="/cms/banner/edit/:id" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsEditBanner />
                      )
                    }
                  </Route>
                  <Route path="/cms/languages" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsLanguages />
                      )
                    }
                  </Route>
                  <Route path="/cms/language/add" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsAddLanguage />
                      )
                    }
                  </Route>
                  <Route path="/cms/languages/edit/:id" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsEditLanguage />
                      )
                    }
                  </Route>
                  <Route path="/cms/language/:id" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsLanguageContent />
                      )
                    }
                  </Route>
                  <Route path="/cms/pages" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsPages />
                      )
                    }
                  </Route>
                  <Route path="/cms/page/create" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsCreatePage />
                      )
                    }
                  </Route>
                  <Route path="/cms/page/edit/:id" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsEditPage />
                      )
                    }
                  </Route>
                  <Route path="/cms/promotions" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsPromotions />
                      )
                    }
                  </Route>
                  <Route path="/cms/promotion/create" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsCreatePromotion />
                      )
                    }
                  </Route>
                  <Route path="/cms/promotions/edit/:id" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsEditPromotion />
                      )
                    }
                  </Route>
                  <Route path="/cms/socials" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <CmsSocials />
                      )
                    }
                  </Route>
                  <Route path="/statistics/providers" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsProviders />
                      )
                    }
                  </Route>
                  <Route path="/statistics/games" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsGames />
                      )
                    }
                  </Route>
                  <Route path="/statistics/partners" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsPartners />
                      )
                    }
                  </Route>
                  <Route path="/statistics/shops" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsShops />
                      )
                    }
                  </Route>
                  <Route path="/statistics/region" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsRegion />
                      )
                    }
                  </Route>
                  <Route path="/statistics/players" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <StatsPlayers />
                      )
                    }
                  </Route>
                  <Route path="/reports" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Reports />
                      )
                    }
                  </Route>
                  <Route path="/financials" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Financials />
                      )
                    }
                  </Route>
                  <Route path="/settings/providers" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsProviders />
                      )
                    }
                  </Route>
                  <Route path="/settings/games" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsGames />
                      )
                    }
                  </Route>
                  <Route path="/settings/games/all" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsGamesAPI />
                      )
                    }
                  </Route>
                  <Route path="/settings/games/:provider" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsGames />
                      )
                    }
                  </Route>
                  <Route path="/settings/jackpots" exact={true}>
                    {
                      (!user.logged || platforms.platform.is_limited == 1) ? (
                        <Redirect to="/" />
                      ) : (
                        <JackpotsSettings />
                      )
                    }
                  </Route>
                  <Route path="/settings/site" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SiteSettings />
                      )
                    }
                  </Route>
                  <Route path="/jackpot/settings/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <JackpotSetting />
                      )
                    }
                  </Route>
                  <Route path="/jackpot/winners/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <JackpotWinners />
                      )
                    }
                  </Route>
                  <Route path="/account/edit" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EditAccount />
                      )
                    }
                  </Route>
                  <Route path="/transactions" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <TransactionsHistory />
                      )
                    }
                  </Route>
                  <Route path="/withdrawals" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Withdrawals />
                      )
                    }
                  </Route>
                  <Route path="/partnerships" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <Partnerships />
                      )
                    }
                  </Route>

                  <Route path="/online/players" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlinePlayers />
                      )
                    }
                  </Route>

                  <Route path="/online/agents/list" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineAgents />
                      )
                    }
                  </Route>
                  <Route path="/online/agents/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineCreateAgent />
                      )
                    }
                  </Route>
                  <Route path="/online/agents/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineEditAgent />
                      )
                    }
                  </Route>
                  <Route path="/online/agent/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlinePreviewAgent nav="overview" />
                      )
                    }
                  </Route>

                  <Route path="/online/player/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineEditPlayer />
                      )
                    }
                  </Route>

                  <Route path="/online/player/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlinePlayer nav="home" />
                      )
                    }
                  </Route>

                  <Route path="/settings/currencies" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsCurrencies />
                      )
                    }
                  </Route>
                  <Route path="/settings/countries" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsCountries />
                      )
                    }
                  </Route>
                  <Route path="/settings/states" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsStates />
                      )
                    }
                  </Route>
                  <Route path="/settings/states/add" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAddState />
                      )
                    }
                  </Route>
                  <Route path="/settings/countries/add" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAddCountry />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminusers" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAdminUsersList />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminusers/add" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAddAdminUser />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminusers/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsEditAdminUser />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminroles" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAdminRolesList />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminroles/add" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsAddAdminRole />
                      )
                    }
                  </Route>
                  <Route path="/settings/adminroles/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SettingsEditAdminRole />
                      )
                    }
                  </Route>

                  <Route path="/online/statistics/players" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineStatsPlayers />
                      )
                    }
                  </Route>

                  <Route path="/online/statistics/games" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineStatsGames />
                      )
                    }
                  </Route>

                  <Route path="/online/withdrawals" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineWithdrawals />
                      )
                    }
                  </Route>

                  <Route path="/transfer" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <TransferPage />
                      )
                    }
                  </Route>

                  <Route path="/online/transactions" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <OnlineTransactions />
                      )
                    }
                  </Route>

                  <Route path="/online/paymentmethod/deposits" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ListDepositMethods />
                      )
                    }
                  </Route>

                  <Route path="/online/paymentmethod/withdrawals" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ListWithdrawMethods />
                      )
                    }
                  </Route>

                  <Route path="/partnerdebts" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <PartnerDebts />
                      )
                    }
                  </Route>

                  <Route path="/shopsbook" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ShopsBook />
                      )
                    }
                  </Route>

                  <Route path="/content/games" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ContentGames />
                      )
                    }
                  </Route>

                  <Route path="/content/providers" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <ContentProviders />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/content" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SportsbookContent />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/earlypayout" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SportsbookEarlyPayout />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/earlypayout/selectors" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SportsbookEarlyPayout tab="selectors" />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/earlypayout/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <SportsbookCreateEPRuleset />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/earlypayout/edit/:id" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EPEditRuleset />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/earlypayout/selectors/create" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/" />
                      ) : (
                        <EPAddSelector />
                      )
                    }
                  </Route>

                  <Route path="/sportsbook/multibonus" exact={true}>
                    {
                      !user.logged ? (
                        <Redirect to="/"></Redirect>
                      ) : (
                        <SportsbookMultibonus />
                      )
                    }
                  </Route>

                </Switch>
              </Router>

              {
                modals.balanceModal && (
                  <BalanceModal />
                )
              }

              <DepositModal />

              {
                // modals.transferModal && (
                //     <Transfer />
                // )
              }
            </>
          )
        }
      </div>
    </>
  )
}