import {Table, IconButton, Checkbox} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle, Modal, Button, Radio, Input } from 'rsuite';

import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../Transfer/Transfer";

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleShopDeleteModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_SHOP_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleShopToggleModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_SHOP_STATUS",
            data : {
                status : rowData['status']
            }
        })
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }
    const toggleTransferModal = (id) => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "agent-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const TogglePercentageModal = (id, perc) => {
        dispatch({
            type : "TOGGLE_PERCENTAGE_MODAL",
            data : {
                toggle : true
            }
        })
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_PERCENTAGE",
            data : {
                percentage : perc
            }
        })
    }
    
    return (
      <Table.Cell {...props} className="link-group">
        <a onClick={() => toggleTransferModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Transfer</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-exchange-alt"></i></IconButton>
            </Whisper>
        </a>
        <Link to={"/agent/hierarchy/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Hierarchy</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-sitemap"></i></IconButton>
            </Whisper>
        </Link>
        <Link to={"/agent/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link to={"/agent/edit/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-user-edit"></i></IconButton>
            </Whisper>
        </Link>
        <a onClick={() => TogglePercentageModal(rowData['id'], rowData['percentage'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Percentage</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-percentage"></i></IconButton>
            </Whisper>
        </a>
        <a onClick={() => toggleShopDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
            </Whisper>
        </a>
        <Toggle color="red" appearance="primary" checked={!rowData['status']} onChange={() => toggleShopToggleModal(rowData['id'])} checked={!rowData['status']} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

const PercentageModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [percentage, changePerc] = useState(0);
    const [full, setFull] = useState(false);

    useEffect(() => {
        changePerc(modals.percentage)
    }, [modals.percentage]);
    
    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PERCENTAGE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    useEffect(() => {
        axios.get(window.__APP__.api+"/agents/payment/model/"+modals.shopId+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFull(response.data.shop);
            }
        })
    }, []);

    const SetPercentage = (id) => {
        axios.post(window.__APP__.api+"/agents/percentage/change/"+id+"?platform="+platforms.platform.id, {
            id : id,
            percentage : percentage,
            full : full
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                dispatch({
                    type : "TOGGLE_PERCENTAGE_MODAL",
                    data : {
                        toggle : false
                    }
                })
            }
        })
    }

    const toggleFullPaument = () => {
        if(full) {
            setFull(false);
        } else {
            setFull(true);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={true}>
            <Modal.Header>
                <Modal.Title>Agent Percentage #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Set Agent Id</h4>
                <br />
                <Input value={percentage} onChange={v => changePerc(v)} placeholder="Percentage" />
                <Checkbox onChange={toggleFullPaument} checked={full}>Full Payment</Checkbox>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={() => SetPercentage(modals.shopId)} appearance="primary">
                    Set Percentage
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeletePartner = () => {
        axios.post(window.__APP__.api+"/agents/delete/"+modals.shopId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Shop #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this shop ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeletePartner} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(0);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/agents/toggle/"+modals.shopId+"?platform="+platforms.platform.id, {type : toggleType}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.shopStatus == 1 ? "Disable" : "Enable"} Shop #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Type</h4>
                <br />
                <Radio onChange={setToggleEvent} value="0" checked={toggleType == 0 ? true : false}>User and sub-users</Radio>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.shopStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function AgentsTable(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {shop, partner} = props;

    // const users = [
    //     {
    //       "id": 1,
    //       "name" : "Yessine Taktak",
    //       "username" : "yessine001",
    //       "balance" : "5000.00",
    //       "shops" : "20",
    //       "players" : "1200",
    //       "uid" : "11125324",
    //       "date" : "2021-09-30",
    //       "phone" : "+21695447119"
    //     },
    //     {
    //         "id": 1,
    //         "name" : "Yessine Taktak",
    //         "username" : "yessine001",
    //         "balance" : "5000.00",
    //         "shops" : "20",
    //         "players" : "1200",
    //         "uid" : "11125324",
    //         "date" : "2021-09-30",
    //         "phone" : "+21695447119"
    //       }
    //   ];

    const GetShops = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/agents/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+"&shop="+shop+"&partner="+partner, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.shops);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetShops();
    }, [platforms.platform, activePage, activeLimit, modals.shopDeleteModal, modals.shopToggleModal, shop, partner]);

    return (
        <>
            <Table
                autoHeight
                data={tableData}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Agent Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Username</Table.HeaderCell>
                    <Table.Cell>{rowData => <Link to={"/agent/"+rowData.id}>{rowData.username}</Link>}</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Phone Number</Table.HeaderCell>
                    <Table.Cell dataKey="phone" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Balance</Table.HeaderCell>
                    <Table.Cell dataKey="balance" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Partner</Table.HeaderCell>
                    <Table.Cell>{ data => <Link to={"/partner/"+data.partnerId}>{data.partner}</Link>}</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Shops</Table.HeaderCell>
                    <Table.Cell dataKey="shops" />
                </Table.Column>

                {/* <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Date Created</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column> */}
                
                <Table.Column minWidth={200} flexGrow={1} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.shopDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
            {
                modals.shopToggleModal && (
                    <ToggleModal show={true} />
                )
            }
            {
                modals.transferModal && (
                    <Transfer />
                )
            }
            {
                modals.percentageModal && (
                    <PercentageModal />
                )
            }
        </>
    )
}