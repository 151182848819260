const initialState = {
    sidebar : true
}

export default function Sidebar(state=initialState, action) {
    switch(action.type) {
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                sidebar : action.data.show
            }
        break;
        default:
            return state;
        break;
    }
}