import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";
import Transfer from "../Transfer/Transfer";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Whisper } from 'rsuite';

import {Button, IconButton, Tooltip, Modal, Input, InputPicker} from "rsuite";
import Nav from 'rsuite/Nav';

import { useHistory, useParams } from "react-router-dom";


import axios from "axios";


/* Navs */
import Overview from "./Navs/Overview";
import Hierarchy from "./Navs/Hierarchy";
import Transactions from "./Navs/Transactions";
import Notes from "./Navs/Notes";
import LoginHistory from "./Navs/LoginHistory";
import Financial from "./Navs/Financial";

import {AiOutlinePlusCircle} from "react-icons/ai";


const MessageModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeMessageModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_MESSAGE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [message, setMessage] = useState("");

    const SendMessage = () => {
        if(message != "") {
            axios.post(window.__APP__.api+"/operators/message/send/"+modals.partnerId+"?platform="+platforms.platform.id, {
                message : message
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeMessageModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    return (
        <Modal open={modals.partnerMessageModal} onClose={closeMessageModal}>
            <Modal.Header>
                <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setMessage} as="textarea" placeholder="Message" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeMessageModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendMessage} appearance="primary">
                    Send Message
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const NoteModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeNoteModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_NOTE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [note, setNote] = useState("");
    const [priority, setPriority] = useState(0);

    const SendNote = () => {
        if(note != "") {
            axios.post(window.__APP__.api+"/operators/note/send/"+modals.partnerId+"?platform="+platforms.platform.id, {
                note : note,
                priority : priority
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeNoteModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    const data = [
        {
            label : "Normal Priority",
            value : 0
        }, {
            label : "High Priority",
            value : 1
        }, {
            label : "Important",
            value : 2
        }
    ]

    return (
        <Modal open={modals.partnerNoteModal} onClose={closeNoteModal}>
            <Modal.Header>
                <Modal.Title>Send Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setNote} as="textarea" placeholder="Note" />
                <InputPicker style={{marginTop : "15px", width : "100%"}} placeholder="Priority" data={data} value={priority} onChange={setPriority}  />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeNoteModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendNote} appearance="primary">
                    Send Note
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Partners(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const {id} = useParams();

    const bars = useSelector(state => state.bars);
    const [activeKey, setActiveKey] = useState(props.nav);
    const [partner, setPartner] = useState({
        name : "",
        location : "",
        balance : 0,
        cashinhand : 0,
        phone : ""
    });

    const GetPartnerInfo = () => {
        axios.get(window.__APP__.api+"/kiosk/partner/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPartner(response.data.operator);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/kiosk/partner/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                GetPartnerInfo();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const ShowMessageModal = (id) => {
        dispatch({
            type : "SET_PARTNER_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_PARTNER_MESSAGE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const ShowNoteModal = (id) => {
        dispatch({
            type : "SET_PARTNER_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_PARTNER_NOTE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPartnerInfo();
        }
    }, [platforms.platform]);

    const MakeTransfer = () => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "partner-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"kpartners"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/kpartners">Partners</Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            {/* <h1>Preview Partner</h1> */}
                        </div>

                        <div className="preview-page">
                            <div className="preview-head">
                                <div className="head-content">
                                    <div className="thumbnail partner-thumb">
                                        <img src="/assets/svg/user.svg" />
                                        <div className="layer">
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="info-row">
                                            <h1>Partner Name</h1>
                                            <h2>{partner.name}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Partner Location</h1>
                                            <h2>{partner.location}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Balance</h1>
                                            <h2>{partner.balance}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Cash In Hand</h1>
                                            <h2>{partner.cashinhand}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Phone</h1>
                                            <h2>{partner.phone}</h2>
                                        </div>
                                    </div>
                                </div>

                                {
                                    partner.status != undefined && (
                                        <div className="custom-buttons">
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Transfer</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => MakeTransfer(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-exchange"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Set Note</Tooltip>
                                                }
                                            >
                                                    <IconButton onClick={() => ShowNoteModal(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-sticky-note"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>{partner.status == 1 ? "Block" : "Unblock"}</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={TogglePartner} className="icon-btn"><i style={{fontSize : "22px"}} className={partner.status == 1 ? "fas fa-lock" : "fas fa-unlock"}></i></IconButton>
                                            </Whisper>
                                        </div>
                                    )
                                }

                                <div className="head-menu">
                                    <Nav activeKey={activeKey} appearance="subtle" onSelect={(key) => setActiveKey(key)}>
                                        <Nav.Item eventKey="home">Overview</Nav.Item>
                                        <Nav.Item eventKey="hierarchy">Hierarchy</Nav.Item>
                                        <Nav.Item eventKey="transactions">Transactions</Nav.Item>
                                        <Nav.Item eventKey="notes">Notes</Nav.Item>
                                        <Nav.Item eventKey="financial">Financial Report</Nav.Item>
                                        <Nav.Item eventKey="login">Login History</Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="preview-body">
                                {
                                    activeKey == "home" ? <Overview /> : activeKey == "hierarchy" ? <Hierarchy /> : activeKey == "transactions" ? <Transactions /> : activeKey == "notes" ? <Notes /> : activeKey == "financial" ? <Financial /> : activeKey == "login" ? <LoginHistory /> : <Overview />
                                 }
                                 {/* activeKey == "documents" ? <Documents /> : activeKey == "transactions" ? <Transactions /> : activeKey == "messages" ? <Messages /> : activeKey == "notes" ? <Notes /> : activeKey == "login" ? <LoginHistory /> : activeKey == "financial" ? <FinancialReport /> : <Overview /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
            <MessageModal />
            <NoteModal />

            {
                modals.transferModal && (
                    <Transfer />
                )
            }
        </>
    )
}