import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link, useParams} from "react-router-dom";
import { Tooltip, Whisper, Input, Badge } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";

import {useState, useEffect} from "react";

import axios from "axios";

export default function WinnersTable() {

    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [winners, setWinners] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const {id} = useParams();

    const GetJackpotWinners = () => {
        axios.get(window.__APP__.api+"/jackpots/winners/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setWinners(response.data.winners);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetJackpotWinners();
        }
    }, [platforms.platform, limit, page]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={winners}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Player</Table.HeaderCell>
                    <Table.Cell>{ data => <Link to={"/player/"+data.player.id}>{data.player.username}</Link>}</Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.Cell dataKey="amount" />
                </Table.Column>

                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.Cell dataKey="title" />
                </Table.Column>

                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>

                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Badge</Table.HeaderCell>
                    <Table.Cell>{ data => data.claimed == 1 ? <Badge color="green" content="Claimed" /> : <Badge color="red" content="Unclaimed" />}</Table.Cell>
                </Table.Column>
                
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}