import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, InputPicker, Whisper, IconButton, Tooltip} from "rsuite";

import {AiOutlinePlusCircle, AiFillPrinter} from "react-icons/ai";
import {FaFilter} from "react-icons/fa";

import {useHistory, useParams} from "react-router-dom";
import CountriesTable from "./Reports/CountriesTable";
import Detailed from "./Reports/Detailed";
import DetailedCurrency from "./Reports/DetailedCurrency";
import Filters from "../Filters/Filters";

import CurrenciesTable from "./Reports/CurrenciesTable";

import axios from "axios";


import ReactExport from "react-export-excel";


export default function Reports() {
    const history = useHistory();
    const dispatch = useDispatch();

    const bars = useSelector(state => state.bars);
    const reports = useSelector(state => state.reports);
    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const [countryName, setCountryName] = useState("");
    const [countryFlag, setCountryFlag] = useState("");
    const [currencyName, setCurrencyName] = useState("");

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const {id} = useParams();

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetCountry = () => {
        if(reports.country != null) {
            axios.get(window.__APP__.api+"/platform/country/"+reports.country+"?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    setCountryName(response.data.country.name);
                    setCountryFlag(response.data.country.flag);
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    }
                }
            })
        } 
    }

    const GetCurrency = () => {
        if(reports.currency != null) {
            axios.get(window.__APP__.api+"/platform/currency/"+reports.currency+"?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    setCurrencyName(response.data.currency.name);
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    }
                }
            })
        } 
    }

    const clearCountry = () => {
        dispatch({
            type : "CLEAR_FR_TESTING"
        })
        dispatch({
            type : "SET_COUNTRY",
            data : {
                country : null
            }
        })
    }

    const clearCurrency = () => {
        dispatch({
            type : "SET_R_CURRENCY",
            data : {
                currency : null
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetCountry();
        }
    }, [platforms.platform.id, reports.country]);

    useEffect(() => {
        if(platforms.platform.id >= 0 && reports.currency != null) {
            GetCurrency();
        }
    }, [platforms.platform, reports.currency])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"reports"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Reports</h1>
                            {
                                reports.country != null && (
                                    <ExcelFile element={<Button className="head-btn" color={"primary"}><AiFillPrinter style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Export Report</Button>}>
                                        {
                                            reports.finalReport.regions.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.regions} name="Regions">
                                                    <ExcelColumn label="Region" value="name"/>
                                                    <ExcelColumn label="Partners" value="partners"/>
                                                    <ExcelColumn label="Shops" value="shops"/>
                                                    <ExcelColumn label="Players" value="players"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.partners.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.partners} name="Partners">
                                                    <ExcelColumn label="Partner" value="name"/>
                                                    <ExcelColumn label="Players" value="players"/>
                                                    <ExcelColumn label="Shops" value="shops"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.shops.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.shops} name="Shops">
                                                    <ExcelColumn label="Shop" value="name"/>
                                                    <ExcelColumn label="Players" value="players"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.players.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.players} name="Players">
                                                    <ExcelColumn label="Player" value="username"/>
                                                    <ExcelColumn label="City" value="city"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.providers.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.providers} name="Providers">
                                                    <ExcelColumn label="Provider" value="name"/>
                                                    <ExcelColumn label="Type" value="type"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                    <ExcelColumn label="Loyalties" value="pay"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.jackpots.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.jackpots} name="Jackpots">
                                                    <ExcelColumn label="Jackpot" value="jackpot"/>
                                                    <ExcelColumn label="Count" value="count"/>
                                                    <ExcelColumn label="Payout" value="total"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.cashbacks.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.cashbacks} name="Cashbacks">
                                                    <ExcelColumn label="Cashback Payout" value="payout"/>
                                                </ExcelSheet>
                                            )
                                        }
                                        {
                                            reports.finalReport.testing.length > 0 && (
                                                <ExcelSheet data={reports.finalReport.testing} name="Testing GGR">
                                                    <ExcelColumn label="Wins" value="wins"/>
                                                    <ExcelColumn label="Bets" value="bets"/>
                                                    <ExcelColumn label="GGR" value="ggr"/>
                                                </ExcelSheet>
                                            )
                                        }
                                    </ExcelFile>
                                )
                            }
                            
                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px", marginLeft : "15px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        {
                            (reports.country == null && reports.currency == null) ? (
                                <Panel style={{padding : "10px", marginTop : "15px"}} className="filters-panel">
                                    {/* <CountriesTable /> */}
                                    <CurrenciesTable />
                                </Panel>
                            ) : reports.currency != null ? (
                                <>
                                    <div className="language-info">
                                        <h4>{currencyName}</h4>
                                        <i onClick={clearCurrency} style={{marginLeft : "20px", fontSize : "13px", cursor : "pointer"}} className="fas fa-times" />
                                    </div>
                                    <DetailedCurrency />
                                </>
                            ) : (
                                <>
                                    <div className="language-info">
                                        <img style={{width : "25px"}} src={countryFlag} />
                                        <h4>{countryName}</h4>
                                        <i onClick={clearCountry} style={{marginLeft : "20px", fontSize : "13px", cursor : "pointer"}} className="fas fa-times" />
                                    </div>
                                    <Detailed />
                                </>
                            )
                        }
                    </div>
                </div>
            </div> 
            <Filters />
        </>
    )
}