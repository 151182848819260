import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import Transfer from "../../Transfer/Transfer";

import WithdrawalsTable from "./WithdrawalsTable";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function ListWithdrawals() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState([]);

    const GetCurrencies = () => {
        axios.get(window.__APP__.api+"/platform/currencies?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            var mcurrencies = [];

            response.data.currencies.map(currency => {
                mcurrencies.push({
                    label : currency.currency,
                    value : currency.id
                })
            })

            setCurrencies(mcurrencies);
        });
    }

    useEffect(() => {
        GetCurrencies();
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"paymentsmethods-withdrawals"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Withdraw Methods</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Withdraw Methods</h1>
                        </div>

                        <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <InputPicker value={currency} onChange={setCurrency} placeholder="Currency" style={{flexGrow : 2, flex : 1, marginLeft : "10px"}} data={currencies} />
                            </div>
                        </Panel>

                        <Panel style={{marginTop : "20px"}}>
                            <WithdrawalsTable currency={currency} />
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}