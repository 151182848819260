import {Dropdown, Button, Input} from "rsuite";
import { FaBolt } from "react-icons/fa";

import { useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function QuickSearch() {

    const history = useHistory();

    const platforms = useSelector(state => state.platforms);

    const [searchParams, setSearchParams] = useState({
        username : "",
        phone : "",
        email : ""
    })

    const [loading, setLoading] = useState({
        username : false,
        phone : false,
        email : false
    })
    const [error, setError] = useState({
        username : false,
        phone : false,
        email : false
    })

    const SearchPlayer = async (param) => {
        setError({
            username : false,
            phone : false,
            email : false
        });

        switch(param) {
            case "username":
                setLoading({
                    ...loading,
                    username : true
                })

                var response = await axios.post(window.__APP__.api+"/players/player/search/username?platform="+platforms.platform.id, {
                    username : searchParams.username
                }, {
                    headers : {
                      "Authorization" : "Bearer "+localStorage.getItem("jwt")
                    }
                })

                setLoading({
                    ...loading,
                    username : false
                })
                setSearchParams({
                    ...searchParams,
                    username : ""
                })

                if(response.data.error == null) {
                    history.push("/online/player/"+response.data.id);
                } else {
                    setError({
                        ...error,
                        username : true
                    })
                }
            break;
            case "phone":
                setLoading({
                    ...loading,
                    phone : true
                })

                var response = await axios.post(window.__APP__.api+"/players/player/search/phone?platform="+platforms.platform.id, {
                    phone : searchParams.phone
                }, {
                    headers : {
                      "Authorization" : "Bearer "+localStorage.getItem("jwt")
                    }
                })

                setLoading({
                    ...loading,
                    phone : false
                })
                setSearchParams({
                    ...searchParams,
                    phone : ""
                })

                if(response.data.error == null) {
                    history.push("/online/player/"+response.data.id);
                } else {
                    setError({
                        ...error,
                        phone : true
                    })
                }
            break;
            case "email":
                setLoading({
                    ...loading,
                    email : true
                })

                var response = await axios.post(window.__APP__.api+"/players/player/search/email?platform="+platforms.platform.id, {
                    email : searchParams.email
                }, {
                    headers : {
                      "Authorization" : "Bearer "+localStorage.getItem("jwt")
                    }
                })

                setLoading({
                    ...loading,
                    email : false
                })
                setSearchParams({
                    ...searchParams,
                    email : ""
                })

                if(response.data.error == null) {
                    history.push("/online/player/"+response.data.id);
                } else {
                    setError({
                        ...error,
                        email : true
                    })
                }
            break;
        }
    }

    return (
        <>
        
            <Dropdown className="user-side-notification" icon={<FaBolt style={{fontSize : "18px"}} />} placement="bottomEnd" noCaret>
                <div className="search-dropdown-widen">
                    <h2>Search Players</h2>
                    <div className="search-items">
                        <div className="dropdown-search-box">
                            <Input className={error["username"] ? "error-input" : ""} onChange={v => setSearchParams({ ...searchParams, username : v})} placeholder="Username"></Input>
                            <Button loading={loading["username"]} onClick={() => SearchPlayer("username")} color="primary"><i className="fas fa-search"></i></Button>
                        </div>
                        <div className="dropdown-search-box">
                            <Input className={error["phone"] ? "error-input" : ""} onChange={v => setSearchParams({ ...searchParams, phone : v})} placeholder="Phone"></Input>
                            <Button loading={loading["phone"]} onClick={() => SearchPlayer("phone")} color="primary"><i className="fas fa-search"></i></Button>
                        </div>
                        <div className="dropdown-search-box">
                            <Input className={error["email"] ? "error-input" : ""} onChange={v => setSearchParams({ ...searchParams, email : v})} placeholder="Email"></Input>
                            <Button loading={loading["email"]} onClick={() => SearchPlayer("email")} color="primary"><i className="fas fa-search"></i></Button>
                        </div>
                    </div>
                </div>
            </Dropdown>
        </>
    )
}