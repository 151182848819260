import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";

export default function ShopsTable() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();
   
    const [players, setPlayers] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);


    const selectCountry = (country) => {
        dispatch({
            type : "SET_COUNTRY",
            data : {
                country : country
            }
        })
    }

    const GetPlayers = () => {
        axios.get(window.__APP__.api+"/reports/players/"+reports.region+"/"+reports.partner+"/"+reports.shop+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPlayers(response.data.players);
                setPages(response.data.pagination.pages);
                dispatch({
                    type : "SET_FR_PLAYERS",
                    data : {
                        players : response.data.players
                    }
                })
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPlayers();
        }
    }, [platforms.platform, limit, page]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={players}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Username</Table.HeaderCell>
                    <Table.Cell dataKey="username" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>City</Table.HeaderCell>
                    <Table.Cell dataKey="city" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}