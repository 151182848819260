import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Panel } from 'rsuite';

import { Button, Input, InputPicker, Form, Message } from "rsuite";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaFilter, FaRegQuestionCircle } from "react-icons/fa";

import Rulesets from "./Rulesets";

import { useHistory } from "react-router-dom";

import axios from "axios";

export default function EPCreateRuleset() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [sports, setSports] = useState([]);
    const [markets, setMarkets] = useState([]);

    const [activeKey, setActiveKey] = useState("sp-rulesets");
    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const bettypes = [
        {
            label: "Prematch",
            value: 1
        },
        {
            label: "Live",
            value: 2
        },
        {
            label: "Prematch/Live",
            value: 3
        }
    ]

    const [form, setForm] = useState({
        name: "",
        sport: "",
        market: "",
        advantage: "",
        bettype: ""
    })

    const FetchSports = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/sports?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setSports(response.data.sports);
        }
    }
    const FetchMarkets = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/marketsdesc?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setMarkets(response.data.markets);
        }
    }

    const CreateRuleset = async () => {
        setLoading(true);

        const response = await axios.post(window.__APP__.api + "/sportsbook/CreateEPRuleset?platform=" + platforms.platform.id, form, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if (response.data.Error == null) {
            history.push("/sportsbook/earlypayout");
        } else {
            setFormMessage(response.data.Error);
            setFormMessageType("error");
        }
    }

    useEffect(() => {
        FetchSports();
        FetchMarkets();
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"sports-earlypayout"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Sportsbook Early Payout</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Create Early Payout Ruleset</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            {
                                formMessage != "" && (
                                    <Message showIcon type={formMessageType}>
                                        {formMessage}
                                    </Message>
                                )
                            }

                            <Form className="form-box">
                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Rule Name</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, name: value })} value={form.name} name="rulename" placeholder="Rule Name" />
                                    </Form.Group>
                                </div>

                                <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Sport</Form.ControlLabel>
                                        <InputPicker onChange={value => setForm({ ...form, sport: value })} value={form.sport} placeholder="Sport" data={sports} />
                                    </Form.Group>
                                </div>

                                <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Market</Form.ControlLabel>
                                        <InputPicker onChange={value => setForm({ ...form, market: value })} value={form.market} placeholder="Market" data={markets} />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Advantage</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, advantage: value })} value={form.advantage} name="advantage" placeholder="Advantage" />
                                    </Form.Group>
                                </div>

                                <div className="form-row form-row-sport" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="rulename">
                                        <Form.ControlLabel>Bet Type</Form.ControlLabel>
                                        <InputPicker onChange={value => setForm({ ...form, bettype: value })} value={form.bettype} placeholder="Bet Type" data={bettypes} />
                                    </Form.Group>
                                </div>

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button onClick={CreateRuleset} loading={loading} type="button" style={{ height: "100%" }} color="primary">Create Ruleset</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div>
        </>
    )
}