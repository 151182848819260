import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Badge } from 'rsuite';
import { Toggle, Modal, Button } from 'rsuite';

import axios from "axios";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import { Radio, RadioGroup } from 'rsuite';
import moment from "moment/moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleApproveModal = (id) => {
        dispatch({
            type : "SET_WITHDRAWAL_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_WITHDRAWALS_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleCancelModal = (id) => {
        dispatch({
            type : "SET_WITHDRAWAL_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_CANCEL_WITHDRAWALS_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <Table.Cell {...props} className="link-group">
            {
                rowData['status'] == 2 ? (
                    <>
                        <a style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Approve</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleApproveModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-check"></i></IconButton>
                            </Whisper>
                        </a>
                        <a style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Cancel</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleCancelModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-times"></i></IconButton>
                            </Whisper>
                        </a>
                    </>
                ) : (
                    <a style={{marginRight : "10px"}}>
                        -
                    </a>
                )
            }
        </Table.Cell>
    );
};

const ApproveModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const ApproveTransaction = () => {

        setLoading(true);

        axios.post(window.__APP__.api+"/withdrawals/approve/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false)

            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Approve Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to approve this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ApproveTransaction} color="blue" appearance="primary">
                    Approve
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const CancelModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_CANCEL_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const CancelWithdrawal = () => {
        axios.post(window.__APP__.api+"/withdrawals/cancel/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Cancel Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to cancel this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={CancelWithdrawal} color="red" appearance="primary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function WithdrawalsTable(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const filters = useSelector(state => state.filters);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {type} = props;

    const GetHistory = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";
        
        axios.get(window.__APP__.api+"/withdrawals/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+"&type="+type+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.transactions);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetHistory();
    }, [platforms.platform, activePage, activeLimit, filters.dateRange, modals.withdrawalCancelModal, modals.withdrawalsModal, type]);
    

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column align="center">
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column minWidth={200} flexGrow={1} align="center">
                    <Table.HeaderCell>Player</Table.HeaderCell>
                    <Table.Cell>{ data => <Link to={"/online/player/"+data.user_id}>{data.player}</Link> }</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={100} flexGrow={1} align="center">
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.Cell dataKey="amount" />
                </Table.Column>  

                <Table.Column minWidth={250} flexGrow={1} align="center">
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column> 

                <Table.Column minWidth={100} flexGrow={1} align="center">
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.Cell>{ data => <>{data.status == 2 ? <Badge color="blue" content="Pending" /> : data.status == 1 ? <Badge color="green" content="Processed" /> :  data.status == 3 ? <Badge color="green" content="Approved Manually" /> :  data.status == 0 ? <Badge color="red" content="Canceled" /> : <></>}</> }</Table.Cell>
                </Table.Column>      

                <Table.Column minWidth={250} flexGrow={1} align="center">
                    <Table.HeaderCell>Approval Date</Table.HeaderCell>
                    <Table.Cell>{data => <>{data.validated != null ? moment(data.validated).format("DD/MM/YYYY HH:mm:ss") : "-"}</>}</Table.Cell>
                </Table.Column>        
                
                <Table.Column minWidth={200} flexGrow={1} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    // onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.withdrawalsModal && (
                    <ApproveModal show={true} />
                )
            }

            {
                modals.withdrawalCancelModal && (
                    <CancelModal show={true} />
                )
            }
        </>
    )
}