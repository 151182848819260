import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle, Modal, Button, Radio } from 'rsuite';

import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../Transfer/Transfer";

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleShopDeleteModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_SHOP_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleShopToggleModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_SHOP_STATUS",
            data : {
                status : rowData['status']
            }
        })
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }
    const toggleTransferModal = (id) => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "shop-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }
    
    return (
      <Table.Cell {...props} className="link-group">
        <a onClick={() => toggleTransferModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Transfer</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-exchange-alt"></i></IconButton>
            </Whisper>
        </a>
        <Link to={"/voucher/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link to={"/voucher/edit/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-user-edit"></i></IconButton>
            </Whisper>
        </Link>
        <a onClick={() => toggleShopDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
            </Whisper>
        </a>
        <Toggle color="red" appearance="primary" checked={!rowData['status']} onChange={() => toggleShopToggleModal(rowData['id'])} checked={!rowData['status']} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeletePartner = () => {
        axios.post(window.__APP__.api+"/vouchers/delete/"+modals.shopId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Voucher #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this voucher shop ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeletePartner} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(0);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/vouchers/toggle/"+modals.shopId+"?platform="+platforms.platform.id, {type : 0}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.shopStatus == 1 ? "Disable" : "Enable"} Voucher Shop #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Voucher Shop</h4>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.shopStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function VouchersTable(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {shop} = props;

    // const users = [
    //     {
    //       "id": 1,
    //       "name" : "Yessine Taktak",
    //       "username" : "yessine001",
    //       "balance" : "5000.00",
    //       "shops" : "20",
    //       "players" : "1200",
    //       "uid" : "11125324",
    //       "date" : "2021-09-30",
    //       "phone" : "+21695447119"
    //     },
    //     {
    //         "id": 1,
    //         "name" : "Yessine Taktak",
    //         "username" : "yessine001",
    //         "balance" : "5000.00",
    //         "shops" : "20",
    //         "players" : "1200",
    //         "uid" : "11125324",
    //         "date" : "2021-09-30",
    //         "phone" : "+21695447119"
    //       }
    //   ];

    const GetShops = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/vouchers/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+"&shop="+shop, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.shops);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetShops();
    }, [platforms.platform, activePage, activeLimit, modals.shopDeleteModal, modals.shopToggleModal, shop]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Shop Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Username</Table.HeaderCell>
                    <Table.Cell>{rowData => <Link to={"/voucher/"+rowData.id}>{rowData.username}</Link>}</Table.Cell>
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Phone Number</Table.HeaderCell>
                    <Table.Cell dataKey="phone" />
                </Table.Column>

                <Table.Column width={120}>
                    <Table.HeaderCell>Balance</Table.HeaderCell>
                    <Table.Cell dataKey="balance" />
                </Table.Column>

                <Table.Column width={110}>
                    <Table.HeaderCell>Partner</Table.HeaderCell>
                    <Table.Cell dataKey="partner" />
                </Table.Column>

                <Table.Column width={140}>
                    <Table.HeaderCell>Date Created</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.shopDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
            {
                modals.shopToggleModal && (
                    <ToggleModal show={true} />
                )
            }
            {
                modals.transferModal && (
                    <Transfer />
                )
            }
        </>
    )
}