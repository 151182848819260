import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import SortableList from "../Sortable/SortableList";

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {Button, Modal} from "rsuite";

import { arrayMoveImmutable } from 'array-move';

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const userx = useSelector(state => state.user);

    const toggleBannerDeleteModal = (id) => {
        dispatch({
            type : "SET_BANNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_BANNER_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleBannerOrderModal = (id) => {
        dispatch({
            type : "SET_BANNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_BANNER_ORDER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            rowData["locked"] ? (
                <>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Banner reserved. Upgrade your package for more actions.</Tooltip>
                        }
                    >
                        <i className="fas fa-lock"></i>
                    </Whisper> 
                </>
            ) : (
                <>
                {
                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("banners_edit"))) >= 0 ? (
                        <Link to={"/cms/banner/edit/"+rowData['id']} style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Edit</Tooltip>
                                }
                            >
                                <IconButton className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
                            </Whisper>
                        </Link>
                    ) : (
                        <></>
                    )
                }
                {
                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("banners_set_order"))) >= 0 ? (
                        <a onClick={() => toggleBannerOrderModal(rowData['id'])} style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Order</Tooltip>
                                }
                            >
                                <IconButton className="table-button no-icon"><i className="fas fa-list"></i></IconButton>
                            </Whisper>
                        </a>
                    ) : (
                        <></>
                    )
                }
                {
                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("banners_delete"))) >= 0 ? (
                        <a onClick={() => toggleBannerDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Delete</Tooltip>
                                }
                            >
                                <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
                            </Whisper>
                        </a>
                    ) : (
                        <></>
                    )
                }
                </> 
            )
        }
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_BANNER_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeleteBanner = () => {
        axios.post(window.__APP__.api+"/cms/banners/delete/"+modals.bannerId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Banner #{modals.bannerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this banner ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeleteBanner} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const OrderModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [items, setItems] = useState([])

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_BANNER_ORDER_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const OrderBanners = async () => {

        var ItemsObject = {};

        for(var i=0; i<items.length; i++) {
            var item = items[i];

            ItemsObject[item.id] = i;
        }

        var response = await axios.post(window.__APP__.api+"/cms/banners/order?platform="+platforms.platform.id, {
            order : ItemsObject
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            toggleDocumentsModal()
        }
    }

    const OrderedList = async () => {
        var response = await axios.get(window.__APP__.api+"/cms/banners/orderedlist?id="+modals.bannerId+"&platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {

            var banners = response.data.banners;

            banners.sort((a, b) => {
                return a.order-b.order;
            })

            setItems(banners);
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(prevItem => (arrayMoveImmutable(prevItem, oldIndex, newIndex)));
    };

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            OrderedList()
        }
    }, [platforms])

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Banners Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SortableList helperClass={"draggable-class"} items={items} onSortEnd={onSortEnd} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={OrderBanners} color="blue" appearance="primary">
                    Set Order
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function BannersTable() {
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);


    const GetBanners = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/cms/banners/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.banners);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetBanners();
    }, [platforms.platform, activePage, activeLimit, modals.bannerDeleteModal]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150} align={"center"}>
                    <Table.HeaderCell>Banner</Table.HeaderCell>
                    <Table.Cell>{dataRow => <><img style={{width: "100%", position : "relative", top : "-10px"}} src={dataRow.thumbnail} /></>}</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.Cell dataKey="title" />
                </Table.Column>

                <Table.Column width={150} align={"center"}>
                    <Table.HeaderCell>Page</Table.HeaderCell>
                    <Table.Cell dataKey="page" />
                </Table.Column>

                <Table.Column width={150} align={"center"}>
                    <Table.HeaderCell>Resolution</Table.HeaderCell>
                    <Table.Cell>{data => data.resolution == 0 ? "Responsive" : data.resolution == 1 ? "Mobile" : "Desktop"}</Table.Cell>
                </Table.Column>

                <Table.Column width={170} align={"center"}>
                    <Table.HeaderCell>Date Added</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.bannerDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
            {
                modals.bannerOrderModal && (
                    <OrderModal show={true}></OrderModal>
                )
            }
        </>
    )
}