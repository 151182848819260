import {useState, useEffect} from "react";

import "./Financials.css";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Loader, Table } from 'rsuite';

import {Button, InputPicker, Whisper, IconButton, Tooltip} from "rsuite";

import {AiOutlinePlusCircle, AiFillPrinter} from "react-icons/ai";
import {FaFilter} from "react-icons/fa";

import {useHistory, useParams, Link} from "react-router-dom";
import Filters from "../Filters/Filters";


import axios from "axios";


import ReactExport from "react-export-excel";


export default function Financials() {
    const history = useHistory();
    const dispatch = useDispatch();

    const bars = useSelector(state => state.bars);
    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [boxData, setBoxData] = useState({});
    const [shops, setShops] = useState([]);

    const {id} = useParams();

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetBoxData = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";
            
        axios.get(window.__APP__.api+"/reports/financials?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.boxes != undefined && response.data.shops != undefined && response.data.error == null) {
                setBoxData(response.data.boxes);
                var allShops = response.data.shops.sort((a, b) => b.profit-a.profit)
                setShops(allShops);
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetBoxData();
        }
    }, [platforms, filters.dateRange]);


    const openPopup = (id) => {
        if(filters.dateRange.length > 0)
        var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
    else
        var dateQuery = "&dateFrom=&dateTo=";

    window.open("/shop/report/print/"+id+"?p=1"+dateQuery,'popUpWindow',"height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes");
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"financials"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Financials</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Financial Reports</h1>

                            {
                                shops.length > 0 && (
                                    <ExcelFile element={<Button className="head-btn" color={"primary"}><AiFillPrinter style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Export Report</Button>}>
                                        <ExcelSheet data={shops} name="Regions">
                                            <ExcelColumn label="Id" value="id"/>
                                            <ExcelColumn label="Username" value="username"/>
                                            <ExcelColumn label="Deposit" value="deposit"/>
                                            <ExcelColumn label="Withdraw" value="withdraw"/>
                                            <ExcelColumn label="Profit" value="profit"/>
                                            <ExcelColumn label="Return" value="return"/>
                                        </ExcelSheet>
                                    </ExcelFile>
                                )
                            }
                            
                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px", marginLeft : "15px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <div className="reports-body">
                            <div className="dashboard-overview-boxes">
                                <div className="dashboard-overview-box">
                                    <div style={{flex : "1 1 auto"}}>
                                        <h2>{boxData.deposit != undefined ? boxData.deposit.total : <Loader />}</h2>
                                        <h1>Total Deposit</h1>
                                    </div>
                                    <div style={{flex : "1 1 auto", position : "relative"}}>
                                        <div className="icon balance-icon"></div>
                                    </div>
                                </div>

                                <div className="dashboard-overview-box">
                                    <div style={{flex : "1 1 auto"}}>
                                        <h2>{boxData.withdraw != undefined ? boxData.withdraw.total : <Loader />}</h2>
                                        <h1>Total Withdraw</h1>
                                    </div>
                                    <div style={{flex : "1 1 auto", position : "relative"}}>
                                        <div className="icon balance-icon"></div>
                                    </div>
                                </div>

                                <div className="dashboard-overview-box">
                                    <div style={{flex : "1 1 auto"}}>
                                        <h2>{boxData.profit != undefined ? boxData.profit.total : <Loader />}</h2>
                                        <h1>Profit</h1>
                                    </div>
                                    <div style={{flex : "1 1 auto", position : "relative"}}>
                                        <div className="icon profit-icon"></div>
                                    </div>
                                </div>

                                <div className="dashboard-overview-box">
                                    <div style={{flex : "1 1 auto"}}>
                                        <h2>{boxData.return != undefined ? boxData.return.total : <Loader />}</h2>
                                        <h1>Return</h1>
                                    </div>
                                    <div style={{flex : "1 1 auto", position : "relative"}}>
                                        <div className="icon balance-icon"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Panel className="report-panel" header="Financials" style={{marginTop : "10px"}} bordered>
                            <Table
                                // height={600}
                                autoHeight
                                data={shops}
                                // sortColumn={sortColumn}
                                // sortType={sortType}
                                // onSortColumn={handleSortColumn}
                                // loading={loading}
                            >
                                <Table.Column width={50} align="center" fixed>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell>{data => data.id}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={100} align="center">
                                    <Table.HeaderCell>Username</Table.HeaderCell>
                                    <Table.Cell>{data => <Link to={"/shop/"+data.id}>{data.username}</Link>}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Deposit</Table.HeaderCell>
                                    <Table.Cell>{data => data.deposit+" TND"}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Withdraw</Table.HeaderCell>
                                    <Table.Cell>{data => data.withdraw+" TND"}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Porift</Table.HeaderCell>
                                    <Table.Cell>{data => data.profit+" TND"}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Return</Table.HeaderCell>
                                    <Table.Cell>{data => data.return+" TND"}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={150} align="center">
                                    <Table.HeaderCell>Debt</Table.HeaderCell>
                                    <Table.Cell>{data => data.debt+" TND"}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={150} align="center">
                                    <Table.HeaderCell>Restourne</Table.HeaderCell>
                                    <Table.Cell>{data => <Button size="xs" onClick={() => openPopup(data.id)} style={{float : "right", marginRight : "10px"}} appearance="primary"><i className="fas fa-print" style={{marginRight : "5px"}}></i> Print</Button>}</Table.Cell>
                                </Table.Column>
                            </Table>
                        </Panel>
                    </div>
                </div>
            </div> 
            <Filters />
        </>
    )
}