import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button } from 'rsuite';

import { Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function CreateAgent() {
    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const bars = useSelector(state => state.bars);
  
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [partners, setPartners] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const model = Schema.Model({
      firstname: Schema.Types.StringType().isRequired(),
      lastname : Schema.Types.StringType().isRequired(),
      username : Schema.Types.StringType().isRequired(),
      email: Schema.Types.StringType().isEmail().isRequired(),
      password : Schema.Types.StringType().isRequired(),
      repassword : Schema.Types.StringType().isRequired(),
      country : Schema.Types.StringType().isRequired(),
      city : Schema.Types.StringType().isRequired(),
      cin : Schema.Types.StringType(),
      partner : Schema.Types.StringType().isRequired(),
      passport : Schema.Types.StringType(),
      pphone  : Schema.Types.StringType().isRequired(),
      wphone : Schema.Types.StringType()
    });

    const [formError, setFormError] = useState({})

    const [form, setForm] = useState({
      firstname : "",
      lastname : "",
      username : "",
      email : "",
      country : "",
      city : "",
      cin : "",
      passport : "",
      cinFile : "",
      passportFile : "",
      pphone : "",
      wphone : "",
      password : "",
      repassword : "",
      partner : ""
    });

    const [errors, setErrors] = useState({
      country : false,
      city : false,
      email : false,
      cinFile : false,
      passportFile : false,
      password : false,
      repassword : false,
      partner : false
    })

    const GetCountries = () => {
      setCountries([]);
      axios.get(window.__APP__.api+"/platform/countries?platform="+platforms.platform.id, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setCountries(response.data.countries);
        } else {
          if(response.data.error == "Unauthorized") {
              dispatch({
                  type : "SET_LOGGED",
                  data : {
                      logged : false
                  }
              })
              localStorage.removeItem("jwt");
              history.push("/");
          }
        }
      });
    }

    const GetCities = (country) => {
      setCities([]);
      axios.get(window.__APP__.api+"/platform/cities?platform="+platforms.platform.id+"&country="+country, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setCities(response.data.cities);
        } else {
          if(response.data.error == "Unauthorized") {
              dispatch({
                  type : "SET_LOGGED",
                  data : {
                      logged : false
                  }
              })
              localStorage.removeItem("jwt");
              history.push("/");
          }
        }
      });
    }

    const GetPartners = () => {
      setPartners([]);

      axios.get(window.__APP__.api+"/platform/partners?platform="+platforms.platform.id, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setPartners(response.data.partners);
        } else {
          if(response.data.error == "Unauthorized") {
              dispatch({
                  type : "SET_LOGGED",
                  data : {
                      logged : false
                  }
              })
              localStorage.removeItem("jwt");
              history.push("/");
          }
        }
      });
    }

    useEffect(() => {
      if(platforms.platform.id >= 0) {
        GetCountries();
        GetPartners();
      }
    }, [platforms.platform]);

    useEffect(() => {
      if(form.country != "")
        GetCities(form.country)
    }, [form.country]);


    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const SubmitForm = () => {
      if(form.country == "" || form.city == "" || form.firstname == "" || form.lastname == "" || form.username == ""  || form.email == "" || form.pphone == "") {
        // console.log(form)
        var errorObj = errors;

        if(form.country == "") {
          errorObj.country = true;
        } else {
          errorObj.country = false;
        }

        if(form.city == "") {
          errorObj.city = true;
        } else {
          errorObj.city = false;
        }

        // if(form.cinFile == "") {
        //   errorObj.cinFile = true;
        // } else {
        //   errorObj.cinFile = false;
        // }

        // if(form.passportFile == "") {
        //   errorObj.passportFile = true;
        // } else {
        //   errorObj.passportFile = false;
        // }

        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else if(!validateEmail(form.email)) {
        var errorObj = errors;
        if(form.email == "") {
          errorObj.email = true;
        } else {
          errorObj.email = false;
        }
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else if(form.password != form.repassword) {
        var errorObj = errors;
        errorObj.password = true;
        errorObj.repassword = true;
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else {
        setErrors({
          country : false,
          city : false,
          email : false,
          cinFile : false,
          passportFile : false,
          password : false,
          repassword : false
        });

        axios.post(window.__APP__.api+"/agents/create?platform="+platforms.platform.id, form, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem("jwt")
          }
        }).then(response => {
          if(response.data.error == null) {
            setFormMessageType("");
            setFormMessage("");

            toaster.push(<Notification type="info" header="Notification" closable>Shop Created Successfully</Notification>, {placement: "topEnd"});

            history.push("/agents");
          } else {
            switch(response.data.error) {
              case "User Already Exists":
                setFormMessage("User Already Exists");
                setFormMessageType("error");
              break;
              case "City Undefined":
              case "Country Undefined":
                setFormMessage("Location Undefined/Disabled");
                setFormMessageType("error");
              break;
              case "Platform Not Found":
              case "Unexpected Error":
                setFormMessage("Unexpected Error");
                setFormMessageType("error");
              break;
              case "Unauthorized":
                dispatch({
                    type : "SET_LOGGED",
                    data : {
                        logged : false
                    }
                })
                localStorage.removeItem("jwt");
                history.push("/");
              break;
            }
          }
        })
      }
    }

    const checkUploadFile = (details, type) => {
      if(details.error == null) {
        var path = "/documents/agents/"+details.file.name;

        switch(type) {
          case "cin":
            setErrors({...errors, cinFile : false});
            setForm({...form, cinFile : path});
          break;
          case "passport":
            setErrors({...errors, passportFile : false});
            setForm({...form, passportFile : path});
          break;
        }
      } else {
        switch(type) {
          case "cin":
            setErrors({...errors, cinFile : true});
          break;
          case "passport":
            setErrors({...errors, passportFile : true});
          break;
        }
      }
    }

    const toggleFileButton = (details, type) => {
      switch(type) {
        case "cin":
          if(details.length == 0) {
            setForm({...form, cinFile : ""});
          }
        break;
        case "passport":
          if(details.length == 0) {
            setForm({...form, passportFile : ""});
          }
        break;
      }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"agents"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/agents">Shop</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Agent</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Create Agent</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Form onSubmit={SubmitForm} model={model} className="form-box">
                                {
                                  formMessage != "" && (
                                    <Message showIcon type="error">{formMessage}</Message>
                                  )
                                }

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="firstname">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({...form, firstname : value})} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({...form, lastname : value})} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="username">
                                        <Form.ControlLabel>Username</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({...form, username : value})} name="username" placeholder="Username" />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({...form, email : value})} name="email" placeholder="Email" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="country">
                                        <Form.ControlLabel>Country</Form.ControlLabel>
                                        <InputPicker className={errors.country ? "errored" : ""} name="country" onChange={(value) => setForm({...form, country : value})} size="md" placeholder="Country" style={{width : "100%"}} data={countries} />
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.ControlLabel>City</Form.ControlLabel>
                                        <InputPicker className={errors.city ? "errored" : ""} name="city" onChange={(value) => setForm({...form, city : value})} size="md" placeholder="City" style={{width : "100%"}} data={cities} />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="pphone">
                                        <Form.ControlLabel>Personal Phone</Form.ControlLabel>
                                        <Form.Control type="tel" onChange={value => setForm({...form, pphone : value})} type="tel" onChange={value => setForm({...form, pphone : value})} name="pphone" placeholder="Personal Phone" />
                                    </Form.Group>
                                    <Form.Group controlId="wphone">
                                        <Form.ControlLabel>Work Phone</Form.ControlLabel>
                                        <Form.Control type="tel" onChange={value => setForm({...form, wphone : value})} type="tel" onChange={value => setForm({...form, wphone : value})} name="wphone" placeholder="Work Phone" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="partner">
                                        <Form.ControlLabel>Partner</Form.ControlLabel>
                                        <InputPicker className={errors.partner ? "errored" : ""} name="partner" onChange={(value) => setForm({...form, partner : value})} size="md" placeholder="Partner" style={{width : "100%"}} data={partners} />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} onChange={value => setForm({...form, password : value})} type="password" name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="repassword">
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} onChange={value => setForm({...form, repassword : value})} type="password" name="repassword" placeholder="Confirm Password" />
                                    </Form.Group>
                                </div>

                                <div className="id-group">
                                    <div className="documents">
                                        <Form.Group controlId="cin">
                                            <Form.ControlLabel>CIN</Form.ControlLabel>
                                            <div className="input-box">
                                                <Form.Control onChange={value => setForm({...form, cin : value})} name="cin" className="document-input" style={{marginRight : "25px"}} />
                                                <small style={{display : "block", height : "10px", color : errors.cinFile ? "red" : ""}}>File types allowed : png,jpg,docx,pdf</small>
                                                <Uploader onChange={(details) => toggleFileButton(details, "cin")} disabled={form.cinFile != ""} onSuccess={(response) => checkUploadFile(response, "cin")} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "shop"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} style={{marginTop : "15px", float : "left", maxWidth : "200px"}} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="passport">
                                            <Form.ControlLabel>Passport</Form.ControlLabel>
                                            <div className="input-box">
                                                <Form.Control  onChange={value => setForm({...form, passport : value})} name="passport" className="document-input" style={{marginRight : "25px"}} />
                                                <small style={{display : "block", height : "10px", color : errors.passportFile ? "red" : ""}}>File types allowed : png,jpg,docx,pdf</small>
                                                <Uploader onChange={(details) => toggleFileButton(details, "passport")} disabled={form.passportFile != ""} onSuccess={(response) => checkUploadFile(response, "passport")} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "shop"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} style={{marginTop : "15px", float : "left", maxWidth : "200px"}} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Create Agent</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}