import React, {useState, useEffect} from "react";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip } from "rsuite";
import {FaFilter} from "react-icons/fa";

import {useDispatch, useSelector} from "react-redux";

import { useHistory } from "react-router";

import { Pie, PolarArea } from 'react-chartjs-2';

import Filters from "../Filters/Filters";

import axios from "axios";

export default function Shops() {

    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const [shopCountries, setShopCountries] = useState([]);
    const [shopNumbers, setShopNumbers] = useState([]);
    const [shopRegions, setShopRegions] = useState([]);
    const [regionNumbers, setRegionNumbers] = useState([]);
    const [ggrShops, setGGRShops] = useState([]);
    const [ggrNumbers, setGGRNumbers] = useState([]);
    const [shopPlayers, setShopPlayers] = useState([]);
    const [shopPlayersN, setShopPlayersN] = useState([]);

    const history = useHistory();

    const bars = useSelector(state => state.bars);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetShopsCountry = () => {
        axios.get(window.__APP__.api+"/statistics/shops/country?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShopCountries(response.data.data.labels);
                setShopNumbers(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }   
    
    const GetShopRegions = () => {
        axios.get(window.__APP__.api+"/statistics/shops/cities?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShopRegions(response.data.data.labels);
                setRegionNumbers(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    } 
    
    const GetShopGGR = () => {
        axios.get(window.__APP__.api+"/statistics/shops/ggr?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setGGRShops(response.data.data.labels);
                setGGRNumbers(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    } 

    const GetShopPlayers = () => {
        axios.get(window.__APP__.api+"/statistics/shops/players?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShopPlayers(response.data.data.labels);
                setShopPlayersN(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    } 

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetShopsCountry();
            GetShopRegions();
            GetShopPlayers();
        }
    }, [platforms.platform]);

    useEffect(() => {
        GetShopGGR();
    }, [platforms.platform]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"stats-shops"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>
                    
                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Statistics</Breadcrumb.Item>
                            <Breadcrumb.Item active>Shops</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Shops Statistics</h1>

                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <div className="stats-boxes">
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Shops By Country</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={{
                                        labels: shopCountries,
                                        datasets: [
                                          {
                                            label: 'Countries',
                                            data: shopNumbers,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Top Regions</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={{
                                        labels: shopRegions,
                                        datasets: [
                                          {
                                            label: 'Countries',
                                            data: regionNumbers,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Shops By GGR</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={{
                                        labels: ggrShops,
                                        datasets: [
                                          {
                                            label: 'Countries',
                                            data: ggrNumbers,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Shops By Players</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={{
                                        labels: shopPlayers,
                                        datasets: [
                                          {
                                            label: 'Countries',
                                            data: shopPlayersN,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <Filters />
        </>
    )
}