import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useSelector, useDispatch} from "react-redux";

import axios from "axios";
import { useEffect, useState } from "react";

export default function CurrenciesTable() {
    const reports = useSelector(state => state.reports);
    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);
    
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [dataTable, setDataTable] = useState([]);

    const dispatch = useDispatch();
   
    const selectCurrency = (currency) => {
        dispatch({
            type : "SET_R_CURRENCY",
            data : {
                currency : currency
            }
        })
    }

    const GetCurrencies = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api+"/reports/currencies?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setDataTable(response.data.currencies);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id) {
            GetCurrencies();
        }
    }, [platforms.platform, page, limit, filters.dateRange]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={dataTable}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column minWidth={120} align="center" flexGrow={1}>
                    <Table.HeaderCell>Currency</Table.HeaderCell>
                    <Table.Cell>{data => <span onClick={() => selectCurrency(data.id)} style={{cursor : "pointer", color : "#3498ff"}}>{data.currency}</span>}</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={120} align="center" flexGrow={1}>
                    <Table.HeaderCell>Total Bets</Table.HeaderCell>
                    <Table.Cell dataKey="bets" />
                </Table.Column>

                <Table.Column minWidth={120} align="center" flexGrow={1}>
                    <Table.HeaderCell>Total Wins</Table.HeaderCell>
                    <Table.Cell dataKey="wins" />
                </Table.Column>

                <Table.Column minWidth={120} align="center" flexGrow={1}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>

                <Table.Column minWidth={120} align="center" flexGrow={1}>
                    <Table.HeaderCell>Players</Table.HeaderCell>
                    <Table.Cell dataKey="players" />
                </Table.Column>
            </Table>
        </>
    )
}