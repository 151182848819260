import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Modal, Button } from 'rsuite';
import { Toggle } from 'rsuite';

import {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const userx = useSelector(state => state.user);

    const togglePageDeleteModal = (id) => {
        dispatch({
            type : "SET_PROMOTION_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PROMOTION_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("promotions_edit"))) >= 0 ? (
                <Link to={"/cms/promotions/edit/"+rowData['id']} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Edit</Tooltip>
                        }
                    >
                        <IconButton className="table-button edit-file-icon"></IconButton>
                    </Whisper>
                </Link>
            ) : (
                <></>
            )
        }
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("promotions_delete"))) >= 0 ? (
                <a onClick={() => togglePageDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Delete</Tooltip>
                        }
                    >
                        <IconButton className="table-button deleted-document-icon"></IconButton>
                    </Whisper>
                </a>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PROMOTION_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeletePage = () => {
        axios.post(window.__APP__.api+"/cms/promotions/delete/"+modals.promotionId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Promotion #{modals.promotionId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this promotion ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeletePage} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function PromotionsTable() {
    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState([]);

    const GetPages = () => {
        axios.get(window.__APP__.api+"/cms/promotions/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPages(response.data.pages)
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPages();
        }
    }, [platforms.platform, modals.promotionsDeleteModal]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={pages}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={100} align={"center"}>
                    <Table.HeaderCell>Thumbnail</Table.HeaderCell>
                    <Table.Cell>{dataRow => <><img style={{width: "50px", position : "relative", top : "-3px"}} src={dataRow.thumbnail} /></>}</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Promotion</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.Cell dataKey="title" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Language</Table.HeaderCell>
                    <Table.Cell dataKey="language" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Date Added</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            {
                modals.promotionsDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
        </>
    )
}