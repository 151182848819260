import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Badge, Input, Checkbox} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    
    const dispatch = useDispatch();
    
    const ShowCashierTransactions = () => {
        dispatch({
            type : "SET_CASHIER_TRANSACTIONS_MODAL",
            data : {
                modal : true,
                id : rowData["id"]
            }
        })
    }
    const ShowCashierTransaction = () => {
        dispatch({
            type : "SET_CASHIER_TRANSACTION_MODAL",
            data : {
                modal : true,
                id : rowData["id"]
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            rowData["status"] == 0 && (
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Transaction</Tooltip>
                    }
                >
                    <IconButton style={{marginRight : "10px"}} onClick={ShowCashierTransaction} className="table-button no-icon"><i className="fas fa-coins"></i></IconButton>
                </Whisper>
            )
        }
        <Whisper
            placement={"top"}
            speaker={
                <Tooltip>Cashier's History</Tooltip>
            }
        >
            <IconButton onClick={ShowCashierTransactions} className="table-button no-icon"><i className="fas fa-list"></i></IconButton>
        </Whisper>
      </Table.Cell>
    );
};

const CashierTransactions = (props) => {

    const {id} = useParams();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_CASHIER_TRANSACTIONS_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const FetchCashierTransactions = () => {

        axios.get(window.__APP__.api+"/operators/cashiers/"+id+"/transactions?platform="+platforms.platform.id+"&cashier="+modals.cashierId+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions)
                setPages(response.data.pagination.pages)
            }
        })
    }

    useEffect(() => {
        if(modals.cashierId != null) {
            FetchCashierTransactions() 
        }   
    }, [modals.cashierId, page, limit])

    return (
        <>
            <Modal onClose={HideModal} size="lg" open={modals.cashierTransactionsModal}>
                <Modal.Header>
                    <Modal.Title>Cashier Transactions History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table
                        data={transactions}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Credits</Table.HeaderCell>
                            <Table.Cell>{data => parseFloat(data.amount).toFixed(2)}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell>{data => parseFloat(data.real_amount).toFixed(2)}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const CashierTransaction = (props) => {

    const {id} = useParams();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState("");
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_CASHIER_TRANSACTION_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const SubmitTransaction = async () => {
        try {

            setLoading(true);

            var response = await axios.post(window.__APP__.api+"/operators/cashiers/"+id+"/transaction?platform="+platforms.platform.id, {
                cashier : modals.cashierId,
                amount : amount
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            setLoading(false);

            if(response.data.error == null) {
                HideModal();
            }
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <Modal onClose={HideModal} size="sm" open={modals.cashierTransactionModal}>
                <Modal.Header>
                    <Modal.Title>Perform Cashier Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input onChange={setAmount} placeholder="Amount" value={amount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button loading={isLoading} onClick={SubmitTransaction} appearance="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default function Transactions(props) {
    const {id, shopId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [cashiers, setCashiers] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const GetCashiers = () => {
        axios.get(window.__APP__.api+"/operators/cashiers/list/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&shop="+modals.financialDebtsId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCashiers(response.data.cashiers);
                setPages(response.data.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetCashiers();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo, modals.cashierId]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const ShowCashSettings = () => {
        dispatch({
            type : "SET_CASHIER_SETTINGS_MODAL",
            data : {
                modal : true,
                id : id
            }
        })
    }

    return (
        <>
            <div className="nav-overview">
                {/* <Panel className="filters-panel" bordered>
                </Panel> */}
                <div className="nav-header" style={{textAlign : "right"}}>
                    {/* <Button onClick={ShowCashSettings} color="primary">Cashier Settings</Button> */}
                </div>
                <div style={{marginTop : "30px"}}>
                    <Table
                        data={cashiers}
                        autoHeight
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Credits</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="real_amount" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Total Paid</Table.HeaderCell>
                            <Table.Cell dataKey="total_paid" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Rest To Pay</Table.HeaderCell>
                            <Table.Cell dataKey="topay" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell>{data => data.status == "1" ? <Badge color="green" content="Paid" />: <Badge color="red" content="Not Paid" />}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>
            
            {
                modals.cashierTransactionsModal && (
                    <CashierTransactions />
                )
            }
            {
                modals.cashierTransactionModal && (
                    <CashierTransaction />
                )
            }
        </>
    )
}