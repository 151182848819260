import {useState, useEffect} from "react";

import {Doughnut, PolarArea} from "react-chartjs-2";

import { Dropdown } from 'rsuite';

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import {useHistory} from "react-router-dom";

export default function MonthlyGGR(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const {timeframe} = props;

    const [details, setDetails] = useState({labels : [], datasets : [], background : []});

    const [choice, setChoice] = useState(0);  

    const [backDetails, setBackDetails] = useState({});

    const GetCategories = async (tf) => {
      setDetails({labels : [], datasets : [], background : []});

      var response = await axios.get(window.__APP__.api+"/statistics/bets/categories?platform="+platforms.platform.id+"&choice="+choice+"&timeframe="+tf+"&currency="+(platforms.currency.id >= 0 ? platforms.currency.id : ""), {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      })


      if(response.data.error == null) {
        setBackDetails({
          labels : response.data.details.labels,
          datasets : response.data.details.datasets,
          background : response.data.details.colors,
          platform : response.data.platform
        })
      } else {
        if(response.data.error == "Unauthorized") {
          dispatch({
              type : "SET_LOGGED",
              data : {
                  logged : false
              }
          })
          localStorage.removeItem("jwt");
          history.push("/");
        }
      } 
    }

    useEffect(() => {
      if(backDetails.labels != undefined) {
        if(backDetails.platform == platforms.platform.id) {
          setDetails({
            labels : backDetails.labels,
            datasets : backDetails.datasets,
            background : backDetails.background
          }) 
        }
        
      }
    }, [backDetails])

    useEffect(() => {
      if(platforms.platform.id > -1) {
        GetCategories(timeframe);
      }
    }, [platforms.platform, platforms.currency, choice, timeframe]);


    return (
        <>
            <div className="box-chart">
                <div className="box-chart-header monthly-overview">
                    <i className="fas fa-chart-line" />
                    <h3>Monthly {choice == 0 ? "Bets" : choice == 1 ? "Wins" : "Profit"} By Category</h3>

                    <div className="actions">
                      <Dropdown title={choice == 0 ? "Bets" : choice == 1 ? "Wins" : "Profit"}>
                        <Dropdown.Item onClick={() => setChoice(0)}>Monthly Bets By Category</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChoice(1)}>Monthly Wins By Category</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChoice(2)}>Monthly GGR By Category</Dropdown.Item>
                      </Dropdown>
                    </div>
                </div>
                <PolarArea data={{labels : details.labels, datasets : [{data : details.datasets, backgroundColor : details.background}]}} options={[]} />
            </div>
        </>
    )
}