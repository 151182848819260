import {Table} from "rsuite";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {Link} from "react-router-dom";

import {useState, useEffect} from "react";

export default function TopWinsTable() {
    
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
    const history = useHistory();

    const [topWins, setTopWins] = useState([]);

    const GetTopWins = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api+"/statistics/games/topwins?platform="+platforms.platform.id+dateQuery+"&currency="+(platforms.currency.id >= 0 ? platforms.currency.id : ""), {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTopWins(response.data.data);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id > -1) {
            GetTopWins();
        }
    }, [platforms.platform, platforms.currency, filters.dateRange]);

    return (
        <>
        <Table
                // height={600}
                autoHeight
                data={topWins}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Game Name</Table.HeaderCell>
                    <Table.Cell dataKey="game" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Bet Amount</Table.HeaderCell>
                    <Table.Cell dataKey="bet" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Win Amount</Table.HeaderCell>
                    <Table.Cell dataKey="win" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Player</Table.HeaderCell>
                    <Table.Cell dataKey="player" />
                </Table.Column>

                {
                    platforms.platform.agent_enabled == 1 ? (
                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Parent</Table.HeaderCell>
                            <Table.Cell>{ data =>  data.parentId != null ? <Link to={"/online/agent/"+data.parentId}>{data.parent}</Link> : <>{data.parent}</>}</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Player Balance</Table.HeaderCell>
                    <Table.Cell dataKey="balance" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>
            </Table>
        </>
    )
}