import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Modal, Input, InputPicker } from 'rsuite';

import {Button} from "rsuite";

import HistoryTable from "./WithdrawalsTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {useMediaQuery} from "react-responsive";
import { Whisper, Tooltip, IconButton } from "rsuite";
import Filters from "../../Filters/Filters";
import {FaFilter} from "react-icons/fa";
import axios from "axios";

export default function Withdrawals() {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);

    const filters = useSelector(state => state.filters);

    const isSmall = useMediaQuery({ query: '(max-width: 400px)' })

    const bars = useSelector(state => state.bars);

    const modals = useSelector(state => state.modals);

    const [filterType, setFilterType] = useState(0);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }


    const types = [
        {
            label : "All",
            value : 0
        },
        {
            label : "Processed",
            value : 1
        },
        {
            label : "Approved Manually",
            value : 2
        },
        {
            label : "Canceled",
            value : 4
        },
        {
            label : "Pending",
            value : 3
        }
    ]

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"withdrawals"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Withdrawals</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Withdrawals</h1>

                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                    <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                    <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <InputPicker value={filterType} onChange={setFilterType} data={types} placeholder="Type" style={{marginLeft : "10px", flex : 1}} />
                            </div>
                        </Panel>

                        <Panel style={{marginTop : "20px"}}>
                            <HistoryTable type={filterType}></HistoryTable>
                        </Panel>
                    </div>
                </div>
            </div> 

            <Filters />
        </>
    )
}