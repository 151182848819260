import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Badge } from 'rsuite';
import { Toggle, Modal, Button } from 'rsuite';

import axios from "axios";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../Transfer/Transfer";

import { Radio, RadioGroup } from 'rsuite';

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const togglePartnershipModal = (id) => {
        dispatch({
            type : "SET_PARTNERSHIP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PARTNERSHIP_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <Table.Cell {...props} className="link-group">
            <a style={{marginRight : "10px"}}>
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>View Partnership Details</Tooltip>
                    }
                >
                    <IconButton onClick={() => togglePartnershipModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
                </Whisper>
            </a>
        </Table.Cell>
    );
};

const PartnershipModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [partnership, setPartnership] = useState({});

    const togglePartnershipModal = (id) => {
        dispatch({
            type : "SET_PARTNERSHIP_ID",
            data : {
                id : null
            }
        })
        dispatch({
            type : "TOGGLE_PARTNERSHIP_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const FetchPartnership = (id) => {
        if(id != null) {
            
            axios.get(window.__APP__.api+"/partnerships/partnership/"+id+"?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    setPartnership(response.data.partnership);
                }
            });
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            FetchPartnership(modals.partnershipId)
        }
        
    }, [modals.partnershipId, platforms.platform])

    return (
        <Modal onClose={togglePartnershipModal} open={true}>
            <Modal.Header>
                <Modal.Title>Partnership #{modals.partnershipId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    partnership.id != undefined && (
                        <>
                            <div className="partnership_details">
                                <p>Name : {partnership.name}</p>
                                <p>City : {partnership.city}</p>
                                <p>Phone : {partnership.phone}</p>
                                <p>Email : {partnership.email}</p>
                                <p>Address : {partnership.address}</p>
                            </div>
                            <div className="partner-img">
                                <img src={partnership.image}></img>
                            </div>
                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}

const ApproveModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const ApproveTransaction = () => {
        axios.post(window.__APP__.api+"/withdrawals/approve/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Approve Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to approve this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ApproveTransaction} color="blue" appearance="primary">
                    Approve
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const CancelModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_CANCEL_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const CancelWithdrawal = () => {
        axios.post(window.__APP__.api+"/withdrawals/cancel/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Cancel Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to cancel this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={CancelWithdrawal} color="red" appearance="primary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function PartnershipsTable(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const filters = useSelector(state => state.filters);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {type} = props;

    const GetHistory = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";
        
        axios.get(window.__APP__.api+"/partnerships/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.partnerships);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetHistory();
    }, [platforms.platform, activePage, activeLimit, filters.dateRange, modals.withdrawalCancelModal, modals.withdrawalsModal, type]);
    

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.Cell>{ data => data.name }</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Phone</Table.HeaderCell>
                    <Table.Cell>{ data => data.phone }</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align="center">
                    <Table.HeaderCell>City</Table.HeaderCell>
                    <Table.Cell>{ data => data.state }</Table.Cell>
                </Table.Column>

                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.Cell>{ data => data.date }</Table.Cell>
                </Table.Column>

       
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    // onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.partnershipModal && (
                    <PartnershipModal show={true} />
                )
            }
            {
                modals.withdrawalsModal && (
                    <ApproveModal show={true} />
                )
            }

            {
                modals.withdrawalCancelModal && (
                    <CancelModal show={true} />
                )
            }
        </>
    )
}