import {useState, useEffect, useRef} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory, useParams } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function Jackpots() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [formLoading, setFormLoading] = useState(false);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
        facebook : "",
        instagram : "",
        youtube : "",
        linkedin : "",
        tiktok : "",
        whatsapp : ""
    });

    const [errors, setErrors] = useState({
        percentage : false,
        threshold : false,
        maxwin : false
    })
    const [formError, setFormError] = useState({})

    const GetSocials = () => {
        axios.get(window.__APP__.api+"/cms/socials/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setForm(response.data.socials);
            }
        });
    }

    useEffect(() => {
      if(platforms.platform.id >= 0) {
        GetSocials();
      }
    }, [platforms.platform]);

    const SaveSocials = () => {
      setFormLoading(true);
      axios.post(window.__APP__.api+"/cms/socials/update?platform="+platforms.platform.id, form, {
          headers : {
              "Authorization" : "Bearer "+localStorage.getItem("jwt")
          }
      }).then(response => {
          if(response.data.error == null) {
            setFormLoading(false);
          }
      });
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-socials"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Socials</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Socials Settings</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                              formMessage != "" && (
                                <Message showIcon type={formMessageType}>
                                  {formMessage}
                                </Message>
                              )
                            }
                            
                            <Form onSubmit={SaveSocials} className="form-box">
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="facebook">
                                        <Form.ControlLabel>Facebook</Form.ControlLabel>
                                        <Form.Control value={form.facebook} onChange={value => setForm({...form, facebook : value})} name="facebook" placeholder="Facebook" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="youtube">
                                        <Form.ControlLabel>Youtube</Form.ControlLabel>
                                        <Form.Control value={form.youtube} onChange={value => setForm({...form, youtube : value})} name="youtube" placeholder="Youtube" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="instagram">
                                        <Form.ControlLabel>Instagram</Form.ControlLabel>
                                        <Form.Control value={form.instagram} onChange={value => setForm({...form, instagram : value})} name="instagram" placeholder="Instagram" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="linkedin">
                                        <Form.ControlLabel>Linkedin</Form.ControlLabel>
                                        <Form.Control value={form.linkedin} onChange={value => setForm({...form, linkedin : value})} name="linkedin" placeholder="Linkedin" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="tiktok">
                                        <Form.ControlLabel>Tiktok</Form.ControlLabel>
                                        <Form.Control value={form.tiktok} onChange={value => setForm({...form, tiktok : value})} name="tiktok" placeholder="Tiktok" />
                                    </Form.Group>
                                </div>
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="whatsapp">
                                        <Form.ControlLabel>Whatsapp</Form.ControlLabel>
                                        <Form.Control value={form.whatsapp} onChange={value => setForm({...form, whatsapp : value})} name="Whatsapp" placeholder="Whatsapp" />
                                    </Form.Group>
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button disabled={formLoading} type="submit" style={{height : "100%"}} onClick={SaveSocials} color="primary">Save Socials</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}