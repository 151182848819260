const initialState = {
    filtersDrawer : false,
    dateRange : [],
    customDateRange : []
}

export default function filtersReducer(state=initialState, action) {
    switch(action.type) {
        case "TOGGLE_DRAWER":
            return {
                ...state,
                filtersDrawer : action.data.show
            }
        break;
        case "SET_DATE_RANGE":
            return {
                ...state,
                dateRange : action.data.range
            }
        break;
        case "SET_CUSTOM_RANGE":
            return {
                ...state,
                customDateRange : action.data.range
            }
        break;
        default:
            return state;
        break;
    }
}