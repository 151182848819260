import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Radio } from 'rsuite';

import {Button, Modal, IconButton, Checkbox} from "rsuite";
import Nav from 'rsuite/Nav';

import { useHistory, useParams } from "react-router-dom";

import {Link} from "react-router-dom"

import axios from "axios";

/* Navs */
import Overview from "./Navs/Overview";
import Hierarchy from "./Navs/Hierarchy";
import Documents from "./Navs/Documents";
import Transactions from "./Navs/Transactions";
import Messages from "./Navs/Messages";
import Notes from "./Navs/Notes";
import LoginHistory from "./Navs/LoginHistory";
import FinancialHistory from "./Navs/Report";
import ShopReport from "./Navs/ShopReport";

import {Whisper, Input, InputPicker, Tooltip} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";

import Transfer from "../Transfer/Transfer";

const PercentageModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [percentage, changePerc] = useState(0);
    const [full, setFull] = useState(false);

    useEffect(() => {
        changePerc(modals.percentage)
    }, [modals.percentage]);
    
    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PERCENTAGE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    useEffect(() => {
        axios.get(window.__APP__.api+"/agents/payment/model/"+modals.shopId+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFull(response.data.shop);
            }
        })
    }, []);

    const SetPercentage = (id) => {
        axios.post(window.__APP__.api+"/agents/percentage/change/"+id+"?platform="+platforms.platform.id, {
            id : id,
            percentage : percentage,
            full : full
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                dispatch({
                    type : "TOGGLE_PERCENTAGE_MODAL",
                    data : {
                        toggle : false
                    }
                })
            }
        })
    }

    const toggleFullPaument = () => {
        if(full) {
            setFull(false);
        } else {
            setFull(true);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={true}>
            <Modal.Header>
                <Modal.Title>Agent Percentage #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Set Agent Id</h4>
                <br />
                <Input value={percentage} onChange={v => changePerc(v)} placeholder="Percentage" />
                <Checkbox onChange={toggleFullPaument} checked={full}>Full Payment</Checkbox>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={() => SetPercentage(modals.shopId)} appearance="primary">
                    Set Percentage
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const MessageModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeMessageModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_MESSAGE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [message, setMessage] = useState("");

    const SendMessage = () => {
        if(message != "") {
            axios.post(window.__APP__.api+"/shops/message/send/"+modals.shopId+"?platform="+platforms.platform.id, {
                message : message
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeMessageModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    return (
        <Modal open={modals.shopMessageModal} onClose={closeMessageModal}>
            <Modal.Header>
                <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setMessage} as="textarea" placeholder="Message" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeMessageModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendMessage} appearance="primary">
                    Send Message
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const NoteModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeNoteModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_NOTE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [note, setNote] = useState("");
    const [priority, setPriority] = useState(0);

    const SendNote = () => {
        if(note != "") {
            axios.post(window.__APP__.api+"/shops/note/send/"+modals.shopId+"?platform="+platforms.platform.id, {
                note : note,
                priority : priority
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeNoteModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    const data = [
        {
            label : "Normal Priority",
            value : 0
        }, {
            label : "High Priority",
            value : 1
        }, {
            label : "Important",
            value : 2
        }
    ]

    return (
        <Modal open={modals.shopNoteModal} onClose={closeNoteModal}>
            <Modal.Header>
                <Modal.Title>Send Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setNote} as="textarea" placeholder="Note" />
                <InputPicker style={{marginTop : "15px", width : "100%"}} placeholder="Priority" data={data} value={priority} onChange={setPriority}  />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeNoteModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendNote} appearance="primary">
                    Send Note
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(0);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/shops/toggle/"+modals.shopId+"?platform="+platforms.platform.id, {type : toggleType}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.shopStatus == 1 ? "Disable" : "Enable"} Shop #{modals.shopId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Type</h4>
                <br />
                <Radio onChange={setToggleEvent} value="0" checked={toggleType == 0 ? true : false}>User and sub-users</Radio>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.shopStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Partners(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const bars = useSelector(state => state.bars);
    const [activeKey, setActiveKey] = useState(props.nav);

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const [clonesLoading, setClonesLoading] = useState(false);

    const [shop, setShop] = useState({
        name : "",
        location : "",
        balance : 0,
        cashinhand : 0,
        phone : "",
        clones : ""
    });

    const GetShopInfo = () => {
        axios.get(window.__APP__.api+"/shops/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShop(response.data.operator);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    // const ToggleShop = () => {
    //     axios.post(window.__APP__.api+"/shops/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
    //         headers : {
    //             "Authorization" : "Bearer "+localStorage.getItem("jwt")
    //         }
    //     }).then(response => {
    //         if(response.data.error == null) {
    //             GetShopInfo();
    //         } else {
    //             if(response.data.error == "Unauthorized") {
    //                 localStorage.removeItem("jwt");
    //                 dispatch({
    //                     type : "SET_LOGGED",
    //                     data : {
    //                         logged : false
    //                     }
    //                 })
    //             } 
    //         }
    //     })
    // }

    const ToggleShop = () => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_SHOP_STATUS",
            data : {
                status : shop.status
            }
        })
        dispatch({
            type : "TOGGLE_SHOP_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const ShowMessageModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_SHOP_MESSAGE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const ShowNoteModal = (id) => {
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_SHOP_NOTE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetShopInfo();
        }
    }, [platforms.platform, modals.shopToggleModal]);

    const MakeTransfer = () => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "shop-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "shop"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const TogglePercentageModal = (id, perc) => {
        dispatch({
            type : "TOGGLE_PERCENTAGE_MODAL",
            data : {
                toggle : true
            }
        })
        dispatch({
            type : "SET_SHOP_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_PERCENTAGE",
            data : {
                percentage : perc
            }
        })
    }

    const ToggleReplicates = async (id) => {

        if(clonesLoading) {
            return ;
        }

        setClonesLoading(true);

        const response = await axios.get(window.__APP__.api+"/shops/replicates/toggle/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setClonesLoading(false);

        GetShopInfo()
    }
        
    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"shops"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/shops">Shops</Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            {/* <h1>Preview Partner</h1> */}
                        </div>

                        <div className="preview-page">
                            <div className="preview-head">
                                <div className="head-content">
                                    <div className="thumbnail shop-thumb">
                                        <img src="/assets/svg/user.svg" />
                                        <div className="layer">
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="info-row">
                                            <h1>Username</h1>
                                            <h2>{shop.username}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Owner Name</h1>
                                            <h2>{shop.name}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Shop Location</h1>
                                            <h2>{shop.location}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Balance</h1>
                                            <h2>{shop.balance}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Cash In Hand</h1>
                                            <h2>{shop.cashinhand}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Partner</h1>
                                            <h2><Link to={"/partner/"+shop.partner_id}>{shop.partner}</Link></h2>
                                        </div>
                                        {
                                            shop.agent_name != null && (
                                                <div className="info-row">
                                                    <h1>Agent</h1>
                                                    <h2><Link to={"/agent/"+shop.agent}>{shop.agent_name}</Link></h2>
                                                </div>
                                            )
                                        }
                                        <div className="info-row">
                                            <h1>Phone</h1>
                                            <h2>{shop.phone}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Owner ID</h1>
                                            <h2>{shop.cin == "" ? "N/A" : shop.cin}</h2>
                                        </div>
                                        {/* <div className="info-row">
                                            <h1>GGR</h1>
                                            <h2>{shop.phone}</h2>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    (shop.username != undefined && shop.username != "") && (
                                        <div className="custom-buttons">
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Transfer</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => MakeTransfer(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-exchange"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>{shop.clones == 1 ? "Disable" : "Enable"} Replicates</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => ToggleReplicates(id)} className="icon-btn"><i style={{fontSize : "22px"}} className={"fa"+(shop.clones == 1 ? "s" : "r")+" fa-closed-captioning"}></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Percentage</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => TogglePercentageModal(id, shop.percentage)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-percentage"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Send Message</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => ShowMessageModal(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-paper-plane"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Set Note</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => ShowNoteModal(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-sticky-note"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>{shop.status == 1 ? "Block" : "Unblock"}</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={ToggleShop} className="icon-btn"><i style={{fontSize : "22px"}} className={shop.status == 1 ? "fas fa-lock" : "fas fa-unlock"}></i></IconButton>
                                            </Whisper>
                                        </div>
                                    )
                                }
                                <div className="head-menu">
                                    <Nav activeKey={activeKey} appearance="subtle" onSelect={(key) => setActiveKey(key)}>
                                        <Nav.Item eventKey="home">Overview</Nav.Item>
                                        <Nav.Item eventKey="hierarchy">Hierarchy</Nav.Item>
                                        <Nav.Item eventKey="documents">Documents</Nav.Item>
                                        <Nav.Item eventKey="transactions">Transactions</Nav.Item>
                                        <Nav.Item eventKey="messages">Messages</Nav.Item>
                                        <Nav.Item eventKey="notes">Notes</Nav.Item>
                                        <Nav.Item eventKey="financial">Financial Report</Nav.Item>
                                        <Nav.Item eventKey="shopreport">Shop Report</Nav.Item>
                                        <Nav.Item eventKey="login">Login History</Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="preview-body">
                                {
                                    activeKey == "home" ? <Overview /> : activeKey == "hierarchy" ? <Hierarchy /> : activeKey == "documents" ? <Documents /> : activeKey == "transactions" ? <Transactions agent={shop.agent} /> : activeKey == "messages" ? <Messages /> : activeKey == "notes" ? <Notes /> : activeKey == "login" ? <LoginHistory /> : activeKey == "financial" ? <FinancialHistory /> : activeKey == "shopreport" ? <ShopReport /> : <Overview />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <MessageModal />
            <NoteModal />

            {
                modals.transferModal && (
                    <Transfer />
                )
            }
            {
                modals.percentageModal && (
                    <PercentageModal />
                )
            }
            {
                modals.shopToggleModal && (
                    <ToggleModal show={true} />
                )
            }
        </>
    )
}