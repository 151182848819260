import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Modal, Form, Input} from "rsuite";

import ContentTable from "./ContentTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory, useParams} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

const AddContentModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [addForm, setAddForm] = useState({
        key : "",
        value : ""
    })

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_LANGUAGE_CONTENT_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const AddContent = () => {
        axios.post(window.__APP__.api+"/cms/language/content/add/"+modals.languageId+"?platform="+platforms.platform.id, addForm, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={true}>
            <Modal.Header>
                <Modal.Title>Add Language Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form style={{marginLeft : "10px"}}>
                    <Form.Group>
                        <Form.ControlLabel>Content Key</Form.ControlLabel>
                        <Input onChange={value => setAddForm({...addForm, key : value})} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Content Value</Form.ControlLabel>
                        <Input onChange={value => setAddForm({...addForm, value : value})} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={AddContent} appearance="primary">
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function LanguageContent() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const {id} = useParams();

    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const userx = useSelector(state => state.user);

    const [language, setLanguage] = useState({
        name : "",
        flag : ""
    });

    const GetLanguage = () => {
        axios.get(window.__APP__.api+"/cms/language/get/"+id+"?platform="+platforms.platform.id, {
            headers: {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setLanguage({
                    name : response.data.language.name,
                    flag : response.data.language.image
                })
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetLanguage();
        }
    }, [platforms.platform]);

    const OpenLanguageContent = () => {
        dispatch({
            type : "SET_LANGUAGE_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_LANGUAGE_CONTENT_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-languages"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/cms/languages">Languages</Breadcrumb.Item>
                            <Breadcrumb.Item active>Language</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Language</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <div className="language-header">
                                <div style={{flex : "1 1 auto"}} className="language-info">
                                    <img style={{width : "25px"}} src={language.flag} />
                                    <h4>{language.name}</h4>
                                </div>
                                {
                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("languages_content_add"))) >= 0 ? (
                                        <div style={{alignItems : "right"}}>
                                            <Button onClick={OpenLanguageContent} appearance="primary">Add Content</Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>

                            <ContentTable></ContentTable>
                        </Panel>
                    </div>
                </div>
            </div> 

            {
                modals.languageContentModal && (
                    <AddContentModal />
                )
            }
        </>
    )
}