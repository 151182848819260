import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import Message from 'rsuite/Message';


import { Form } from 'rsuite';

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, InputPicker } from 'rsuite';

import {Button} from "rsuite";

import ProvidersTable from "./ProvidersTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {FaFilter} from "react-icons/fa";


import axios from "axios";

export default function Providers() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [settings, setSettings] = useState({
        daily_player_kiosk_limit : 0
    })

    const [message, setMessage] = useState({
        message : "",
        status : ""
    })

    const UpdateSettings = () => {
        axios.post(window.__APP__.api+"/settings/site/update?platform="+platforms.platform.id, settings, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setMessage({
                    message : "Settings updated successfully",
                    status : "success"
                })
            } else {
                setMessage({
                    message : "Failed to update settings",
                    status : "error"
                })
            }
        });
    }   

    const GetSettings = () => {
        axios.get(window.__APP__.api+"/settings/site/get?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setSettings(response.data.settings);
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetSettings();
    }, [platforms]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-site"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Settings</Breadcrumb.Item>
                            <Breadcrumb.Item active>Site Settings</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Site Settings</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>

                            {
                                message.message != "" && (
                                    <>
                                        <Message type={message.status}>{message.message}</Message>
                                        <br />
                                    </>
                                )
                            }
                            

                            <Form>
                                <Form.Group controlId="limit">
                                    <Form.ControlLabel>KIOSK Daily Deposit Limit</Form.ControlLabel>
                                    <Form.Control placeholder="Daily Deposit Limit" onChange={e => setSettings({...settings, daily_player_kiosk_limit : e})} name="limit" value={settings.daily_player_kiosk_limit} />
                                </Form.Group>
                                <Button color="primary" onClick={UpdateSettings}>Update Settings</Button>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}