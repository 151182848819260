import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Link} from "react-router-dom";
import {Tooltip, Form, Toggle, Whisper, Modal, Button} from "rsuite";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Uploader from 'rsuite/Uploader';
import DatePicker from 'rsuite/DatePicker';

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleDocumentsModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_MODAL",
            data : {
                toggle : true
            }
        })
    }
    const toggleDocumentsUpdateModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_UPDATE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleDocumentsDeleteModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        <a onClick={() => toggleDocumentsModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button view-file-icon"></IconButton>
            </Whisper>
        </a>
        <a onClick={() => toggleDocumentsUpdateModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button edit-file-icon"></IconButton>
            </Whisper>
        </a>
        <a onClick={() => toggleDocumentsDeleteModal(rowData['id'])}style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button delete-file-icon"></IconButton>
            </Whisper>
        </a>
      </Table.Cell>
    );
};

const SubActions = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} className="link-group">
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>View File</Tooltip>
                }
            >
                <IconButton className="table-button view-file-icon"></IconButton>
            </Whisper>
        </Link>
      </Table.Cell>
    );
};

const PreviewModal = function(props) {
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const getDocumentFiles = () => {
        axios.get(window.__APP__.api+"/players/document/"+modals.documentId+"?platform="+platforms.platform.id, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFiles(response.data.files);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        getDocumentFiles();
    }, []);

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table
                        height={300}
                        // autoHeight
                        data={files}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        <Table.Column width={30} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column width={100} align="center">
                            <Table.HeaderCell>Document Type</Table.HeaderCell>
                            <Table.Cell dataKey="type" />
                        </Table.Column>

                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                            <Table.Cell dataKey="expiry" />
                        </Table.Column>

                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell dataKey="status" />
                        </Table.Column>

                        <Table.Column width={100} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <SubActions />
                        </Table.Column>
                    </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const UpdateModal = function(props) {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [updateForm, setUpdateForm] = useState({
        expiry : "",
        file : ""
    });
    const [updateError, setUpdateError] = useState({
        file : false
    })

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_UPDATE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const toggleFileButton = (details) => {
        if(details.length == 0) {
            setUploadDisabled(false);
        }
    }

    const checkUploadFile = (details, type) => {
        if(details.error == null) {
          var path = "/documents/shops/"+details.file.name;
          setUpdateForm({...updateForm, file : path});
          setUploadDisabled(true);
        } else {
          setUploadDisabled(false);
        }
    }

    const UpdateDocument = () => {
        if(updateForm.file == "") {
            setUpdateError({...updateError, file: true});
        } else {
            setUpdateError({...updateError, file: false});

            axios.post(window.__APP__.api+"/shops/document/update/"+modals.documentId+"?platform="+platforms.platform.id, updateForm, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    toggleDocumentsModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } 
                }
            })
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Update Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form style={{marginLeft : "10px"}}>
                    <Form.Group>
                        <Form.ControlLabel>Expiry Date</Form.ControlLabel>
                        <DatePicker onChange={value => setUpdateForm({...updateForm, expiry : value})} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Document</Form.ControlLabel>
                        <Uploader onChange={(details) => toggleFileButton(details, "passport")} disabled={uploadDisabled} onSuccess={(response) => checkUploadFile(response, "passport")} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "operator"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} />
                        <small style={{display : "block", height : "10px", color : updateError.file ? "red" : ""}}>File types allowed : png,jpg,docx,pdf</small>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={UpdateDocument}>Update Document</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [updateForm, setUpdateForm] = useState({
        expiry : "",
        file : ""
    });
    const [updateError, setUpdateError] = useState({
        file : false
    })

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const toggleFileButton = (details) => {
        if(details.length == 0) {
            setUploadDisabled(false);
        }
    }

    const checkUploadFile = (details, type) => {
        if(details.error == null) {
          var path = "/documents/shops/"+details.file.name;
          setUpdateForm({...updateForm, file : path});
          setUploadDisabled(true);
        } else {
          setUploadDisabled(false);
        }
    }

    const DeleteDocument = () => {
        axios.post(window.__APP__.api+"/shops/document/delete/"+modals.documentId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this document ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeleteDocument} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Documents() {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const {id} = useParams();

    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [filterType, setFilterType] = useState(-1);

    const [documents, setDocuments] = useState([]);

    const documentTypes = [
        {
            label : "CIN",
            value : "0"
        },
        {
            label : "Passport",
            value : "1"
        }
    ]

    const GetDocuments = () => {
        axios.get(window.__APP__.api+"/players/documents/list/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&type="+filterType, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setDocuments(response.data.documents);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id) {
            GetDocuments();
        }
    }, [platforms.platform, page, limit, filterType, modals]);

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <InputPicker  style={{flex : 1}} onChange={setFilterType} data={documentTypes} placeholder="Document Type" />
                    </div>
                </Panel>
                <div>
                    <Table
                        autoHeight
                        data={documents}
                    >
                        <Table.Column minWidth={70} flexGrow={1} align="center">
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}  align="center">
                            <Table.HeaderCell>Document Type</Table.HeaderCell>
                            <Table.Cell dataKey="type" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}  align="center">
                            <Table.HeaderCell>Document Number</Table.HeaderCell>
                            <Table.Cell dataKey="number" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}  align="center">
                            <Table.HeaderCell>Date Submitted</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}  align="center">
                            <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                            <Table.Cell dataKey="expiry_date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell dataKey="status" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['total', '-', 'limit', '|', 'pager']}
                            size={100}
                            // prev={prev}
                            // next={next}
                            // first={first}
                            // last={last}
                            // ellipsis={ellipsis}
                            // boundaryLinks={boundaryLinks}
                            total={100}
                            limit={25}
                            limitOptions={[30,50,100]}
                            maxButtons={5}
                            activePage={1}
                            // onChangePage={setActivePage}
                            // onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>

            {
                modals.documentsModal && (
                    <PreviewModal show={true} />
                )
            }

            {
                modals.documentsUpdateModal && (
                    <UpdateModal show={true} />
                )
            }

            {
                modals.documentsDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
        </>
    )
}