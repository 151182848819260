import { Bar } from 'react-chartjs-2';

import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import { useHistory, useParams } from 'react-router';

import {FaFilter} from "react-icons/fa";

import {Loader,  Whisper, Tooltip, Table, IconButton} from "rsuite";
import {Line} from "react-chartjs-2";
import {Doughnut, PolarArea} from "react-chartjs-2";

import Filters from "../../Filters/Filters";

import axios from "axios";

export default function Overview() {
    const {id} = useParams();

    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [topGamesLoading, setTopGamesLoading] = useState(false);
    const [topWinsLoading, setWinsLoading] = useState(false);

    const [boxes, setBoxes] = useState({});

    const filters = useSelector(state => state.filters);


    const [partitions, setPartitions] = useState({
        labels : [],
        partitions : []
    })

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetOverviewBoxes = () => {
        axios.get(window.__APP__.api+"/vouchers/overview/main/"+id+"?platform="+platforms.platform.id, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setBoxes(response.data.details);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetVouchersPartitions = () => {
        setTopGamesLoading(true);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/vouchers/overview/partitions/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setTopGamesLoading(false);

            if(response.data.error == null) {
                setPartitions(response.data.partitions);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetOverviewBoxes();
        }
    }, [platforms.platform])

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetVouchersPartitions();
        }
    }, [platforms.platform, filters.dateRange]);

    return (
        <>
            <div className="nav-overview">
                <div className="overview-boxes">
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.balance != undefined ? boxes.balance.number : <Loader />}</h2>
                            <h1>Balance</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon balance-icon"></div>
                            <div className="percentage-box">
                                {boxes.balance != undefined ? boxes.balance.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.totalVouchers != undefined ? boxes.totalVouchers.number : <Loader />}</h2>
                            <h1>Total Vouchers</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon voucher-icon"></div>
                            <div className="percentage-box">
                                {boxes.profit != undefined ? boxes.profit.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.activeVouchers != undefined ? boxes.activeVouchers.number : <Loader />}</h2>
                            <h1>Active Vouchers</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon voucher-active-icon"></div>
                            <div className="percentage-box">
                                {boxes.profit != undefined ? boxes.profit.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.profit != undefined ? boxes.profit.number : <Loader />}</h2>
                            <h1>Profit</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon profit-icon"></div>
                            <div className="percentage-box">
                                {boxes.profit != undefined ? boxes.profit.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filters-row">
                    <div className="filters-side">
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>

                <div className="overview-details-row">
                    <div className="chart-box">
                            {/* <Bar data={data} options={options} /> */}
                            <div className="box-chart-header">
                                <i className="fas fa-chart-line" />
                                <h3>Vouchers Partitions</h3>
                            </div>
                            {
                                partitions.labels.length > 0 && (
                                    <Doughnut data={{labels : partitions.labels, datasets : [{data : partitions.partitions, backgroundColor : ["#897FBA", "#8870FF"]}]}} options={[]} />
                                )
                            }
                            
                        </div>

                       

                </div>

                
            </div> 

            <Filters /> 
        </>
    )
}