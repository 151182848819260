import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, InputPicker } from 'rsuite';

import {Button} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {FaFilter} from "react-icons/fa";

import GamesTable from "./APIGamesTable";


export default function Providers() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-games"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Settings</Breadcrumb.Item>
                            <Breadcrumb.Item active>All Games</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>All Games</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <GamesTable />
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}