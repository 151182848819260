import {useState, useEffect} from "react";

import {Table, IconButton, CheckPicker, Input, Radio} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import TagPicker from 'rsuite/TagPicker';
import {Link} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import TreeTable from "./AgentTree/Tree";

import axios from "axios";

import qs from "querystring";

const ActionCell = ({ rowData, dataKey, setToggleModal, setToggleModalStatus, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    return (
      <Table.Cell {...props} className="link-group">
        <Link to={"/online/agent/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link to={"/online/agents/edit/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
            </Whisper>
        </Link>
        <Toggle defaultChecked={!rowData['status']} onChange={() => { setToggleModal(rowData['id']); setToggleModalStatus(rowData['status']) }} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/online/agents/toggle/"+props.id+"?platform="+platforms.platform.id, { type : toggleType }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                props.setToggleModal(null);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={() => props.setToggleModal(null)} open={props.show}>
            <Modal.Header>
                <Modal.Title>{props.toggleModalStatus == 1 ? "Disable" : "Enable"} Agent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Radio onChange={setToggleEvent} value="0" checked={toggleType == 0 ? true : false}>User and sub-users</Radio>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setToggleModal(null)} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {props.toggleModalStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function AgentTree(props) {

    const {agent} = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const platforms = useSelector(state => state.platforms);

    const [toggleModal, setToggleModal] = useState(null);
    const [toggleModalStatus, setToggleModalStatus] = useState(0);

    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [filters, setFilters] = useState({
        roles : [],
        username : ""
    });
    const [users, setUsers] = useState([]);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetUsers = () => {
        setUsers([]);
        setLoading(true);

        axios.get(window.__APP__.api+"/online/agents/agent/hierarchy/"+id, {
            params : {
                platform : platforms.platform.id,
                filters : {
                    roles : filters.roles,
                    username : filters.username
                },
                page : page,
                limit : limit
            },
            headers : {
                "Authorization" : "Beaer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setUsers(response.data.users);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetRoles();
        }
    }, [platforms.platform]);

    const GetRoles = () => {
        axios.get(window.__APP__.api + "/online/agents/roles/list?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setRoles(response.data.roles);
        });
    }

    useEffect(() => {
        GetUsers();
    }, [filters, toggleModal, page, limit]);

    const setNewTypes = (types) => {
        setFilters({...filters, roles : types})
    }

    const GetAgentTreeDirect = async () => {

        setLoading(true);

        const response = await axios.get(window.__APP__.api + "/online/agents/agent/tree/"+id+"?platform="+platforms.platform.id+"&username="+filters.username+"&master=1", {
            headers : {
                "Authorization" : "bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null) {
            setRows(response.data.users)
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) 
            GetAgentTreeDirect();
    }, [platforms.platform, filters.username])

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body panel-body-filters">
                        <div className="filter-element" style={{flex : 1}}>
                            <Input placeholder="Username" value={filters.username} onChange={v => setFilters({...filters, username : v})} />
                        </div>
                    </div>
                </Panel>

                <div className="agent-treetable">
                    <TreeTable
                        columns={[
                            {
                                Key : "Role",
                                Name : "Role"
                            },
                            {
                                Key : "Username",
                                Name : "Username"
                            },
                            {
                                Key : "Currency",
                                Name : "Main Currency"
                            },
                            {
                                Key : "Players",
                                Name : "Players"
                            },
                            {
                                Key : "TotalAvailable",
                                Name : "Total Available"
                            }
                        ]}
                        data={rows}
                        loading={loading}
                        username={filters.username}
                    ></TreeTable>
                </div>  
            </div>

            <ToggleModal show={toggleModal != null} id={toggleModal} setToggleModal={setToggleModal} toggleModalStatus={toggleModalStatus} />
        </>
    )
}