import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button } from 'rsuite';

import { Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory, useParams } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function EditAccount() {
    const history = useHistory();

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const bars = useSelector(state => state.bars);
  
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
      firstname : "",
      lastname : "",
      username : "",
      email : "",
      phone : "",
      work_phone: "",
      password : "",
      repassword : ""
    });

    const [errors, setErrors] = useState({
      firstname : false,
      lastname : false,
      email : false,
      phone : false,
      password : false,
      repassword : false
    })

    const GetAccountDetails = () => {
        axios.get(window.__APP__.api+"/account/details?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Beader "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setForm(response.data.details);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetAccountDetails();
        }
    }, [platforms.platform]);

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const SubmitForm = () => {
        var errored = false;

        if(form.email != "" && form.email != null) {
            if(!validateEmail(form.email)) {
                var errorObj = errors;
                errorObj.email = true;
                var obj = Object.assign({}, errorObj);
                errored = true;
                setErrors(obj);
            }
        } 
        if(form.password != "" && form.password != form.repassword) {
            var errorObj = errors;
            errorObj.password = true;
            errorObj.repassword = true;
            var obj = Object.assign({}, errorObj);
            errored = true;
            setErrors(obj);
        } 

        if(!errored) {
            setErrors({
                firstname : false,
                lastname : false,
                email : false,
                phone : false,
                password : false,
                repassword : false
            });
    
            axios.post(window.__APP__.api+"/account/edit?platform="+platforms.platform.id, form, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    setFormMessageType("");
                    setFormMessage("");
        
                    toaster.push(<Notification type="info" header="Notification" closable>Account Edited Successfully</Notification>, {placement: "topEnd"});
                } else {
                    switch(response.data.error) {
                    case "Platform Not Found":
                    case "Unexpected Error":
                        setFormMessage("Unexpected Error");
                        setFormMessageType("error");
                    break;
                    case "Unauthorized":
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                        localStorage.removeItem("jwt");
                        history.push("/");
                    break;
                    }
                }
            })
        }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"shops"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">My Account</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>My Account</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Form onSubmit={SubmitForm} className="form-box">
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="firstname">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control value={form.firstname} onChange={value => setForm({...form, firstname : value})} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control value={form.lastname} onChange={value => setForm({...form, lastname : value})} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="username">
                                        <Form.ControlLabel>Username</Form.ControlLabel>
                                        <Form.Control disabled value={form.username} name="username" placeholder="Username" />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control className={errors.email ? "errored" : ""} value={form.email} onChange={value => setForm({...form, email : value})} name="email" placeholder="Email" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} value={form.password} type="password" onChange={value => setForm({...form, password : value})} name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="rpassword">
                                        <Form.ControlLabel>Repeat Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} value={form.repassword} type="password" onChange={value => setForm({...form, repassword : value})} name="repassword" placeholder="Repeat Password" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="pphone">
                                        <Form.ControlLabel>Personal Phone</Form.ControlLabel>
                                        <Form.Control value={form.phone} type="tel" onChange={value => setForm({...form, phone : value})} type="tel" name="pphone" placeholder="Personal Phone" />
                                    </Form.Group>
                                    <Form.Group controlId="wphone">
                                        <Form.ControlLabel>Work Phone</Form.ControlLabel>
                                        <Form.Control value={form.wphone} type="tel" onChange={value => setForm({...form, wphone : value})} type="tel" name="wphone" placeholder="Work Phone" />
                                    </Form.Group>
                                </div>


                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Edit Account</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}