import { useState, useEffect, useRef } from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Form, Input, Button, Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster } from 'rsuite';
import { useParams } from "react-router-dom";

export default function EditAdminUser() {

    const {id} = useParams();

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [roles, setRoles] = useState([]);
    const [parents, setParents] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [partners, setPartners] = useState([]);

    const [form, setForm] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        phone: "",
        password: "",
        repassword: "",
        role: "",
        timezone : ""
    });

    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        username: false,
        email: false,
        phone: false,
        password: false,
        repassword: false,
        role: false
    })

    const model = Schema.Model({
    });

    const timezones = [
        { label : "America/Mexico_City", value : "America/Mexico_City" },
        { label: "Pacific/Midway", value: "Pacific/Midway" },
        { label: "America/Adak", value: "America/Adak" },
        { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
        { label: "America/Anchorage", value: "America/Anchorage" },
        { label: "America/Los_Angeles", value: "America/Los_Angeles" },
        { label: "America/Denver", value: "America/Denver" },
        { label: "America/Chicago", value: "America/Chicago" },
        { label: "America/New_York", value: "America/New_York" },
        { label: "America/Halifax", value: "America/Halifax" },
        { label: "America/Argentina/Buenos_Aires", value: "America/Argentina/Buenos_Aires" },
        { label: "America/Noronha", value: "America/Noronha" },
        { label: "Atlantic/Azores", value: "Atlantic/Azores" },
        { label: "Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
        { label: "Europe/London", value: "Europe/London" },
        { label: "Europe/Berlin", value: "Europe/Berlin" },
        { label: "Europe/Moscow", value: "Europe/Moscow" },
        { label: "Africa/Cairo", value: "Africa/Cairo" },
        { label: "Africa/Johannesburg", value: "Africa/Johannesburg" },
        { label: "Asia/Jerusalem", value: "Asia/Jerusalem" },
        { label: "Asia/Dubai", value: "Asia/Dubai" },
        { label: "Asia/Kabul", value: "Asia/Kabul" },
        { label: "Asia/Karachi", value: "Asia/Karachi" },
        { label: "Asia/Kolkata", value: "Asia/Kolkata" },
        { label: "Asia/Kathmandu", value: "Asia/Kathmandu" },
        { label: "Asia/Dhaka", value: "Asia/Dhaka" },
        { label: "Asia/Bangkok", value: "Asia/Bangkok" },
        { label: "Asia/Shanghai", value: "Asia/Shanghai" },
        { label: "Asia/Tokyo", value: "Asia/Tokyo" },
        { label: "Australia/Sydney", value: "Australia/Sydney" },
        { label: "Pacific/Noumea", value: "Pacific/Noumea" },
        { label: "Pacific/Auckland", value: "Pacific/Auckland" },
        { label: "Pacific/Fiji", value: "Pacific/Fiji" }
    ];

    const [formError, setFormError] = useState({});

    const GetRoles = () => {
        axios.get(window.__APP__.api + "/settings/adminroles/pickerlist?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setRoles(response.data.roles);
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetRoles();
        }
    }, [platforms.platform]);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const SubmitForm = async () => {
        if (form.firstname == "" || form.lastname == "" || form.phone == "") {
            var errorObj = errors;

            if (form.phone == "" || form.phone == null) {
                errorObj.phone = true;
            } else {
                errorObj.phone = false;
            }

            if (form.lastname == "" || form.lastname == null) {
                errorObj.lastname = true;
            } else {
                errorObj.lastname = false;
            }

            if (form.firstname == "" || form.firstname == null) {
                errorObj.firstname = true;
            } else {
                errorObj.firstname = false;
            }

            var obj = Object.assign({}, errorObj);
            setFormError(obj);
        } else if (form.password != form.repassword) {
            var errorObj = errors;
            errorObj.password = true;
            errorObj.repassword = true;
            var obj = Object.assign({}, errorObj);

            setFormError(obj);
        } else {
            setErrors({
                firstname: false,
                lastname: false,
                username: false,
                email: false,
                phone: false,
                password: false,
                repassword: false,
                role: false
            });

            setLoading(true);

            const response = await axios.post(window.__APP__.api + "/settings/adminusers/edit/"+id+"?platform=" + platforms.platform.id, form, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                }
            })

            setLoading(false);

            if (response.data.error == null) {
                setFormMessageType("");
                setFormMessage("");

                toaster.push(<Notification type="info" header="Notification" closable>Admin User Edited Successfully</Notification>, { placement: "topEnd" });

                history.push("/settings/adminusers");
            } else {
                switch (response.data.error) {
                    case "User Already Exists":
                        setFormMessage("User Already Exists");
                        setFormMessageType("error");
                        break;
                    case "City Undefined":
                    case "Country Undefined":
                        setFormMessage("Location Undefined/Disabled");
                        setFormMessageType("error");
                        break;
                    case "Platform Not Found":
                    case "Unexpected Error":
                        setFormMessage("Unexpected Error");
                        setFormMessageType("error");
                        break;
                    case "Unauthorized":
                        dispatch({
                            type: "SET_LOGGED",
                            data: {
                                logged: false
                            }
                        })
                        localStorage.removeItem("jwt");
                        history.push("/");
                        break;
                }
            }
        }
    }

    const GetAdminUser = async () => {
        const response = await axios.get(window.__APP__.api + "/settings/adminusers/get/"+id+"?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setForm(response.data.user)
        }
    }

    useEffect(() => {
        GetAdminUser(); 
    }, [id])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-adminusers"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/settings/adminusers">Admin Users</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Admin User</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Edit Admin User</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            {
                                formMessage != "" && (
                                    <Message showIcon type={formMessageType}>
                                        {formMessage}
                                    </Message>
                                )
                            }

                            <Form onSubmit={SubmitForm} model={model} className="form-box">
                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="firstname">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, firstname: value })} value={form.firstname} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, lastname: value })} value={form.lastname} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="pphone">
                                        <Form.ControlLabel>Phone Number</Form.ControlLabel>
                                        <Form.Control type="tel" onChange={value => setForm({ ...form, phone: value })} value={form.phone} name="phone" placeholder="Personal Phone" />
                                    </Form.Group>
                                    <Form.Group controlId="parent">
                                        <Form.ControlLabel>Role</Form.ControlLabel>
                                        <InputPicker className={errors.role ? "errored" : ""} value={form.role} name="parent" onChange={(value) => setForm({ ...form, role: value })} size="md" placeholder="Role" style={{ width: "100%" }} data={roles} />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{ marginTop: "5px" }}>
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} onChange={value => setForm({ ...form, password: value })} type="password" name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="repassword">
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} onChange={value => setForm({ ...form, repassword: value })} type="password" name="repassword" placeholder="Confirm Password" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="timezone">
                                        <Form.ControlLabel>Timezone</Form.ControlLabel>
                                        <InputPicker name="timezone" onChange={(value) => setForm({ ...form, timezone: value })} value={form.timezone} size="md" placeholder="Timezone" style={{ width: "100%" }} data={timezones} />
                                    </Form.Group>
                                </div>

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button loading={loading} type="submit" style={{ height: "100%" }} color="primary">Edit Admin User</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div>
        </>
    )
}