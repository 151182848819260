import {Table, IconButton} from "rsuite";
import {Panel, InputPicker, Input} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import TagPicker from 'rsuite/TagPicker';
import {Link} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {useState, useEffect} from "react";

import axios from "axios";

import qs from "querystring";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    

    // const DeletePlayer = (id) => {
    //     axios.post(window.__APP__.api+"/players/delete/"+id+"?platform="+platforms.platform.id, {}, {
    //         headers : {
    //             "Authorization" : "Bearer "+localStorage.getItem("jwt")
    //         }
    //     }).then(response => {
    //         if(response.data.error == null) {
    //             toggleDocumentsModal();
    //         } else {
    //             if(response.data.error == "Unauthorized") {
    //                 localStorage.removeItem("jwt");
    //                 dispatch({
    //                     type : "SET_LOGGED",
    //                     data : {
    //                         logged : false
    //                     }
    //                 })
    //             } 
    //         }
    //     })
    // }

    const TogglePlayer = (id) => {
        axios.post(window.__APP__.api+"/players/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                // pass
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            rowData.type == 0 ? (
                <Link to={"/player/"+rowData.id} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Preview</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
                    </Whisper>
                </Link>
            ) : (
                <Link to={"/shop/"+rowData.id} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Preview</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
                    </Whisper>
                </Link>
            )
        }
        <Toggle defaultChecked={!rowData['status']} onChange={() => TogglePlayer(rowData['id'])} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

export default function Partner() {
    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const platforms = useSelector(state => state.platforms);

    const [userTypes, setUserTypes] = useState([]);
    const [filters, setFilters] = useState({
        userTypes : []
    });
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const [search, setSearch] = useState("");

    const GetUsers = () => {
        setUsers([]);
        axios.get(window.__APP__.api+"/agents/hierarchy", {
            params : {
                shop : id,
                platform : platforms.platform.id,
                filter : {
                    types : filters.userTypes
                },
                page : page,
                limit : limit,
                username : search
            },
            headers : {
                "Authorization" : "Beaer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setUsers(response.data.users);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetUserTypes = () => {
        axios.get(window.__APP__.api+"/agents/hierarchy/types?shop="+id+"&platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFilters({...filters, userTypes : response.data.default});
                setUserTypes(response.data.types);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetUserTypes();
        }
    }, [platforms.platform]);

    useEffect(() => {
        GetUsers();
    }, [filters, page, limit, search]);

    const setNewTypes = (types) => {
        setFilters({...filters, userTypes : types})
    }

    // const users = [
    //     {
    //         "id": 1,
    //         "name" : "Yessine Taktak",
    //         "username" : "yessine001",
    //         "balance" : "5000.00",
    //         "shops" : "20",
    //         "players" : "1200",
    //         "uid" : "11125324",
    //         "date" : "2021-09-30",
    //         "phone" : "+21695447119"
    //     },
    //     {
    //         "id": 1,
    //         "name" : "Yessine Taktak",
    //         "username" : "yessine001",
    //         "balance" : "5000.00",
    //         "shops" : "20",
    //         "players" : "1200",
    //         "uid" : "11125324",
    //         "date" : "2021-09-30",
    //         "phone" : "+21695447119"
    //       }
    // ];

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <Input placeholder="Username" onChange={value => setSearch(value)} style={{flex : 1}} />
                    </div>
                </Panel>
                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={users}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Agent Name</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.Cell>
                                {
                                    rowData => (
                                        rowData.type == 0 ? (
                                            <Link to={"/player/"+rowData.id}>{rowData.username}</Link>
                                        ) : (
                                            <Link to={"/shop/"+rowData.id}>{rowData.username}</Link>
                                        )
                                    )
                                }
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Phone Number</Table.HeaderCell>
                            <Table.Cell dataKey="phone" />
                        </Table.Column>
                        
                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell>{ data => data.type == 0 ? "Player" : "Shop" }</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                            <Table.Cell dataKey="balance" />
                        </Table.Column>

                        {/* <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date Created</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column> */}
                        
                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>
        </>
    )
}