const initialState = {
    platforms : [],
    platform : {
        id : -1,
        name : "",
        type : "",
        credits_type : 0,
        credits : 0
    },
    currency : {
        id : -1,
        name : ""
    },
    randomV : ""
};

export default function platformReducer(state=initialState, action) {
    switch(action.type) {
        case "SET_PLATFORMS":
            return {
                ...state, 
                platforms : action.data.platforms
            }
        break;
        case "SET_RANDOM_V":
            return {
                ...state,
                randomV : action.data.random
            }
        break;
        case "SET_PLATFORM":
            return {
                ...state,
                platform : action.data.platform
            }
        break;  
        case "SET_CURRENCY":
            return {
                ...state,
                currency : action.data.currency
            }
        break;
        default:
            return state;
        break;
    }
}