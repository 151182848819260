import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';
import Input from 'rsuite/Input';

import {Button, Modal} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory, useParams} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";


const ActionCell = ({ rowData, dataKey, ...props }) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms)

    const userx = useSelector(state => state.user);

    const dispatch = useDispatch();

    const updateLanguageContent = (id) => {
        // alert(modals.languageContentid);
        // alert(modals.languageContentValue)

        axios.post(window.__APP__.api+"/cms/language/content/update/"+id+"?platform="+platforms.platform.id, {
            value : modals.languageContentValue
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                dispatch({
                    type : "SET_LANGUAGE_CONTENT_ID",
                    data : {
                        id : id+response.data.random
                    }
                })
                dispatch({
                    type : "SET_LANGUAGE_CONTENT_VALUE",
                    data : {
                        value : null
                    }
                })
            }
        })
    }
    return (
      <Table.Cell {...props} className="link-group">
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("languages_content_update"))) >= 0 ? (
                <a onClick={() => updateLanguageContent(rowData['id'])} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Save</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i class="fas fa-check"></i></IconButton>
                    </Whisper>
                </a>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

export default function LanguagesTable() {

    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const {id} = useParams();

    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    
    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const GetLanguageContents = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/cms/language/"+id+"/contents?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.contents);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetLanguageContents();
    }, [platforms.platform, activePage, activeLimit, modals.languageContentModal, modals.languageContentid]);

    const setInputValue = (id, value) => {
        dispatch({
            type : "SET_LANGUAGE_CONTENT_VALUE",
            data : {
                value : value
            }
        })
    }

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={200} align="center" fixed>
                    <Table.HeaderCell>Content Key</Table.HeaderCell>
                    <Table.Cell dataKey="key" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>English Value</Table.HeaderCell>
                    <Table.Cell dataKey="en_value" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>Current Language Value</Table.HeaderCell>
                    <Table.Cell dataKey="value" />
                </Table.Column>

                <Table.Column width={200} align={"center"}>
                    <Table.HeaderCell>New Language Value</Table.HeaderCell>
                    <Table.Cell>{rowData => <Input onChange={value => setInputValue(rowData['id'], value)} size="xs" style={{width : "100px", marginLeft : "auto", marginRight : "auto"}} />}</Table.Cell>
                </Table.Column>
                
                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>
        </>
    )
}