import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Modal, Input, InputPicker, Table, Badge, Pagination, Checkbox } from 'rsuite';

import { Link } from "react-router-dom";

import {Message} from "rsuite";

import {Button} from "rsuite";

import DateRangePicker from 'rsuite/DateRangePicker';

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {useMediaQuery} from "react-responsive";
import { Whisper, Tooltip, IconButton } from "rsuite";
import Filters from "../Filters/Filters";
import {FaFilter} from "react-icons/fa";
import axios from "axios";

import FinancialCredits from "./FinancialDebts";

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const ShowFinancialDebts = () => {

        dispatch({
            type : "SET_FINANCIAL_DEBTS_ID",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_PARTNER",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : true
            }
        })
    }

    const ShowSubmitTransaction = () => {
        dispatch({
            type : "SET_FINANCIAL_DEBTS_ID",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_FINANCIAL_TRANSACTION_MODAL",
            data : {
                show : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        <Whisper
            placement={"top"}
            speaker={
                <Tooltip>Submit Transaction</Tooltip>
            }
        >
            <IconButton style={{marginRight : "10px"}} onClick={ShowSubmitTransaction} className="table-button no-icon"><i className="fas fa-coins"></i></IconButton>
        </Whisper>
        <Whisper
            placement={"top"}
            speaker={
                <Tooltip>Details</Tooltip>
            }
        >
            <IconButton onClick={ShowFinancialDebts} className="table-button no-icon"><i className="fas fa-table"></i></IconButton>
        </Whisper>
      </Table.Cell>
    );
};

const CashierTransaction = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState("");

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_TRANSACTION_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const SubmitTransaction = async () => {
        try {
            var response = await axios.post(window.__APP__.api+"/operators/staticdebts/transaction/"+modals.financialDebtsId+"?platform="+platforms.platform.id, {
                amount : amount
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            if(response.data.error == null) {
                HideModal();
            }
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <Modal onClose={HideModal} size="sm" open={modals.financialTransactionModal}>
                <Modal.Header>
                    <Modal.Title>Perform Debt Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input onChange={setAmount} placeholder="Amount" value={amount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button onClick={SubmitTransaction} appearance="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const PaidTransactionsModal = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();
    
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    const HideModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_PAID_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const setDateRange = () => {

    }

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/operators/credits/list/paid?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&partner="+modals.financialPartnerId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pages);
            }
        });
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetTransactions();
    }, [platforms, page, limit, modals.financialPartnerId]);


    return (
        <>
            <Modal onClose={HideModal} open={modals.partnerPaidModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Financial Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="nav-overview">
                        <Panel className="filters-panel" bordered>
                            <div className="panel-body" style={{flex : 1}}>
                                <DateRangePicker showOneCalendar onChange={setDateRange} style={{width : "100%"}} placeholder="Range" />
                            </div>
                        </Panel>
                        <div>
                            <Table
                                data={transactions}
                                rowClassName={data => data != undefined ? data.checked == 1 ? "checked-row" : "unchecked-row" : ""}
                                height={400}
                            >
                                <Table.Column width={70} align="center">
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell dataKey="credits" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Total Paid</Table.HeaderCell>
                                    <Table.Cell dataKey="amount" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                    <Table.Cell dataKey="shop" />
                                </Table.Column>
                            </Table>

                            <div className="pagination-box">
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const UncheckedPaidTransactionsModal = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();
    
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_NPAID_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const setDateRange = () => {

    }

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/operators/credits/list/paid/unchecked?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&partner="+modals.financialPartnerId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pages);
            }
        });
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetTransactions();
    }, [platforms, page, limit, modals.financialPartnerId]);

    const ToggleTransaction = (id) => {
        axios.get(window.__APP__.api+"/operators/credits/toggle?platform="+platforms.platform.id+"&id="+id+"&partner="+modals.financialPartnerId, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions([]);
                GetTransactions();
            }
        });
    }

    return (
        <>
            <Modal onClose={HideModal} open={modals.partnerNCheckedModal} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Debt Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="nav-overview">
                        <Panel className="filters-panel" bordered>
                            <div className="panel-body" style={{flex : 1}}>
                                <DateRangePicker showOneCalendar onChange={setDateRange} style={{ width : "100%" }} placeholder="Range" />
                            </div>
                        </Panel>
                        <div>
                            <Table
                                data={transactions}
                                height={400}
                            >
                                <Table.Column width={70} align="center">
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell dataKey="credits" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Total Paid</Table.HeaderCell>
                                    <Table.Cell dataKey="amount" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                    <Table.Cell dataKey="shop" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Check</Table.HeaderCell>
                                    <Table.Cell>{data => <Checkbox onChange={() =>  ToggleTransaction(data.id)}></Checkbox>}</Table.Cell>
                                </Table.Column>
                            </Table>

                            <div className="pagination-box" style={{marginTop : "10px"}}>
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const FinancialDebtsModal = (props) => {

    const modals = useSelector(state => state.modals);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : false
            }
        })
    }

    return (
        <>
            <Modal onClose={HideModal} open={modals.financialDebtsModal} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Financial Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FinancialCredits id={props.id} shopId={modals.financialDebtsId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default function PartnerDebts() {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);

    const filters = useSelector(state => state.filters);

    const isSmall = useMediaQuery({ query: '(max-width: 400px)' })

    const bars = useSelector(state => state.bars);

    const modals = useSelector(state => state.modals);

    const [filterType, setFilterType] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState("");
    const [unchecked, setUnchecked] = useState(0);
    const [credits, setCredits] = useState([]);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetPartners = async () => {
        try {
            var response = await axios.get(window.__APP__.api+"/platform/partners?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            if(response.data.error == null) {
                setPartners(response.data.partners)
            }
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(platforms.platform.id > -1) {
            GetPartners()
        }
    }, [platforms]);

    const ToggleNChecked = (id) => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_FINANCIAL_PARTNER_NPAID_MODAL",
            data : {
                show : true
            }
        })
    }


    const GetCredits = (id) => {
        setCredits([]);
        
        axios.get(window.__APP__.api+"/operators/staticdebts/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCredits(response.data.credits);
                setUnchecked(response.data.stats.unchecked)
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0 && filterType != null) {
            GetCredits(filterType);
        }
    }, [platforms.platform, filterType, modals.financialTransactionModal]);


    const ShowPaidModal = (id) => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PARTNER_PAID_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"debts"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Partner Debt's</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Partner Debts</h1>
                        </div>

                        <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                    <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                    <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <InputPicker value={filterType} onChange={setFilterType} data={partners} placeholder="Type" style={{marginLeft : "10px", flexGrow : 1}} />
                            </div>
                        </Panel>

                        {
                            filterType == null ? (
                                <>
                                    <Message showIcon>Partner is not selected</Message>
                                </>
                            ) : (
                                <>
                                    <div className="nav-overview">
                                        <div style={{marginTop : "25px"}}>
                                            <Table
                                                autoHeight
                                                data={credits}
                                            >

                                                <Table.Column width={180}>
                                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                                    <Table.Cell>{data => (data.id != undefined && data.id != "") ? <Link to={"/shop/"+data.id}>{data.shop}</Link> : data.shop}</Table.Cell>
                                                </Table.Column>

                                                <Table.Column minWidth={120} flexGrow={1}>
                                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                                    <Table.Cell dataKey="amount" />
                                                </Table.Column>

                                                <Table.Column minWidth={120} flexGrow={1} align="center">
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                    <ActionCell partner={filterType} />
                                                </Table.Column>
                                            </Table>
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div> 

            <Filters />
            <PaidTransactionsModal />
            <UncheckedPaidTransactionsModal />
            <FinancialDebtsModal id={filterType} />
            <CashierTransaction />
        </>
    )
}