import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

import MKGroups from "./MarketsGroups";
import Countries from "./Countries";
import Tournaments from "./Tournaments";

export default function SBContent() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [activeKey, setActiveKey] = useState("sp-markets-groups");

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"sports-content"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Sportsbook Content</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Sportsbook Content</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Nav activeKey={activeKey} appearance="subtle" onSelect={v => setActiveKey(v)}>
                                <Nav.Item eventKey="sp-markets-groups">Markets Groups</Nav.Item>
                                <Nav.Item eventKey="sp-countries">Countries</Nav.Item>
                                <Nav.Item eventKey="sp-tournaments">Tournaments</Nav.Item>
                            </Nav>

                            {
                                activeKey == "sp-markets-groups" ? (
                                    <MKGroups />
                                ) : activeKey == "sp-countries" ? (
                                    <Countries />
                                ) : activeKey == "sp-tournaments" ? (
                                    <Tournaments />
                                ) : (
                                    <></>
                                )
                            }
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}