import { useEffect, useState } from "react";

import axios from "axios";

import "./TreeTable.css"
import { Loader } from "rsuite";
import { useSelector } from "react-redux";

const roles = {
    4 : "#f9a19a",
    3 : "#ffc99c"
}

const TreeRow = (props) => {

    const {row, columns, inner, ToggleSubs} = props;

    return (
        <>
        {
            row.subrows != undefined ? (
                <div className="att-list-table-row" style={row["RoleColor"] != undefined ? {"--role-bg" : row["RoleColor"] } : { "--role-bg" : "#f9a19a"} }>
                    <div className={"att-inner"+((inner && row.subrows == undefined) ? "-row" : "")}>
                        {
                            columns.map(column => {
                                return (
                                    <>
                                        {
                                            column.Key == "Role" ? (
                                                <div style={row["RoleColor"] != undefined ? {"--role-bg" : row["RoleColor"] } : { "--role-bg" : "#f9a19a"} } className="att-inner-column att-inner-column-role">
                                                    <div className="att-inner-column-label">
                                                        {
                                                            row.HasSub ? (
                                                                <i onClick={() => ToggleSubs(row["Id"])} className={"fas fa-chevron-"+(row.subrows != undefined ? "up" : "down")}></i>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        <span style={row["RoleColor"] != undefined ? {"--role-bg" : row["RoleColor"] } : { "--role-bg" : "#f9a19a"} } className="role-bg-span">{row[column.Key]}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="att-inner-column">
                                                    <div className="att-inner-column-label">
                                                        <span>{row[column.Key]}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
            
                    {
                        row.subrows != undefined ? row.subrows.map(subrow => {
                            return (
                                <TreeRow columns={columns} row={subrow} inner={true} ToggleSubs={ToggleSubs} />
                            )
                        }) : (
                            <></>
                        )
                    }
                </div>
            ) : (
                <div className={"att-inner"+(inner ? "-row" : "")}>
                    {
                        columns.map(column => {
                            return (
                                <>
                                    {
                                        column.Key == "Role" ? (
                                            <div style={row["RoleColor"] != undefined ? {"--role-bg" : row["RoleColor"] } : { "--role-bg" : "#f9a19a"} } className="att-inner-column att-inner-column-role">
                                                <div className="att-inner-column-label">
                                                    {
                                                        row.HasSub ? (
                                                            <i onClick={() => ToggleSubs(row["Id"])} className={"fas fa-chevron-"+(row.subrows != undefined ? "up" : "down")}></i>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    <span style={row["RoleColor"] != undefined ? {"--role-bg" : row["RoleColor"] } : { "--role-bg" : "#f9a19a"} } className="role-bg-span">{row[column.Key]}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="att-inner-column">
                                                <div className="att-inner-column-label">
                                                    <span>{row[column.Key]}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        })
                    }
                </div>
            )
        }
        </>
    )
}

function RecursivelyCheck(array, id) {
    for(var i=0; i<array.length; i++) {
        if(array[i].Id == id) {
            if(array[i].subrows != undefined) {
                return true;
            }
        }

        if(array[i].subrows != undefined) {
            var f = RecursivelyCheck(array[i].subrows, id)
            if(f) {
                return true;
            }
        }
        
    }

    return false;
}
function RecursivelyDelete(array, id) {
    for(var i=0; i<array.length; i++) {
        if(array[i].Id == id) {
            array[i].subrows = undefined;
        }

        if(array[i].subrows != undefined) {
            RecursivelyDelete(array[i].subrows, id)
        }
        
    }
}
function RecursivelyAdd(array, id, subrows) {
    for(var i=0; i<array.length; i++) {
        if(array[i].Id == id) {
            array[i].subrows = subrows;
            return ;
        }

        if(array[i].subrows != undefined) {
            RecursivelyAdd(array[i].subrows, id, subrows)
        }
        
    }
}

export default function TreeTable(props) {

    const {columns, data, loading} = props;

    const platforms = useSelector(state => state.platforms);

    const [mlaoding, setMLoading] = useState(false);

    const [rows, setRows] = useState([])

    useEffect(() => {
        setRows(data);
    }, [data])
    useEffect(() => {
        setMLoading(loading)
    }, [loading])

    const ToggleSubs = async (id) => {
        var mrows = Object.assign([], rows);

        // var fIndex = mrows.findIndex(e => {
        //     return e.Id == id
        // })

        var fCheck = RecursivelyCheck(mrows, id);

        if(fCheck) {
            RecursivelyDelete(mrows, id);
        } else {
            setMLoading(true);

            const response = await axios.get(window.__APP__.api + "/online/agents/agent/tree/"+id+"?platform="+platforms.platform.id+"&username="+props.username, {
                headers : {
                    "Authorization" : "bearer "+localStorage.getItem("jwt")
                }
            })

            setMLoading(false);

            if(response.data.Error == null) {
                // mrows[fIndex].subrows = response.data.Users;
                RecursivelyAdd(mrows, id, response.data.users)
            }
        }

        // if(fIndex >= 0 && mrows[fIndex].subrows != undefined) {
        //     mrows[fIndex].subrows = undefined;
        // } else {
            
        // }

        setRows(mrows);
    }

    return (
        <>
            <div className="agent-tree-table">
                <div className="att-table-area">
                    <div className="agent-tree-table-heading">
                        <div className="att-row">
                            {
                                columns.map(column => {
                                    return (
                                        <div className="agent-tt-column">
                                            <div className="att-column-label">
                                                <span>{column.Name}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                
                <div className="att-list-wrapper">
                    {
                        mlaoding ? (
                            <div className="att-list-wrapper-loader">
                                <Loader />
                            </div>
                        ) : (
                            <div className="att-list-table">
                                {
                                    rows.map(row => {
                                        return (
                                            <>
                                                <TreeRow ToggleSubs={ToggleSubs} columns={columns} row={row}></TreeRow>
                                            </>
                                        )
                                    })
                                }
                            </div>  
                        )
                    }
                </div>
            </div>
        </>
    )
}