import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Loader, Message, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function Multibonus(props) {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(true);
    const [bonus, setBonus] = useState(null);
    const [minimumOdds, setMinimumOdds] = useState("");
    const [boundaries, setBoundaries] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [alertData, setAlertData] = useState({
        type : "",
        content : ""
    })

    const GetActiveBonus = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api + "/sportsbook/multibonus/list?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null && response.data.bonus != null) {
            setBonus(response.data.bonus);
            setMinimumOdds(response.data.bonus.MinBonusPrice);
            setBoundaries(response.data.bonus.Bonudaries);
        }

    }

    useEffect(() => {
        GetActiveBonus();
    }, []);

    const setBoundItem = (id, type, value) => {
        const bounds = Object.assign([], boundaries);

        var findex = bounds.findIndex(d => {
            return d.Id == id
        })

        if(findex >= 0) {
            bounds[findex][type] = value;
        }

        setBoundaries(bounds);
    }
    const removeBoundary = (id) => {
        const bounds = Object.assign([], boundaries);

        var findex = bounds.findIndex(d => {
            return d.Id == id
        })

        bounds.splice(findex, 1);

        setBoundaries(bounds);
    }

    const addBoundary = () => {
        const bounds = Object.assign([], boundaries);

        bounds.push({
            Id : bounds.length,
            low : 0,
            high : 0,
            bonus : 1
        })

        setBoundaries(bounds);
    }

    const SaveSkinBonus = async () => {

        setAlertData({
            type : "",
            content : ""
        })

        setSaveLoading(true);

        const response = await axios.post(window.__APP__.api + "/sportsbook/multibonus/save?platform=" + platforms.platform.id, {
            MinBonusPrice : minimumOdds,
            Boundaries : boundaries
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setSaveLoading(false);

        if(response.data.error == null) {
            setAlertData({
                type : "success",
                content : "Bonus updated successfully"
            })
        } else {
            setAlertData({
                type : "error",
                content : "An error occured while saving bonus"
            })
        }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"sports-multibonus"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Sportsbook Multi-Bonus</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Sportsbook Multi-Bonus</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                                loading ? (
                                    <div className="loading-area"><Loader /></div>
                                ) : (
                                    <>
                                        {
                                            bonus == null ? (
                                                <div className="no-bonus">
                                                    <Message type="info">No active multi bonus.</Message>
                                                </div>
                                            ) : (
                                                <>
                                                    {
                                                        alertData.type != "" ? (
                                                            <div className="fb-message">
                                                                <Message showIcon type={alertData.type}>{alertData.content}</Message>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    <div className='rs-offering-content rs-operators-board'>
                                                        <div className='rs-bonus-offering'>
                                                            <div className='rs-bonus-item'>
                                                                <label>Minimum Bonus Odds</label>
                                                                <Input onChange={e => setMinimumOdds(e)} value={minimumOdds} placeholder='Minimum odds' />
                                                            </div>

                                                            <h3>Bonus Boundaries</h3>

                                                            <div className='rs-bonus-bounds'>
                                                                {
                                                                    boundaries.map(boundary => {
                                                                        return (
                                                                            <div className='rs-bonus-bound'>
                                                                                <div className='rs-bound-item'>
                                                                                    <label>Low Bound</label>
                                                                                    <Input value={boundary.low} placeholder="Low Bound" onChange={e => setBoundItem(boundary.Id, "low", e)} />
                                                                                </div>
                                                                                <div className='rs-bound-item'>
                                                                                    <label>High Bound</label>
                                                                                    <Input value={boundary.high} placeholder="High Bound" onChange={e => setBoundItem(boundary.Id, "high", e)} />
                                                                                </div>
                                                                                <div className='rs-bound-item'>
                                                                                    <label>Bonus Value</label>
                                                                                    <div className='bonus-extended-input'>
                                                                                        <Input value={boundary.bonus} placeholder="Bonus Value" onChange={e => setBoundItem(boundary.Id, "bonus", e)} />
                                                                                        <Button onClick={() => removeBoundary(boundary.Id)} type="primary"><i className='fas fa-times'></i></Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>

                                                            <div className='bonus-boundaries-actions'>
                                                                <Button onClick={() => addBoundary()} type="primary" className='action-item'><i className='fas fa-plus'></i> Add Boundary</Button>
                                                            </div>

                                                            <div className='rs-bonus-action'>
                                                                <Button type="primary" color="primary" loading={saveLoading} onClick={() => SaveSkinBonus()}>Save Bonus</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}