import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Input } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";

import {useState, useEffect} from "react";

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [toggleStatus, setToggleStatus] = useState(rowData['active']);

    const ToggleJackpot = () => {
        axios.post(window.__APP__.api+"/jackpots/toggle/"+rowData['id']+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setToggleStatus(response.data.toggle);
            }
        });
    }

    return (
      <Table.Cell {...props} className="link-group">
            <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Settings</Tooltip>
                    }
                >
                <Link to={"/jackpot/settings/"+rowData['id']}>
                    <IconButton style={{marginRight : "5px"}} className="table-button no-icon"><i className="fas fa-cog"></i></IconButton>
                </Link>
            </Whisper>
            <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Winners</Tooltip>
                    }
                >
                    <Link to={"/jackpot/winners/"+rowData['id']}>
                        <IconButton style={{marginRight : "5px"}} className="table-button no-icon"><i className="fas fa-trophy"></i></IconButton>
                    </Link>
            </Whisper>
            <Toggle checked={toggleStatus == 0} onChange={ToggleJackpot} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

export default function JackpotsTable() {

    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [jackpots, setJackpots] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const GetJackpots = () => {
        setLoading(true);
        axios.get(window.__APP__.api+"/settings/jackpots/list"+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setJackpots(response.data.jackpots);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetJackpots();
        }
    }, [platforms.platform, limit, page]);

    // const setShare = (share) => {
    //     dispatch({
    //         type : "SET_SETTINGS_PROVIDER_SHARE",
    //         data : {
    //             share : share
    //         }
    //     })
    // }

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={jackpots}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>
                <Table.Column width={115} align="center">
                    <Table.HeaderCell>Jackpot Title</Table.HeaderCell>
                    <Table.Cell dataKey="title" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Type</Table.HeaderCell>
                    <Table.Cell>{ data => <>{data.key == "minor_jackpot" ? "Minor Jackpot" : data.key == "major_jackpot" ? "Major Jackpot" : data.key == "grand_jackpot" ? "Grand Jackpot" : data.key == "shop_jackpot" ? "Shop Jackpot" : "General Jackpot"}</>}</Table.Cell>
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Value</Table.HeaderCell>
                    <Table.Cell dataKey="value" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Percentage</Table.HeaderCell>
                    <Table.Cell dataKey="percentage" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Threshold</Table.HeaderCell>
                    <Table.Cell dataKey="threshold" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Max Win</Table.HeaderCell>
                    <Table.Cell dataKey="max_win" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Difficulty</Table.HeaderCell>
                    <Table.Cell dataKey="difficulty" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <Table.HeaderCell>Jackpot Chance</Table.HeaderCell>
                    <Table.Cell dataKey="chance" />
                </Table.Column>
                <Table.Column width={115} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}