import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Modal, Input, InputPicker, Table, Badge, Pagination, Checkbox } from 'rsuite';

import { Link } from "react-router-dom";

import {Message} from "rsuite";

import {Button} from "rsuite";

import DateRangePicker from 'rsuite/DateRangePicker';

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {useMediaQuery} from "react-responsive";
import { Whisper, Tooltip, IconButton } from "rsuite";
import Filters from "../Filters/Filters";
import {FaFilter} from "react-icons/fa";
import axios from "axios";

// import FinancialCredits from "./FinancialDebts";

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const ShowShopBookModal = () => {
        dispatch({
            type : "SET_SHOPS_BOOK_MODAL",
            data : {
                modal : true,
                id : rowData["id"],
                status : rowData["status"]
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        <Whisper
            placement={"top"}
            speaker={
                <Tooltip>Update Status</Tooltip>
            }
        >
            <IconButton onClick={ShowShopBookModal} style={{marginRight : "10px"}} className="table-button no-icon"><i className="fas fa-info"></i></IconButton>
        </Whisper>
      </Table.Cell>
    );
};

const ShopBookStatus = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const [status, setStatus] = useState();

    const HideModal = () => {
        dispatch({
            type : "SET_SHOPS_BOOK_MODAL",
            data : {
                modal : false,
                id : null,
                status : 0
            }
        })
    }

    const SubmitShopStatus = async () => {
        try {

            var response = await axios.post(window.__APP__.api+"/operators/shopsbook/status/"+modals.shopsBookId+"?platform="+platforms.platform.id, {
                status : status
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            if(response.data.error == null) {
                HideModal();
            }
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setStatus(modals.shopBookStatus);
    }, [modals.shopBookStatus]);

    return (
        <>
            <Modal onClose={HideModal} size="sm" open={modals.shopsBookModal}>
                <Modal.Header>
                    <Modal.Title>Shop Book Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputPicker defaultValue={modals.shopBookStatus} onChange={v => setStatus(v)} style={{width : "100%"}} data={[
                        {
                            label : "Not Contacted",
                            value : 0
                        },
                        {
                            label : "Contacted & Operating",
                            value : 1
                        },
                        {
                            label : "Contacted & Rejected",
                            value : 2
                        }
                    ]}></InputPicker>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button onClick={SubmitShopStatus} appearance="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default function Shopsbook() {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);

    const filters = useSelector(state => state.filters);

    const isSmall = useMediaQuery({ query: '(max-width: 400px)' })

    const bars = useSelector(state => state.bars);

    const modals = useSelector(state => state.modals);

    const [shops, setShops] = useState([]);


    const GetShopsbook = (id) => {
        setShops([]);
        
        axios.get(window.__APP__.api+"/operators/shopsbook?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShops(response.data.shops);
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetShopsbook();
        }
    }, [platforms.platform, modals.shopsBookModal]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"shopsbook"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Shops Book</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Shops Book</h1>
                        </div>

                        <div className="nav-overview">
                            <div style={{marginTop : "25px"}}>
                                <Table
                                    autoHeight
                                    data={shops}
                                >

                                    <Table.Column minWidth={120} flexGrow={1}>
                                        <Table.HeaderCell>Shop Name</Table.HeaderCell>
                                        <Table.Cell dataKey="name"></Table.Cell>
                                    </Table.Column>

                                    <Table.Column minWidth={120} flexGrow={1}>
                                        <Table.HeaderCell>Username</Table.HeaderCell>
                                        <Table.Cell dataKey="username" />
                                    </Table.Column>

                                    <Table.Column minWidth={120} flexGrow={1}>
                                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                                        <Table.Cell dataKey="phone" />
                                    </Table.Column>

                                    <Table.Column minWidth={120} flexGrow={1}>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                        <Table.Cell>{data => data.status == 0 ? <Badge color="blue" content="Not Contacted"></Badge> : data.status == 1 ? <Badge color="green" content="Contacted & Operating"></Badge> : <Badge color="red" content="Contacted & Rejected"></Badge>}</Table.Cell>
                                    </Table.Column>

                                    <Table.Column minWidth={120} flexGrow={1} align="center">
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                        <ActionCell />
                                    </Table.Column>
                                </Table>
                            </div>  
                        </div>
                    </div>
                </div>
            </div> 

            <Filters />

            <ShopBookStatus />
        </>
    )
}