import { Table, IconButton, Badge } from "rsuite";
import { Panel, InputPicker } from "rsuite";

import Pagination from 'rsuite/Pagination';
import { FaFilter } from "react-icons/fa";

import { Tooltip, Toggle, Whisper, Modal, Button } from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import { Link } from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
            <Link style={{ marginRight: "10px" }}>
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Details</Tooltip>
                    }
                >
                    <IconButton disabled={rowData["hasPreview"] == 0} className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
                </Whisper>
            </Link>
        </Table.Cell>
    );
};

export default function Transactions() {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);
    const [performer, setPerformer] = useState(1);

    const [transactions, setTransactions] = useState([]);
    const [summary, setSummary] = useState({});

    const [dateFrom, setDateFrom] = useState(moment().startOf("month").toDate());
    const [dateTo, setDateTo] = useState(moment().endOf("month").toDate());

    const transactionFilterTypes = [
        {
            label: "Deposit",
            value: 0
        },
        {
            label: "Withdraw",
            value: 1
        }
    ]
    const [performers, setPerformers] = useState([
        {
            label: "Parent",
            value: 0
        },
        {
            label: "Self",
            value: 1
        }
    ])

    const GetTransactions = () => {
        setLoading(true);

        axios.get(window.__APP__.api + "/online/agents/agent/transactions/" + id + "?platform=" + platforms.platform.id + "&page=" + page + "&limit=" + limit + "&type=" + filterType + "&dateFrom=" + (dateFrom != "" ? moment(dateFrom).format("YYYY-MM-DD HH:mm:ss") : "") + "&dateTo=" + (dateTo != "" ? moment(dateTo).format("YYYY-MM-DD HH:mm:ss") : "") + "&parent=" + (performer == 0 ? 1 : 0), {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);

            if (response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pagination.pages);
                setSummary(response.data.summary);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo, performer]);

    const setDateRange = (dates) => {
        if (dates != null && dates.length == 2 && dates[0] != null && dates[1] != null) {
            setDateFrom(dates[0]);
            setDateTo(dates[1]);
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                        <FaFilter style={{ fontSize: "16px", position: "relative", top: "1.5px" }} />
                        <h1>Filters</h1>
                    </div>

                    <div className="panel-body filters-body-new">
                        <InputPicker data={performers} value={performer} placeholder="Performer" onChange={setPerformer} style={{ flex: 1 }} />
                        <InputPicker data={transactionFilterTypes} placeholder="Transaction Type" onChange={setFilterType} style={{ flex: 1 }} />
                        <DateRangePicker value={(dateFrom != "" && dateTo != "") ? [dateFrom, dateTo] : null} showOneCalendar onChange={setDateRange} style={{ flex: 1 }} placeholder="Range" />
                    </div>
                </Panel>

                <div className="overview-boxes">
                    {
                        summary.deposits != undefined && (
                            <div className="box" style={{ maxWidth: "20%" }}>
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{summary.deposits}</h2>
                                    <h1>Total Deposit</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon balance-icon"></div>
                                </div>
                            </div>
                        )
                    }
                    {
                        summary.withdraws != undefined && (
                            <div className="box" style={{ maxWidth: "20%" }}>
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{summary.withdraws}</h2>
                                    <h1>Total Withdraw</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon balance-icon"></div>
                                </div>
                            </div>
                        )
                    }
                </div>


                <div>
                    <Table
                        autoHeight
                        data={transactions}
                        loading={loading}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={200} flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell>{data => <>{data.type == "Deposit" ? <Badge content="Deposit" color="green"></Badge> : data.type == "Withdraw" ? <Badge content="Withdraw" color="red"></Badge> : <></>}</>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={200} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Performer</Table.HeaderCell>

                            <Table.Cell>
                                {
                                    data => <>{data.performerId == "" ? data.performer : <Link to={"/online/agent/" + data.performerId}>{data.performer}</Link>}</>
                                }
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver</Table.HeaderCell>
                            <Table.Cell>
                                {
                                    data => <><Link to={"/online/agent/" + data.receiverId}>{data.receiver}</Link></>
                                }
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Sender Balance</Table.HeaderCell>
                            <Table.Cell dataKey="performerBalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver Balance</Table.HeaderCell>
                            <Table.Cell dataKey="receiverBalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25, 50, 100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}