import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import {AiOutlinePlusCircle, AiFillPrinter} from "react-icons/ai";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Loader} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import Filters from "../../Filters/Filters";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

import ReactExport from "react-export-excel";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
          <Link style={{marginRight : "10px"}}>
              <Whisper
                  placement={"top"}
                  speaker={
                      <Tooltip>Details</Tooltip>
                  }
              >
                  <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
              </Whisper>
          </Link>
        </Table.Cell>
      );
};

export default function FinancialReport() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [fullReport, setFullReport] = useState({});

    const [boxes, setBoxes] = useState({});

    const filters = useSelector(state => state.filters);

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        }
    ]

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const GetFullReport = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/shops/getFullReport/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFullReport(response.data.report);
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetFullReport();
        }
    }, [platforms.platform, filters.dateRange]);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    return (
        <>
            <div className="nav-overview">
                    

                <div className="filters-row">
                    <div className="filters-side">
                        <ExcelFile element={<Button style={{marginRight : "10px"}} className="head-btn" color={"primary"}><AiFillPrinter style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Export Report</Button>}>
                            <ExcelSheet data={fullReport.cashbacks} name="Cashback">
                                <ExcelColumn label="Total Cashback" value="payout"/>
                            </ExcelSheet>
                            <ExcelSheet data={fullReport.financials} name="Financial">
                                <ExcelColumn label="Total Deposit" value="deposit"/>
                                <ExcelColumn label="Total Withdraw" value="withdraw"/>
                                <ExcelColumn label="Profit" value="profit"/>
                            </ExcelSheet>
                            <ExcelSheet data={fullReport.jackpots} name="Jackpots">
                                <ExcelColumn label="Jackpot" value="jackpot"/>
                                <ExcelColumn label="Jackpot Payout" value="payout"/>
                            </ExcelSheet>
                        </ExcelFile>
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>
                            
                {
                    fullReport.cashbacks != null && (
                        <>
                        <div className="reports">
                            <Panel className="report-panel" header="Cashbacks" collapsible bordered>
                                <Table
                                    // height={600}
                                    autoHeight
                                    data={fullReport.cashbacks}
                                    // sortColumn={sortColumn}
                                    // sortType={sortType}
                                    // onSortColumn={handleSortColumn}
                                    // loading={loading}
                                >
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Cashback Payout</Table.HeaderCell>
                                        <Table.Cell>{data => data.payout+" TND"}</Table.Cell>
                                    </Table.Column>
                                </Table>
                            </Panel>

                            <Panel className="report-panel" header="Jackpots" collapsible bordered>
                                <Table
                                    // height={600}
                                    autoHeight
                                    data={fullReport.jackpots}
                                >
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Jackpot</Table.HeaderCell>
                                        <Table.Cell dataKey="jackpot" />
                                    </Table.Column>
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Jackpot Payout</Table.HeaderCell>
                                        <Table.Cell>{data => data.payout+" TND"}</Table.Cell>
                                    </Table.Column>
                                </Table>
                            </Panel>

                            <Panel className="report-panel" header="Financials" collapsible bordered>
                                <Table
                                    // height={600}
                                    autoHeight
                                    data={fullReport.financials}
                                    // sortColumn={sortColumn}
                                    // sortType={sortType}
                                    // onSortColumn={handleSortColumn}
                                    // loading={loading}
                                >
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Deposit</Table.HeaderCell>
                                        <Table.Cell>{data => data.deposit+" TND"}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Withdraw</Table.HeaderCell>
                                        <Table.Cell>{data => data.withdraw+" TND"}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={200} align="center" fixed>
                                        <Table.HeaderCell>Profit</Table.HeaderCell>
                                        <Table.Cell>{data => data.profit+" TND"}</Table.Cell>
                                    </Table.Column>
                                </Table>
                            </Panel>
                        </div>       
                        </>
                    )
                }
            </div>

            <Filters /> 
        </>
    )
}