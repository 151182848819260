import React, {useState, useEffect} from "react";
import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip } from "rsuite";
import {FaFilter} from "react-icons/fa";

import {useDispatch, useSelector} from "react-redux";

import { useHistory } from "react-router";

import { Pie, Radar, PolarArea } from 'react-chartjs-2';

import Filters from "../../Filters/Filters";

import GamesTables from "./Addons/GamesTables";
import TopWinsTable from "./Addons/TopWinsTable";

import axios from "axios";

export default function Providers() {

    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [mostPlayedLabels, setMostPlayedLabels] = useState([]);
    const [mostPlayedValues, setMostPlayedValues] = useState([]);
    const [gameTypesLabels, setGameTypesLabels] = useState([]);
    const [gameTypesValues, setGameTypesValues] = useState([]);
    const [gameFavouritesLabels, setGameFavouritesLabels] = useState([]);
    const [gameFavouritesValues, setGameFavouritesValues] = useState([]);

    const history = useHistory();

    const bars = useSelector(state => state.bars);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const getGamesMostPlayed = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api+"/statistics/games/mostplayed?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setMostPlayedLabels(response.data.data.labels);
                setMostPlayedValues(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }
    const getGameTypes = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api+"/statistics/games/types?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setGameTypesLabels(response.data.data.labels);
                setGameTypesValues(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }
    const getFavouriteGames = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api+"/statistics/games/favourites?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setGameFavouritesLabels(response.data.data.labels);
                setGameFavouritesValues(response.data.data.data);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            getGamesMostPlayed();
            getGameTypes();
            getFavouriteGames();
        }
    }, [platforms.platform, filters.dateRange]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"stats-games"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>
                    
                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Statistics</Breadcrumb.Item>
                            <Breadcrumb.Item active>Games</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Games Statistics</h1>

                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <div className="stats-boxes">
                            {
                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("stats_games_mostplayed"))) >= 0 ? (
                                    <div className="stats-box col-3" style={{minWidth: "31.5%"}}>
                                        <div className="stats-box-header">
                                            <h3>Most Played Games</h3>
                                        </div>
                                        <div className="stats-box-content">
                                            <Pie data={{
                                                labels: mostPlayedLabels,
                                                datasets: [
                                                {
                                                    label: 'Most Played',
                                                    data: mostPlayedValues,
                                                    backgroundColor: '#8e44ad',
                                                }
                                                ]
                                            }} options={{
                                                plugins : {
                                                    legend: {
                                                        display: false
                                                    }
                                                }
                                            }} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }

                            {
                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("stats_games_favourites"))) >= 0 ? (
                                    <div className="stats-box col-3" style={{minWidth: "31%"}}>
                                        <div className="stats-box-header">
                                            <h3>Favourite Games</h3>
                                        </div>
                                        <div className="stats-box-content">
                                            <Pie data={{
                                                labels: gameFavouritesLabels,
                                                datasets: [
                                                {
                                                    label: 'Most Played',
                                                    data: gameFavouritesValues,
                                                    backgroundColor: '#8e44ad',
                                                }
                                                ]
                                            }} options={{
                                                plugins : {
                                                    legend: {
                                                        display: false
                                                    }
                                                }
                                            }} />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="stats-box col-3" style={{minWidth: "31%"}}>
                                <div className="stats-box-header">
                                    <h3>Game Categories Statistics</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={{
                                        labels: gameFavouritesLabels,
                                        datasets: [
                                          {
                                            label: 'Most Played',
                                            data: gameFavouritesValues,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                        </div>
                                
                        {
                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("stats_games_topwins"))) >= 0 ? (
                                <div className="stats-boxes">
                                    <div className="stats-box col-1" style={{maxWidth : "95.7%"}}>
                                        <div className="stats-box-header">
                                            <h3>Top Wins</h3>
                                        </div>
                                        <div className="stats-box-content">
                                            <TopWinsTable />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        

                        <div className="stats-table">
                            {/* <GamesTables></GamesTables> */}
                        </div>
                    </div>
                </div>
            </div> 

            <Filters />
        </>
    )
}