import {useState, useEffect} from "react";

import {Line, PolarArea} from "react-chartjs-2";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import {Table} from "rsuite";

import {useHistory, Link} from "react-router-dom";

export default function TopBalances(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);

    const {timeframe} = props;

    const [loading, setLoading] = useState(false);

    const [highBalances, setHighBalances] = useState([]);

    const [details, setDetails] = useState({labels : [], datasets : [], background : []});

    const GetHighBalances = (tf) => {
        setLoading(true);
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/statistics/highbalances?platform="+platforms.platform.id+"&timeframe="+tf+"&currency="+(platforms.currency.id >= 0 ? platforms.currency.id : ""), {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setHighBalances(response.data.data);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetHighBalances(timeframe);
        }
    }, [platforms.platform, platforms.currency, timeframe]);

    return (
        <>
            <div className="box-chart" style={(props.online != undefined && props.online == true) ? {minWidth : "64%", maxWidth : "66%", maxHeight : "500px", overflow : "auto"} : { minWidth : platforms.platform.agent_enabled == 1 ? "66%" : "98.5%" }}>
                <div className="box-chart-header">
                    <i className="fas fa-award" />
                    <h3>High Balance Users</h3>
                </div>
                <Table
                    // height={50}
                    // fillHeight
                    autoHeight
                    data={highBalances}
                    loading={loading}
                >
                    <Table.Column width={60}>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>

                    <Table.Column width={150} flexGrow={1}>
                        <Table.HeaderCell>Player</Table.HeaderCell>
                        {
                            platforms.platform.type == 1 ? (
                                <Table.Cell>{ data => <Link to={"/kplayer/"+data.id}>{data.player}</Link>}</Table.Cell>
                            ) : (platforms.platform.type == 3 ) ? (
                                <Table.Cell>{ data => <Link to={"/online/player/"+data.id}>{data.player}</Link>}</Table.Cell>
                            ) : (
                                <Table.Cell>{ data => <Link to={"/player/"+data.id}>{data.username}</Link>}</Table.Cell>
                            )
                        }
                    </Table.Column>

                    <Table.Column width={150} flexGrow={1}>
                        <Table.HeaderCell>Balance</Table.HeaderCell>
                        <Table.Cell dataKey="balance" />
                    </Table.Column>

                    {
                        platforms.platform.agent_enabled == 1 ? (
                            <>
                                <Table.Column width={150} flexGrow={1}>
                                    <Table.HeaderCell>Parent</Table.HeaderCell>
                                    <Table.Cell>{ data => <Link to={"/online/agent/"+data.parentId}>{data.parent}</Link>}</Table.Cell>
                                </Table.Column>
                            </>
                        ) : (
                            <></>
                        )
                    }
                </Table>
            </div>
        </>
    )
}