import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import Transfer from "../Transfer/Transfer";

import LogsTable from "./LogsTable";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function LoginLogs() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [player, setPlayer] = useState("");
    const [cin, setCin] = useState("");
    const [shops, setShops] = useState([]);
    const [partners, setPartners] = useState([]);
    const [regions, setRegions] = useState([]);
    const [shop, setShop] = useState("");
    const [partner, setPartner] = useState("");
    const [region, setRegion] = useState("");

    const GetShops = () => {
        axios.get(window.__APP__.api+"/platform/shops?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setShops(response.data.shops);
        });
    }
    const GetPartners = () => {
        axios.get(window.__APP__.api+"/platform/partners?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setPartners(response.data.partners);
        });
    }
    const GetRegions = () => {
        axios.get(window.__APP__.api+"/platform/regions?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setRegions(response.data.regions);
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetShops();
            GetPartners();
            GetRegions();
        }
    }, [platforms]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={""}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Login Logs</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Login Logs</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <LogsTable></LogsTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}