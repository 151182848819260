import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import Badge from 'rsuite/Badge';

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";

import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const ShowFinancialDebts = () => {
        dispatch({
            type : "SET_FINANCIAL_DEBTS_ID",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_PARTNER",
            data : {
                id : props.partner
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        <div style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Details</Tooltip>
                }
            >
                <IconButton onClick={ShowFinancialDebts} className="table-button no-icon"><i className="fas fa-table"></i></IconButton>
            </Whisper>
        </div>
      </Table.Cell>
    );
};

export default function Credits() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [credits, setCredits] = useState([]);
    const [unchecked, setUnchecked] = useState(0);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        },
        {
            label : "Admin Deposit",
            value : 2
        },
        {
            label : "Admin Withdraw",
            value : 3
        }
    ]

    const ShowPaidModal = () => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PARTNER_PAID_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const GetCredits = () => {
        axios.get(window.__APP__.api+"/operators/credits/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCredits(response.data.credits);
                setUnchecked(response.data.stats.unchecked)
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetCredits();
        }
    }, [platforms.platform]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const ToggleNChecked = (id) => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_FINANCIAL_PARTNER_NPAID_MODAL",
            data : {
                show : true
            }
        })
    }

    return (
        <>
            <div className="nav-overview">
                
                <div>
                    <Table
                        autoHeight
                        data={credits}
                    >

                        <Table.Column width={180}>
                            <Table.HeaderCell>Shop</Table.HeaderCell>
                            <Table.Cell>{data => (data.id != undefined && data.id != "") ? (data.type == 1 ? <Link to={"/shop/"+data.id}>{data.shop}</Link> : <Link to={"/agent/"+data.id}>{data.shop}</Link>)  : data.shop}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                            <Table.Cell dataKey="balance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Users Balance</Table.HeaderCell>
                            <Table.Cell dataKey="subBalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell partner={id} />
                        </Table.Column>
                    </Table>
                </div>  
            </div>
        </>
    )
}