import {useState, useEffect} from "react";

import { useDispatch, useSelector, useselector } from "react-redux";
import { useHistory } from "react-router";
import { Placeholder, Loader } from 'rsuite';

// import {Navigate} from "react-router-dom";

import axios from "axios";

export default function Overview(props) {

    const {timeframe} = props;

    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [boxData, setBoxData] = useState({});

    const GetOverviewBoxes = (tf) => {
        setBoxData({});
        axios.get(window.__APP__.api+"/statistics/overview?platform="+platforms.platform.id+"&timeframe="+tf+"&currency="+(platforms.currency.id >= 0 ? platforms.currency.id : ""), {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setBoxData(response.data.overview);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetOverviewBoxes(timeframe);
        }
    }, [platforms.platform, timeframe, platforms.currency]);

    const ToggleBalancePartitions = () => {
        if(platforms.platform.type == 0) {
            dispatch({
                type : "SHOW_BALANCE_MODAL",
                data : {
                    modal : true
                }
            })
        }
    }

    return (
        <>
            <div className="dashboard-overview-boxes">

                {
                    platforms.platform.type == 0 ? (
                        <div className="dashboard-overview-box">
                            <div style={{flex : "1 1 auto"}}>
                                <h2>{boxData.operators != undefined ? boxData.operators.total : <Loader />}</h2>
                                <h1>Partners</h1>
                            </div>
                            <div style={{flex : "1 1 auto", position : "relative"}}>
                                <div className="icon operators-icon"></div>
                            </div>
                        </div>
                    ) : (platforms.platform.type == 1 ) ? (
                        <div className="dashboard-overview-box">
                            <div style={{flex : "1 1 auto"}}>
                                <h2>{boxData.operators != undefined ? boxData.operators.total : <Loader />}</h2>
                                <h1>Partners</h1>
                            </div>
                            <div style={{flex : "1 1 auto", position : "relative"}}>
                                <div className="icon operators-icon"></div>
                            </div>
                        </div>
                    ) : (<></>)
                }

                {
                    (platforms.platform.type == 1 || platforms.platform.type == 0) && (
                        <div className="dashboard-overview-box">
                            <div style={{flex : "1 1 auto"}}>
                                <h2>{boxData.agents != undefined ? boxData.agents.total : <Loader />}</h2>
                                <h1>Agents</h1>
                            </div>
                            <div style={{flex : "1 1 auto", position : "relative"}}>
                                <div className="icon operators-icon"></div>
                            </div>
                        </div>
                    )
                }

                {
                    (platforms.platform.type == 0 || platforms.platform.type == 1) && (
                        <div className="dashboard-overview-box">
                            <div style={{flex : "1 1 auto"}}>
                                <h2>{boxData.shops != undefined ? boxData.shops.total : <Loader />}</h2>
                                <h1>Shops</h1>
                            </div>
                            <div style={{flex : "1 1 auto", position : "relative"}}>
                                <div className="icon shops-icon"></div>
                            </div>
                        </div>
                    )
                }

                {
                    (platforms.platform.type == 3) && (
                        <>  
                            <div className="dashboard-overview-box">
                                <div style={{flex : "1 1 auto"}}>
                                    <h2>{boxData.deposit != undefined ? boxData.deposit.total : <Loader />}</h2>
                                    <h1>Total Deposit</h1>
                                </div>
                                <div style={{flex : "1 1 auto", position : "relative"}}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                            <div className="dashboard-overview-box">
                                <div style={{flex : "1 1 auto"}}>
                                    <h2>{boxData.withdraw != undefined ? boxData.withdraw.total : <Loader />}</h2>
                                    <h1>Total Withdraw</h1>
                                </div>
                                <div style={{flex : "1 1 auto", position : "relative"}}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                            <div className="dashboard-overview-box">
                                <div style={{flex : "1 1 auto"}}>
                                    <h2>{boxData.profit != undefined ? boxData.profit.total : <Loader />}</h2>
                                    <h1>Profit</h1>
                                </div>
                                <div style={{flex : "1 1 auto", position : "relative"}}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="dashboard-overview-box">
                    <div style={{flex : "1 1 auto"}}>
                        <h2>{boxData.players != undefined ? boxData.players.total : <Loader />}</h2>
                        <h1>Players</h1>
                    </div>
                    <div style={{flex : "1 1 auto", position : "relative"}}>
                        <div className="icon players-icon"></div>
                    </div>
                </div>

                <div className="dashboard-overview-box" style={{"cursor" : "pointer"}} onClick={ToggleBalancePartitions}>
                    <div style={{flex : "1 1 auto"}}>
                        <h2>{boxData.balance != undefined ? boxData.balance.total : <Loader />}</h2>
                        <h1>Total Balance</h1>
                    </div>
                    <div style={{flex : "1 1 auto", position : "relative"}}>
                        <div className="icon profit-icon"></div>
                    </div>
                </div>

                <div className="dashboard-overview-box">
                    <div style={{flex : "1 1 auto"}}>
                        <h2>{boxData.ggr != undefined ? boxData.ggr.total : <Loader />}</h2>
                        <h1>GGR</h1>
                    </div>
                    <div style={{flex : "1 1 auto", position : "relative"}}>
                        <div className="icon profit-icon"></div>
                    </div>
                </div>
                {/* {boxData.profit != undefined ? boxData.profit.percentage : "0%"} */}
                {/* <div className="dashboard-overview-box">
                    <div style={{flex : "1 1 auto"}}>
                        <h2>{boxData.profit != undefined ? boxData.profit.total : <Loader />}</h2>
                        <h1>Monthly Profit</h1>
                    </div>
                    <div style={{flex : "1 1 auto", position : "relative"}}>
                        <div className="icon profit-icon"></div>
                        <div className="percentage-box">
                            0%
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}