import { Bar } from 'react-chartjs-2';

import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import { useHistory, useParams } from 'react-router';

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip, Table } from "rsuite";
import {FaFilter} from "react-icons/fa";

import {Loader} from "rsuite";
import {Line} from "react-chartjs-2";

import Filters from "../../Filters/Filters";

import axios from "axios";

export default function Overview() {
    const {id} = useParams();


    const filters = useSelector(state => state.filters);


    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [boxes, setBoxes] = useState({});
    const [topGamesLoading, setTopGamesLoading] = useState(false);
    const [topWinsLoading, setWinsLoading] = useState(false);

    const data = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
          {
            label: '# of Red Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: '# of Blue Votes',
            data: [2, 3, 20, 5, 1, 4],
            backgroundColor: 'rgb(54, 162, 235)',
          },
          {
            label: '# of Green Votes',
            data: [3, 10, 13, 15, 22, 30],
            backgroundColor: 'rgb(75, 192, 192)',
          },
        ],
    };

    const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
    };    
    
    const [monthlyAction, setMonthlyAction] = useState({
        label : [],
        deposit : [],
        withdraw : [],
        profit : []
    });
    const [monthlyGGR, setMonthlyGGR] = useState({
        label : [],
        ggr : []
    });
    const [topGames, setTopGames] = useState({
        label : [],
        games : []
    })
    const [topWins, setTopWins] = useState({
        label : [],
        wins : []
    })

    const GetMonthlyChart = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/players/overview/charts/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setMonthlyAction(response.data.charts);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }
    const GetMonthlyGGR = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/players/overview/ggr/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setMonthlyGGR(response.data.charts);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const GetMostPlayedGames = () => {
        setTopGamesLoading(true);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/players/overview/games/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setTopGamesLoading(false);

            if(response.data.error == null) {
                setTopGames(response.data.charts.games);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const GetTopWins = () => {
        setWinsLoading(true);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/players/overview/wins/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setWinsLoading(false);

            if(response.data.error == null) {
                setTopWins(response.data.charts.wins);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const GetOverviewBoxes = () => {
        axios.get(window.__APP__.api+"/players/overview/main/"+id+"?platform="+platforms.platform.id, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setBoxes(response.data.details);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetOverviewBoxes();
        }
    }, [platforms.platform])

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetMonthlyChart();
            GetMonthlyGGR();
            GetMostPlayedGames();
            GetTopWins();
        }
    }, [platforms.platform, filters.dateRange])

    return (
        <>
            <div className="nav-overview">
                <div className="overview-boxes">
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.balance != undefined ? boxes.balance.number : <Loader />}</h2>
                            <h1>Balance</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon balance-icon"></div>
                            <div className="percentage-box">
                                {boxes.balance != undefined ? boxes.balance.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.ggr != undefined ? boxes.ggr.number : <Loader />}</h2>
                            <h1>GGR</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon profit-icon"></div>
                            <div className="percentage-box">
                                {boxes.ggr != undefined ? boxes.ggr.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.profit != undefined ? boxes.profit.number : <Loader />}</h2>
                            <h1>Profit</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon profit-icon"></div>
                            <div className="percentage-box">
                                {boxes.profit != undefined ? boxes.profit.percentage+"%" : "0%"}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filters-row">
                    <div className="filters-side">
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>

                <div className="overview-details-row">
                    <div className="chart-box">
                            {/* <Bar data={data} options={options} /> */}
                            <div className="box-chart-header">
                                <i className="fas fa-chart-line" />
                                <h3>Player Movement</h3>
                            </div>
                            <Line data={{
                                    labels: monthlyAction.label,
                                    datasets: [
                                        {
                                            label: "Deposit",
                                            backgroundColor: "#FF1744",
                                            borderColor : "#FF1744",
                                            data: monthlyAction.deposit
                                        },
                                        {
                                            label: "Withdraw",
                                            backgroundColor: 'rgb(101, 31, 255)',
                                            borderColor : "rgb(101, 31, 255)",
                                            data: monthlyAction.withdraw
                                        },
                                        {
                                            label: "Profit",
                                            backgroundColor: '#FF9100',
                                            borderColor : "#FF9100",
                                            data: monthlyAction.profit
                                        }
                                    ]
                                }} />
                    </div>

                    <div className="chart-box">
                            {/* <Bar data={data} options={options} /> */}
                            <div className="box-chart-header">
                                <i className="fas fa-chart-line" />
                                <h3>Player GGR</h3>
                            </div>
                            <Line data={{
                                    labels: monthlyGGR.label,
                                    datasets: [
                                        {
                                            label: "GGR",
                                            backgroundColor: "#FF1744",
                                            borderColor : "#FF1744",
                                            data: monthlyGGR.ggr
                                        }
                                    ]
                                }} />
                    </div>

                    <div className="chart-box">
                            {/* <Bar data={data} options={options} /> */}
                            <div className="box-chart-header">
                                <i className="fas fa-star" />
                                <h3>Most Played Games</h3>
                            </div>
                            <div className="box-chart-body">
                                <Table
                                    height={350}
                                    // autoHeight
                                    data={topGames}
                                    loading={topGamesLoading}
                                >
                                    <Table.Column flexGrow={3}>
                                        <Table.HeaderCell>Game Name</Table.HeaderCell>
                                        <Table.Cell>{data => <><img width="50" src={data.thumbnail} /> {data.game}</>}</Table.Cell>
                                    </Table.Column>

                                    <Table.Column flexGrow={2}>
                                        <Table.HeaderCell>Provider</Table.HeaderCell>
                                        <Table.Cell dataKey="provider" />
                                    </Table.Column>

                                    <Table.Column flexGrow={3}>
                                        <Table.HeaderCell>Total Plays</Table.HeaderCell>
                                        <Table.Cell dataKey="plays" />
                                    </Table.Column>
                                </Table>
                            </div>
                    </div>

                    <div className="chart-box">
                            {/* <Bar data={data} options={options} /> */}
                            <div className="box-chart-header">
                                <i className="fas fa-award" />
                                <h3>Top Wins</h3>
                            </div>
                            <div className="box-chart-body">
                                <Table
                                    height={350}
                                    // autoHeight
                                    data={topWins}
                                    loading={topWinsLoading}
                                >
                                    <Table.Column width={80}>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.Cell dataKey="id" />
                                    </Table.Column>

                                    <Table.Column flexGrow={6}>
                                        <Table.HeaderCell>Game Name</Table.HeaderCell>
                                        <Table.Cell>{data => <><img title={data.game} width="50" src={data.thumbnail} /> {data.game}</>}</Table.Cell>
                                    </Table.Column>

                                    <Table.Column flexGrow={5}>
                                        <Table.HeaderCell>Provider</Table.HeaderCell>
                                        <Table.Cell dataKey="provider" />
                                    </Table.Column>

                                    <Table.Column flexGrow={3}>
                                        <Table.HeaderCell>Bet</Table.HeaderCell>
                                        <Table.Cell dataKey="bet" />
                                    </Table.Column>
                                    <Table.Column flexGrow={3}>
                                        <Table.HeaderCell>Win</Table.HeaderCell>
                                        <Table.Cell dataKey="win" />
                                    </Table.Column>

                                    <Table.Column flexGrow={3}>
                                        <Table.HeaderCell>Balance</Table.HeaderCell>
                                        <Table.Cell dataKey="balance" />
                                    </Table.Column>

                                    <Table.Column flexGrow={4}>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.Cell dataKey="date" />
                                    </Table.Column>
                                </Table>
                            </div>
                    </div>
                </div>
            </div>  

            <Filters />
        </>
    )
}