import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle, Modal, Button } from 'rsuite';

import axios from "axios";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../Transfer/Transfer";

import { Radio, RadioGroup } from 'rsuite';

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    return (
      <Table.Cell {...props} className="link-group">
        <a style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </a>
      </Table.Cell>
    );
};

export default function HistoryTable(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const filters = useSelector(state => state.filters);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {user, partner} = props;

    const GetHistory = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";
        
        axios.get(window.__APP__.api+"/transactions/history?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+dateQuery+"&user="+user+"&partner="+partner, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.transactions);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetHistory();
    }, [platforms.platform, activePage, activeLimit, filters.dateRange, user, partner]);
    

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                    <Table.Cell dataKey="type" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
                    <Table.Cell dataKey="amount" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Sender</Table.HeaderCell>
                    <Table.Cell dataKey="sender" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Receiver</Table.HeaderCell>
                    <Table.Cell dataKey="receiver" />
                </Table.Column>

                <Table.Column minWidth={120} flexGrow={1}>
                    <Table.HeaderCell>Performed By</Table.HeaderCell>
                    <Table.Cell dataKey="executioner" />
                </Table.Column>
                
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    // onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>
        </>
    )
}