import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Badge, Input, Checkbox} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    
    const dispatch = useDispatch();
    
    const ShowEditRecord = () => {
        
        // dispatch({
        //     type : "SET_FINANCIAL_DEBTS_MODAL",
        //     data : {
        //         show : false
        //     }
        // })
        dispatch({
            type : "SET_FINANCIAL_RECORD_ID",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_FINANCIAL_RECORD_MODAL",
            data : {
                show : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Details</Tooltip>
                }
            >
                <IconButton onClick={ShowEditRecord} className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
            </Whisper>
      </Table.Cell>
    );
};

const EditFinancialRecord = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [amountDisabled, setAmountDisabled] = useState(false);
    const [amount, setAmount] = useState(0);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_RECORD_MODAL",
            data : {
                show : false
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : true
            }
        })
    }

    const togglePaid = () => {
        if(!amountDisabled) {
            setAmountDisabled(true);
        } else {
            setAmountDisabled(false)
        }
    }

    const SetValue = (value) => {
        setAmount(value)
    }

    const SubmitRecord = () => {

        axios.post(window.__APP__.api+"/operators/record/edit?platform="+platforms.platform.id, {
            partner : modals.financialDebtsPartner,
            record : modals.financialRecordId,
            shop : modals.financialDebtsId,
            paid : amountDisabled,
            amount : amount
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                HideModal();
            }
        })
    }

    useEffect(() => {
        setAmountDisabled(false);
    }, [modals.financialRecordId])

    return (
        <>
            <Modal onClose={HideModal} open={modals.financialRecordModal}>
                <Modal.Header>
                    <Modal.Title>Financial Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Full amount</label>
                    <br />
                    <Input onChange={SetValue} disabled={amountDisabled} placeholder="Paid Amount" />
                    <Checkbox onChange={togglePaid}>Paid</Checkbox>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button onClick={SubmitRecord} appearance="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default function Transactions(props) {
    const {id, shopId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/operators/staticdebts/transactions/"+modals.financialDebtsPartner+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&shop="+modals.financialDebtsId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    return (
        <>
            <div className="nav-overview">
                <div>
                    <Table
                        data={transactions}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>
                    </Table>
                </div>  
            </div>

            <EditFinancialRecord recordId={modals.financialRecordId} />
        </>
    )
}