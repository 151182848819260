import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Input } from 'rsuite';

import {Button, InputPicker} from "rsuite";

import VouchersTable from "./VouchersTable";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import {FaFilter} from "react-icons/fa";

export default function Vouchers() {

    const history = useHistory();

    const bars = useSelector(state => state.bars);

    const [shop, setShop] = useState("");

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"vouchers"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Vouchers</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Vouchers</h1>
                            <Button onClick={() => history.push("/voucher/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Voucher Shop</Button>
                        </div>

                        <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                    <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                    <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <Input value={shop} onChange={setShop} style={{maxWidth : "200px"}} placeholder="Username" />
                            </div>
                        </Panel>

                        <Panel style={{marginTop : "20px"}}>
                            <VouchersTable shop={shop}></VouchersTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}