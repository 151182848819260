import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, InputPicker, Input, Table, Whisper, Tooltip, IconButton, Toggle, Pagination, Modal, Badge, Loader } from 'rsuite';

import {Button} from "rsuite";

import {AiOutlinePlusCircle, AiOutlineOrderedList} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {FaFilter} from "react-icons/fa";

const EditModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(true);
    const [editLoading, setEditLoading] = useState(false);
    const [info, setInfo] = useState({
        name : ""
    })

    const EditCountry = async () => {
        setEditLoading(true);
        const response = await axios.post(window.__APP__.api + "/settings/country/update/"+props.id+"?platform="+platforms.platform.id, info, {
            headers : {
                "Authorization" : "bearer "+localStorage.getItem("jwt")
            }
        })

        setEditLoading(false);

        if(response.data.error == null) {
            props.setEditModal(null);
            props.setRandom(Math.random());
        }
    }

    const FetchCountry = async (id) => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/settings/country/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "bearer "+localStorage.getItem("jwt")
            }
        });

        if(response.data.error == null) {
            setLoading(false);
            setInfo(response.data.country);
        }
    }

    useEffect(() => {
        if(props.id != null) {
            FetchCountry(props.id);
        }
    }, [props.id])

    return (
        <Modal onClose={() => props.setEditModal(null)} open={props.show}>
            <Modal.Header>
                <Modal.Title>Edit Country</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    loading ? (
                        <>
                            <div className="modal-loader-area">
                                <Loader />
                            </div>
                        </>
                    ) : (
                        <>
                        <div style={{ display : "flex", flexDirection : "column", gap : "10px"}}>
                            <div>
                                <label>Country</label>
                                <Input value={info.name} onChange={v => setInfo({...info, name : v})} placeholder="Country" ></Input>
                            </div>
                        </div>
                        </>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setEditModal(null)} appearance="subtle">
                    Close
                </Button>
                <Button disabled={info.name == ""} loading={editLoading} onClick={EditCountry} appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ActionCell = ({ rowData, dataKey, setRandom, setEditModal, ...props }) => {
    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const userx = useSelector(state => state.user);

    const [checkedState, setCheckedState] = useState(rowData['active']);

    const selectProvider = (id) => {
        dispatch({
            type : "SET_SETTINGS_PROVIDER",
            data : {
                provider : id
            }
        })
        dispatch({
            type : "SET_SETTINGS_PROVIDER_SHARE",
            data : {
                share : rowData['loyalty']
            }
        })
    }

    const toggleCurrency = (id) => {
        axios.post(window.__APP__.api+"/settings/country/toggle/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCheckedState(response.data.Status);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const SetDefault = async (id) => {
        const response = await axios.post(window.__APP__.api+"/settings/currency/default/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setRandom(Math.random())
        }
    }

    return (
      <Table.Cell {...props} className="link-group" style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("countries_edit"))) >= 0 ? (
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Edit Country</Tooltip>
                    }
                >
                    <IconButton onClick={() => setEditModal(rowData["id"])} style={{ position : "relative", top : "3px", marginLeft : "10px"}} className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
                </Whisper>
            ) : (
                <></>
            )
        }
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("countries_toggle"))) >= 0 ? (
                <Toggle style={{marginLeft : "5px"}} onChange={() => toggleCurrency(rowData['id'])} checked={checkedState == 0} className="table_toggle" size="sm"></Toggle>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

const PriorityModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closePriorityModal = () => {
        dispatch({
            type : "SET_PROVIDERS_PRIORITY_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const [priority, setPriority] = useState("");
    const [loading, setLoading] = useState(false);

    const ChangePriority = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/content/provider/priority/set/"+modals.providersPriorityId+"?platform="+platforms.platform.id+"&priority="+priority, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.Error == null) {
            dispatch({
                type : "SET_PROVIDERS_PRIORITY_MODAL",
                data : {
                    modal : false,
                    id : null
                }
            })
        }
    }

    return (
        <Modal loading={loading} open={modals.providersPriorityModal} onClose={closePriorityModal}>
            <Modal.Header>
                <Modal.Title>Change Priority</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input type="number" value={priority} onChange={value => setPriority(value)} placeholder="Priority" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closePriorityModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ChangePriority} appearance="primary">
                    Update Priority
                </Button>
            </Modal.Footer>
        </Modal>
    )
};


export default function Countries() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const userx = useSelector(state => state.user);

    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [countries, setCountries] = useState([]);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [random, setRandom] = useState("");
    const [editModal, setEditModal] = useState(null);

    const GetCountries = async (m_page, m_limit, m_category, m_name) => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/settings/countries/list"+"?platform="+platforms.platform.id+"&page="+m_page+"&limit="+m_limit+"&name="+name, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.Error == null) {
            setCountries(response.data.countries);
            setPages(response.data.pagination.pages);
        }
    }

    useEffect(() => {
        GetCountries(page, limit, category, name);
    }, [category, name, limit, page, random]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-countries"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content games-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Settings</Breadcrumb.Item>
                            <Breadcrumb.Item active>Countries</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Countries</h1>
                            {
                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("countries_create"))) >= 0 ? (
                                    <Button onClick={() => history.push("/settings/countries/add")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Add Country</Button>
                                ) : (
                                    <></>
                                )
                            }
                        </div>

                        <Panel style={{marginTop : "20px", display : "flex", alignItems : "center", flexDirection : "row", width : "100%"}}>
                            <div className="report-filters-form" style={{ width : "100%" }}>
                                <div className="form-element" style={{ width : "100%"}}>
                                    <label style={{display : "block", marginBottom : "10px"}}>Country</label>
                                    <Input style={{ width : "100%"}} value={name} onChange={setName} placeholder="Country" />
                                </div>
                            </div>
                        </Panel>
                        
                        <div style={{ marginTop : "20px" }}>
                            <Table
                                autoHeight
                                data={countries}
                                loading={loading}
                            >
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>Country</Table.HeaderCell>
                                    <Table.Cell dataKey="country" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>Country Code</Table.HeaderCell>
                                    <Table.Cell dataKey="countryCode" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.Cell>{ data => data.active == 1 ? "Enabled" : "Disabled"}</Table.Cell>
                                </Table.Column>

                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                    <ActionCell setRandom={setRandom} setEditModal={setEditModal} />
                                </Table.Column>
                            </Table>

                            <div className="pagination-box">
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <PriorityModal />
            <EditModal show={editModal != null} id={editModal} setEditModal={setEditModal} setRandom={setRandom} />
        </>
    )
}