import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle, Modal, Button, Radio  } from 'rsuite';

import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../../Transfer/Transfer";

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const [checked, setChecked] = useState(rowData["Active"]);

    const togglePlayerDeleteModal = (id) => {
        dispatch({
            type : "SET_PLAYER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PLAYER_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const togglePlayerToggleModal = (id) => {
        dispatch({
            type : "SET_PLAYER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_PLAYER_STATUS",
            data : {
                status : rowData['status']
            }
        })
        dispatch({
            type : "TOGGLE_PLAYER_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleTransferModal = (id) => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "player-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const GetAuthUrl = async (id) => {
        var response = await axios.get(window.__APP__.api+"/players/autoauthenticate/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.token != undefined) {
            window.open(response.data.token, "_blank");
        }
    }

    const ToggleBonus = async (id) => {
        var response = await axios.post(window.__APP__.api+"/bonuses/toggle/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            // dispatch({
            //     type : "SET_RANDOM_V",
            //     data : {
            //         random : Math.random()
            //     }
            // })

            if(checked) {
                setChecked(false);
            } else {
                setChecked(true);
            }
        }
    }

    return (
      <Table.Cell {...props} className="link-group" style={{ display : "flex", alignItems : "center", justifyContent : "center"}}>
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("bonuses_edit"))) >= 0 ? (
                <Link to={"/online/bonus/"+rowData['Id']} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Preview</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
                    </Whisper>
                </Link>
            ) : (
                <></>
            )
        }
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("bonuses_toggle"))) >= 0 ? (
                <div>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Toggle Bonus</Tooltip>
                        }
                    >
                        <Toggle checked={!checked} onChange={v => ToggleBonus(rowData["Id"])} size="sm" />
                    </Whisper>
                </div>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PLAYER_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeletePartner = () => {
        axios.post(window.__APP__.api+"/players/delete/"+modals.playerId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Player #{modals.playerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this player ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeletePartner} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PLAYER_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/players/toggle/"+modals.playerId+"?platform="+platforms.platform.id, {type : toggleType}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.playerStatus == 1 ? "Disable" : "Enable"} Player #{modals.playerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Type</h4>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.playerStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function BonusesTable(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const GetBonuses = () => {
        setLoading(true);
        setTableData([]);
        
        axios.get(window.__APP__.api+"/bonuses/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.bonuses);
                // setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetBonuses();
    }, [platforms.platform, activePage, activeLimit, platforms.randomV]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="Id" />
                </Table.Column>

                <Table.Column flexGrow={1} minWidth={120}>
                    <Table.HeaderCell>Bonus Name</Table.HeaderCell>
                    <Table.Cell dataKey="Name" />
                </Table.Column>

                <Table.Column flexGrow={1} minWidth={120}>
                    <Table.HeaderCell>Signup Bonus</Table.HeaderCell>
                    <Table.Cell>{rowData => <>{rowData.IsSignup ? "Signup Bonus" : "Not Signup"}</>}</Table.Cell>
                </Table.Column>

                {/* <Table.Column flexGrow={1} minWidth={120}>
                    <Table.HeaderCell>Assigned Promotion</Table.HeaderCell>
                    <Table.Cell>{rowData => <>{ rowData.Promotion != null ? <Link to={"/cms/promotions/edit/"+rowData.Promotion}>View Promotion</Link> : "Not Assigned" }</>}</Table.Cell>
                </Table.Column> */}
                
                <Table.Column flexGrow={1} minWidth={170} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            {/* <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div> */}

            {
                modals.playerDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
            {
                modals.playerToggleModal && (
                    <ToggleModal show={true} />
                )
            }
            {
                modals.transferModal && (
                    <Transfer />
                )
            }
        </>
    )
}