import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Checkbox, Panel, Whisper, Table, Pagination, Radio } from 'rsuite';

import {Button, IconButton, Tooltip, Modal, Input, InputPicker} from "rsuite";
import Nav from 'rsuite/Nav';

import { useHistory, useParams } from "react-router-dom";

import DateRangePicker from 'rsuite/DateRangePicker';

import axios from "axios";


/* Navs */
import Overview from "./Navs/Overview";
import Hierarchy from "./Navs/Hierarchy";
import Documents from "./Navs/Documents";
import Transactions from "./Navs/Transactions";
import Messages from "./Navs/Messages";
import Notes from "./Navs/Notes";
import LoginHistory from "./Navs/LoginHistory";
import FinancialReport from "./Navs/FinancialReport";
import Credits from "./Navs/Credits";
import FinancialCredits from "./Navs/FinancialDebts";
import Cashier from "./Navs/Cashier";
import CashierWins from "./Navs/CashierWins";

import Transfer from "../Transfer/Transfer";

import {AiOutlinePlusCircle} from "react-icons/ai";


const MessageModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeMessageModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_MESSAGE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [message, setMessage] = useState("");

    const SendMessage = () => {
        if(message != "") {
            axios.post(window.__APP__.api+"/operators/message/send/"+modals.partnerId+"?platform="+platforms.platform.id, {
                message : message
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeMessageModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    return (
        <Modal open={modals.partnerMessageModal} onClose={closeMessageModal}>
            <Modal.Header>
                <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setMessage} as="textarea" placeholder="Message" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeMessageModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendMessage} appearance="primary">
                    Send Message
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const FinancialDebtsModal = (props) => {

    const modals = useSelector(state => state.modals);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : false
            }
        })
    }

    return (
        <>
            <Modal onClose={HideModal} open={modals.financialDebtsModal} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Financial Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FinancialCredits shopId={modals.financialDebtsId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const CashierSettingsModal = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const [amount, setAmount] = useState("");

    const HideModal = () => {
        dispatch({
            type : "SET_CASHIER_SETTINGS_MODAL",
            data : {
                modal : false,
                id : null
            }
        })
    }

    const GetCashierSettings = async () => {
        try {
            var response = await axios.get(window.__APP__.api+"/operators/cashier/"+modals.partnerId+"/settings?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            if(response.data.error == null) {
                setAmount(response.data.cashierValue);
            }
        } catch(e) {
            console.log(e);
        }
    }

    const SaveSettings = async () => {
        try {
            var response = await axios.post(window.__APP__.api+"/operators/cashier/"+modals.partnerId+"/settings?platform="+platforms.platform.id, {
                cashierValue : amount 
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            })

            if(response.data.error == null) {
                HideModal();
            }
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetCashierSettings();
    }, [platforms]);

    return (
        <>
            <Modal onClose={HideModal} open={modals.cashierSettings} size={"sm"}>
                <Modal.Header>
                    <Modal.Title>Cashier Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input onChange={setAmount} value={amount} placeholder="Cashier's Value" />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button onClick={SaveSettings} appearance="primary">
                        Save Settings
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const PaidTransactionsModal = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();
    
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    const HideModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_PAID_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const setDateRange = () => {

    }

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/operators/credits/list/paid?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&partner="+modals.financialPartnerId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pages);
            }
        });
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetTransactions();
    }, [platforms, page, limit, modals.financialPartnerId]);


    return (
        <>
            <Modal onClose={HideModal} open={modals.partnerPaidModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Financial Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="nav-overview">
                        <Panel className="filters-panel" bordered>
                            <div className="panel-body" style={{flex : 1}}>
                                <DateRangePicker showOneCalendar onChange={setDateRange} style={{width : "100%"}} placeholder="Range" />
                            </div>
                        </Panel>
                        <div>
                            <Table
                                data={transactions}
                                rowClassName={data => data != undefined ? data.checked == 1 ? "checked-row" : "unchecked-row" : ""}
                                height={400}
                            >
                                <Table.Column width={70} align="center">
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell dataKey="credits" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Total Paid</Table.HeaderCell>
                                    <Table.Cell dataKey="amount" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                    <Table.Cell dataKey="shop" />
                                </Table.Column>
                            </Table>

                            <div className="pagination-box">
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const UncheckedPaidTransactionsModal = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();
    
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_PARTNER_NPAID_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const setDateRange = () => {

    }

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/operators/credits/list/paid/unchecked?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&partner="+modals.financialPartnerId+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pages);
            }
        });
    }
    
    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetTransactions();
    }, [platforms, page, limit, modals.financialPartnerId]);

    const ToggleTransaction = (id) => {
        axios.get(window.__APP__.api+"/operators/credits/toggle?platform="+platforms.platform.id+"&id="+id+"&partner="+modals.financialPartnerId, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions([]);
                GetTransactions();
            }
        });
    }

    return (
        <>
            <Modal onClose={HideModal} open={modals.partnerNCheckedModal} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Financial Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="nav-overview">
                        <Panel className="filters-panel" bordered>
                            <div className="panel-body" style={{flex : 1}}>
                                <DateRangePicker showOneCalendar onChange={setDateRange} style={{ width : "100%" }} placeholder="Range" />
                            </div>
                        </Panel>
                        <div>
                            <Table
                                data={transactions}
                                height={400}
                            >
                                <Table.Column width={70} align="center">
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell dataKey="credits" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Total Paid</Table.HeaderCell>
                                    <Table.Cell dataKey="amount" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                    <Table.Cell dataKey="shop" />
                                </Table.Column>

                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Check</Table.HeaderCell>
                                    <Table.Cell>{data => <Checkbox onChange={() =>  ToggleTransaction(data.id)}></Checkbox>}</Table.Cell>
                                </Table.Column>
                            </Table>

                            <div className="pagination-box" style={{marginTop : "10px"}}>
                                <Pagination
                                    layout={['', '-', 'limit', '|', 'pager']}
                                    pages={pages}
                                    limit={limit}
                                    limitOptions={[25,50,100]}
                                    maxButtons={5}
                                    activePage={page}
                                    onChangePage={setPage}
                                    onChangeLimit={setLimit}
                                />
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const EditFinancialRecord = (props) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [amountDisabled, setAmountDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);

    const dispatch = useDispatch();

    const HideModal = () => {
        dispatch({
            type : "SET_FINANCIAL_RECORD_MODAL",
            data : {
                show : false
            }
        })
        dispatch({
            type : "SET_FINANCIAL_DEBTS_MODAL",
            data : {
                show : true
            }
        })
    }

    const togglePaid = () => {
        if(!amountDisabled) {
            setAmountDisabled(true);
        } else {
            setAmountDisabled(false)
        }
    }

    const SetValue = (value) => {
        setAmount(value)
    }

    const SubmitRecord = () => {

        setLoading(true);

        axios.post(window.__APP__.api+"/operators/record/edit?platform="+platforms.platform.id, {
            partner : modals.financialDebtsPartner,
            record : modals.financialRecordId,
            shop : modals.financialDebtsId,
            paid : amountDisabled,
            amount : amount
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                HideModal();
            }
        })
    }

    useEffect(() => {
        setAmountDisabled(false);
    }, [modals.financialRecordId])

    return (
        <>
            <Modal onClose={HideModal} open={modals.financialRecordModal}>
                <Modal.Header>
                    <Modal.Title>Financial Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Full amount</label>
                    <br />
                    <Input onChange={SetValue} disabled={amountDisabled} placeholder="Paid Amount" />
                    <Checkbox onChange={togglePaid}>Paid</Checkbox>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={HideModal} appearance="subtle">
                        Close
                    </Button>
                    <Button disabled={loading} onClick={SubmitRecord} appearance="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const NoteModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const closeNoteModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_NOTE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const [note, setNote] = useState("");
    const [priority, setPriority] = useState(0);

    const SendNote = () => {
        if(note != "") {
            axios.post(window.__APP__.api+"/operators/note/send/"+modals.partnerId+"?platform="+platforms.platform.id, {
                note : note,
                priority : priority
            }, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    closeNoteModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } else if(response.data.error == "Operator Not Found") {
                        history.push("/");
                    }
                }
            })
        }
    }

    const data = [
        {
            label : "Normal Priority",
            value : 0
        }, {
            label : "High Priority",
            value : 1
        }, {
            label : "Important",
            value : 2
        }
    ]

    return (
        <Modal open={modals.partnerNoteModal} onClose={closeNoteModal}>
            <Modal.Header>
                <Modal.Title>Send Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input onChange={setNote} as="textarea" placeholder="Note" />
                <InputPicker style={{marginTop : "15px", width : "100%"}} placeholder="Priority" data={data} value={priority} onChange={setPriority}  />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeNoteModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={SendNote} appearance="primary">
                    Send Note
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(0);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PARTNER_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/operators/toggle/"+modals.partnerId+"?platform="+platforms.platform.id, {type : toggleType}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.partnerStatus == 1 ? "Disable" : "Enable"} Partner #{modals.partnerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Type</h4>
                <br />
                <Radio onChange={setToggleEvent} value="0" checked={toggleType == 0 ? true : false}>User and sub-users</Radio>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.partnerStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Partners(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals );

    const {id} = useParams();

    const bars = useSelector(state => state.bars);
    const [activeKey, setActiveKey] = useState(props.nav);
    
    const [partner, setPartner] = useState({
        name : "",
        location : "",
        balance : 0,
        cashinhand : 0,
        phone : ""
    });

    const GetPartnerInfo = () => {
        axios.get(window.__APP__.api+"/operators/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPartner(response.data.operator);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    // const TogglePartner = () => {
    //     axios.post(window.__APP__.api+"/operators/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
    //         headers : {
    //             "Authorization" : "Bearer "+localStorage.getItem("jwt")
    //         }
    //     }).then(response => {
    //         if(response.data.error == null) {
    //             GetPartnerInfo();
    //         } else {
    //             if(response.data.error == "Unauthorized") {
    //                 localStorage.removeItem("jwt");
    //                 dispatch({
    //                     type : "SET_LOGGED",
    //                     data : {
    //                         logged : false
    //                     }
    //                 })
    //             } 
    //         }
    //     })
    // }

    const TogglePartner = () => {
        dispatch({
            type : "SET_PARTNER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_PARTNER_STATUS",
            data : {
                status : partner.status
            }
        })
        dispatch({
            type : "TOGGLE_PARTNER_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const ShowMessageModal = (id) => {
        dispatch({
            type : "SET_PARTNER_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_PARTNER_MESSAGE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const ShowNoteModal = (id) => {
        dispatch({
            type : "SET_PARTNER_ID",
            data : {
                id : id
            }
        });
        dispatch({
            type : "TOGGLE_PARTNER_NOTE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPartnerInfo();
        }
    }, [platforms.platform, modals.partnerToggleModal]);

    const MakeTransfer = () => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "partner-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"partners"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/partners">Partners</Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            {/* <h1>Preview Partner</h1> */}
                        </div>

                        <div className="preview-page">
                            <div className="preview-head">
                                <div className="head-content">
                                    <div className="thumbnail partner-thumb">
                                        <img src="/assets/svg/user.svg" />
                                        <div className="layer">
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="info-row">
                                            <h1>Partner Name</h1>
                                            <h2>{partner.name}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Partner Location</h1>
                                            <h2>{partner.location}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Balance</h1>
                                            <h2>{partner.balance}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Cash In Hand</h1>
                                            <h2>{partner.cashinhand}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Phone</h1>
                                            <h2>{partner.phone}</h2>
                                        </div>
                                        <div className="info-row">
                                            <h1>Date</h1>
                                            <h2>{partner.date}</h2>
                                        </div>
                                    </div>
                                </div>

                                {
                                    partner.status != undefined && (
                                        <div className="custom-buttons">
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Transfer</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => MakeTransfer(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-exchange"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Send Message</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={() => ShowMessageModal(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-paper-plane"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>Set Note</Tooltip>
                                                }
                                            >
                                                    <IconButton onClick={() => ShowNoteModal(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-sticky-note"></i></IconButton>
                                            </Whisper>
                                            <Whisper
                                                placement={"top"}
                                                speaker={
                                                    <Tooltip>{partner.status == 1 ? "Block" : "Unblock"}</Tooltip>
                                                }
                                            >
                                                <IconButton onClick={TogglePartner} className="icon-btn"><i style={{fontSize : "22px"}} className={partner.status == 1 ? "fas fa-lock" : "fas fa-unlock"}></i></IconButton>
                                            </Whisper>
                                        </div>
                                    )
                                }

                                <div className="head-menu">
                                    <Nav activeKey={activeKey} appearance="subtle" onSelect={(key) => setActiveKey(key)}>
                                        <Nav.Item eventKey="home">Overview</Nav.Item>
                                        <Nav.Item eventKey="hierarchy">Hierarchy</Nav.Item>
                                        <Nav.Item eventKey="documents">Documents</Nav.Item>
                                        <Nav.Item eventKey="transactions">Transactions</Nav.Item>
                                        <Nav.Item eventKey="messages">Messages</Nav.Item>
                                        <Nav.Item eventKey="notes">Notes</Nav.Item>
                                        <Nav.Item eventKey="financial">Financial Report</Nav.Item>
                                        <Nav.Item eventKey="cashier">Cashier</Nav.Item>
                                        <Nav.Item eventKey="cashierwin">Cashier Wins</Nav.Item>
                                        <Nav.Item eventKey="debts">Debts</Nav.Item>
                                        <Nav.Item eventKey="login">Login History</Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="preview-body">
                                {
                                    activeKey == "home" ? <Overview /> : activeKey == "hierarchy" ? <Hierarchy /> : activeKey == "documents" ? <Documents /> : activeKey == "transactions" ? <Transactions /> : activeKey == "messages" ? <Messages /> : activeKey == "notes" ? <Notes /> : activeKey == "login" ? <LoginHistory /> : activeKey == "financial" ? <FinancialReport /> : activeKey == "credits" ? <Credits /> : activeKey == "cashier" ? <Cashier /> : activeKey == "cashierwin" ? <CashierWins /> : activeKey == "debts" ? <Credits /> : <Overview />
                                 }
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
            <MessageModal />
            <NoteModal />
            <FinancialDebtsModal />
            <EditFinancialRecord recordId={modals.financialRecordId} />
            <PaidTransactionsModal />
            <UncheckedPaidTransactionsModal />

            {
                modals.transferModal && (
                    <Transfer />
                )
            }

            {
                modals.cashierSettings && (
                    <CashierSettingsModal />
                )
            }
            {
                modals.partnerToggleModal && (
                    <ToggleModal show={true} />
                )
            }
            
        </>
    )
}