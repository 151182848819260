import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useSelector, useDispatch} from "react-redux";
import {useState, useEffect} from "react";
import { Badge } from 'rsuite';

import axios from "axios";

export default function ProductsTable() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
   
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetProducts = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";
        
            axios.get(window.__APP__.api+"/reports/products/currency/"+reports.currency+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setProducts(response.data.products);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetProducts();
        }
    }, [platforms.platform, limit, page, filters.dateRange]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={products}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Provider Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Bets</Table.HeaderCell>
                    <Table.Cell dataKey="bets" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Wins</Table.HeaderCell>
                    <Table.Cell dataKey="wins" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>
            </Table>
        </>
    )
}