import { Table, IconButton, Col } from "rsuite";
import Pagination from 'rsuite/Pagination';

import { Link } from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { useEffect, useState } from "react";

const { Column, HeaderCell, Cell } = Table;

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props} style={{ padding: 5 }}>
        <IconButton
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                expandedRowKeys.some(key => key === rowData["id"]) ? (
                    <i className="fas fa-minus" />
                ) : (
                    <i className="fas fa-plus" />
                )
            }
        />
    </Cell>
);

const RenderRowExpanded = (props) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const GetStatesData = () => {

        setLoading(true);

        axios.get(window.__APP__.api + "/reports/bystates/" + reports.currency + "/" + props.rowData.id + "?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);

            if (response.data.error == null) {
                setData(response.data.data)
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        GetStatesData();
    }, [])

    return (
        <div>
            <div className="states-table">
                <b>States Report</b>
                <Table
                    className="rs-table"
                    loading={true}
                    data={data}
                    autoHeight
                    minHeight={400}
                >
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>State</Table.HeaderCell>
                        <Table.Cell dataKey="state"></Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>Players</Table.HeaderCell>
                        <Table.Cell dataKey="players"></Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>Total Bets</Table.HeaderCell>
                        <Table.Cell dataKey="bets"></Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>Total Wins</Table.HeaderCell>
                        <Table.Cell dataKey="wins"></Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>GGR</Table.HeaderCell>
                        <Table.Cell dataKey="ggr"></Table.Cell>
                    </Table.Column>
                </Table>
            </div>
        </div>
    );
};

export default function ByCountriesTable() {
    const reports = useSelector(state => state.reports);
    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const GetCountriesData = () => {

        setLoading(true);

        axios.get(window.__APP__.api + "/reports/bycountries/" + reports.currency + "?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);

            if (response.data.error == null) {
                setDataTable(response.data.data)
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }


    useEffect(() => {
        if (platforms.platform.id >= 0 && reports.currency != null) {
            GetCountriesData();
        }
    }, [platforms.platform, reports.currency]);

    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData["id"]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData["id"]);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    return (
        <>
            <Table
                autoHeight
                data={dataTable}
                loading={loading}
                rowKey="id"
                expandedRowKeys={expandedRowKeys}
                renderRowExpanded={rowData => <RenderRowExpanded rowData={rowData} />}
                shouldUpdateScroll={false}
                rowExpandedHeight={350}
            >
                <Column width={70} align="center">
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
                </Column>

                <Table.Column minWidth={150} flexGrow={1}>
                    <Table.HeaderCell>Country</Table.HeaderCell>
                    <Table.Cell dataKey="country"></Table.Cell>
                </Table.Column>

                <Table.Column minWidth={150} flexGrow={1}>
                    <Table.HeaderCell>Players</Table.HeaderCell>
                    <Table.Cell dataKey="players" />
                </Table.Column>

                <Table.Column minWidth={150} flexGrow={1}>
                    <Table.HeaderCell>Total Bets</Table.HeaderCell>
                    <Table.Cell dataKey="bets" />
                </Table.Column>

                <Table.Column minWidth={150} flexGrow={1}>
                    <Table.HeaderCell>Total Wins</Table.HeaderCell>
                    <Table.Cell dataKey="wins" />
                </Table.Column>

                <Table.Column minWidth={150} flexGrow={1}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>
            </Table>
        </>
    )
}