import {useState, useEffect, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button } from 'rsuite';

import { Notification, toaster, Message  } from 'rsuite';
import { Checkbox, CheckboxGroup } from 'rsuite';

import { Editor } from '@tinymce/tinymce-react';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ProgressiveCasino(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const {id} = useParams();
    const {bonus} = props;

    const editorRef = useRef(null);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");
    const [languages, setLanguages] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [loaded, setLoaded] = useState(false);

    var bonusDetails = JSON.parse(bonus.Details);

    const [form, setForm] = useState({
        name : bonus.Name,
        signup : bonus.IsSignup,
        rollover : bonusDetails.rollover,
        rounds : bonusDetails.maxWins.length,
        maxWins : bonusDetails.maxWins,
        maxBonus : bonusDetails.maxBonus,
        freespins : bonusDetails.freespins,
        multipliers : bonusDetails.multipliers,
        minimumDeposit : bonusDetails.minimumDeposit,
        promotion : JSON.parse(bonus.Promotion)
    })

    const [message, setMessage] = useState({
        type : "",
        message : ""
    })


    const saveDetails = async () => {

        setMessage({
            type : "",
            message : ""
        })

        setButtonDisabled(true);

        const response = await axios.post(window.__APP__.api+"/bonuses/bonus/save/"+id+"?platform="+platforms.platform.id,form, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setButtonDisabled(false);
        window.scrollTo(0, 0);
        
        if(response.data.error == null) {
            setMessage({
                type : "success",
                message : "Bonus details saved successfully."
            })
        } else {
            setMessage({
                type : "error",
                message : "Unexpected error."
            })
        }
    }

    const GetPromotions = async () => {
        const response = await axios.get(window.__APP__.api+"/cms/promotions/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            var promos = [];

            for(var i=0; i<response.data.pages.length; i++) {
                var promo = response.data.pages[i];

                promos.push({
                    label : promo.title,
                    value : promo.id
                })
            }

            setPromotions(promos);
        }
    }

    const GetLanguages = async () => {
        const response = await axios.get(window.__APP__.api+"/cms/languages/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            var langs = [];

            for(var i=0; i<response.data.languages.length; i++) {
                var lang = response.data.languages[i];

                langs.push({
                    Id : lang.code,
                    Language : lang.language
                })
            }

            setLanguages(langs)
        }
    }

    useEffect(() => {
        GetPromotions();
        GetLanguages();
    }, [])

    const GenerateRoundArrays = (r) => {
        var arr = [];

        for(var i=1; i<=r; i++) {
            arr.push(i);
        }

        return arr;
    } 

    const ChangeMValue = (key, index, value) => {
        var mform = Object.assign({}, form);

        if(mform[key] == undefined) {
            return ;
        }

        mform[key][index] = value != "" ? parseFloat(value) : "";

        setForm(mform);
    }

    const setPromotion = (lang, id) => {
        var frm = Object.assign({}, form);

        if(id == null) {
            frm.promotion[lang] = undefined;
        } else {
            frm.promotion[lang] = id;
        }

        setForm(frm)
    }

    return (
        <>
            <Form className="form-box">
                {
                    message.type != "" && (
                        <Message showIcon type={message.type}>
                            {message.message}
                        </Message>
                    )
                }

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Bonus Title</Form.ControlLabel>
                        <Form.Control onChange={v => setForm({...form, name : v})} placeholder="Bonus Title" value={form.name} />
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Is Signup Bonus</Form.ControlLabel>
                        <Checkbox checked={form.signup} onChange={v => { form.signup == 0 ? setForm({...form, signup : 1}) : setForm({...form, signup : 0})}}>Signup Bonus</Checkbox>
                    </Form.Group>
                </div>

                <h5 style={{ marginTop : " 15px", marginBottom : "15px" }}>Assign Promotion</h5>

                <div className="form-row" style={{marginTop : "20px"}}>
                    {
                        languages.map(language => {
                            return (
                                <>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Promotion ( {language.Language} )</Form.ControlLabel>
                                        <InputPicker value={form.promotion[language.Id] != undefined ? form.promotion[language.Id] : ""} onChange={v => setPromotion(language.Id, v)} style={{ width : "100%" }} data={promotions} placeholder="Promotion" />
                                    </Form.Group>
                                </>
                            )
                        })
                    }
                </div>

                <h5 style={{ marginTop : " 15px", marginBottom : "15px" }}>Bonus Parameters</h5>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Total Rollover</Form.ControlLabel>
                        <Form.Control onChange={v => setForm({...form, rollover : v})} placeholder="Rollover" value={form.rollover} />
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Steps</Form.ControlLabel>
                        <Form.Control onChange={v => setForm({...form, rounds : v})} placeholder="Steps" value={form.rounds} />
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Max Wins</Form.ControlLabel>
                        {
                            form.rounds > 0 && GenerateRoundArrays(form.rounds).map(round => {
                                return (
                                    <>
                                        <div style={{ marginBottom : "10px"}}>
                                            <Form.Control value={form.maxWins[round-1]} onChange={v => ChangeMValue("maxWins", round-1, v)} placeholder={"Max Win ( Step "+round+" )"} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Max Bonus</Form.ControlLabel>
                        {
                            form.rounds > 0 && GenerateRoundArrays(form.rounds).map(round => {
                                return (
                                    <>
                                        <div style={{ marginBottom : "10px"}}>
                                            <Form.Control value={form.maxBonus[round-1]} onChange={v => ChangeMValue("maxBonus", round-1, v)} placeholder={"Max Bonus ( Step "+round+" )"} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Freespins</Form.ControlLabel>
                        {
                            form.rounds > 0 && GenerateRoundArrays(form.rounds).map(round => {
                                return (
                                    <>
                                        <div style={{ marginBottom : "10px"}}>
                                            <Form.Control value={form.freespins[round-1]} onChange={v => ChangeMValue("freespins", round-1, v)} placeholder={"Freespins ( Step "+round+" )"} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Multipliers</Form.ControlLabel>
                        {
                            form.rounds > 0 && GenerateRoundArrays(form.rounds).map(round => {
                                return (
                                    <>
                                        <div style={{ marginBottom : "10px"}}>
                                            <Form.Control value={form.multipliers[round-1]} onChange={v => ChangeMValue("multipliers", round-1, v)} placeholder={"Multiplier ( Step "+round+" )"} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </Form.Group>
                </div>

                <div className="form-row" style={{marginTop : "20px"}}>
                    <Form.Group controlId="name-1">
                        <Form.ControlLabel>Minimum Deposit</Form.ControlLabel>
                        {
                            form.rounds > 0 && GenerateRoundArrays(form.rounds).map(round => {
                                return (
                                    <>
                                        <div style={{ marginBottom : "10px"}}>
                                            <Form.Control value={form.minimumDeposit[round-1]} onChange={v => ChangeMValue("minimumDeposit", round-1, v)} placeholder={"Minimum Deposit ( Step "+round+" )"} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </Form.Group>
                </div>

                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                    <Button loading={buttonDisabled} onClick={saveDetails} style={{height : "100%"}} color="primary">Save Bonus Details</Button>
                </div>
            </Form>
        </>
    )
}