import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Whisper, IconButton, Tooltip, Radio, Loader} from "rsuite";
import Nav from 'rsuite/Nav';

import { useHistory, useParams } from "react-router-dom";

import {Link} from "react-router-dom";

import axios from "axios";

import {Modal} from "rsuite";

import {Input, InputPicker} from 'rsuite';


import Transfer from "../../Transfer/OnlineTransfer";

/* Navs */
import Overview from "./Navs/Overview";
import Hierarchy from "./Navs/Hierarchy";
import AgentTree from "./Navs/AgentTree";
import Players from "./Navs/Players";
import Transactions from "./Navs/Transactions";
import LoginHistory from "./Navs/LoginLogs";

import {AiOutlinePlusCircle} from "react-icons/ai";

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/online/agents/toggle/"+props.id+"?platform="+platforms.platform.id, { type : toggleType }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                props.setToggleModal(null);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={() => props.setToggleModal(null)} open={props.show}>
            <Modal.Header>
                <Modal.Title>{props.toggleModalStatus == 1 ? "Disable" : "Enable"} Agent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Radio onChange={setToggleEvent} value="0" checked={toggleType == 0 ? true : false}>User and sub-users</Radio>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setToggleModal(null)} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {props.toggleModalStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Agent(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const bars = useSelector(state => state.bars);
    const [activeKey, setActiveKey] = useState(props.nav);

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const userx = useSelector(state => state.user);

    const [loading, setLoading] = useState(false);

    const [messageModal, setMessageModal] = useState(false);
    const [toggleModal, setToggleModal] = useState(null);
    const [toggleModalStatus, setToggleModalStatus] = useState(0);

    const [agent, setAgent] = useState({
        name : "",
        location : "",
        balance : 0,
        cashinhand : 0,
        phone : "",
        status : 0,
        verified : 0
    });

    const GetAgentInfo = () => {

        setLoading(true);

        axios.get(window.__APP__.api+"/online/agents/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {

            setLoading(false);

            if(response.data.error == null) {
                setAgent(response.data.agent);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    const ToggleAgent = () => {

    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetAgentInfo();
        }
    }, [platforms.platform, id, toggleModal]);


    const MakeTransfer = () => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : parseInt(id)
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }


    const ShowVerificationModal = (id) => {
        dispatch({
            type : "SET_VERIFICATION_MODAL",
            data : {
                modal : true,
                id : id
            }
        })
    }
    const ShowKYCVerificationModal = (id) => {
        dispatch({
            type : "SET_KYC_VERIFICATION_MODAL",
            data : {
                modal : true,
                id : id
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"agents-list"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/online/agents">Agents</Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head"></div>

                        {
                            loading ? (
                                <div className="form-loader">
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    <div className="preview-page">
                                        <div className="preview-head">
                                            <div className="head-content">
                                                <div className="thumbnail">
                                                    <img src="/assets/svg/user.svg" />
                                                    <div className="layer">
                                                    </div>
                                                </div>
                                                {
                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_info"))) >= 0 ? (
                                                        <div className="info">
                                                            <div className="info-row">
                                                                <h1>ID</h1>
                                                                <h2>{id}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Username</h1>
                                                                <h2>{agent.username}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Agent Name</h1>
                                                                <h2>{agent.name}</h2>
                                                            </div>
                                                            {
                                                                agent.phone != null && (
                                                                    <div className="info-row">
                                                                        <h1>Phone Number</h1>
                                                                        <h2>{agent.phone}</h2>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                agent.email != null && (
                                                                    <div className="info-row">
                                                                        <h1>Email</h1>
                                                                        <h2>{agent.email}</h2>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="info-row">
                                                                <h1>Agent Location</h1>
                                                                <h2>{agent.location}</h2>
                                                            </div>
                                                            {
                                                                agent.city != null && (
                                                                    <div className="info-row">
                                                                        <h1>Agent City</h1>
                                                                        <h2>{agent.city}</h2>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="info-row">
                                                                <h1>Total Balance</h1>
                                                                <h2>{agent.credits}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Total Sub Users Balance</h1>
                                                                <h2>{agent.subBalance}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>GGR</h1>
                                                                <h2>{agent.ggr}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Role</h1>
                                                                <h2>{agent.role}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Parent</h1>
                                                                <h2>{agent.parentId != null ? <><Link to={"/online/agent/"+agent.parentId}>{agent.parent}</Link></> : agent.parent}</h2>
                                                            </div>
                                                            <div className="info-row">
                                                                <h1>Currency</h1>
                                                                <h2>{agent.currency}</h2>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>

                                            {
                                                (agent.username != undefined && agent.username != "") && (
                                                    <div className="custom-buttons">
                                                        {
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("transfer_credits"))) >= 0 ? (
                                                                <Whisper
                                                                    placement={"top"}
                                                                    speaker={
                                                                        <Tooltip>Transfer</Tooltip>
                                                                    }
                                                                >
                                                                    <IconButton onClick={() => MakeTransfer(id)} className="icon-btn"><i style={{fontSize : "22px"}} className="fas fa-exchange"></i></IconButton>
                                                                </Whisper>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_toggle"))) >= 0 ? (
                                                                <Whisper
                                                                    placement={"top"}
                                                                    speaker={
                                                                        <Tooltip>{agent.status == 0 ? "Enable" : "Disable"}</Tooltip>
                                                                    }
                                                                >
                                                                    <IconButton onClick={() => {setToggleModal(id); setToggleModalStatus(agent.status)}} className="icon-btn"><i style={{fontSize : "22px"}} className={agent.status == 1 ? "fas fa-lock" : "fas fa-unlock"}></i></IconButton>
                                                                </Whisper>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_edit"))) >= 0 ? (
                                                                <Whisper
                                                                    placement={"top"}
                                                                    speaker={
                                                                        <Tooltip>{"Edit Agent"}</Tooltip>
                                                                    }
                                                                >
                                                                    <IconButton onClick={() => history.push("/online/agents/edit/"+agent.id)} className="icon-btn"><i style={{fontSize : "22px"}} className={"fas fa-edit"}></i></IconButton>
                                                                </Whisper>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                            <div className="head-menu">
                                                <Nav activeKey={activeKey} appearance="subtle" onSelect={(key) => setActiveKey(key)}>
                                                    <Nav.Item eventKey="overview">Overview</Nav.Item>
                                                    {
                                                        agent.type > 1 ? (
                                                            <>
                                                            {
                                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_users"))) >= 0 ? (
                                                                    <Nav.Item eventKey="hierarchy">Users</Nav.Item>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                            {
                                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_tree"))) >= 0 ? (
                                                                    <Nav.Item eventKey="tree">Agents Tree</Nav.Item>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_players"))) >= 0 ? (
                                                            <Nav.Item eventKey="players">Players</Nav.Item>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_transactions"))) >= 0 ? (
                                                            <Nav.Item eventKey="transactions">Transactions</Nav.Item>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_login_history"))) >= 0 ? (
                                                            <Nav.Item eventKey="login">Login History</Nav.Item>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </Nav>
                                            </div>
                                        </div>
                                        <div className="preview-body">
                                            {
                                                activeKey == "overview" ? <Overview /> : activeKey == "hierarchy" ? <Hierarchy agent={agent} /> : activeKey == "tree" ? <AgentTree agent={agent} /> : activeKey == "players" ? <Players agent={agent} /> : activeKey == "transactions" ? <Transactions agent={agent}></Transactions> : activeKey == "login" ? <LoginHistory agent={agent}></LoginHistory> : <></>
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div> 
            {
                modals.transferModal && (
                    <Transfer />
                )
            }

            <ToggleModal show={toggleModal != null} id={toggleModal} setToggleModal={setToggleModal} toggleModalStatus={toggleModalStatus} />
        </>
    )
}