import {useState, useEffect, useRef} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, DatePicker, DateRangePicker, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";
import moment from "moment";

import { useHistory, useParams } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function EditPlayer() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
      firstname : "",
      lastname : "",
      username : "",
      dob : "",
      password : "",
      repassword : ""
    });

    const [errors, setErrors] = useState({
      dob : false,
      password : false,
      repassword : false
    })
    const [formError, setFormError] = useState({})

    const GetOperatorInfo = () => {
        axios.get(window.__APP__.api+"/players/player/get/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setForm({
                    ...response.data.player,
                    dob : response.data.player.dob != null ? moment(response.data.player.dob).toDate() : ""
                });
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetOperatorInfo(platforms.platform.id)
        }
    }, [platforms.platform])

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const SubmitForm = () => {

        console.log(form)

      if(form.firstname == "" || form.lastname == "" || form.dob == "") {

        var errorObj = errors;
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else if(form.password != "" && form.password != form.repassword) {
        var errorObj = errors;
        errorObj.password = true;
        errorObj.repassword = true;
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else {

        setErrors({
          password : false,
          repassword : false
        });

        axios.post(window.__APP__.api+"/players/online/player/edit/"+id+"?platform="+platforms.platform.id, form, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem("jwt")
          }
        }).then(response => {
          if(response.data.error == null) {
            setFormMessageType("");
            setFormMessage("");

            toaster.push(<Notification type="info" header="Notification" closable>Player Edited Successfully</Notification>, {placement: "topEnd"});

            history.push("/online/players");
          } else {
            switch(response.data.error) {
              case "User Already Exists":
                setFormMessage("User Already Exists");
                setFormMessageType("error");
              break;
              case "City Undefined":
              case "Country Undefined":
                setFormMessage("Location Undefined/Disabled");
                setFormMessageType("error");
              break;
              case "Platform Not Found":
              case "Unexpected Error":
                setFormMessage("Unexpected Error");
                setFormMessageType("error");
              break;
              case "Unauthorized":
                dispatch({
                    type : "SET_LOGGED",
                    data : {
                        logged : false
                    }
                })
                localStorage.removeItem("jwt");
                history.push("/");
              break;
            }
          }
        })
      }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"kplayers"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/online/players">Players</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Player</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Edit Player</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                              formMessage != "" && (
                                <Message showIcon type={formMessageType}>
                                  {formMessage}
                                </Message>
                              )
                            }
                            
                            <Form onSubmit={SubmitForm} className="form-box">
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="firstname">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control value={form.firstname} onChange={value => setForm({...form, firstname : value})} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control value={form.lastname} onChange={value => setForm({...form, lastname : value})} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="username">
                                        <Form.ControlLabel>Username</Form.ControlLabel>
                                        <Form.Control disabled value={form.username} name="username" placeholder="Username" />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control disabled value={form.email} name="email" placeholder="Email" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{ marginBottom : "20px"}}>
                                    <Form.Group controlId="username" style={{ width : "100%"}}>
                                        <Form.ControlLabel>Date of birth</Form.ControlLabel>
                                        <DatePicker onChange={value => setForm({...form, dob : value})} value={form.dob != null ? form.dob : ""} style={{ width : "100%"}} showOneCalendar={true} />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} onChange={value => setForm({...form, password : value})} type="password" name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="repassword">
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} onChange={value => setForm({...form, repassword : value})} type="password" name="repassword" placeholder="Confirm Password" />
                                    </Form.Group>
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Edit Player</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}