import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import { Button, Input, InputPicker, ButtonGroup, CheckPicker } from "rsuite";

import Transfer from "../../Transfer/Transfer";

import PlayersTable from "./PlayersTable";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaFilter, FaRegQuestionCircle } from "react-icons/fa";

import { useHistory } from "react-router-dom";

import axios from "axios";

export default function Partners() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const [exportModal, setExportModal] = useState(false);
    const [columnsModal, setColumnsModal] = useState(false);
    const [pid, setPId] = useState("");
    const [player, setPlayer] = useState("");
    const [cin, setCin] = useState("");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [mtsid, setMtsId] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [birthday, setBirthday] = useState("");
    const [ip, setIp] = useState("");
    const [currency, setCurrency] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState([]);

    const ClearFilters = () => {
        setPId("");
        setPlayer("");
        setCin("");
        setCountry("");
        setPhone("");
        setEmail("");
        setMtsId("");
        setFirstname("");
        setLastname("");
        setBirthday("");
        setIp("");
        setCurrencies([]);
        setParent([]);
    }

    const GetCountries = () => {
        axios.get(window.__APP__.api + "/platform/countries?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setCountries(response.data.countries);
        });
    }
    const GetParents = () => {
        axios.get(window.__APP__.api + "/online/agents/parents/list?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setParents(response.data.users);
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetCountries();

            if (platforms.platform.agent_enabled == 1) {
                GetParents();
            }
        }
    }, [platforms]);

    const GetCurrencies = () => {
        axios.get(window.__APP__.api + "/platform/currencies?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            var mcurrencies = [];

            response.data.currencies.map(currency => {
                mcurrencies.push({
                    label: currency.currency,
                    value: currency.id
                })
            })

            setCurrencies(mcurrencies);
        });
    }

    useEffect(() => {
        GetCurrencies();
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"players"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Players</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Players</h1>
                            {/* <Button onClick={() => history.push("/partner/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Partner</Button> */}
                        </div>

                        {
                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_list"))) >= 0 ? (
                                <>
                                    <Panel className="filters-panel" bordered>
                                        <div className="panel-head">
                                            <FaFilter style={{ fontSize: "16px", position: "relative", top: "1.5px" }} />
                                            <h1>Filters</h1>
                                        </div>

                                        <div className="panel-body panel-body-gapped">
                                            <Input value={pid} onChange={setPId} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="ID" />
                                            <Input value={mtsid} onChange={setMtsId} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="MTS ID" />
                                            <Input value={firstname} onChange={setFirstname} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Firstname" />
                                            <Input value={lastname} onChange={setLastname} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Lastname" />
                                            <Input value={birthday} onChange={setBirthday} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Birthday (YYYY-MM-DD)" />
                                            <Input value={ip} onChange={setIp} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="IP Address" />
                                            <Input value={player} onChange={setPlayer} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Username" />
                                            <Input value={phone} onChange={setPhone} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Phone" />
                                            <Input value={email} onChange={setEmail} style={{ flexGrow: 1, flex: 1, minWidth: "200px" }} placeholder="Email" />
                                            <InputPicker value={country} onChange={setCountry} placeholder="Country" style={{ flexGrow: 2, flex: 1, marginLeft: "10px" }} data={countries} />
                                            {
                                                (platforms.platform.id >= 0 && platforms.platform.is_mc == 1) ? (
                                                    <CheckPicker value={currency} onChange={setCurrency} placeholder="Currency" style={{ flexGrow: 2, flex: 1, marginLeft: "10px" }} data={currencies} />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            {
                                                (platforms.platform.id >= 0 && platforms.platform.agent_enabled == 1) ? (
                                                    <CheckPicker value={parent} onChange={setParent} placeholder="Parent" style={{ flexGrow: 2, flex: 1, marginLeft: "10px" }} data={parents} />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <Button onClick={ClearFilters}>Reset Filters</Button>
                                        </div>
                                    </Panel>

                                    <Panel style={{ marginTop: "20px" }} header={
                                        <div className="table-data-panel-header">
                                            <ButtonGroup>
                                                <Button onClick={() => setExportModal(true)} color="primary">Export Data</Button>
                                                <Button onClick={() => setColumnsModal(true)} color="secondary"><i className="fas fa-cog"></i></Button>
                                            </ButtonGroup>
                                        </div>
                                    }>
                                        <PlayersTable showColumnsModal={columnsModal} showExportModal={exportModal} closeColumnsModal={() => setColumnsModal(false)} closeExportModal={() => setExportModal(false)} ip={ip} firstname={firstname} lastname={lastname} birthday={birthday} pid={pid} mtsid={mtsid} phone={phone} email={email} player={player} cin={cin} country={country} currency={currency} parent={parent}></PlayersTable>
                                    </Panel>
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}