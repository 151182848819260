import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Badge } from 'rsuite';
import { Toggle, Modal, Button, InputPicker, Checkbox, ButtonGroup } from 'rsuite';

import axios from "axios";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import ReactExport from "react-data-export";
import { CSVLink, CSVDownload } from "react-csv";

import { Radio, RadioGroup } from 'rsuite';

function Download(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    return (
        <>
            {
                props.dataType == 0 ? (
                    <ExcelFile hideElement={true} filename="History Data" fileExtension={props.dataType == 0 ? "xlsx" : "csv"}>
                        <ExcelSheet data={props.data} name="History">
                            {
                                props.scolumns.filter(d => {
                                    return d.active == true
                                }).map(column => {
                                    return (
                                        <ExcelColumn label={column.name} value={column.key} />
                                    )
                                })
                            }
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <>
                        <CSVDownload data={props.csvdata} target="_blank" />;
                    </>
                )
            }
        </>
    );
}
const selectKeys = (arr, keys) => {
    return arr.map(item => 
      keys.reduce((acc, key) => {
        if (item.hasOwnProperty(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {})
    );
};
const ExportModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    
    const [dataPage, setDataPage] = useState(0);
    const [dataType, setDataType] = useState(0);
    const [loading, setLoading] = useState(false);

    const [downloadable, setDownloadable] = useState(false);
    const [data, setData] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [scolumns, setSColumns] = useState([]);

    const ExportData = async () => {
        setLoading(true);

        if(props.filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+props.filters.dateRange[0]+"&dateTo="+props.filters.dateRange[1];
        else
            var dateQuery = "";

        const response = await axios.get(window.__APP__.api+"/transactions/online?platform="+platforms.platform.id+"&page="+props.page+"&limit="+props.limit+"&status="+props.filters.type+"&type="+props.filters.status+dateQuery+"&statusx="+props.filters.statusx+"&ShowAllData="+dataPage, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null) {
            setData(response.data.transactions);

            var columns = [];

            for(var k=0; k<props.columns.length; k++) {
                var column = props.columns[k];

                if(column.active == true) {
                    columns.push(column.key)
                }
            }

            setSColumns(columns);
            setCsvData(selectKeys(response.data.transactions, columns))

            setDownloadable(true);

            props.closeModal();
        }
    }   

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Export Players Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="export-data-body">
                    <div className="export-data-row">
                        <label>Export Data Page</label>
                        <InputPicker onChange={v => setDataPage(v)} value={dataPage} placeholder="Export Data Page" data={[
                            {
                                label : "Current Page Data",
                                value : 0
                            },
                            {
                                label : "All Data",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>

                    <div className="export-data-row">
                        <label>Export Data Type</label>
                        <InputPicker onChange={v => setDataType(v)} value={dataType} placeholder="Export Data Type" data={[
                            {
                                label : "XLS",
                                value : 0
                            },
                            {
                                label : "CSV",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>
                </div>
                {
                    downloadable ? (
                        <Download scolumns={props.columns} dataType={dataType} csvdata={csvdata} data={data} />
                    ) : (
                        <></>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ExportData} color="primary" appearance="primary">
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
const ColumnsModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    var [mcolumns, setMColumns] = useState(props.dcolumns);

    const UpdateColumnStatus = (key) => {
        var m = Object.assign([], mcolumns);

        var checkColumn = m.findIndex(d => {
            return d.key == key
        })

        if(checkColumn >= 0) {
            if(m[checkColumn].active) {
                m[checkColumn].active = false
            } else {
                m[checkColumn].active = true
            }
        }

        setMColumns(m);
    }

    const Save = () => {
        props.UpdateColumns(mcolumns);
        props.closeModal()
    }

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Select Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checkbox-columnss">
                    {
                        props.dcolumns.map(column => {
                            return (
                                <Checkbox onChange={v => UpdateColumnStatus(column.key)} checked={column.active ? true : false}>{column.name}</Checkbox>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={Save} color="primary" appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleApproveModal = (id) => {
        dispatch({
            type : "SET_WITHDRAWAL_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_WITHDRAWALS_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleCancelModal = (id) => {
        dispatch({
            type : "SET_WITHDRAWAL_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_CANCEL_WITHDRAWALS_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
        <Table.Cell {...props} className="link-group">
            {
                rowData['status'] == 0 ? (
                    <>
                        <a style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Approve</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleApproveModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-check"></i></IconButton>
                            </Whisper>
                        </a>
                        <a style={{marginRight : "10px"}}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Cancel</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleCancelModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-times"></i></IconButton>
                            </Whisper>
                        </a>
                    </>
                ) : (
                    <a style={{marginRight : "10px"}}>
                        -
                    </a>
                )
            }
        </Table.Cell>
    );
};

const ApproveModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const ApproveTransaction = () => {
        axios.post(window.__APP__.api+"/withdrawals/approve/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Approve Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to approve this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ApproveTransaction} color="blue" appearance="primary">
                    Approve
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const CancelModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type : "TOGGLE_CANCEL_WITHDRAWALS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const CancelWithdrawal = () => {
        axios.post(window.__APP__.api+"/withdrawals/cancel/"+modals.withdrawalId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleApproveModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Cancel Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to cancel this withdrawal ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={CancelWithdrawal} color="red" appearance="primary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function TransactionsTable(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const filters = useSelector(state => state.filters);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showExportModal, setShowExportModal] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);

    const {type, status, statusx} = props;

    const [dcolumns, setDColumns] = useState([
        {
            key : "id",
            name : "Id",
            active : true
        },
        {
            key : "player",
            name : "Player",
            active : true
        },
        {
            key : "amount",
            name : "Amount",
            active : true
        },
        {
            key : "type",
            name : "Type",
            active : true
        },
        {
            key : "date",
            name : "Date",
            active : true
        },
        {
            key : "status",
            name : "Status",
            active : true
        }
    ]);

    const GetColumnStatus = (key) => {
        var d = dcolumns.findIndex(d => {
            return d.key == key
        })

        if(d >= 0) {
            if(dcolumns[d].active) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const closeExportModal = () => {
        setShowExportModal(false);
    }
    const closeColumnsModal = () => {
        setShowColumnsModal(false);
    }

    const GetHistory = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "";
        
        axios.get(window.__APP__.api+"/transactions/online?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+"&status="+type+"&type="+status+dateQuery+"&statusx="+statusx+"&currency="+(platforms.currency.id >= 0 ? platforms.currency.id : ""), {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.transactions);
                setPages(response.data.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetHistory();
    }, [platforms.platform, platforms.currency, activePage, activeLimit, filters.dateRange, modals.withdrawalCancelModal, modals.withdrawalsModal, type, status, statusx]);
    

    return (
        <>
            <div className="panel-heading-custom" style={{marginBottom : "20px"}}>
                <div className="table-data-panel-header">
                    <ButtonGroup>
                        <Button onClick={() => setShowExportModal(true)} color="primary">Export Data</Button>
                        <Button onClick={() => setShowColumnsModal(true)} color="secondary"><i className="fas fa-cog"></i></Button>
                    </ButtonGroup>
                </div>
            </div>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                {
                    GetColumnStatus("id") ? (
                        <Table.Column width={70} align="center">
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("player") ? (
                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Player</Table.HeaderCell>
                            <Table.Cell>{ data => <Link to={"/online/player/"+data.user_id}>{data.player}</Link> }</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("amount") ? (
                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column> 
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("type") ? (
                        <Table.Column minWidth={150} flexGrow={1} align="center">
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell dataKey="type" />
                        </Table.Column> 
                    ) : (
                        <></>
                    )
                } 
                {
                    GetColumnStatus("date") ? (
                        <Table.Column minWidth={250} flexGrow={1} align="center">
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column> 
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("status") ? (
                        <Table.Column minWidth={150} flexGrow={1} align="center">
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell>{ data => <>{ data.status == 0 ? <Badge color="red" content="Canceled" /> : data.status == 1 ? <Badge color="green" content="Processed" /> : data.status == 2 ? <Badge color="yellow" content="Pending" /> : data.status == 3 ? <Badge color="green" content="Approved Manually" /> : data.status == 4 ? <Badge color="green" content="Processed Manually" /> :  data.status == -1 ? <Badge color="red" content="Canceled" /> : <></>}</> }</Table.Cell>
                        </Table.Column> 
                    ) : (
                        <></>
                    )
                }  
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.withdrawalsModal && (
                    <ApproveModal show={true} />
                )
            }

            {
                modals.withdrawalCancelModal && (
                    <CancelModal show={true} />
                )
            }

            {
                showExportModal ? (
                    <ExportModal columns={dcolumns} filters={{type,status,statusx,dateRange : filters.dateRange}} limit={activeLimit} page={activePage} closeModal={closeExportModal} show={showExportModal}></ExportModal>
                ) : (
                    <></>
                )
            }

            {
                showColumnsModal ? (
                    <ColumnsModal UpdateColumns={setDColumns} dcolumns={dcolumns} showColumnsModal={true} show={showColumnsModal} closeModal={closeColumnsModal} />
                ) : (
                    <></>
                )
            }
        </>
    )
}