import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { useEffect, useState } from "react";

export default function RegionsTable() {
    const reports = useSelector(state => state.reports);
    const filters =  useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState([]);

    const selectCountry = (country) => {
        dispatch({
            type : "SET_COUNTRY",
            data : {
                country : country
            }
        })
    }

    const GetRegionData = () => {
        axios.get(window.__APP__.api+"/reports/regions/"+reports.country+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setDataTable(response.data.regions)
                dispatch({
                    type : "SET_FR_REGIONS",
                    data : {
                        regions : response.data.regions
                    }
                })
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }


    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetRegionData();
        }
    }, [platforms.platform])

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={dataTable}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>City</Table.HeaderCell>
                    <Table.Cell dataKey="name"></Table.Cell>
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Partners</Table.HeaderCell>
                    <Table.Cell dataKey="partners" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Shops</Table.HeaderCell>
                    <Table.Cell dataKey="shops" />
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Players</Table.HeaderCell>
                    <Table.Cell dataKey="players" />
                </Table.Column>
            </Table>
        </>
    )
}