import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker, CheckPicker, ButtonGroup} from "rsuite";

import Transfer from "../../Transfer/Transfer";

import AgentsTable from "./AgentsTable";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function Agents() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);
    

    const [exportModal, setExportModal] = useState(false);
    const [columnsModal, setColumnsModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [roles, setRoles] = useState([]);

    const [pid, setPId] = useState("");
    const [player, setPlayer] = useState("");
    const [cin, setCin] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [ip, setIp] = useState("");
    const [currency, setCurrency] = useState([]);
    const [role, setRole] = useState([]);
    const [parents, setParents] = useState([]);

    const ClearFilters = () => {
        setPId("");
        setPlayer("");
        setCin("");
        setCountry("");
        setPhone("");
        setEmail("");
        setFirstname("");
        setLastname("");
        setIp("");
        setCurrency([]);
        setRole([]);
    }

    const GetCountries = () => {
        axios.get(window.__APP__.api+"/platform/countries?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setCountries(response.data.countries);
        });
    }
    const GetCurrencies = () => {
        axios.get(window.__APP__.api+"/platform/currencies?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            var mcurrencies = [];

            response.data.currencies.map(currency => {
                mcurrencies.push({
                    label : currency.currency,
                    value : currency.id
                })
            })

            setCurrencies(mcurrencies);
        });
    }
    const GetRoles = () => {
        axios.get(window.__APP__.api+"/online/agents/roles/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setRoles(response.data.roles);
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetCountries();
            GetCurrencies();
            GetRoles();
        }
    }, [platforms]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"agents-list"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Agents</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Agents</h1>
                            {
                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_create"))) >= 0 ? (
                                    <Button onClick={() => history.push("/online/agents/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Agent</Button>
                                ) : (
                                    <></>
                                )
                            }
                        </div>

                        {
                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_list"))) >= 0 ? (
                                <>
                                    <Panel className="filters-panel" bordered>
                                        <div className="panel-head">
                                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                            <h1>Filters</h1>
                                        </div>

                                        <div className="panel-body panel-body-gapped">
                                            <Input value={pid} onChange={setPId} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="ID" />
                                            <Input value={firstname} onChange={setFirstname} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="Firstname" />
                                            <Input value={lastname} onChange={setLastname} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="Lastname" />
                                            <Input value={ip} onChange={setIp} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="IP Address" />
                                            <Input value={player} onChange={setPlayer} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="Username" />
                                            <Input value={phone} onChange={setPhone} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="Phone" />
                                            <Input value={email} onChange={setEmail} style={{flexGrow : 1, flex : 1, minWidth : "200px"}} placeholder="Email" />
                                            <InputPicker value={country} onChange={setCountry} placeholder="Country" style={{flexGrow : 2, flex : 1, marginLeft : "10px"}} data={countries} />
                                            {
                                                (platforms.platform.id >= 0 && platforms.platform.is_mc == 1) ? (
                                                    <CheckPicker value={currency} onChange={setCurrency} placeholder="Currency" style={{flexGrow : 2, flex : 1, marginLeft : "10px"}} data={currencies} />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <CheckPicker value={role} onChange={setRole} placeholder="Role" style={{flexGrow : 2, flex : 1, marginLeft : "10px"}} data={roles} />
                                            <Button onClick={ClearFilters}>Reset Filters</Button>
                                        </div>
                                    </Panel>
                                    <Panel style={{marginTop : "20px"}} header={
                                        <div className="table-data-panel-header">
                                            <ButtonGroup>
                                                <Button onClick={() => setExportModal(true)} color="primary">Export Data</Button>
                                                <Button onClick={() => setColumnsModal(true)} color="secondary"><i className="fas fa-cog"></i></Button>
                                            </ButtonGroup>
                                        </div>
                                    }>
                                        <AgentsTable showColumnsModal={columnsModal} showExportModal={exportModal} closeColumnsModal={() => setColumnsModal(false)} closeExportModal={() => setExportModal(false)} currency={currency} ip={ip} firstname={firstname} lastname={lastname} birthday={""} pid={pid} mtsid={""} phone={phone} email={email} player={player} cin={cin} country={country} role={role}></AgentsTable>
                                    </Panel>
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div>
            </div> 
        </>
    )
}