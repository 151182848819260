const initialState = {
    country : null,
    currency : null,
    region : null,
    partner : null,
    shop : null,
    provider : null,
    finalReport : {
        regions : [],
        partners : [],
        shops : [],
        players : [],
        providers : [],
        jackpots : [],
        cashbacks : [],
        testing : []
    }
}

export default function setCountry(state=initialState, action) {
    switch(action.type) {
        case "SET_COUNTRY":
            return {...state, country : action.data.country}
        break;
        case "SET_R_CURRENCY":
            return {...state, currency : action.data.currency}
        break;
        case "SET_REPORTS_REGION":
            return {...state, region : action.data.region}
        break;
        case "SET_REPORTS_PARTNER":
            return {...state, partner : action.data.partner}
        break;
        case "SET_REPORTS_SHOP":
            return {...state, shop : action.data.shop}
        break;
        case "SET_REPORTS_PROVIDER":
            return {...state, provider : action.data.provider}
        break;
        case "SET_FR_REGIONS":
            return {...state, finalReport : {...state.finalReport, regions : action.data.regions}}
        break;
        case "SET_FR_PARTNERS":
            return {...state, finalReport : {...state.finalReport, partners : action.data.partners}}
        break;
        case "SET_FR_SHOPS":
            return {...state, finalReport : {...state.finalReport, shops : action.data.shops}}
        break;
        case "SET_FR_PLAYERS":
            return {...state, finalReport : {...state.finalReport, players : action.data.players}}
        break;
        case "SET_FR_PROVIDERS":
            return {...state, finalReport : {...state.finalReport, providers : action.data.providers}}
        break;
        case "SET_FR_JACKPOTS":
            return {...state, finalReport : {...state.finalReport, jackpots : action.data.jackpots}}
        break;
        case "SET_FR_CASHBACKS":
            return {...state, finalReport : {...state.finalReport, cashbacks : action.data.cashbacks}}
        break;
        case "SET_FR_TESTING":
            return {...state, finalReport : {...state.finalReport, testing : action.data.testing}}
        break;
        case "CLEAR_FR_TESTING":
            return {
                ...state,
                finalReport : {
                    regions : [],
                    partners : [],
                    shops : [],
                    players : [],
                    providers : [],
                    jackpots : [],
                    cashbacks : [],
                    testing : []
                }
            }
        break;
        default:
            return state;
        break;
    }
}