import {useState, useEffect, useRef} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button } from 'rsuite';

import { Notification, toaster, Message  } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";
import { useParams, useHistory } from "react-router";
import { Editor } from '@tinymce/tinymce-react';

export default function EditPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const editorRef = useRef(null);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [languages, setLanguages] = useState([])
    const [pageLoaded, setPageLoaded] = useState(false);

    const {id} = useParams();

    const [formValues, setFormValues] = useState({
        title : "",
        name : "",
        url : "",
        language : "",
        content : ""
    })
    const [errors, setErrors] = useState({
        title : false,
        name : false,
        url : false,
        language : false,
        content : false
    })

    const GetActiveLanguages = () => {
        axios.get(window.__APP__.api+"/cms/pages/languages?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setLanguages(response.data.languages);
            }
        });
    }
    const GetPageDetailed = () => {
        axios.get(window.__APP__.api+"/cms/page/get/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFormValues(response.data.page);
                setPageLoaded(true);
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetActiveLanguages();
            GetPageDetailed();
        }
    }, [platforms.platform]);

    const savePage = () => {
        var errorObj = Object.assign({}, errors);
        var decompiled = Object.entries(formValues);

        var hasErrors = false;

        for(var i=0; i<decompiled.length; i++) {
            var dec = decompiled[i];

            if(dec[1] == "") {
                errorObj[dec[0]] = true;
                hasErrors = true;
            } else {
                errorObj[dec[0]] = false;
            }
        }

        setErrors(errorObj)

        if(!hasErrors) {
            setButtonDisabled(true);
            axios.post(window.__APP__.api+"/cms/pages/edit/"+id+"?platform="+platforms.platform.id, formValues, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                setButtonDisabled(false);
                if(response.data.error == null) {
                    setFormMessageType("");
                    setFormMessage("");
        
                    toaster.push(<Notification type="info" header="Notification" closable>Page Saved Successfully</Notification>, {placement: "topEnd"});

                    history.push("/cms/pages");
                } else {
                    switch(response.data.error) {
                        case "Unauthorized":
                            localStorage.removeItem("jwt");
                            dispatch({
                                type : "SET_LOGGED",
                                data : {
                                    logged : false
                                }
                            })
                        break;
                        case "page_exists":
                            setFormMessage("A page with this url already exists.");
                            setFormMessageType("error");
                        break;
                        default:
                            setFormMessage("Unexpected Error");
                            setFormMessageType("error");
                        break;
                    }
                }
            })
        }
    }

    const log = () => {
        if (editorRef.current) {
            // console.log(editorRef.current.getContent());
            setFormValues({...formValues, content : editorRef.current.getContent()})
        }
    };

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-pages"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/cms/pages">Pages</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Page</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Edit Page</h1>
                        </div>

                        {
                            pageLoaded && (
                                <Panel style={{marginTop : "20px"}}>
                                    <Form className="form-box">
                                        {
                                            formMessage != "" && (
                                                <Message showIcon type={formMessageType}>
                                                    {formMessage}
                                                </Message>
                                            )
                                        }
                                        <div className="form-row" style={{marginTop : "20px"}}>
                                            <Form.Group controlId="name-1">
                                                <Form.ControlLabel>Page Title</Form.ControlLabel>
                                                <Form.Control className={errors.title ? "errored" : ""} onChange={value => setFormValues({...formValues, title : value})} value={formValues.title} name="name" placeholder="Page Title" />
                                            </Form.Group>
                                            <Form.Group controlId="name-1">
                                                <Form.ControlLabel>Page Name</Form.ControlLabel>
                                                <Form.Control className={errors.name ? "errored" : ""} onChange={value => setFormValues({...formValues, name : value})} value={formValues.name} name="name" placeholder="Page Name" />
                                            </Form.Group>
                                        </div>
                                        <div className="form-row" >
                                            <Form.Group controlId="name-1">
                                                <Form.ControlLabel>Page URL</Form.ControlLabel>
                                                <Form.Control disabled className={errors.url ? "errored" : ""} onChange={value => setFormValues({...formValues, url : value})} value={formValues.url} name="url" placeholder="Page URL" />
                                            </Form.Group>
                                            <Form.Group controlId="name-1">
                                                <Form.ControlLabel>Page Language</Form.ControlLabel>
                                                <InputPicker className={errors.language ? "errored" : ""} onChange={value => setFormValues({...formValues, language : value})} value={formValues.language} size="md" placeholder="Language" style={{width : "100%"}} data={languages} />
                                            </Form.Group>
                                        </div>

                                        {/* <div className="id-group" style={{marginTop : "20px"}}>
                                            <div style={{width : "100%"}}>
                                                <Form.ControlLabel>Content</Form.ControlLabel>
                                                <textarea onChange={event => setFormValues({...formValues, content : event.target.value})}  className={"textarea-box "+(errors.content ? "errored" : "")}>{formValues.content}</textarea>
                                            </div>
                                        </div> */}

                                        <div className="id-group" style={{marginTop : "20px"}}>
                                            <div style={{width : "100%"}}>
                                                <Form.ControlLabel>Content</Form.ControlLabel>
                                                <Editor 
                                                    initialValue={formValues.content}
                                                    className={errors.content ? "errored" : ""}
                                                    apiKey="2kfu7h568j6okfjjkqcv8nbq93chf57unnwm3ozctm5z8ptl"
                                                    onChange={v => log()}
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                />
                                            </div>
                                        </div>

                                        <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                            <Button disabled={buttonDisabled} onClick={savePage} style={{height : "100%"}} color="primary">Save Page</Button>
                                        </div>
                                    </Form>
                                </Panel>
                            )
                        }
                        
                    </div>
                </div>
            </div> 
        </>
    )
}