import "./Login.css";

import { Form, Schema } from 'rsuite';
import { Input, InputGroup, Button } from 'rsuite';
import { Checkbox, CheckboxGroup, Message } from 'rsuite';

import {useEffect, useState} from "react";

import axios from "axios";

import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";


export default function Login() {

    const history = useHistory();

    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const model = Schema.Model({
        username: Schema.Types.StringType().isRequired(),
        password: Schema.Types.StringType().isRequired()
    });

    const login = () => {
        setMessage("");
        setLoading(false);

        axios.post(window.__APP__.api+"/user/Authenticate", {
            username : username,
            password : password
        }).then(response => {
            if(response.data.error == null) {
                axios.get(window.__APP__.api+"/user/Check", {
                    headers : {
                        "Authorization" : "Bearer "+response.data.token
                    }
                }).then(cresponse => {
                    if(cresponse.data.error == null) {
                        setLoading(true);
                        localStorage.setItem("jwt", response.data.token);

                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : true
                            }
                        })

                        dispatch({
                            type : "SET_USER",
                            data : {
                                user : cresponse.data.user
                            }
                        });

                        dispatch({
                            type : "SET_PLATFORMS",
                            data : {
                                platforms : cresponse.data.platforms
                            }
                        })

                        if(cresponse.data.platforms.length > 0) {
                            var platform = cresponse.data.platforms[0];
                
                            dispatch({
                              type : "SET_PLATFORM",
                              data : {
                                platform : {
                                  id : platform.platform_id,
                                  name : platform.platform_name,
                                  type : platform.platform_type,
                                  credits_type : platform.platform_credits_type,
                                  credits : platform.platform_credits,
                                  is_limited : platform.is_limited,
                                  is_mc : platform.is_mc,
                                  agent_enabled : platform.agent_enabled,
                                  version : platform.version
                                }
                              }
                            })
                        }

                        if(cresponse.data.user.type == 4) {
                            history.push("/cms/languages");
                        } else {
                            history.push("/dashboard");
                        }
                        
                    } else {
                        setMessage("Wrong Credentials")
                    }
                });
            } else {
                setMessage("Wrong Credentials");
            }
        })
    }
    
    return (
        <>
            <div className="login-page">
                <div className="login-background"></div>
                <div className="login-form">
                    <h1>Login</h1>
                    
                    {
                        message != "" && (
                            <Message showIcon closable type="error">{message}</Message>
                        )
                    }
                    
                    <Form model={model} onSubmit={() => login()} style={{marginTop : "20px", width : "95%", "marginLeft" : "auto", "marginRight" : "auto"}}>
                        <Form.Group controlId="username" style={{marginBottom : "10px"}}>
                            <Form.Control className="login-control" name="username" onChange={(value) => setUsername(value)} placeholder="Username" />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Control className="login-control" name="password" onChange={(value) => setPassword(value)} type="password" placeholder="Password" />
                        </Form.Group>
                        <Form.Group style={{textAlign : "center"}}>
                            <Button loading={loading} type="submit" className="login-btn" color={"primary"}>Login</Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    )
}