import { Bar } from 'react-chartjs-2';

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip, Table } from "rsuite";
import { FaFilter } from "react-icons/fa";

import { Loader } from "rsuite";
import { Line } from "react-chartjs-2";

import Filters from "../../../Filters/Filters";

import axios from "axios";

export default function Overview() {
    const { id } = useParams();


    const filters = useSelector(state => state.filters);


    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const [boxes, setBoxes] = useState({});
    const [topGamesLoading, setTopGamesLoading] = useState(false);
    const [topWinsLoading, setWinsLoading] = useState(false);

    const [monthlyAction, setMonthlyAction] = useState({
        label: [],
        deposit: [],
        withdraw: [],
        profit: []
    });
    const [monthlyGGR, setMonthlyGGR] = useState({
        label: [],
        ggr: []
    });
    const [topGames, setTopGames] = useState({
        label: [],
        games: []
    })
    const [topWins, setTopWins] = useState({
        label: [],
        wins: []
    })

    const GetMonthlyChart = () => {
        if (filters.dateRange.length > 0)
            var dateQuery = "&dateFrom=" + filters.dateRange[0] + "&dateTo=" + filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api + "/online/agents/agent/overview/transactions/" + id + "?platform=" + platforms.platform.id + dateQuery, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setMonthlyAction(response.data.charts);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }
    const GetMonthlyGGR = () => {
        if (filters.dateRange.length > 0)
            var dateQuery = "&dateFrom=" + filters.dateRange[0] + "&dateTo=" + filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api + "/online/agents/agent/overview/ggr/" + id + "?platform=" + platforms.platform.id + dateQuery, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setMonthlyGGR(response.data.charts);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    const GetOverviewBoxes = () => {
        axios.get(window.__APP__.api + "/online/agents/agent/overview/" + id + "?platform=" + platforms.platform.id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setBoxes(response.data.overview);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }

    const toggleFiltersBar = () => {
        if (filters.filtersDrawer) {
            dispatch({
                type: "TOGGLE_DRAWER",
                data: {
                    show: false
                }
            })
        } else {
            dispatch({
                type: "TOGGLE_DRAWER",
                data: {
                    show: true
                }
            })
        }
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetOverviewBoxes();
        }
    }, [platforms.platform])

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetMonthlyChart();
            GetMonthlyGGR();
        }
    }, [platforms.platform, filters.dateRange])

    return (
        <>
            <div className="nav-overview">
                {
                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_overview"))) >= 0 ? (
                        <div className="overview-boxes">
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.balance != undefined ? boxes.balance : <Loader />}</h2>
                                    <h1>Balance</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon balance-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.directPlayers != undefined ? boxes.directPlayers : <Loader />}</h2>
                                    <h1>Direct Players</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon players-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.players != undefined ? boxes.players : <Loader />}</h2>
                                    <h1>Players</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon players-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.subUsers != undefined ? boxes.subUsers : <Loader />}</h2>
                                    <h1>Sub Agents</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon players-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.ggr != undefined ? boxes.ggr : <Loader />}</h2>
                                    <h1>GGR</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.deposit != undefined ? parseFloat(boxes.deposit).toFixed(2) : <Loader />}</h2>
                                    <h1>Deposit</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.withdraw != undefined ? parseFloat(boxes.withdraw).toFixed(2) : <Loader />}</h2>
                                    <h1>Withdraw</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                            <div className="box">
                                <div style={{ flex: "1 1 auto" }}>
                                    <h2>{boxes.profit != undefined ? boxes.profit : <Loader />}</h2>
                                    <h1>Profit</h1>
                                </div>
                                <div style={{ flex: "1 1 auto", position: "relative" }}>
                                    <div className="icon profit-icon"></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }

                <div className="filters-row">
                    <div className="filters-side">
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton onClick={toggleFiltersBar} style={{ padding: "10px", paddingBottom: "5px", paddingTop: "6px" }}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>

                <div className="overview-details-row">
                    {
                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_overview_transactions"))) >= 0 ? (
                            <div className="chart-box">
                                {/* <Bar data={data} options={options} /> */}
                                <div className="box-chart-header">
                                    <i className="fas fa-chart-line" />
                                    <h3>Agent Transactions</h3>
                                </div>
                                <Line data={{
                                    labels: monthlyAction.label,
                                    datasets: [
                                        {
                                            label: "Deposit",
                                            backgroundColor: "#FF1744",
                                            borderColor: "#FF1744",
                                            data: monthlyAction.deposit
                                        },
                                        {
                                            label: "Withdraw",
                                            backgroundColor: 'rgb(101, 31, 255)',
                                            borderColor: "rgb(101, 31, 255)",
                                            data: monthlyAction.withdraw
                                        },
                                        {
                                            label: "Profit",
                                            backgroundColor: '#FF9100',
                                            borderColor: "#FF9100",
                                            data: monthlyAction.profit
                                        }
                                    ]
                                }} />
                            </div>
                        ) : (
                            <></>
                        )
                    }

                    {
                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("agents_overview_ggr"))) >= 0 ? (
                            <div className="chart-box">
                                {/* <Bar data={data} options={options} /> */}
                                <div className="box-chart-header">
                                    <i className="fas fa-chart-line" />
                                    <h3>Agent GGR</h3>
                                </div>
                                <Line data={{
                                    labels: monthlyGGR.label,
                                    datasets: [
                                        {
                                            label: "GGR",
                                            backgroundColor: "#FF1744",
                                            borderColor: "#FF1744",
                                            data: monthlyGGR.ggr
                                        }
                                    ]
                                }} />
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    
                </div>
            </div>

            <Filters />
        </>
    )
}