import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Panel } from 'rsuite';

import { Button, Input, InputPicker, Whisper, Tooltip, IconButton, Table } from "rsuite";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaFilter, FaRegQuestionCircle } from "react-icons/fa";

import { useHistory } from "react-router-dom";

import axios from "axios";

const {Column, HeaderCell, Cell} = Table;

export default function SBRulesets() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [randomv, setRandomv] = useState("");

    const FetchRuleSets = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api + "/sportsbook/ep/ruleset/list?platform="+platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.Error == null) {
            setData(response.data.data);
        }
    }
    
    const Edit = async (id) => {
        history.push("/sportsbook/earlypayout/edit/"+id)
    }
    const Delete = async (id) => {
        const response = await axios.post(window.__APP__.api+"/sportsbook/DeleteRuleset/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer " + localStorage.getItem("jwt")
            }
        })

        if(response.data.Error == null) {
            setRandomv(Math.random());
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            FetchRuleSets()
        }
    }, [platforms, randomv])

    return (
        <>
            <div className="tab-content-sp-cntnt">
                <div className="tab-content-sp-heading">
                    <Button onClick={() => history.push("/sportsbook/earlypayout/create")} color="primary"><i className="fas fa-plus"></i> Create Ruleset</Button>
                </div>

                <div className="tab-content-sp-table tcs-ruleset-table">
                    <Table
                        loading={loading}
                        data={data}
                        autoHeight
                    >
                        <Column minWidth={150} flexGrow={1}>
                            <HeaderCell>Rule Name</HeaderCell>
                            <Cell>{data => data.Name}</Cell>
                        </Column>
                        <Column minWidth={150} flexGrow={1}>
                            <HeaderCell>Sport</HeaderCell>
                            <Cell>{data => data.Sport}</Cell>
                        </Column>
                        <Column minWidth={150} flexGrow={1}>
                            <HeaderCell>Market</HeaderCell>
                            <Cell>{data => data.Market}</Cell>
                        </Column>
                        <Column minWidth={150} flexGrow={1}>
                            <HeaderCell>Advantage</HeaderCell>
                            <Cell>{data => data.Advantage}</Cell>
                        </Column>
                        <Column minWidth={150} flexGrow={1}>
                            <HeaderCell>Bet Types</HeaderCell>
                            <Cell>{data => data.BetTypes}</Cell>
                        </Column>
                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.Cell className="link-group">
                                {
                                    data => {
                                        return (
                                            <>
                                                <a onClick={() => Edit(data.Id)} style={{ marginRight: "10px" }}>
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>Edit</Tooltip>
                                                        }
                                                    >
                                                        <IconButton className="table-button no-icon"><i class="fas fa-edit"></i></IconButton>
                                                    </Whisper>
                                                </a>
                                                <a onClick={() => Delete(data.Id)} style={{ marginRight: "10px" }}>
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>Delete</Tooltip>
                                                        }
                                                    >
                                                        <IconButton className="table-button no-icon"><i class="fas fa-trash-alt"></i></IconButton>
                                                    </Whisper>
                                                </a>
                                            </>
                                        )
                                    }
                                }
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            </div>
        </>
    )
}