import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Modal } from 'rsuite';

import {Button} from "rsuite";

import PartnersTable from "./PartnersTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {useMediaQuery} from "react-responsive";
import { Whisper, Tooltip } from "rsuite";

export default function Partners() {
    const history = useHistory();

    const isSmall = useMediaQuery({ query: '(max-width: 400px)' })

    const bars = useSelector(state => state.bars);

    const modals = useSelector(state => state.modals);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"partners"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Partners</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Partners</h1>
                            {
                                isSmall ? (
                                    <Whisper
                                        placement="topCenter"
                                        speaker={
                                            <Tooltip>Create Partner</Tooltip>
                                        }
                                    >
                                        <Button style={{marginTop : "7px"}} onClick={() => history.push("/partner/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /></Button>
                                    </Whisper>
                                ) : (
                                    <Button onClick={() => history.push("/partner/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Partner</Button>
                                )
                            }
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <PartnersTable></PartnersTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}