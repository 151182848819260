import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import { Button, Whisper, IconButton, Tooltip, Radio } from "rsuite";
import Nav from 'rsuite/Nav';

import { useHistory, useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import axios from "axios";

import { Modal } from "rsuite";

import { Input, InputPicker } from 'rsuite';


import Transfer from "../../Transfer/OnlineTransfer";

/* Navs */
import Overview from "./Navs/Overview";
import Documents from "./Navs/Documents";
import Transactions from "./Navs/Transactions";
import History from "./Navs/History";
import LoginHistory from "./Navs/LoginHistory";

import { AiOutlinePlusCircle } from "react-icons/ai";

const VerificationBonusModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const toggleDocumentsModal = () => {
        dispatch({
            type: "SET_VERIFICATION_MODAL",
            data: {
                modal: false,
                id: null
            }
        })
    }

    const ConfirmBonus = () => {
        axios.post(window.__APP__.api + "/players/verification/" + modals.verificationId + "?platform=" + platforms.platform.id, {
            grantBonus: toggleType
        }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if (toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Registration Bonus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Grant Registration Bonus</h4>
                <br />
                <Radio onClick={() => setToggleEvent(0)} value="0" checked={toggleType == 0 ? true : false}>Do not grant</Radio>
                <br />
                <Radio onClick={() => setToggleEvent(1)} value="1" checked={toggleType == 1 ? true : false}>Grant Bonus</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ConfirmBonus} appearance="primary">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const KYCVerificationBonusModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const toggleDocumentsModal = () => {
        dispatch({
            type: "SET_KYC_VERIFICATION_MODAL",
            data: {
                modal: false,
                id: null
            }
        })
    }

    const ConfirmBonus = () => {
        axios.post(window.__APP__.api + "/players/kycverification/" + modals.verificationId + "?platform=" + platforms.platform.id, {
            grantBonus: toggleType
        }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if (toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Registration Bonus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Grant Registration Bonus</h4>
                <br />
                <Radio onClick={() => setToggleEvent(0)} value="0" checked={toggleType == 0 ? true : false}>Do not grant</Radio>
                <br />
                <Radio onClick={() => setToggleEvent(1)} value="1" checked={toggleType == 1 ? true : false}>Grant Bonus</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={ConfirmBonus} appearance="primary">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Player(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const bars = useSelector(state => state.bars);
    const [activeKey, setActiveKey] = useState(props.nav);

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const userx = useSelector(state => state.user);

    const [messageModal, setMessageModal] = useState(false);

    const [player, setPlayer] = useState({
        name: "",
        location: "",
        balance: 0,
        cashinhand: 0,
        phone: "",
        status: 0,
        verified: 0
    });

    const GetPlayerInfo = () => {
        axios.get(window.__APP__.api + "/players/info/" + id + "?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setPlayer(response.data.operator);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                } else if (response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    const TogglePlayer = (toggleType) => {
        axios.post(window.__APP__.api + "/players/toggle/" + id + "?platform=" + platforms.platform.id, { type: 1 }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                GetPlayerInfo();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    const TogglePlayerFake = (toggleType) => {
        axios.post(window.__APP__.api + "/players/toggle/fake/" + id + "?platform=" + platforms.platform.id, { type: 1 }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                GetPlayerInfo();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    const ToggleKYCVerification = async () => {
        const response = await axios.post(window.__APP__.api + "/players/kycverification/" + id + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            GetPlayerInfo();
        } else {
            if (response.data.error == "Unauthorized") {
                localStorage.removeItem("jwt");
                dispatch({
                    type: "SET_LOGGED",
                    data: {
                        logged: false
                    }
                })
            }
        }
    }

    const ToggleVerification = () => {
        axios.post(window.__APP__.api + "/players/verification/" + id + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                GetPlayerInfo();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetPlayerInfo();
        }
    }, [platforms.platform, modals.verificationModal, modals.kycVerificationModal]);


    const MakeTransfer = () => {
        dispatch({
            type: "SET_TRANSFER_USER",
            data: {
                id: parseInt(id)
            }
        })
        dispatch({
            type: "SET_TRANSFER_TYPE",
            data: {
                type: "player"
            }
        })
        dispatch({
            type: "TOGGLE_TRANSFER_MODAL",
            data: {
                toggle: true
            }
        })
    }


    const ShowVerificationModal = (id) => {
        dispatch({
            type: "SET_VERIFICATION_MODAL",
            data: {
                modal: true,
                id: id
            }
        })
    }
    const ShowKYCVerificationModal = (id) => {
        dispatch({
            type: "SET_KYC_VERIFICATION_MODAL",
            data: {
                modal: true,
                id: id
            }
        })
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"players"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/online/players">Players</Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            {/* <h1>Preview Partner</h1> */}
                        </div>

                        <div className="preview-page">
                            <div className="preview-head">
                                <div className="head-content">
                                    <div className="thumbnail">
                                        <img src="/assets/svg/user.svg" />
                                        <div className="layer">
                                        </div>
                                    </div>
                                    {
                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_info"))) >= 0 ? (
                                            <div className="info">
                                                <div className="info-row">
                                                    <h1>ID</h1>
                                                    <h2>{id}</h2>
                                                </div>
                                                <div className="info-row">
                                                    <h1>Username</h1>
                                                    <h2>{player.username}</h2>
                                                </div>
                                                <div className="info-row">
                                                    <h1>Player Name</h1>
                                                    <h2>{player.name}</h2>
                                                </div>
                                                {
                                                    player.phone != null && (
                                                        <div className="info-row">
                                                            <h1>Phone Number</h1>
                                                            <h2>{player.phone}</h2>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    player.email != null && (
                                                        <div className="info-row">
                                                            <h1>Email</h1>
                                                            <h2>{player.email}</h2>
                                                        </div>
                                                    )
                                                }
                                                <div className="info-row">
                                                    <h1>Player Country</h1>
                                                    <h2>{player.location}</h2>
                                                </div>
                                                {
                                                    player.city != null && (
                                                        <div className="info-row">
                                                            <h1>Player City</h1>
                                                            <h2>{player.city}</h2>
                                                        </div>
                                                    )
                                                }
                                                <div className="info-row">
                                                    <h1>Balance</h1>
                                                    <h2>{player.balance}</h2>
                                                </div>
                                                <div className="info-row">
                                                    <h1>GGR</h1>
                                                    <h2>{player.ggr}</h2>
                                                </div>
                                                {
                                                    player.active_bonus != null && (
                                                        <div className="info-row">
                                                            <h1>Active Bonus</h1>
                                                            <h2>{player.active_bonus.Name}</h2>
                                                        </div>
                                                    )
                                                }
                                                <div className="info-row">
                                                    <h1>Bonus</h1>
                                                    <h2>{player.bonus}</h2>
                                                </div>
                                                <div className="info-row">
                                                    <h1>Sports Bonus</h1>
                                                    <h2>{player.sports_bonus}</h2>
                                                </div>
                                                {
                                                    player.active_bonus != null && (
                                                        <div className="info-row">
                                                            <h1>Current Rollover</h1>
                                                            <h2>{player.rollover}</h2>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    player.tracking_code != null && (
                                                        <div className="info-row">
                                                            <h1>Tracking Code</h1>
                                                            <h2>{player.tracking_code}</h2>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    player.MTSId != null && (
                                                        <div className="info-row">
                                                            <h1>MTS ID</h1>
                                                            <h2>{player.MTSId}</h2>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    player.sourceName != undefined ? (
                                                        <div className="info-row">
                                                            <h1>Source</h1>
                                                            <h2>{player.sourceName}</h2>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {
                                                    (platforms.platform.agent_enabled == 1 && player.source == 1) ? (
                                                        <>
                                                            <div className="info-row">
                                                                <h1>Parent</h1>
                                                                <h2>{player.parentId != null ? <Link to={"/online/agent/" + player.parentId}>{player.parent}</Link> : <></>}</h2>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className="info-row">
                                                    <h1>Currency</h1>
                                                    <h2>{player.currency}</h2>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                {
                                    (player.username != undefined && player.username != "") && (
                                        <div className="custom-buttons">
                                            {
                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("transfer_credits"))) >= 0 ? (
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>Transfer</Tooltip>
                                                        }
                                                    >
                                                        <IconButton onClick={() => MakeTransfer(id)} className="icon-btn"><i style={{ fontSize: "22px" }} className="fas fa-exchange"></i></IconButton>
                                                    </Whisper>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            {
                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_toggle"))) >= 0 ? (
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>{player.status == 1 ? "Block" : "Unblock"}</Tooltip>
                                                        }
                                                    >
                                                        <IconButton onClick={TogglePlayer} className="icon-btn"><i style={{ fontSize: "22px" }} className={player.status == 1 ? "fas fa-lock" : "fas fa-unlock"}></i></IconButton>
                                                    </Whisper>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            {
                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("player_toggle_fake"))) >= 0 ? (
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>{player.isfake == 1 ? "Set As Real Balance" : "Set As Fake Balance"}</Tooltip>
                                                        }
                                                    >
                                                        <IconButton onClick={TogglePlayerFake} className="icon-btn"><i style={{ fontSize: "22px" }} className={player.isfake == 1 ? "fas fa-vial player-fake-red" : "fas fa-vial"}></i></IconButton>
                                                    </Whisper>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            {
                                                player.kiosk == 0 && (
                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_toggle_verification"))) >= 0 ? (
                                                        <Whisper
                                                            placement={"top"}
                                                            speaker={
                                                                <Tooltip>{player.verified == 0 ? "Verify" : "Unverify"}</Tooltip>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { (player.verified == 0 && player.BonusTrigger == "EMAIL") ? ShowVerificationModal(id) : ToggleVerification() }} className="icon-btn"><i style={{ fontSize: "22px" }} className={player.verified == 0 ? "fas fa-check" : "fas fa-times"}></i></IconButton>
                                                        </Whisper>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            }
                                            {
                                                player.kiosk == 0 && (
                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_toggle_kyc"))) >= 0 ? (
                                                        <Whisper
                                                            placement={"top"}
                                                            speaker={
                                                                <Tooltip>{player.kyc_verified == 0 ? "Verify KYC" : "Unverify KYC"}</Tooltip>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { (player.kyc_verified == 0 && player.BonusTrigger == "KYC") ? ShowKYCVerificationModal(id) : ToggleKYCVerification() }} className="icon-btn"><i style={{ fontSize: "22px" }} className={player.kyc_verified == 0 ? "fas fa-passport kyc-inactive" : "fas fa-passport kyc-active"}></i></IconButton>
                                                        </Whisper>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }

                                <div className="head-menu">
                                    <Nav activeKey={activeKey} appearance="subtle" onSelect={(key) => setActiveKey(key)}>
                                        <Nav.Item eventKey="home">Overview</Nav.Item>
                                        {
                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_list_documents"))) >= 0 ? (
                                                <Nav.Item eventKey="documents">Documents</Nav.Item>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_list_transactions"))) >= 0 ? (
                                                <Nav.Item eventKey="transactions">Transactions</Nav.Item>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_history"))) >= 0 ? (
                                                <Nav.Item eventKey="history">History</Nav.Item>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_login_history"))) >= 0 ? (
                                                <Nav.Item eventKey="login">Login History</Nav.Item>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Nav>
                                </div>
                            </div>
                            <div className="preview-body">
                                {
                                    activeKey == "home" ? <Overview /> : activeKey == "documents" ? <Documents /> : activeKey == "transactions" ? <Transactions /> : activeKey == "history" ? <History /> : activeKey == "login" ? <LoginHistory /> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                modals.transferModal && (
                    <Transfer />
                )
            }
            {
                modals.verificationModal && (
                    <VerificationBonusModal show={true} />
                )
            }
            {
                modals.kycVerificationModal && (
                    <KYCVerificationBonusModal show={true} />
                )
            }
        </>
    )
}