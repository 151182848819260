const initialState = {
    selectedProvider : null,
    selectedProviderShare : 0
}

export default function settingsProviders(state=initialState, action) {
    switch(action.type) {
        case "SET_SETTINGS_PROVIDER":
            return {...state, selectedProvider : action.data.provider};
        break;
        case "SET_SETTINGS_PROVIDER_SHARE":
            return {...state, selectedProviderShare : action.data.share};
        break;
        default:
            return state;
        break;
    }
}