import { useState, useEffect } from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Form, Input, Button, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster } from 'rsuite';

export default function AddState() {

    const bars = useSelector(state => state.bars);

    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [countries, setCountries] = useState([]);
    const [form, setForm] = useState({
        name: "",
        code: "",
        country : ""
    })

    const [errors, setFormError] = useState({
        name: false,
        code: false
    });

    const directions = [
        {
            label: "Left To Right",
            value: 1
        },
        {
            label: "Right To Left",
            value: 0
        }
    ]

    const SubmitState = () => {
        if (form.name == "" || form.code == "") {

            var errorObj = errors;

            if (form.name == "") {
                errorObj.name = true;
            } else {
                errorObj.name = false;
            }
            if (form.code == "") {
                errorObj.code = true;
            } else {
                errorObj.code = false;
            }

            var obj = Object.assign({}, errorObj);

            setFormError(obj);
        } else {
            setFormError({
                name: false,
                code: false
            });

            var nform = Object.assign({}, form);

            axios.post(window.__APP__.api + "/settings/state/create?platform=" + platforms.platform.id, nform, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                }
            }).then(response => {
                if (response.data.error == null) {
                    setFormMessageType("");
                    setFormMessage("");

                    toaster.push(<Notification type="info" header="Notification" closable>State Added Successfully</Notification>, { placement: "topEnd" });

                    history.push("/settings/states");
                } else {
                    switch (response.data.error) {
                        case "Platform Not Found":
                        case "Unexpected Error":
                            setFormMessage("Unexpected Error");
                            setFormMessageType("error");
                            break;
                        case "Unauthorized":
                            dispatch({
                                type: "SET_LOGGED",
                                data: {
                                    logged: false
                                }
                            })
                            localStorage.removeItem("jwt");
                            history.push("/");
                            break;
                        default:
                            setFormMessage(response.data.error);
                            setFormMessageType("error");
                            break;
                    }
                }
            })
        }
    }

    const GetCountriesList = async () => {
        const response = await axios.get(window.__APP__.api+"/settings/countries/items/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setCountries(response.data.countries);
        }
    }

    useEffect(() => {
        GetCountriesList();
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-states"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/settings/states">States</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add States</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Add State</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            <Form onSubmit={SubmitState} className="form-box">
                                {
                                    formMessage != "" && (
                                        <Message showIcon type={formMessageType}>
                                            {formMessage}
                                        </Message>
                                    )
                                }
                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>State</Form.ControlLabel>
                                        <Form.Control className={errors.name ? "errored" : ""} onChange={value => setForm({ ...form, name: value })} placeholder="State Name" />
                                    </Form.Group>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>State Code</Form.ControlLabel>
                                        <Form.Control className={errors.code ? "errored" : ""} onChange={value => setForm({ ...form, code: value })} placeholder="State code" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Country</Form.ControlLabel>
                                        <InputPicker style={{ width : "100%" }} data={countries} className={errors.country ? "errored" : ""} onChange={value => setForm({ ...form, country: value })} placeholder="Country" />
                                    </Form.Group>
                                </div>

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button type="submit" style={{ height: "100%" }} color="primary">Add State</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div>
        </>
    )
}