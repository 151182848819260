import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";

export default function JackpotsPanel() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);

    const dispatch = useDispatch();
   
    const [shops, setShops] = useState([]);
    const [jackpotPayouts, setJackpotPayouts] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);


    const selectCountry = (country) => {
        dispatch({
            type : "SET_COUNTRY",
            data : {
                country : country
            }
        })
    }

    const GetJackpotPayouts = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/reports/jackpots"+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setJackpotPayouts(response.data.jackpots);
                dispatch({
                    type : "SET_FR_JACKPOTS",
                    data : {
                        jackpots : response.data.jackpots
                    }
                })
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetJackpotPayouts();
        }
    }, [platforms.platform, limit, page, filters.dateRange]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={jackpotPayouts}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={200} align="center" fixed>
                    <Table.HeaderCell>Jackpot Type</Table.HeaderCell>
                    <Table.Cell dataKey="jackpot" />
                </Table.Column>

                <Table.Column width={200}>
                    <Table.HeaderCell>Total Payout</Table.HeaderCell>
                    <Table.Cell>{data => data.total+" TND"}</Table.Cell>
                </Table.Column>

                <Table.Column width={200}>
                    <Table.HeaderCell>Total Jackpots</Table.HeaderCell>
                    <Table.Cell dataKey="count" />
                </Table.Column>
            </Table>
        </>
    )
}