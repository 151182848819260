import Drawer from 'rsuite/Drawer';

import {useDispatch, useSelector} from "react-redux";

import { DateRangePicker, InputPicker } from 'rsuite';
import moment from "moment";

export default function Filters() {

    const filters = useSelector(state => state.filters);

    const dispatch = useDispatch();

    const closeDrawer = () => {
        dispatch({
            type : "TOGGLE_DRAWER",
            data : {
                show : false
            }
        })
    }

    const SetDateRange = (range) => {

        if(range == null) {
            dispatch({
                type : "SET_DATE_RANGE",
                data : {
                    range : []
                }
            })
            dispatch({
                type : "SET_CUSTOM_RANGE",
                data : {
                    range : []
                }
            })

            return ;
        }

        var customRange = [];
        if(range.length > 0) {
            customRange.push(moment(range[0]).format("YYYY-MM-DD 00:00:00"));
            customRange.push(moment(range[1]).format("YYYY-MM-DD 23:59:59"));
        }

        dispatch({
            type : "SET_DATE_RANGE",
            data : {
                range : customRange
            }
        })
        dispatch({
            type : "SET_CUSTOM_RANGE",
            data : {
                range : range
            }
        })
    }

    return (
        <>
        <Drawer size="xs" open={filters.filtersDrawer} onClose={closeDrawer}>
            <Drawer.Header className="filters-header">
                <h2>Filters</h2>
            </Drawer.Header>
            <Drawer.Body>
                <div className="filters-form">
                    <div className="filter-box">
                        <label>Date Range</label>
                        <DateRangePicker showOneCalendar value={filters.customDateRange} onChange={value => SetDateRange(value)} placement="bottomEnd" className="filter-input" />
                    </div>
                    {/* <div className="filter-boxes">
                        <div className="filter-box flex-auto">
                            <label>Country</label>
                            <InputPicker />
                        </div>
                        <div className="filter-box flex-auto">
                            <label>Regions</label>
                            <InputPicker />
                        </div>
                    </div> */}
                </div>
            </Drawer.Body>
        </Drawer>
        </>
    )
}