import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Loader} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import Filters from "../../Filters/Filters";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

import ReactExport from "react-export-excel";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
          <Link style={{marginRight : "10px"}}>
              <Whisper
                  placement={"top"}
                  speaker={
                      <Tooltip>Details</Tooltip>
                  }
              >
                  <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
              </Whisper>
          </Link>
        </Table.Cell>
      );
};

export default function FinancialReport() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [printableData, setPrintableData] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [boxes, setBoxes] = useState({});

    const filters = useSelector(state => state.filters);

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        }
    ]

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const GetBoxes = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/shops/reportbox/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setBoxes(response.data.boxes);
            }
        });
    }

    const GetTransactions = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/shops/reporttransactions/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&filterType="+filterType+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pagination.pages);
                setPrintableData(response.data.printable);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
            GetBoxes();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo, filters.dateRange]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const openPopup = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        window.open("/shop/report/print/"+id+"?p=1"+dateQuery,'popUpWindow',"height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes");
    }

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    return (
        <>
            <div className="nav-overview">
                    

                <div className="filters-row">
                    <div className="filters-side">
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>
                

                <div className="overview-boxes">
                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.deposit != undefined ? boxes.deposit.number : <Loader />}</h2>
                            <h1>Total Deposit</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon balance-icon"></div>
                        </div>
                    </div>

                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.withdraw != undefined ? boxes.withdraw.number : <Loader />}</h2>
                            <h1>Total Withdraw</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon balance-icon"></div>
                        </div>
                    </div>

                    <div className="box">
                        <div style={{flex : "1 1 auto"}}>
                            <h2>{boxes.profit != undefined ? boxes.profit.number : <Loader />}</h2>
                            <h1>Profit</h1>
                        </div>
                        <div style={{flex : "1 1 auto", position : "relative"}}>
                            <div className="icon profit-icon"></div>
                        </div>
                    </div>
                </div>

                <div className="table-options">
                    <ExcelFile element={<Button style={{float : "right"}} appearance="primary"><i className="fas fa-file-export" style={{marginRight : "5px"}}></i> Export Report</Button>}>
                        <ExcelSheet data={printableData} name="Report">
                            <ExcelColumn label="DATE AND TIME" value="date"/>
                            <ExcelColumn label="DEPOSIT" value="deposit"/>
                            <ExcelColumn label="WITHDRAW" value="withdraw"/>
                        </ExcelSheet>
                    </ExcelFile>
                    <Button onClick={() => openPopup()} style={{float : "right", marginRight : "10px"}} appearance="primary"><i className="fas fa-print" style={{marginRight : "5px"}}></i> Print</Button>
                </div>

                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={transactions}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Sender</Table.HeaderCell>
                            <Table.Cell>{data => (data.senderId != "self" && data.senderId != "admin" && data.senderId != "partner") ? <Link to={(data.senderId != "self" && data.senderId != "admin") ? "/shop/"+data.senderId : ""}>{data.sender}</Link> : <>{data.sender}</>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver</Table.HeaderCell>
                            <Table.Cell>{data => (data.receiverId != "self" && data.receiverId != "admin" && data.receiverId != "partner") ? <Link to={(data.receiverId != "self" && data.receiverId != "admin") ? "/player/"+data.receiverId : ""}>{data.receiver}</Link> : <>{data.receiver}</>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>
                        
                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Sender Balance</Table.HeaderCell>
                            <Table.Cell dataKey="sbalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver Balance</Table.HeaderCell>
                            <Table.Cell dataKey="rbalance" />
                        </Table.Column>

                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>

            <Filters /> 
        </>
    )
}