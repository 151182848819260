import React, {useState, useEffect} from "react";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip } from "rsuite";
import {FaFilter} from "react-icons/fa";

import {useDispatch, useSelector} from "react-redux";

import { useHistory } from "react-router";

import { Pie } from 'react-chartjs-2';

import Filters from "../Filters/Filters";

export default function Region() {

    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const data = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
          {
            label: '#',
            data: [10],
            backgroundColor: '#8e44ad',
          }
        ],
    };

    const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
    }; 

    const history = useHistory();

    const bars = useSelector(state => state.bars);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"stats-region"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>
                    
                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Statistics</Breadcrumb.Item>
                            <Breadcrumb.Item active>Region</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Region Statistics</h1>

                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <div className="stats-boxes">
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Regions By Partners</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={data} options={options} />
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Regions By Shops</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={data} options={options} />
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Regions By Players</h3>
                                </div>
                                <div className="stats-box-content">
                                    <Pie data={data} options={options} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <Filters />
        </>
    )
}