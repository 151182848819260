import {useState, useEffect, useRef} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Loader, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button } from 'rsuite';

import { Notification, toaster, Message  } from 'rsuite';

import { Editor } from '@tinymce/tinymce-react';

import InputPicker from 'rsuite/InputPicker';

import ProgressiveCasino from "./Modules/ProgressiveCasino";
import FreespinsRegistration from "./Modules/FreespinsRegistration";

import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Bonus() {
    const history = useHistory();
    const dispatch = useDispatch();
    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const {id} = useParams();

    const editorRef = useRef(null);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [languages, setLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [bonus, setBonus] = useState({});


    const saveDetails = async () => {

        setButtonDisabled(true);

        

    }

    const GetBonusDetails = async () => {
        const response = await axios.get(window.__APP__.api+"/bonuses/bonus/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoaded(true);

        if(response.data.error == null) {
            setBonus(response.data.bonus);
        }

    }
    
    useEffect(() => {
        GetBonusDetails();
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"bonuses-bonuses"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/bonuses/list">Bonuses</Breadcrumb.Item>
                            <Breadcrumb.Item active>Bonus Details</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Bonus Details</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                                !loaded ? (
                                    <div style={{ display : "flex", justifyContent : "center"}}>
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            bonus.Key != undefined && (
                                                <>
                                                    {
                                                        bonus.Key == "casino_progressive_welcome" ? (
                                                            <>
                                                            <ProgressiveCasino bonus={bonus} />
                                                            </>
                                                        ) : bonus.Key == "freespins_registration" ? (
                                                            <>
                                                            <FreespinsRegistration bonus={bonus} />
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}