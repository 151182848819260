import { useState, useEffect } from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Checkbox, Panel, Uploader } from 'rsuite';

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Form, Input, Button, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster } from 'rsuite';

export default function AddAdminRole() {

    const bars = useSelector(state => state.bars);

    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");
    const [groups, setGroups] = useState([]);

    const [form, setForm] = useState({
        name: "",
        code: ""
    })

    const [errors, setFormError] = useState({
        name: false,
        code: false
    });

    const directions = [
        {
            label: "Left To Right",
            value: 1
        },
        {
            label: "Right To Left",
            value: 0
        }
    ]

    const GetRoleGroups = async () => {
        const response = await axios.get(window.__APP__.api + "/settings/adminroles/roles/groups?platform="+platforms.platform.id, {
            headers : {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setGroups(response.data.groups);
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetRoleGroups()
    }, [platforms])

    const SubmitCountry = () => {
        if (form.name == "") {

            var errorObj = errors;

            if (form.name == "") {
                errorObj.name = true;
            } else {
                errorObj.name = false;
            }

            var obj = Object.assign({}, errorObj);

            setFormError(obj);
        } else {
            setFormError({
                name: false
            });

            var nform = Object.assign({}, form);

            var permissions = [];

            for(var i=0; i<groups.length; i++) {
                var group = groups[i];

                for(var j=0; j<groups[i].subs.length; j++) {
                    var subGroup = groups[i].subs[j];

                    var allChecked = subGroup.permissions.filter(d => {
                        return d.checked == true
                    })

                    if(allChecked.length > 0) {
                        allChecked.map(d => {
                            permissions.push(d.value);
                        })
                    }
                }
            }

            axios.post(window.__APP__.api + "/settings/adminroles/create?platform=" + platforms.platform.id, {
                name : nform.name,
                permissions : permissions
            }, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                }
            }).then(response => {
                if (response.data.error == null) {
                    setFormMessageType("");
                    setFormMessage("");
                    toaster.push(<Notification type="info" header="Notification" closable>Role Added Successfully</Notification>, { placement: "topEnd" });
                    history.push("/settings/adminroles");
                } else {
                    switch (response.data.error) {
                        case "Platform Not Found":
                        case "Unexpected Error":
                            setFormMessage("Unexpected Error");
                            setFormMessageType("error");
                            break;
                        case "Unauthorized":
                            dispatch({
                                type: "SET_LOGGED",
                                data: {
                                    logged: false
                                }
                            })
                            localStorage.removeItem("jwt");
                            history.push("/");
                            break;
                        default:
                            setFormMessage(response.data.error);
                            setFormMessageType("error");
                            break;
                    }
                }
            })
        }
    }

    const toggleGroupSelect = (key, subkey) => {
        var grps = Object.assign([], groups);

        var fIndex = grps.findIndex(d => {
            return d.key == key
        })

        if(fIndex >= 0) {
            var subIndex = grps[fIndex].subs.findIndex(d => {
                return d.key == subkey
            })

            if(grps[fIndex].subs[subIndex].checked == undefined || !grps[fIndex].subs[subIndex].checked) {
                grps[fIndex].subs[subIndex].checked = true;

                for(var i=0; i<grps[fIndex].subs[subIndex].permissions.length; i++) {
                    grps[fIndex].subs[subIndex].permissions[i].checked = true;
                }
            } else {
                grps[fIndex].subs[subIndex].checked = false;

                for(var i=0; i<grps[fIndex].subs[subIndex].permissions.length; i++) {
                    grps[fIndex].subs[subIndex].permissions[i].checked = false;
                }
            }

        }

        setGroups(grps);
    }
    
    const togglePermission = (key, subkey, permission) => {
        var grps = Object.assign([], groups);

        var fIndex = grps.findIndex(d => {
            return d.key == key
        })

        if(fIndex >= 0) {
            var subIndex = grps[fIndex].subs.findIndex(d => {
                return d.key == subkey
            })

            if(grps[fIndex].subs[subIndex].checked != true) {
                var permIndex = grps[fIndex].subs[subIndex].permissions.findIndex(d => {
                    return d.value == permission
                })

                if(permIndex >= 0) {
                    if(grps[fIndex].subs[subIndex].permissions[permIndex].checked == true) {
                        grps[fIndex].subs[subIndex].permissions[permIndex].checked = false;
                    } else {
                        grps[fIndex].subs[subIndex].permissions[permIndex].checked = true;
                    }
                }
            }

        }

        setGroups(grps);
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-adminroles"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/settings/adminroles">Admin Roles</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Admin Role</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Add Admin Role</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            <Form onSubmit={SubmitCountry} className="form-box">
                                {
                                    formMessage != "" && (
                                        <Message showIcon type={formMessageType}>
                                            {formMessage}
                                        </Message>
                                    )
                                }
                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Role Name</Form.ControlLabel>
                                        <Form.Control className={errors.name ? "errored" : ""} onChange={value => setForm({ ...form, name: value })} placeholder="Role Name" />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{ marginTop : "15px" }}>
                                    <div className="grouped-roles">
                                        {
                                            groups.map(group => {
                                                return (
                                                    <>
                                                        <div className="group-role">
                                                            <h5>{group.name}</h5>

                                                            {
                                                                group.subs.map(subgroup => {
                                                                    return (
                                                                        <div className="group-subgroup-role">
                                                                            <h6>{subgroup.name} <Checkbox onChange={v => toggleGroupSelect(group.key, subgroup.key)}>Check All</Checkbox></h6>

                                                                            <div className="group-subgroup-permissions">
                                                                                {
                                                                                    subgroup.permissions.map(permission => {
                                                                                        return (
                                                                                            <>
                                                                                                <Checkbox onChange={v => togglePermission(group.key, subgroup.key, permission.value)} checked={permission.checked}>{permission.label}</Checkbox>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button type="submit" style={{ height: "100%" }} color="primary">Add Role</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div>
        </>
    )
}