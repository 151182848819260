import { Table, IconButton } from "rsuite";
import Pagination from 'rsuite/Pagination';

import { Link } from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Badge } from 'rsuite';

import axios from "axios";

export default function AgentsManualTransactions() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetManualTransactionsOverview = () => {
        if (filters.dateRange.length > 0)
            var dateQuery = "&dateFrom=" + filters.dateRange[0] + "&dateTo=" + filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        setLoading(true);

        axios.get(window.__APP__.api + "/reports/agents/manualtransactions/currency/" + reports.currency + "?platform=" + platforms.platform.id + "&page=" + page + "&limit=" + limit + dateQuery, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if (response.data.error == null) {
                setData(response.data.data);
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetManualTransactionsOverview();
        }
    }, [platforms.platform, limit, page, filters.dateRange]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={data}
                loading={loading}
            >
                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Deposit</Table.HeaderCell>
                    <Table.Cell dataKey="deposit" />
                </Table.Column>
                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Withdraw</Table.HeaderCell>
                    <Table.Cell dataKey="withdraw" />
                </Table.Column>
                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Profit</Table.HeaderCell>
                    <Table.Cell dataKey="profit" />
                </Table.Column>
            </Table>
        </>
    )
}