import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import {AiOutlinePlusCircle, AiFillPrinter} from "react-icons/ai";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Loader} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import Filters from "../../Filters/Filters";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

import ReactExport from "react-export-excel";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
          <Link style={{marginRight : "10px"}}>
              <Whisper
                  placement={"top"}
                  speaker={
                      <Tooltip>Details</Tooltip>
                  }
              >
                  <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
              </Whisper>
          </Link>
        </Table.Cell>
      );
};

export default function ShopsReport() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const filters = useSelector(state => state.filters);

    const [data, setData] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const GetData = async () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        var response = await axios.get(window.__APP__.api+"/agents/shops/reports/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setData(response.data.shops);
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetData();
        }
    }, [platforms.platform, filters.dateRange]);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const openPopup = (id) => {
        if(filters.dateRange.length > 0)
        var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
    else
        var dateQuery = "&dateFrom=&dateTo=";

        window.open("/shop/report/print/"+id+"?p=1"+dateQuery,'popUpWindow',"height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes");
    }

    return (
        <>
            <div className="nav-overview">
                    

                <div className="filters-row">
                    <div className="filters-side">
                        {/* <ExcelFile element={<Button style={{marginRight : "10px"}} className="head-btn" color={"primary"}><AiFillPrinter style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Export Report</Button>}>
                            <ExcelSheet data={fullReport.cashbacks} name="Cashback">
                                <ExcelColumn label="Total Cashback" value="payout"/>
                            </ExcelSheet>
                            <ExcelSheet data={fullReport.financials} name="Financial">
                                <ExcelColumn label="Total Deposit" value="deposit"/>
                                <ExcelColumn label="Total Withdraw" value="withdraw"/>
                                <ExcelColumn label="Profit" value="profit"/>
                            </ExcelSheet>
                            <ExcelSheet data={fullReport.jackpots} name="Jackpots">
                                <ExcelColumn label="Jackpot" value="jackpot"/>
                                <ExcelColumn label="Jackpot Payout" value="payout"/>
                            </ExcelSheet>
                        </ExcelFile> */}
                        <Whisper
                            placement="left"
                            speaker={
                                <Tooltip>Filters</Tooltip>
                            }
                        >
                            <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                        </Whisper>
                    </div>
                </div>
                            
                <Table
                    // height={600}
                    autoHeight
                    data={data}
                    // sortColumn={sortColumn}
                    // sortType={sortType}
                    // onSortColumn={handleSortColumn}
                    // loading={loading}
                >
                    <Table.Column minWidth={120} flexGrow={1} align="center">
                        <Table.HeaderCell>Shop</Table.HeaderCell>
                        <Table.Cell dataKey="shop" />
                    </Table.Column>

                    <Table.Column minWidth={120} flexGrow={1}>
                        <Table.HeaderCell>Total Deposit</Table.HeaderCell>
                        <Table.Cell dataKey="deposit" />
                    </Table.Column>

                    <Table.Column minWidth={120} flexGrow={1}>
                        <Table.HeaderCell>Total Withdraw</Table.HeaderCell>
                        <Table.Cell dataKey="withdraw"></Table.Cell>
                    </Table.Column>
                    
                    <Table.Column minWidth={120} flexGrow={1}>
                        <Table.HeaderCell>Profit</Table.HeaderCell>
                        <Table.Cell dataKey="profit"></Table.Cell>
                    </Table.Column>

                    <Table.Column minWidth={120} flexGrow={1}>
                        <Table.HeaderCell>Bonus</Table.HeaderCell>
                        <Table.Cell dataKey="bonus"></Table.Cell>
                    </Table.Column>

                    <Table.Column minWidth={120} flexGrow={1}>
                        <Table.HeaderCell>Print</Table.HeaderCell>
                        <Table.Cell>{data => <Button size="xs" onClick={() => openPopup(data.id)} color="primary">Print</Button>}</Table.Cell>
                    </Table.Column>
                </Table>
            </div>

            <Filters /> 
        </>
    )
}