import {useState, useEffect} from "react";

import {Doughnut, PolarArea} from "react-chartjs-2";

import TopWins from "./TopWins";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import {useHistory} from "react-router-dom";

export default function MonthlyGGR(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [details, setDetails] = useState({labels : [], datasets : [], background : []});

    const {timeframe} = props;

    const GetCategories = () => {
      axios.get(window.__APP__.api+"/statistics/ggr/regions?platform="+platforms.platform.id, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setDetails({
            labels : response.data.details.labels,
            datasets : response.data.details.datasets,
            background : response.data.details.colors
          })
        } else {
          if(response.data.error == "Unauthorized") {
            dispatch({
                type : "SET_LOGGED",
                data : {
                    logged : false
                }
            })
            localStorage.removeItem("jwt");
            history.push("/");
          }
        } 
      });
    }

    useEffect(() => {
      if(platforms.platform.id > -1 && platforms.platform.type != 3)
        GetCategories();
    }, [platforms.platform])
    

    return (
        <>
          {
            platforms.platform.type != 3 ? (
              <div className="box-chart">
                  <div className="box-chart-header">
                      <i className="fas fa-chart-line" />
                      <h3>GGR By Region</h3>
                  </div>
                  <PolarArea data={{labels : details.labels, datasets : [{data : details.datasets, backgroundColor : details.background}]}} options={[]} />
              </div>
            ) : (
              <TopWins online={true}  timeframe={timeframe}  />
            )
          }
        </>
    )
}