const initialState = {
    type : "",
    content : ""
}

export default function messageReducer(state=initialState, action) {
    switch(action.type) {
        case "SHOW_MESSAGE":
            return {...state, type : action.data.type, content : action.data.content}
        break;
        default:
            return state;
        break;
    }
}