
import React, {useState, useEffect} from "react";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, FlexboxGrid } from "rsuite";
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';

import { Bar } from 'react-chartjs-2';


import Transfer from "./OnlineTransfer";

export default function TransferPage() {

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const dispatch = useDispatch();

    const [timeframe, setTimeframe] = useState(4);

    useEffect(() => {
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }, [])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"overview"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>
                    
                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Transfer</Breadcrumb.Item>
                        </Breadcrumb>

                        {
                            modals.transferModal && (
                                <Transfer isPage={true} />
                            )
                        }
                    </div>
                </div>
            </div> 
        </>
    )
}