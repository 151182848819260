import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import Rulesets from "./Rulesets";
import Selectors from "./Selectors";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function SBEarlyPayout(props) {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [activeKey, setActiveKey] = useState(props.tab == undefined ? "sp-rulesets" : (props.tab == "selectors" ? "sp-selectors" : ""));

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"sports-earlypayout"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Sportsbook Early Payout</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Sportsbook Early Payout</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Nav activeKey={activeKey} appearance="subtle" onSelect={v => setActiveKey(v)}>
                                <Nav.Item eventKey="sp-rulesets">Rulesets</Nav.Item>
                                <Nav.Item eventKey="sp-selectors">Selectors</Nav.Item>
                            </Nav>

                            {
                                activeKey == "sp-rulesets" ? (
                                    <Rulesets />
                                ) : activeKey == "sp-selectors" ? (
                                    <Selectors />
                                ) : (
                                    <></>
                                )
                            }
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}