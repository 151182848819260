import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button} from "rsuite";

import PromotionsTable from "./PromotionsTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function Pages() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const userx = useSelector(state => state.user);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-promotions"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Promotions</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Promotions</h1>
                            {
                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("promotions_create"))) >= 0 ? (
                                    <Button onClick={() => history.push("/cms/promotion/create")} className="head-btn" color={"primary"}><AiOutlinePlusCircle style={{fontSize : "18px", position : "relative", top : "3.5px"}} /> Create Promotion</Button>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                        
                        {
                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("promotions_list"))) >= 0 ? (
                                <Panel style={{marginTop : "20px"}}>
                                    <PromotionsTable></PromotionsTable>
                                </Panel>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div>
            </div> 
        </>
    )
}