import { useState, useEffect, useRef } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Form, Input, Button, Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster } from 'rsuite';

export default function CreateAgent() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [roles, setRoles] = useState([]);
    const [parents, setParents] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [partners, setPartners] = useState([]);

    const [form, setForm] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        country: "",
        state: "",
        phone: "",
        password: "",
        repassword: "",
        parent: "",
        role: "",
        currency : ""
    });

    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        username: false,
        email: false,
        country: false,
        state: false,
        phone: false,
        password: false,
        repassword: false,
        parent: false,
        role: false,
        currency : false
    })

    const model = Schema.Model({
        firstname: Schema.Types.StringType().isRequired(),
        lastname: Schema.Types.StringType().isRequired(),
        username: Schema.Types.StringType().isRequired(),
        email: Schema.Types.StringType().isEmail().isRequired(),
        password: Schema.Types.StringType().isRequired(),
        repassword: Schema.Types.StringType().isRequired(),
        country: Schema.Types.StringType().isRequired(),
        state: Schema.Types.StringType().isRequired(),
        phone: Schema.Types.StringType().isRequired(),
        role: Schema.Types.StringType().isRequired(),
        parent: Schema.Types.StringType().isRequired()
    });

    const [formError, setFormError] = useState({});

    const GetRoles = () => {
        axios.get(window.__APP__.api + "/online/agents/roles/list?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setRoles(response.data.roles);
        });
    }
    const GetCurrencies = () => {
        axios.get(window.__APP__.api + "/platform/currencies?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            var mcurrencies = [];

            response.data.currencies.map(currency => {
                mcurrencies.push({
                    label : currency.currency,
                    value : currency.id
                })
            })

            setCurrencies(mcurrencies);
        });
    }

    const GetCountries = () => {
        setCountries([]);
        axios.get(window.__APP__.api + "/platform/countries?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setCountries(response.data.countries);
            } else {
                if (response.data.error == "Unauthorized") {
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    const GetCities = (country) => {
        setCities([]);
        axios.get(window.__APP__.api + "/platform/states?platform=" + platforms.platform.id + "&country=" + country, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                setCities(response.data.cities);
            } else {
                if (response.data.error == "Unauthorized") {
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetCountries();
            GetRoles();
            GetCurrencies();
        }
    }, [platforms.platform]);

    useEffect(() => {
        if (form.country != "")
            GetCities(form.country)
    }, [form.country]);

    const FetchPossibleParents = async () => {
        const response = await axios.get(window.__APP__.api + "/online/agents/possibleparents/" + form.role + "?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setParents(response.data.parents);
        }
    }

    useEffect(() => {
        if (form.role !== "")
            FetchPossibleParents();
    }, [form.role]);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const SubmitForm = async () => {
        if ((form.country == "" || form.country == null) || (form.state == "" || form.state == null) || form.firstname == "" || form.lastname == "" || form.username == "" || form.email == "" || form.phone == "" || (form.role == "" || form.role == null)) {
            var errorObj = errors;

            if (form.country == "" || form.country == null) {
                errorObj.country = true;
            } else {
                errorObj.country = false;
            }

            if (form.state == "" || form.state == null) {
                errorObj.state = true;
            } else {
                errorObj.state = false;
            }

            if (form.role == "" || form.role == null) {
                errorObj.role = true;
            } else {
                errorObj.role = false;
            }

            var obj = Object.assign({}, errorObj);
            setFormError(obj);
        } else if (!validateEmail(form.email)) {
            var errorObj = errors;
            if (form.email == "") {
                errorObj.email = true;
            } else {
                errorObj.email = false;
            }
            var obj = Object.assign({}, errorObj);

            setFormError(obj);
        } else if (form.password != form.repassword) {
            var errorObj = errors;
            errorObj.password = true;
            errorObj.repassword = true;
            var obj = Object.assign({}, errorObj);

            setFormError(obj);
        } else {
            setErrors({
                firstname: false,
                lastname: false,
                username: false,
                email: false,
                country: false,
                state: false,
                phone: false,
                password: false,
                repassword: false,
                parent: false,
                role: false
            });

            setLoading(true);

            const response = await axios.post(window.__APP__.api + "/online/agents/create?platform=" + platforms.platform.id, form, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                }
            })

            setLoading(false);

            if (response.data.error == null) {
                setFormMessageType("");
                setFormMessage("");

                toaster.push(<Notification type="info" header="Notification" closable>Agent Created Successfully</Notification>, { placement: "topEnd" });

                history.push("/online/agents/list");
            } else {
                switch (response.data.error) {
                    case "User Already Exists":
                        setFormMessage("User Already Exists");
                        setFormMessageType("error");
                        break;
                    case "City Undefined":
                    case "Country Undefined":
                        setFormMessage("Location Undefined/Disabled");
                        setFormMessageType("error");
                        break;
                    case "Platform Not Found":
                    case "Unexpected Error":
                        setFormMessage("Unexpected Error");
                        setFormMessageType("error");
                        break;
                    case "Unauthorized":
                        dispatch({
                            type: "SET_LOGGED",
                            data: {
                                logged: false
                            }
                        })
                        localStorage.removeItem("jwt");
                        history.push("/");
                        break;
                }
            }
        }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"agents-list"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/online/agents/list">Agents</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Agent</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Create Agent</h1>
                        </div>

                        <Panel style={{ marginTop: "20px" }}>
                            {
                                formMessage != "" && (
                                    <Message showIcon type={formMessageType}>
                                        {formMessage}
                                    </Message>
                                )
                            }

                            <Form onSubmit={SubmitForm} model={model} className="form-box">
                                <div className="form-row" style={{ marginTop: "20px" }}>
                                    <Form.Group controlId="firstname">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, firstname: value })} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, lastname: value })} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="username">
                                        <Form.ControlLabel>Username</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, username: value })} name="username" placeholder="Username" />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control onChange={value => setForm({ ...form, email: value })} name="email" placeholder="Email" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="pphone">
                                        <Form.ControlLabel>Phone Number</Form.ControlLabel>
                                        <Form.Control type="tel" onChange={value => setForm({ ...form, phone: value })} name="phone" placeholder="Personal Phone" />
                                    </Form.Group>
                                    <Form.Group controlId="parent">
                                        <Form.ControlLabel>Role</Form.ControlLabel>
                                        <InputPicker className={errors.role ? "errored" : ""} name="parent" onChange={(value) => setForm({ ...form, role: value })} size="md" placeholder="Role" style={{ width: "100%" }} data={roles} />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="role">
                                        <Form.ControlLabel>Parent</Form.ControlLabel>
                                        <InputPicker disabled={form.role === "" || form.role === null} className={errors.parent ? "errored" : ""} name="parent" onChange={(value) => setForm({ ...form, parent: value })} size="md" placeholder="Parent" style={{ width: "100%" }} data={parents} />
                                        <small>Empty parent will create an agent directly under the admin.</small>
                                    </Form.Group>
                                    {
                                        platforms.platform.id >= 0 && platforms.platform.is_mc == 1 ? (
                                            <Form.Group controlId="role">
                                                <Form.ControlLabel>Currency</Form.ControlLabel>
                                                <InputPicker disabled={form.parent !== null && form.parent !== ""} className={errors.parent ? "errored" : ""} name="currency" onChange={(value) => setForm({ ...form, currency: value })} size="md" placeholder="Currency" style={{ width: "100%" }} data={currencies} />
                                            </Form.Group>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                <div className="form-row" style={{ marginTop: "25px" }}>
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} onChange={value => setForm({ ...form, password: value })} type="password" name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="repassword">
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} onChange={value => setForm({ ...form, repassword: value })} type="password" name="repassword" placeholder="Confirm Password" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="country">
                                        <Form.ControlLabel>Country</Form.ControlLabel>
                                        <InputPicker className={errors.country ? "errored" : ""} name="country" onChange={(value) => setForm({ ...form, country: value })} size="md" placeholder="Country" style={{ width: "100%" }} data={countries} />
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.ControlLabel>State</Form.ControlLabel>
                                        <InputPicker className={errors.state ? "errored" : ""} name="state" onChange={(value) => setForm({ ...form, state: value })} size="md" placeholder="State" style={{ width: "100%" }} data={cities} />
                                    </Form.Group>
                                </div>

                                <div style={{ textAlign: "right", marginTop: "20px", width: "90%", maxWidth: "1100px", marginRight: "auto", marginLeft: "auto" }}>
                                    <Button loading={loading} type="submit" style={{ height: "100%" }} color="primary">Create Agent</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div>
        </>
    )
}