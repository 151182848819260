import {useState, useEffect, useRef} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Schema, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory, useParams } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function Partners() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
      firstname : "",
      lastname : "",
      username : "",
      email : "",
      country : "",
      city : "",
      pphone : "",
      wphone : "",
      password : "",
      repassword : ""
    });

    const [errors, setErrors] = useState({
      country : false,
      city : false,
      email : false,
      cinFile : false,
      passportFile : false,
      password : false,
      repassword : false
    })
    const [formError, setFormError] = useState({})

    const GetCountries = () => {
      setCountries([]);
      axios.get(window.__APP__.api+"/platform/countries?platform="+platforms.platform.id, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setCountries(response.data.countries);
          GetOperatorInfo();
        } else {
          if(response.data.error == "Unauthorized") {
              dispatch({
                  type : "SET_LOGGED",
                  data : {
                      logged : false
                  }
              })
              localStorage.removeItem("jwt");
              history.push("/");
          }
        }
      });
    }

    const GetCities = (country) => {
      setCities([]);
      axios.get(window.__APP__.api+"/platform/cities?platform="+platforms.platform.id+"&country="+country, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        if(response.data.error == null) {
          setCities(response.data.cities);
        } else {
          if(response.data.error == "Unauthorized") {
              dispatch({
                  type : "SET_LOGGED",
                  data : {
                      logged : false
                  }
              })
              localStorage.removeItem("jwt");
              history.push("/");
          }
        }
      });
    }

    const GetOperatorInfo = () => {
        axios.get(window.__APP__.api+"/kiosk/shop/get/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setForm(response.data.partner);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
      if(platforms.platform.id >= 0) {
        GetCountries();
      }
    }, [platforms.platform]);

    useEffect(() => {
      if(form.country != "")
        GetCities(form.country)
    }, [form.country]);

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const SubmitForm = () => {
      if(form.country == "" || form.city == "" || form.firstname == "" || form.lastname == "" || form.username == ""  || form.email == ""  || form.pphone == "") {
        
        var errorObj = errors;

        if(form.country == "") {
          errorObj.country = true;
        } else {
          errorObj.country = false;
        }

        if(form.city == "") {
          errorObj.city = true;
        } else {
          errorObj.city = false;
        }
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else if(!validateEmail(form.email)) {
        var errorObj = errors;
        if(form.email == "") {
          errorObj.email = true;
        } else {
          errorObj.email = false;
        }
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else if(form.password != "" && form.password != form.repassword) {
        var errorObj = errors;
        errorObj.password = true;
        errorObj.repassword = true;
        var obj = Object.assign({}, errorObj);

        setFormError(obj);
      } else {
        setErrors({
          country : false,
          city : false,
          email : false,
          cinFile : false,
          passportFile : false,
          password : false,
          repassword : false
        });

        axios.post(window.__APP__.api+"/kiosk/shop/edit/"+id+"?platform="+platforms.platform.id, form, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem("jwt")
          }
        }).then(response => {
          if(response.data.error == null) {
            setFormMessageType("");
            setFormMessage("");

            toaster.push(<Notification type="info" header="Notification" closable>Shop Edited Successfully</Notification>, {placement: "topEnd"});

            history.push("/kshops");
          } else {
            switch(response.data.error) {
              case "User Already Exists":
                setFormMessage("User Already Exists");
                setFormMessageType("error");
              break;
              case "City Undefined":
              case "Country Undefined":
                setFormMessage("Location Undefined/Disabled");
                setFormMessageType("error");
              break;
              case "Platform Not Found":
              case "Unexpected Error":
                setFormMessage("Unexpected Error");
                setFormMessageType("error");
              break;
              case "Unauthorized":
                dispatch({
                    type : "SET_LOGGED",
                    data : {
                        logged : false
                    }
                })
                localStorage.removeItem("jwt");
                history.push("/");
              break;
            }
          }
        })
      }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"kagents"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/kshops">Shops</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Shop</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Edit Shop</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                              formMessage != "" && (
                                <Message showIcon type={formMessageType}>
                                  {formMessage}
                                </Message>
                              )
                            }
                            
                            <Form onSubmit={SubmitForm} className="form-box">
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="firstname" controlId="name-1">
                                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                                        <Form.Control value={form.firstname} onChange={value => setForm({...form, firstname : value})} name="firstname" placeholder="Firstname" />
                                    </Form.Group>
                                    <Form.Group controlId="lastname">
                                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                                        <Form.Control value={form.lastname} onChange={value => setForm({...form, lastname : value})} name="lastname" placeholder="Lastname" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="username">
                                        <Form.ControlLabel>Username</Form.ControlLabel>
                                        <Form.Control disabled value={form.username} name="username" placeholder="Username" />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control value={form.email} onChange={value => setForm({...form, email : value})} name="email" placeholder="Email" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="pphone">
                                        <Form.ControlLabel>Personal Phone</Form.ControlLabel>
                                        <Form.Control value={form.pphone} type="tel" onChange={value => setForm({...form, pphone : value})} name="pphone" placeholder="Personal Phone" />
                                    </Form.Group>
                                    <Form.Group controlId="wphone">
                                        <Form.ControlLabel>Work Phone</Form.ControlLabel>
                                        <Form.Control value={form.wphone} type="tel" onChange={value => setForm({...form, wphone : value})} name="wphone" placeholder="Work Phone" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>Password</Form.ControlLabel>
                                        <Form.Control className={errors.password ? "errored" : ""} onChange={value => setForm({...form, password : value})} type="password" name="password" placeholder="Password" />
                                    </Form.Group>
                                    <Form.Group controlId="repassword">
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control className={errors.repassword ? "errored" : ""} onChange={value => setForm({...form, repassword : value})} type="password" name="repassword" placeholder="Confirm Password" />
                                    </Form.Group>
                                </div>

                                <div className="form-row">
                                    <Form.Group controlId="country">
                                        <Form.ControlLabel>Country</Form.ControlLabel>
                                        <InputPicker value={form.country} className={errors.country ? "errored" : ""} name="country" onChange={(value) => setForm({...form, country : value})} size="md" placeholder="Country" style={{width : "100%"}} data={countries} />
                                    </Form.Group>
                                    <Form.Group controlId="city">
                                        <Form.ControlLabel>City</Form.ControlLabel>
                                        <InputPicker value={form.city} className={errors.city ? "errored" : ""} name="city" onChange={(value) => setForm({...form, city : value})} size="md" placeholder="City" style={{width : "100%"}} data={cities} />
                                    </Form.Group>
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Edit Shop</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}