import { useEffect, useState } from "react";
import { InputPicker, Table, Pagination, Whisper, Tooltip, IconButton, Input } from "rsuite";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

const { Column, HeaderCell, Cell } = Table;

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms)

    const dispatch = useDispatch();

    const updateLanguageContent = (id) => {
        // alert(modals.languageContentid);
        // alert(modals.languageContentValue)

        axios.post(window.__APP__.api + "/cms/language/content/update/" + id + "?platform=" + platforms.platform.id, {
            value: modals.languageContentValue
        }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            if (response.data.error == null) {
                dispatch({
                    type: "SET_LANGUAGE_CONTENT_ID",
                    data: {
                        id: id + response.data.random
                    }
                })
                dispatch({
                    type: "SET_LANGUAGE_CONTENT_VALUE",
                    data: {
                        value: null
                    }
                })
            }
        })
    }
    return (
        <Table.Cell {...props} className="link-group">
            <a onClick={() => updateLanguageContent(rowData['id'])} style={{ marginRight: "10px" }}>
                <Whisper
                    placement={"top"}
                    speaker={
                        <Tooltip>Save</Tooltip>
                    }
                >
                    <IconButton className="table-button no-icon"><i class="fas fa-check"></i></IconButton>
                </Whisper>
            </a>
        </Table.Cell>
    );
};

export default function Tournaments(props) {

    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const [languages, setLanguages] = useState([]);
    const [sports, setSports] = useState([]);
    const [categories, setCategories] = useState([]);
    const [language, setLanguage] = useState("");
    const [sport, setSport] = useState("");
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [content, setContent] = useState([]);

    const Fetchlanguages = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/languages?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setLanguages(response.data.languages);
        }
    }
    const FetchSports = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/sports?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setSports(response.data.sports);
        }
    }
    const FetchCategories = async () => {
        const response = await axios.get(window.__APP__.api + "/sportsbook/categories?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        if (response.data.error == null) {
            setCategories(response.data.categories);
        }
    }


    useEffect(() => {

        if (platforms.platform.id >= 0) {
            Fetchlanguages();
            FetchSports();
            FetchCategories();
        }

    }, [platforms.platform])

    const GetTournamentsContent = async (id, mpage) => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api + "/sportsbook/tournamentscontent?platform=" + platforms.platform.id + "&page=" + mpage + "&language=" + id + "&sport=" + (sport != null ? sport : "") + "&category=" + (category != null ? category : "") + "&name=" + name, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if (response.data.error == null) {
            setContent(response.data.content);
            setPages(response.data.pages);
        }
    }

    useEffect(() => {
        if (language !== "") {
            GetTournamentsContent(language, page);
        }
    }, [language, page, sport, category, name])

    const setNameContent = (id, value) => {
        var m = Object.assign([], content);

        var checkC = m.findIndex(d => {
            return d.Id == id
        })

        if (checkC >= 0) {
            m[checkC].Name = value;
        }

        setContent(m);
    }

    const SaveName = async (id) => {
        var m = Object.assign([], content);

        var checkC = m.findIndex(d => {
            return d.Id == id
        })

        if (checkC >= 0) {
            setLoading(true);

            const response = await axios.post(window.__APP__.api + "/sportsbook/UpdateTournamentContentName?platform=" + platforms.platform.id + "&language=" + language, {
                name: m[checkC].Name,
                id: id
            }, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                }
            })

            if (response.data.error == null) {
                const mresponse = await axios.get(window.__APP__.api + "/sportsbook/tournamentscontent?platform=" + platforms.platform.id + "&page=" + page + "&language=" + language + "&sport=" + (sport != null ? sport : "")  + "&category=" + (category != null ? category : "") + "&name=" + name, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("jwt")
                    }
                })
                setLoading(false);

                if (mresponse.data.error == null) {
                    setContent(mresponse.data.content);
                    setPages(mresponse.data.pages);
                }
            }

        }
    }

    return (
        <>
            {
                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("sportsbook_countries_content"))) >= 0 ? (
                    <>
                        <div className="tab-content-sp-cntnt">
                            <div className="sb-content-filters">
                                <div className="language-selector">
                                    <label>Language</label>
                                    <InputPicker value={language} onChange={v => setLanguage(v)} data={languages} />
                                </div>
                                <div className="language-selector">
                                    <label>Sport</label>
                                    <InputPicker value={sport} onChange={v => setSport(v)} data={sports} />
                                </div>
                                <div className="language-selector">
                                    <label>Category</label>
                                    <InputPicker value={category} onChange={v => setCategory(v)} data={categories} />
                                </div>
                                <div className="language-selector">
                                    <label>Search</label>
                                    <Input placeholder="Search Tournament" value={name} onChange={v => setName(v)} style={{ minWidth: "300px" }} />
                                </div>
                            </div>

                            {
                                language !== "" ? (
                                    <>
                                        <div className="language-content-table-sp">
                                            <Table
                                                loading={loading}
                                                data={content}
                                                autoHeight
                                            >
                                                <Column flexGrow={1}>
                                                    <HeaderCell>English Name</HeaderCell>
                                                    <Cell>{data => data.EnglishName}</Cell>
                                                </Column>
                                                <Column flexGrow={1}>
                                                    <HeaderCell>Sport</HeaderCell>
                                                    <Cell>{data => data.Sport}</Cell>
                                                </Column>
                                                <Column flexGrow={1}>
                                                    <HeaderCell>Tournament Name ( Translated )</HeaderCell>
                                                    <Cell>{data => <><Input onChange={v => setNameContent(data.Id, v)} value={data.Name} size="xs" style={{}} /></>}</Cell>
                                                </Column>
                                                <Table.Column width={200} align="center">
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                    <Table.Cell className="link-group">
                                                        {
                                                            data => {
                                                                return (
                                                                    <>
                                                                        <a onClick={() => SaveName(data.Id)} style={{ marginRight: "10px" }}>
                                                                            <Whisper
                                                                                placement={"top"}
                                                                                speaker={
                                                                                    <Tooltip>Save</Tooltip>
                                                                                }
                                                                            >
                                                                                <IconButton className="table-button no-icon"><i class="fas fa-check"></i></IconButton>
                                                                            </Whisper>
                                                                        </a>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                    </Table.Cell>
                                                </Table.Column>
                                            </Table>
                                        </div>

                                        <div className="pagination-box">
                                            <Pagination
                                                layout={['', '-', '|', 'pager']}
                                                pages={pages}
                                                limit={30}
                                                limitOptions={[25, 50, 100]}
                                                maxButtons={5}
                                                activePage={page}
                                                onChangePage={page => setPage(page)}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    </>
                ) : (
                    <div className="tab-content-sp-cntnt"></div>
                )
            }
        </>
    )
}