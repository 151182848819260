import {createStore} from "redux";
import { combineReducers } from "redux";


import userReducer from "./reducers/userReducer";
import barsReducer from "./reducers/barsReducer";
import platformReducer from "./reducers/platformsReducer";
import filtersReducer from "./reducers/filtersReducer";
import reportsReducer from "./reducers/reportsReducer";
import settingsReducer from "./reducers/settingsReducer";
import messageReducer from "./reducers/messageReducer";
import modalsReducer from "./reducers/modalsReducer";

const rootReducer = combineReducers({user: userReducer, bars : barsReducer, platforms : platformReducer, filters : filtersReducer, reports : reportsReducer, settings : settingsReducer, message : messageReducer, modals : modalsReducer});

const store = createStore(rootReducer);

export default store;