import {useState, useEffect} from "react";

import {Sidenav, Nav, Dropdown} from 'rsuite';

import {useSelector, useDispatch} from "react-redux";

import {useHistory} from "react-router-dom";

import {FaTools, FaAddressBook} from "react-icons/fa";
import {AiFillPieChart} from "react-icons/ai";
import {FaFileInvoiceDollar, FaCogs, FaPuzzlePiece} from "react-icons/fa";
import {IoCogSharp, IoGift, IoGameController, IoFootball} from "react-icons/io5";

import { useMediaQuery } from 'react-responsive';

export default function Sidebar(props) {

    const isSidebarClosed = useMediaQuery({ query: '(max-width: 1160px)' })
    
    const [platformType, setPlatformType] = useState(null);


    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platformx = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const GoToLink = (link) => {
        setTimeout(() => {
            history.push(link);
        }, 100)
    }

    useEffect(() => {
        if(platformx.platform != undefined) {
            if(platformx.platform.type != undefined) {
                setPlatformType(platformx.platform.type);
            } else {
                setPlatformType(0);
            }
        }
    }, [platformx.platform.id]);

    return (
        <>
            {
                platformType != null ? (
                    <Sidenav expanded={!bars.sidebar} className="fixed-sidebar" activeKey={props.active}>
                        <Sidenav.Header className="side-header">
                            {
                                (bars.sidebar || isSidebarClosed) ? (
                                    <div onClick={() => history.push("/")} style={{cursor : "pointer"}} className="royal-logo"></div>
                                ) : (
                                    <div onClick={() => history.push("/")} style={{cursor : "pointer"}} className="royal-logo-desktop"></div>
                                )
                            }
                        </Sidenav.Header>
                        <Sidenav.Body>
                            <Nav>
                                {
                                    (userx.user != undefined && userx.user.type == 4) ? (
                                        <Dropdown placement="rightStart" eventKey="cms" title="CMS" icon={<FaTools style={{fontSize : "20px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                            <Dropdown.Item onClick={() => GoToLink("/cms/languages")} eventKey="cms-languages">Languages</Dropdown.Item>
                                        </Dropdown>
                                    ) : (
                                        <>
                                            <Nav.Item eventKey="overview" onClick={() => GoToLink("/")} icon={<i className="fas fa-tachometer-alt" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                <span>Overview</span>
                                            </Nav.Item>
                                            {
                                                platformx.platform.agent_enabled == 1 && (
                                                    (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("agents"))) >= 0 ? (
                                                        <Dropdown placement="rightStart" eventKey="agents" title="Agents" icon={<FaAddressBook style={{fontSize : "20px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                            <Dropdown.Item onClick={() => GoToLink("/online/agents/list")} eventKey="agents-list">Agents List</Dropdown.Item>
                                                        </Dropdown>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            }
                                            {
                                                userx.user.type != 5 && (
                                                    <>
                                                    {
                                                        (platformType == 0) ? (
                                                            <>
                                                                <Nav.Item eventKey="partners" onClick={() => GoToLink("/partners")} icon={<i className="fas fa-user-secret" style={{fontSize : "22px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Partners</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="agents" onClick={() => GoToLink("/agents")} icon={<i className="fas fa-user-tie" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Agents</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="shops" onClick={() => GoToLink("/shops")} icon={<i className="fas fa-store-alt" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Shops</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="vouchers" onClick={() => GoToLink("/vouchers")} icon={<i className="fas fa-ticket-alt" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Vouchers</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="players" onClick={() => GoToLink("/players")} icon={<i className="fas fa-users" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Players</span>
                                                                </Nav.Item>
                                                            </>
                                                        ) : (platformType == 3) ? (
                                                            <>
                                                                {
                                                                    (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("players"))) >= 0 ? (
                                                                        <Nav.Item eventKey="players" onClick={() => GoToLink("/online/players")} icon={<i className="fas fa-users" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                            <span>Players</span>
                                                                        </Nav.Item>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Nav.Item eventKey="kpartners" onClick={() => GoToLink("/kpartners")} icon={<i className="fas fa-user-tie" style={{fontSize : "22px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Partners</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="kagents" onClick={() => GoToLink("/kagents")} icon={<i className="fas fa-user" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Agents</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="kshops" onClick={() => GoToLink("/kshops")} icon={<i className="fas fa-store-alt" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Shops</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="kplayers" onClick={() => GoToLink("/kplayers")} icon={<i className="fas fa-users" style={{fontSize : "19px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Players</span>
                                                                </Nav.Item>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.is_limited == 0 && (
                                                            (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("cms"))) >= 0 ? (
                                                                <Dropdown placement="rightStart" eventKey="cms" title="CMS" icon={<FaTools style={{fontSize : "20px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("banners"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/cms/banners")} eventKey="cms-banners">Banners</Dropdown.Item>
                                                                        ) : (   
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("languages"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/cms/languages")} eventKey="cms-languages">Languages</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("pages"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/cms/pages")} eventKey="cms-pages">Pages</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("promotions"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/cms/promotions")} eventKey="cms-promotions">Promotions</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("socials_list"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/cms/socials")} eventKey="cms-socials">Socials</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Dropdown>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    }
                                                    </>
                                                )
                                            }

                                            {
                                                (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("settings"))) >= 0 ? (
                                                    <Dropdown placement="rightStart" eventKey="settings" title="Settings" icon={<FaCogs style={{fontSize : "20px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                        {
                                                            platformx.platform.is_mc == 1 ? (
                                                                (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("currencies_list"))) >= 0 ? (
                                                                    <Dropdown.Item onClick={() => GoToLink("/settings/currencies")} eventKey="settings-currencies">Currencies</Dropdown.Item>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("countries_list"))) >= 0 ? (
                                                                <Dropdown.Item onClick={() => GoToLink("/settings/countries")} eventKey="settings-countries">Countries</Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("states_list"))) >= 0 ? (
                                                                <Dropdown.Item onClick={() => GoToLink("/settings/states")} eventKey="settings-states">States</Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("adminusers"))) >= 0 ? (
                                                                <Dropdown.Item onClick={() => GoToLink("/settings/adminusers")} eventKey="settings-adminusers">Admin Users</Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("adminroles"))) >= 0 ? (
                                                                <Dropdown.Item onClick={() => GoToLink("/settings/adminroles")} eventKey="settings-adminroles">Admin Roles</Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </Dropdown>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            
                                            {
                                                platformx.platform.is_limited == 0 && (
                                                    (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("statistics"))) >= 0 ? (
                                                        <Dropdown placement="rightStart" eventKey="stats" title="Statistics" icon={<AiFillPieChart style={{fontSize : "20px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                            {
                                                                (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("providers-stats"))) >= 0 ? (
                                                                    <Dropdown.Item onClick={() => GoToLink("/statistics/providers")} eventKey="stats-providers">Providers</Dropdown.Item>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }

                                                            {
                                                                <>
                                                                    {
                                                                        userx.user.type != 5 && (
                                                                            <>
                                                                                {
                                                                                    platformx.platform.type == 3 ? (
                                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("games-stats"))) >= 0 ? (
                                                                                            <Dropdown.Item onClick={() => GoToLink("/online/statistics/games")} eventKey="stats-games">Games</Dropdown.Item>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )
                                                                                    ) : (
                                                                                        <Dropdown.Item onClick={() => GoToLink("/statistics/games")} eventKey="stats-games">Games</Dropdown.Item>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    platformx.platform.type == 0 && (
                                                                                        <Dropdown.Item onClick={() => GoToLink("/statistics/partners")} eventKey="stats-partners">Partners</Dropdown.Item>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    platformx.platform.type != 3 ? (
                                                                                        <>
                                                                                            <Dropdown.Item onClick={() => GoToLink("/statistics/shops")} eventKey="stats-shops">Shops</Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => GoToLink("/statistics/players")} eventKey="stats-players">Players</Dropdown.Item>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {
                                                                                                (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("players-stats"))) >= 0 ? (
                                                                                                    <Dropdown.Item onClick={() => GoToLink("/online/statistics/players")} eventKey="stats-players">Players</Dropdown.Item>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </Dropdown>
                                                    ) : (   
                                                        <></>
                                                    )
                                                )
                                            }

                                            {
                                                (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("reports"))) >= 0 ? (
                                                    <Nav.Item eventKey="reports" onClick={() => GoToLink("/reports")} icon={<i className="fas fa-file" style={{marginLeft : "4px", fontSize : "19px", width : "35px", display : "inline-block", textAlign : "left"}} />}>
                                                        <span>Reports</span>
                                                    </Nav.Item>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            
                                            {
                                                userx.user.type != 5 && (
                                                    <>
                                                    {
                                                        platformx.platform.type == 0 ? (
                                                            <Nav.Item eventKey="reports" onClick={() => GoToLink("/reports")} icon={<i className="fas fa-file" style={{marginLeft : "4px", fontSize : "19px", width : "35px", display : "inline-block", textAlign : "left"}} />}>
                                                                <span>Reports</span>
                                                            </Nav.Item>
                                                        ) : (platformx.platform.type == 3) ? (
                                                            <></>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (platformx.platform != undefined && platformx.platform.type == 0) && (
                                                            <Nav.Item eventKey="financials" onClick={() => GoToLink("/financials")} icon={<i className="fas fa-file-invoice-dollar" style={{marginLeft : "4px", fontSize : "19px", width : "35px", display : "inline-block", textAlign : "left"}} />}>
                                                                <span>Financial Reports</span>
                                                            </Nav.Item>
                                                        )
                                                    }
                                                    {/* <Dropdown placement="rightStart" eventKey="settings" title="Settings" icon={<IoCogSharp style={{fontSize : "21px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                        <Dropdown.Item onClick={() => GoToLink("/settings/providers")} eventKey="settings-providers">Providers</Dropdown.Item>
                                                        {
                                                            (platformx.platform.type != 3) ? (
                                                                <>
                                                                    {
                                                                        platformx.platform.is_limited == 0 && (
                                                                            <Dropdown.Item onClick={() => GoToLink("/settings/jackpots")} eventKey="settings-jackpots">Jackpots</Dropdown.Item>
                                                                        )
                                                                    }
                                                                    <Dropdown.Item onClick={() => GoToLink("/settings/site")} eventKey="settings-site">Site Settings</Dropdown.Item>
                                                                </>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )
                                                        }
                                                    </Dropdown> */}
                                                    {
                                                        (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("contents"))) >= 0 ? (
                                                            <Dropdown placement="rightStart" eventKey="content" title="Content Management" icon={<IoGameController style={{fontSize : "21px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                                {
                                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("content_providers_list"))) >= 0 ? (
                                                                        <Dropdown.Item onClick={() => GoToLink("/content/providers")} eventKey="content-providers">Providers</Dropdown.Item>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                                {
                                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("content_games_list"))) >= 0 ? (
                                                                        <Dropdown.Item onClick={() => GoToLink("/content/games")} eventKey="content-games">Games</Dropdown.Item>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </Dropdown>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 0 ? (
                                                            <>
                                                                <Nav.Item eventKey="partnerships" onClick={() => GoToLink("/partnerships")} icon={<i className="fas fa-handshake" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Partnerships</span>
                                                                </Nav.Item>
                                                                <Nav.Item eventKey="withdrawals" onClick={() => GoToLink("/withdrawals")} icon={<i className="fas fa-money-check-alt" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Withdrawals</span>
                                                                </Nav.Item>
                                                            </>
                                                        ) : (platformx.platform.type == 3) ? (
                                                            <>
                                                                {
                                                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("withdrawals_list"))) >= 0 ? (
                                                                        <Nav.Item eventKey="withdrawals" onClick={() => GoToLink("/online/withdrawals")} icon={<i className="fas fa-money-check-alt" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                            <span>Withdrawals</span>
                                                                        </Nav.Item>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 3 ? (
                                                            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("transactions_online_list"))) >= 0 ? (
                                                                <Nav.Item eventKey="online-transactions" onClick={() => GoToLink("/online/transactions")} icon={<i className="fas fa-money-check-alt" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                    <span>Online Transactions</span>
                                                                </Nav.Item>
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : (
                                                            <Nav.Item eventKey="transactions" onClick={() => GoToLink("/transactions")} icon={<i className="fas fa-history" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                <span>Transactions</span>
                                                            </Nav.Item>
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 0 && (
                                                            <Nav.Item eventKey="debts" onClick={() => GoToLink("/partnerdebts")} icon={<i className="fas fa-coins" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                <span>Partner's Debts</span>
                                                            </Nav.Item>
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 0 && (
                                                            <Nav.Item eventKey="shopsbook" onClick={() => GoToLink("/shopsbook")} icon={<i className="fas fa-store" style={{fontSize : "20px", width : "40px", display : "inline-block", textAlign : "left"}} />}>
                                                                <span>Shops Book</span>
                                                            </Nav.Item>
                                                        )
                                                    } 
                                                    {
                                                        platformx.platform.type == 3 && (
                                                            (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("paymentmethods"))) >= 0 ? (
                                                                <Dropdown placement="rightStart" eventKey="paymentsmethods" title="Payment Methods" icon={<FaPuzzlePiece style={{fontSize : "21px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                                    {
                                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("paymentsmethods_deposits"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/online/paymentmethod/deposits")} eventKey="paymentsmethods-deposits">Deposit Methods</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("paymentsmethods_withdrawals"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/online/paymentmethod/withdrawals")} eventKey="paymentsmethods-withdrawals">Withdraw Methods</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Dropdown>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 3 && (
                                                            (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("bonuses"))) >= 0 ? (
                                                                <Dropdown placement="rightStart" eventKey="bonuses" title="Bonuses" icon={<IoGift style={{fontSize : "21px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                                    {
                                                                        (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("bonuses_list"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/bonuses/list")} eventKey="bonuses-bonuses">Bonuses</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Dropdown>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        platformx.platform.type == 3 && (
                                                            (userx.user.permissiongroups == null || (userx.user.permissiongroups != null && userx.user.permissiongroups.indexOf("sportsbook"))) >= 0 ? (
                                                                <Dropdown placement="rightStart" eventKey="sportsbook" title="Sportsbook" icon={<IoFootball style={{fontSize : "21px", marginRight : "20px", display : "inline-block", textAlign : "left"}} />}>
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("sportsbookcontent"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/sportsbook/content")} eventKey="sports-content">Content</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("spearlypayout"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/sportsbook/earlypayout")} eventKey="sports-earlypayout">Early Payout</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        (userx.user.permissionsubgroups == null || (userx.user.permissionsubgroups != null && userx.user.permissionsubgroups.indexOf("spbonus"))) >= 0 ? (
                                                                            <Dropdown.Item onClick={() => GoToLink("/sportsbook/multibonus")} eventKey="sports-multibonus">Multi-Bonus</Dropdown.Item>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Dropdown>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    }
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Nav>
                        </Sidenav.Body>
                    </Sidenav>
                ) : (
                    <></>
                )
            }
        </>
    )
}