import {useState, useEffect} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, InputPicker } from 'rsuite';

import {Button} from "rsuite";

import ProvidersTable from "./ProvidersTable";

import {AiOutlinePlusCircle} from "react-icons/ai";

import {useHistory} from "react-router-dom";

import {FaFilter} from "react-icons/fa";


export default function Providers() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-providers"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Settings</Breadcrumb.Item>
                            <Breadcrumb.Item active>Providers</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Providers</h1>
                        </div>

                        {/* <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                    <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                    <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <div className="report-filters-form">
                                    <div className="form-element">
                                        <label style={{display : "block", marginBottom : "10px"}}>Provider</label>
                                        <InputPicker />
                                    </div>
                                </div>
                            </div>
                        </Panel> */}

                        <Panel style={{marginTop : "20px"}}>
                            <ProvidersTable></ProvidersTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}