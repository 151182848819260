import { useState, useEffect } from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import Nav from 'rsuite/Nav';

import { Breadcrumb, Col, Panel, Toggle } from 'rsuite';

import { Button, Input, InputPicker, Whisper, Tooltip, IconButton, Table, Modal } from "rsuite";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaFilter, FaRegQuestionCircle } from "react-icons/fa";

import { useHistory } from "react-router-dom";

import axios from "axios";

const { Column, HeaderCell, Cell } = Table;

export default function SBEPSelectors() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [randomv, setRandomv] = useState("");
    const [cadv, setCadv] = useState(null);

    const FetchRuleSets = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api + "/sportsbook/ListEPSelectors?platform=" + platforms.platform.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if (response.data.Error == null) {
            setData(response.data.Selectors);
        }
    }

    const Delete = async (id) => {
        setLoading(true);
        const response = await axios.post(window.__APP__.api + "/sportsbook/DeleteEPSelector/" + id + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })
        setLoading(false);

        if (response.data.Error == null) {
            setRandomv(Math.random());
        }
    }
    const ToggleF = async (id) => {
        setLoading(true);
        const response = await axios.post(window.__APP__.api + "/sportsbook/ToggleEPSelector/" + id + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        })
        setLoading(false);

        if (response.data.Error == null) {
            setRandomv(Math.random());
        }
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            FetchRuleSets()
        }
    }, [platforms, randomv])

    return (
        <>
            <div className="tab-content-sp-cntnt">
                <div className="tab-content-sp-heading">
                    <Button onClick={() => history.push("/sportsbook/earlypayout/selectors/create")} color="primary"><i className="fas fa-plus"></i> Create Selector</Button>
                </div>

                <div className="tab-content-sp-table tcs-ruleset-table">
                    <Table
                        loading={loading}
                        data={data}
                        autoHeight
                    >
                        <Column minWidth={100}>
                            <HeaderCell>Selector</HeaderCell>
                            <Cell>{data => data.Type}</Cell>
                        </Column>
                        <Column minWidth={600} flexGrow={1}>
                            <HeaderCell>ID</HeaderCell>
                            <Cell>{data => data.SelectorId}</Cell>
                        </Column>
                        <Column minWidth={50} maxWidth={100}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell>{data => data.Status == 1 ? "Enabled" : "Disabled"}</Cell>
                        </Column>
                        {/* <Column flexGrow={1} minWidth={200} maxWidth={200}>
                            <HeaderCell>Custom Advantage</HeaderCell>
                            <Cell>{data => data.Advantage == "N/A" ? "N/A" : Object.entries(data.Advantage).map(entry => {
                                return (
                                    <>EId:{entry[0]}, Advantage:{entry[1]}. </>
                                )
                            })}</Cell>
                        </Column> */}
                        <Table.Column minWidth={200} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.Cell className="link-group">
                                {
                                    data => {
                                        return (
                                            <>
                                                {
                                                    data.Advantage != "N/A" ? (
                                                        <a onClick={() => setCadv(Object.entries(data.Advantage).map(d => {
                                                            return {
                                                                eid : d[0],
                                                                adv : d[1]
                                                            }
                                                        }))} style={{ marginRight: "10px" }}>
                                                            <Whisper
                                                                placement={"top"}
                                                                speaker={
                                                                    <Tooltip>Custom Advantages</Tooltip>
                                                                }
                                                            >
                                                                <IconButton className="table-button no-icon"><i class="fas fa-table"></i></IconButton>
                                                            </Whisper>
                                                        </a>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <a onClick={() => Delete(data.Id)} style={{ marginRight: "10px" }}>
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>Delete</Tooltip>
                                                        }
                                                    >
                                                        <IconButton className="table-button no-icon"><i class="fas fa-trash-alt"></i></IconButton>
                                                    </Whisper>
                                                </a>
                                                <a style={{ marginRight: "10px" }}>
                                                    <Whisper
                                                        placement={"top"}
                                                        speaker={
                                                            <Tooltip>{data.Status == 0 ? "Enable" : "Disable"}</Tooltip>
                                                        }
                                                    >
                                                        <Toggle onChange={() => ToggleF(data.Id)} size="sm" checked={data.Status == 0 ? 1 : 0} />
                                                    </Whisper>
                                                </a>
                                            </>
                                        )
                                    }
                                }
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            </div>

            <Modal open={cadv != null} onClose={() => setCadv(null)}>
                <Modal.Header>
                    <Modal.Title>Custom Advantages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        cadv != null ? (
                            <Table
                                data={cadv}
                            >
                                <Column flexGrow={1}>
                                    <Table.HeaderCell>Market EID</Table.HeaderCell>
                                    <Table.Cell dataKey="eid"></Table.Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <Table.HeaderCell>Advantage</Table.HeaderCell>
                                    <Table.Cell dataKey="adv"></Table.Cell>
                                </Column>
                            </Table>
                        ) : (
                            <></>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setCadv(null)} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}