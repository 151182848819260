import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper, Input } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";

import {useState, useEffect} from "react";

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [checkedState, setCheckedState] = useState(rowData['status']);

    const selectProvider = (id) => {
        dispatch({
            type : "SET_SETTINGS_PROVIDER",
            data : {
                provider : id
            }
        })
        dispatch({
            type : "SET_SETTINGS_PROVIDER_SHARE",
            data : {
                share : rowData['share']
            }
        })
    }

    const saveGame = (id) => {
        axios.post(window.__APP__.api+"/settings/games/share/"+id+"?platform="+platforms.platform.id, {
            loyalty : settings.selectedProviderShare
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                dispatch({
                    type : "SET_SETTINGS_PROVIDER",
                    data : {
                        provider : null
                    }
                })
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    const toggleGame = (id) => {
        axios.post(window.__APP__.api+"/settings/games/toggle/"+id+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCheckedState(response.data.status);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
            {
                settings.selectedProvider != rowData['id'] ? (
                    <Whisper
                            placement={"top"}
                            speaker={
                                <Tooltip>Edit Share %</Tooltip>
                            }
                        >
                        <IconButton style={{marginRight : "5px"}} onClick={() => selectProvider(rowData['id'])} className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
                    </Whisper>
                ) : (
                    <Whisper
                            placement={"top"}
                            speaker={
                                <Tooltip>Save Modification</Tooltip>
                            }
                        >
                        <IconButton style={{marginRight : "5px"}} onClick={() => saveGame(rowData['id'])} className="table-button no-icon"><i className="fas fa-check"></i></IconButton>
                    </Whisper>
                )
            }
            <Toggle onChange={() => toggleGame(rowData['id'])} checked={checkedState == 0} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

export default function GamesTable() {

    const settings = useSelector(state => state.settings);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [games, setGames] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetGames = () => {
        axios.get(window.__APP__.api+"/settings/games/list"+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setGames(response.data.games);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetGames();
        }
    }, [platforms.platform, limit, page, settings.selectedProvider]);
    const setShare = (share) => {
        dispatch({
            type : "SET_SETTINGS_PROVIDER_SHARE",
            data : {
                share : share
            }
        })
    }

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={games}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                // loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={200}>
                    <Table.HeaderCell>Game Name</Table.HeaderCell>
                    <Table.Cell>{rowData => <span style={{cursor : "pointer", color : "#3498ff"}}>{rowData.name}</span>}</Table.Cell>
                </Table.Column>

                <Table.Column width={150}>
                    <Table.HeaderCell>Game Share</Table.HeaderCell>
                    <Table.Cell>
                        {
                            dataRow => (
                                settings.selectedProvider == dataRow.id ? (
                                    <><Input onChange={setShare} style={{maxWidth : "70px"}} size="xs" value={settings.selectedProviderShare} /></>
                               ) : (
                                    <>{dataRow.loyalty}%</>
                               )
                            )
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={200} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}