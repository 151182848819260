import {Table, IconButton} from "rsuite";
import {Panel, InputPicker, Badge} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
          <Link style={{marginRight : "10px"}}>
              <Whisper
                  placement={"top"}
                  speaker={
                      <Tooltip>Details</Tooltip>
                  }
              >
                  <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
              </Whisper>
          </Link>
        </Table.Cell>
      );
};

export default function Transactions() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);
    const [summary, setSummary] = useState({});

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        },
        {
            label : "Agent Deposit",
            value : 2
        },
        {
            label : "Agent Withdraw",
            value : 3
        },
        {
            label : "Partner Deposit",
            value : 4
        },
        {
            label : "Partner Withdraw",
            value : 5
        },
        {
            label : "Admin Deposit",
            value : 6
        },
        {
            label : "Admin Withdraw",
            value : 7
        }
    ]

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/kiosk/shop/transactions/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&filterType="+filterType+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pagination.pages);
                setSummary(response.data.summary);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <InputPicker data={transactionFilterTypes} placeholder="Transaction Type" style={{flex : 1}} onChange={setFilterType} />
                        <DateRangePicker showOneCalendar onChange={setDateRange} style={{marginLeft : "20px", flex : 1}} placeholder="Range" />
                    </div>
                </Panel>
                

                {
                        summary.total != undefined && (
                            <div className="overview-boxes">
                                <div className="box" style={{maxWidth : "20%"}}>
                                    <div style={{flex : "1 1 auto"}}>
                                        <h2>{summary.total}</h2>
                                        <h1>Total</h1>
                                    </div>
                                    <div style={{flex : "1 1 auto", position : "relative"}}>
                                        <div className="icon balance-icon"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                <div>
                    <Table
                        autoHeight
                        data={transactions}
                        rowClassName={data => data != undefined ? data.isPartner == 1 ? "special-row" : "" : ""}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Sender</Table.HeaderCell>

                            <Table.Cell>
                                {
                                    data => (
                                        <>
                                            {
                                                (data.senderType == 0) ? (
                                                    <Link to={"/kplayer/"+data.senderId}>{data.sender}</Link>
                                                ) : (data.senderType == 1) ? (
                                                    <Link to={"/kshop/"+data.senderId}>{data.sender}</Link>
                                                ) : (data.senderType == 2) ? (
                                                    <Link to={"/kagent/"+data.senderId}><Badge color="yellow" content={data.sender}></Badge></Link>
                                                ) : (data.senderType == 3) ? (
                                                    <Link to={"/kpartner/"+data.senderId}><Badge color="green" content={data.sender}></Badge></Link>
                                                )  : (data.senderType == "self") ? (
                                                    <>
                                                        <Whisper
                                                            placement={"top"}
                                                            speaker={
                                                                <Tooltip>Self</Tooltip>
                                                            }
                                                        >
                                                            <Badge color="blue" content={data.sender}></Badge>
                                                        </Whisper>
                                                    </>
                                                ) : (data.senderType == "admin") ? (
                                                    <>
                                                        <Badge content={data.sender}></Badge>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver</Table.HeaderCell>
                            <Table.Cell>
                                {
                                    data => (
                                        <>
                                            {
                                                (data.receiverType == 0) ? (
                                                    <Link to={"/kplayer/"+data.receiverId}>{data.receiver}</Link>
                                                ) : (data.receiverType == 1) ? (
                                                    <Link to={"/kshop/"+data.receiverId}>{data.receiver}</Link>
                                                ) : (data.receiverType == 2) ? (
                                                    <Link to={"/kagent/"+data.receiverId}><Badge color="yellow" content={data.receiver}></Badge></Link>
                                                ) : (data.receiverType == 3) ? (
                                                    <Link to={"/kpartner/"+data.receiverId}><Badge color="green" content={data.receiver}></Badge></Link>
                                                ) : (data.receiverType == "self") ? (
                                                    <>
                                                        <Whisper
                                                            placement={"top"}
                                                            speaker={
                                                                <Tooltip>Self</Tooltip>
                                                            }
                                                        >
                                                            <Badge color="blue" content={data.receiver}></Badge>
                                                        </Whisper>
                                                    </>
                                                ) : (data.receiverType == "admin") ? (
                                                    <>
                                                        <Badge content={data.receiver}></Badge>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.Cell dataKey="amount" />
                        </Table.Column>
                        
                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Sender Balance</Table.HeaderCell>
                            <Table.Cell dataKey="sbalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Receiver Balance</Table.HeaderCell>
                            <Table.Cell dataKey="rbalance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                    
                </div>  
            </div>
        </>
    )
}