import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { DateRangePicker } from "rsuite";

import axios from "axios";

import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} className="link-group">
        {/* <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Hierarchy</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-sitemap"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-user-edit"></i></IconButton>
            </Whisper>
        </Link>
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
            </Whisper>
        </Link>
        <Toggle className="table_toggle" size="sm"></Toggle> */}
      </Table.Cell>
    );
};

export default function LoginHistory() {
    const {id} = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [loginHistory, setLoginHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(25);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const GetHistory = () => {
        axios.get(window.__APP__.api+"/shops/loginhistory/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setLoginHistory(response.data.history);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetHistory()
        }
    }, [platforms.platform, page, limit, dateFrom, dateTo]);

    const setDateRange = (date) => {
        if(date.length == 2) {
            setDateFrom(moment(date[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(date[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <DateRangePicker showOneCalendar onChange={setDateRange} placeholder="Date Range" style={{flex : 1}} />
                    </div>
                </Panel>
                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={loginHistory}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Device</Table.HeaderCell>
                            <Table.Cell dataKey="device" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Browser</Table.HeaderCell>
                            <Table.Cell dataKey="browser" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>OS</Table.HeaderCell>
                            <Table.Cell dataKey="os" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>IP</Table.HeaderCell>
                            <Table.Cell dataKey="ip" />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>
        </>
    )
}