import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
 
const SortableItem = (props) => {
  return <li>
    <b>{JSON.stringify(props.order)}.</b>
    <img src={props.value.image} width="150"></img>
    <h1>{props.value.title}</h1>
  </li>
}
 
export default SortableElement(SortableItem);