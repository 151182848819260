import {useState, useEffect} from "react";

import {Line, PolarArea} from "react-chartjs-2";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import {Table} from "rsuite";

import {useHistory} from "react-router-dom";

export default function TransactionDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const filters = useSelector(state => state.filters);

    const [transactionLoading, setTransactionLoading] = useState(false);

    const [transactions, setTransactions] = useState([]);

    const GetGGR = () => {
        setTransactionLoading(true);

        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";
            
      axios.get(window.__APP__.api+"/statistics/transactions/top?platform="+platforms.platform.id+dateQuery, {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem("jwt")
        }
      }).then(response => {
        setTransactionLoading(false);
        if(response.data.error == null) {
            setTransactions(response.data.transactions);
        } else {
          if(response.data.error == "Unauthorized") {
            dispatch({
                type : "SET_LOGGED",
                data : {
                    logged : false
                }
            })
            localStorage.removeItem("jwt");
            history.push("/");
          }
        } 
      });
    }

    useEffect(() => {
      if(platforms.platform.id > -1)
        GetGGR();
    }, [platforms.platform])
    

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };    

    return (
        <>
            <div className="box-chart"  style={{minWidth : "100%"}}>
                <div className="box-chart-header">
                    <i className="fas fa-bell" />
                    <h3>Alarming Transactions</h3>
                </div>
                <Table
                    height={350}
                    // autoHeight
                    data={transactions}
                    loading={transactionLoading}
                >
                    <Table.Column width={60}>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Sender</Table.HeaderCell>
                        <Table.Cell dataKey="sender" />
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Receiver</Table.HeaderCell>
                        <Table.Cell dataKey="receiver" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.Cell dataKey="amount" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Sender Balance</Table.HeaderCell>
                        <Table.Cell dataKey="sender_balance" />
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Receiver Balance</Table.HeaderCell>
                        <Table.Cell dataKey="receiver_balance" />
                    </Table.Column>

                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.Cell dataKey="date" />
                    </Table.Column>
                </Table>
            </div>
        </>
    )
}