const initialState = {
    logged : false,
    user : {
        id : -1,
        username : "",
        name : {
            firstname : "",
            lastname : ""
        },
        type : null,
        timezone : null,
        permissions : null,
        permissiongroups : null,
        permissionsubgroups : null
    }
}

export default function userReducer(state=initialState, action) {
    switch(action.type) {
        case "SET_LOGGED":
            return {
                ...state,
                logged : action.data.logged
            }
        break;
        case "SET_USER":
            return {
                ...state,
                user : action.data.user
            }
        break;
        default:
            return state;
            break;
    }
}