import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Input} from "rsuite";

import {Link} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const ShowDetails = () => {
        dispatch({
            type : "SET_SPORTS_ID",
            data : {
                id : rowData['id']
            }
        })
        dispatch({
            type : "SET_SPORTS_MODAL",
            data : {
                show : true
            }
        })
    }

    return (
        <Table.Cell {...props} className="link-group">
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Details</Tooltip>
                }
            >
                <IconButton onClick={ShowDetails} className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
      </Table.Cell>
    );
};


const SportsbookLists = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [betDetails, setBetDetails] = useState([]);

    const closeSportsMoal = () => {
        dispatch({
            type : "SET_SPORTS_MODAL",
            data : {
                show : false
            }
        })
    }

    const GetBetDetails = async () => {
        var response = await axios.get(window.__APP__.api+"/kiosk/betdetails/"+modals.sportsId+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt"),
                "Accept-Encoding": "*"
            }
        });

        if(response.data.error == null) {
            setBetDetails(response.data.details);
        }
    }

    useEffect(() => {
        GetBetDetails();
    }, []);

    return (
        <Modal size="lg" open={modals.sportsModal} onClose={closeSportsMoal}>
            <Modal.Header>
                <Modal.Title>Bet Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{"overflow" : "auto"}}>
                <Table
                    autoHeight
                    data={betDetails}
                    loading={false}
                >
                    <Table.Column width={200} align="center">
                        <Table.HeaderCell>Event</Table.HeaderCell>
                        <Table.Cell dataKey="event" />
                    </Table.Column>
                    <Table.Column width={200} align="center">
                        <Table.HeaderCell>Odd Name</Table.HeaderCell>
                        <Table.Cell dataKey="name" />
                    </Table.Column>
                    <Table.Column width={200} align="center">
                        <Table.HeaderCell>Odd Price</Table.HeaderCell>
                        <Table.Cell dataKey="odd" />
                    </Table.Column>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeSportsMoal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default function History() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const modals = useSelector(state => state.modals);

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [sportsHistory, setSportsHistory] = useState({
        show : false,
        id : null
    })


    const [providers, setProviders] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [provider, setProvider] = useState("");
    const [loading, setLoading] = useState(false);

    const [sortColumn, setSortColumn] = useState("");
    const [sortType, setSortType] = useState("");

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        }
    ]

    const GetTransactions = () => {
        setLoading(true);
        axios.get(window.__APP__.api+"/kiosk/player/history/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&filterType="+filterType+"&dateFrom="+dateFrom+"&dateTo="+dateTo+"&provider="+provider+"&winSort="+sortType, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTransactions(response.data.history);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetProviders = () => {
        axios.get(window.__APP__.api+"/platform/providers?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setProviders(response.data.providers)
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        GetProviders();
    }, [])

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo, provider, sortType]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const handleSortColumn = (value, type) => {
        setSortType(type)
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <DateRangePicker showOneCalendar onChange={setDateRange} style={{flex : 1}} placeholder="Range" />
                        <InputPicker onChange={setProvider} data={providers} style={{marginLeft : "20px", flex : 1}} placeholder="Provider" />
                    </div>
                </Panel>
                <div>
                    <Table
                        // height={600}
                        autoHeight
                        data={transactions}
                        loading={loading}
                        sortColumn={"win"}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Game</Table.HeaderCell>
                            <Table.Cell dataKey="game" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Provider</Table.HeaderCell>
                            <Table.Cell dataKey="provider" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Bet</Table.HeaderCell>
                            <Table.Cell dataKey="bet" />
                        </Table.Column>
                        
                        <Table.Column sortable minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Win</Table.HeaderCell>
                            <Table.Cell dataKey="win" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance Before</Table.HeaderCell>
                            <Table.Cell dataKey="balancebe" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance After</Table.HeaderCell>
                            <Table.Cell dataKey="balanceaf" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>

            {
                modals.sportsModal && (
                    <SportsbookLists />
                )
            }
        </>
    )
}