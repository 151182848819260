import {Table, IconButton} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";

export default function GamesTable() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();
   
    const [games, setGames] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const GetGames = () => {
        setLoading(true);
        
        axios.get(window.__APP__.api+"/reports/games/currency/"+reports.currency+"/"+reports.provider+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);

            if(response.data.error == null) {
                setGames(response.data.games);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetGames();
        }
    }, [platforms.platform, limit, page]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={games}
                loading={loading}
            >
                <Table.Column width={70} align="center">
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={200} flexGrow={1}>
                    <Table.HeaderCell>Game Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Bets</Table.HeaderCell>
                    <Table.Cell dataKey="bets" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Wins</Table.HeaderCell>
                    <Table.Cell dataKey="wins" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}