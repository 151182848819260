import React, {useState, useEffect} from "react";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { Breadcrumb, Button, Panel, IconButton, Whisper, Tooltip } from "rsuite";
import {FaFilter} from "react-icons/fa";

import {useDispatch, useSelector} from "react-redux";

import { useHistory } from "react-router";

import { Pie, Bar, PolarArea } from 'react-chartjs-2';

import Filters from "../Filters/Filters";
import axios from "axios";

export default function Partners() {

    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);
    const dispatch = useDispatch();

    const [countriesLabels, setCountriesLabels] = useState([]);
    const [countryPartners, setCountryPartners] = useState([]);
    const [shopsLabels, setShopsLabels] = useState([]);
    const [shopsPartners, setShopsPartners] = useState([]);
    const [playersLabels, setPlayersLabels] = useState([]);
    const [playersPartners, setPlayersPartners] = useState([]);
    const [ggrLabels, setGGRLabels] = useState([]);
    const [ggrPartners, setPartnersGGR] = useState([]);

    const history = useHistory();

    const bars = useSelector(state => state.bars);

    const toggleFiltersBar = () => {
        if(filters.filtersDrawer) {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_DRAWER",
                data : {
                    show : true
                }
            })
        }
    }

    const GetPartnerStatsByCountry = () => {
        axios.get(window.__APP__.api+"/statistics/partners/country?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCountriesLabels(response.data.data.labels);
                setCountryPartners(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    const GetPartnersByShops = () => {
        axios.get(window.__APP__.api+"/statistics/partners/shops?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShopsLabels(response.data.data.labels);
                setShopsPartners(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        })
    }

    const GetPartnersByPlayers = () => {
        axios.get(window.__APP__.api+"/statistics/partners/players?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPlayersLabels(response.data.data.labels);
                setPlayersPartners(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        })
    }

    const GetPartnersByGGR = () => {
        if(filters.dateRange.length > 0)
            var dateQuery = "&dateFrom="+filters.dateRange[0]+"&dateTo="+filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/statistics/partners/ggr?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setGGRLabels(response.data.data.labels);
                setPartnersGGR(response.data.data.values);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        })
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPartnerStatsByCountry();
            GetPartnersByShops();
            GetPartnersByPlayers();
        }
    }, [platforms.platform]);

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetPartnersByGGR();
        }
    }, [platforms.platform, filters.dateRange]);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"stats-partners"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>
                    
                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Statistics</Breadcrumb.Item>
                            <Breadcrumb.Item active>Partners</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Partners Statistics</h1>

                            <Whisper
                                placement="left"
                                speaker={
                                    <Tooltip>Filters</Tooltip>
                                }
                            >
                                <IconButton  onClick={toggleFiltersBar} style={{padding : "10px", paddingBottom : "5px", paddingTop : "6px"}}><FaFilter /></IconButton>
                            </Whisper>
                        </div>

                        <div className="stats-boxes">
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Partners By Country</h3>
                                </div>
                                <div className="stats-box-content">
                                    <PolarArea data={{
                                        labels: countriesLabels,
                                        datasets: [
                                          {
                                            label: 'Most Played',
                                            data: countryPartners,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }}/>
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Partners By Shops</h3>
                                </div>
                                <div className="stats-box-content">
                                    <PolarArea data={{
                                        labels: shopsLabels,
                                        datasets: [
                                          {
                                            label: 'Most Played',
                                            data: shopsPartners,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }}/>
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Partners By Players</h3>
                                </div>
                                <div className="stats-box-content">
                                    <PolarArea data={{
                                        labels: playersLabels,
                                        datasets: [
                                          {
                                            label: 'Most Played',
                                            data: playersPartners,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }}/>
                                </div>
                            </div>
                            <div className="stats-box col-3">
                                <div className="stats-box-header">
                                    <h3>Partners By GGR</h3>
                                </div>
                                <div className="stats-box-content">
                                    <PolarArea data={{
                                        labels: ggrLabels,
                                        datasets: [
                                          {
                                            label: 'GGR',
                                            data: ggrPartners,
                                            backgroundColor: '#8e44ad',
                                          }
                                        ]
                                    }} options={{
                                        plugins : {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <Filters />
        </>
    )
}