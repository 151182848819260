import { Table, IconButton } from "rsuite";
import Pagination from 'rsuite/Pagination';

import { Link } from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle } from 'rsuite';

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Badge } from 'rsuite';

import axios from "axios";

export default function ProvidersTable() {
    const reports = useSelector(state => state.reports);
    const platforms = useSelector(state => state.platforms);
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetProviders = () => {
        if (filters.dateRange.length > 0)
            var dateQuery = "&dateFrom=" + filters.dateRange[0] + "&dateTo=" + filters.dateRange[1];
        else
            var dateQuery = "&dateFrom=&dateTo=";

        setLoading(true);

        axios.get(window.__APP__.api + "/reports/providers/currency/" + reports.currency + "?platform=" + platforms.platform.id + "&page=" + page + "&limit=" + limit + dateQuery, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if (response.data.error == null) {
                setProviders(response.data.providers);
                setPages(response.data.pagination.pages);
                dispatch({
                    type: "SET_FR_PROVIDERS",
                    data: {
                        providers: response.data.providers
                    }
                })
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0) {
            GetProviders();
        }
    }, [platforms.platform, limit, page, filters.dateRange]);

    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={providers}
                loading={loading}
            >
                <Table.Column width={70} align="center" fixed>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Provider Name</Table.HeaderCell>
                    <Table.Cell dataKey="name" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.Cell>{rowData => rowData.type == "sportsbook" ? <Badge style={{ background: '#4caf50' }} content="Sportsbook" /> : rowData.type == "casino" ? <Badge style={{ background: '#4caf50' }} content="Casino" /> : rowData.type == "livecasino" ? <Badge style={{ background: '#4caf50' }} content="Livecasino" /> : rowData.type == "fastgames" ? <Badge style={{ background: '#4caf50' }} content="Fast Games" /> : rowData.type == "virtuals" ? <Badge style={{ background: '#4caf50' }} content="Virtual Games" /> : <>N/A</>}</Table.Cell>
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Bets</Table.HeaderCell>
                    <Table.Cell dataKey="bets" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>Total Wins</Table.HeaderCell>
                    <Table.Cell dataKey="wins" />
                </Table.Column>

                <Table.Column width={150} flexGrow={1}>
                    <Table.HeaderCell>GGR</Table.HeaderCell>
                    <Table.Cell dataKey="ggr" />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={limit}
                    limitOptions={[25, 50, 100]}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </>
    )
}