import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Message } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function AddLanguage() {

    const bars = useSelector(state => state.bars);

    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
        name : "",
        key : "",
        image : "",
        direction : 1
    })

    const [errors, setFormError] = useState({
        name : false,
        key : false,
        image : false
    });

    const directions = [
        {
            label : "Left To Right",
            value : 1
        },
        {
            label : "Right To Left",
            value : 0
        }
    ]

    const checkUploadFile = (details) => {
        if(details.error == null) {
            var path = "/flags/"+details.file.name;
  
            setFormError({...errors, image : false});
            setForm({...form, image : path});
        } else {
            setFormError({...errors, image : true});
            setForm({...form, image : ""});
        }
    }

    const toggleFileButton = (details, type) => {
        if(details.length == 0) {
            setForm({...form, image : ""});
        }
    }

    const SubmitLanguage = () => {
        if(form.name == "" || form.key == "" || form.image == "") {
          
            var errorObj = errors;
    
            if(form.name == "") {
              errorObj.name = true;
            } else {
              errorObj.name = false;
            }
    
            if(form.key == "") {
              errorObj.key = true;
            } else {
              errorObj.key = false;
            }
    
            if(form.image == "") {
              errorObj.image = true;
            } else {
              errorObj.image = false;
            }
    
            var obj = Object.assign({}, errorObj);
    
            setFormError(obj);
        } else {
            setFormError({
                name : false,
                key : false,
                image : false
            });

            var nform = Object.assign({}, form);
    
            axios.post(window.__APP__.api+"/cms/languages/create?platform="+platforms.platform.id, nform, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
            if(response.data.error == null) {
                setFormMessageType("");
                setFormMessage("");
    
                toaster.push(<Notification type="info" header="Notification" closable>Language Added Successfully</Notification>, {placement: "topEnd"});
    
                history.push("/cms/languages");
            } else {
                switch(response.data.error) {
                case "Platform Not Found":
                case "Unexpected Error":
                    setFormMessage("Unexpected Error");
                    setFormMessageType("error");
                break;
                case "Unauthorized":
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                break;
                }
            }
            })
        }
    }

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-languages"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/cms/languages">Languages</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Language</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Add Language</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Form onSubmit={SubmitLanguage} className="form-box">
                                {
                                    formMessage != "" && (
                                        <Message showIcon type={formMessageType}>
                                        {formMessage}
                                        </Message>
                                    )
                                }
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Language</Form.ControlLabel>
                                        <Form.Control className={errors.name ? "errored" : ""} onChange={value => setForm({...form, name : value})} placeholder="Language" />
                                    </Form.Group>
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Language Key</Form.ControlLabel>
                                        <Form.Control className={errors.key ? "errored" : ""} onChange={value => setForm({...form, key : value})} placeholder="Language key" />
                                    </Form.Group>
                                </div>
                                <div className="form-row">
                                    <Form.Group controlId="name-1">
                                        <Form.ControlLabel>Direction</Form.ControlLabel>
                                        <InputPicker value={form.direction} className={errors.page ? "errored" : ""} onChange={value => setForm({...form, direction : value})}  size="md" placeholder="Direction" style={{width : "100%"}} data={directions} />
                                    </Form.Group>
                                </div>
                                <div className="id-group" style={{marginTop : "20px"}}>
                                    <div className="">
                                        <Form.ControlLabel>Language Image</Form.ControlLabel>
                                        <small style={{display : "block", height : "10px", color : errors.image ? "red" : ""}}>File types allowed : png,jpg,gif,svg</small>
                                        <div className="upload-area">
                                            <Uploader onChange={(details) => toggleFileButton(details)} disabled={form.image != ""} onSuccess={(response) => checkUploadFile(response)} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "flag"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} listType="picture-text" />
                                        </div>
                                    </div>
                                    
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Add Language</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}