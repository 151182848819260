import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Schema, Message, InputGroup } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory } from "react-router";

import { Notification, toaster  } from 'rsuite';

export default function CreatePartner() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [form, setForm] = useState({
        title : "",
        page : "",
        action : "",
        custom : "",
        resolution : "",
        image : ""
    })

    const [errors, setFormError] = useState({
        title : false,
        page : false,
        action : false,
        custom : false,
        resolution : false,
        image : false
    })


    const pages = [
        {
            label : "Home",
            value : "0"
        },
        {
            label : "Casino",
            value : "1"
        },
        {
            label : "Live Casino",
            value : "2"
        },
        {
            label : "Fast Games",
            value : "3"
        },
        {
            label : "Virtual Games",
            value : "4"
        },
        {
            label : "Sports",
            value : "5"
        },
        {
            label : "Promotions",
            value : "6"
        }
    ]

    const actions = [
        {
            label : "Casino",
            value : "casino"
        },
        {
            label : "Live Casino",
            value : "livecasino"
        },
        {
            label : "Fast Games",
            value : "fastgames"
        },
        {
            label : "Virtual Games",
            value : "virtuals"
        },
        {
            label : "Launch Game",
            value : "game"
        },
        {
            label : "Custom Link",
            value : "link"
        }
    ]

    const resolutions = [
        {
            label : "Responsive",
            value : "responsive"
        }, {
            label : "Mobile",
            value : "mobile"
        },
        {
            label : "Desktop",
            value : "desktop"
        }
    ]

    const checkUploadFile = (details) => {
        if(details.error == null) {
            var path = "/bnrs/"+details.file.name;
  
            setFormError({...errors, image : false});
            setForm({...form, image : path});
        } else {
            setFormError({...errors, image : true});
            setForm({...form, image : ""});
        }
    }

    const toggleFileButton = (details, type) => {
        if(details.length == 0) {
            setForm({...form, image : ""});
        }
    }

    const SubmitForm = () => {
        if(form.title == "" || form.page == "" || form.image == "") {
          
          var errorObj = errors;
  
          if(form.title == "") {
            errorObj.title = true;
          } else {
            errorObj.title = false;
          }
  
          if(form.page == "") {
            errorObj.page = true;
          } else {
            errorObj.page = false;
          }
  
        //   if(form.action == "") {
        //     errorObj.action = true;
        //   } else {
        //     errorObj.action = false;
        //   }
  
          if(form.image == "") {
            errorObj.image = true;
          } else {
            errorObj.image = false;
          }
  
          var obj = Object.assign({}, errorObj);
  
          setFormError(obj);
        } else if(form.action == "link" && form.custom == "") {
          var errorObj = errors;
          if(form.custom == "") {
            errorObj.custom = true;
          } else {
            errorObj.custom = false;
          }
  
          var obj = Object.assign({}, errorObj);
  
          setFormError(obj);
        } else {
            setFormError({
            title : false,
            page : false,
            action : false,
            custom : false,
            image : false
          });

          var nform = Object.assign({}, form);

          if(nform.action == "game") {
            nform.custom = "/game/"+nform.custom;
          }
  
          axios.post(window.__APP__.api+"/cms/banners/create?platform="+platforms.platform.id, nform, {
            headers : {
              "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
          }).then(response => {
            if(response.data.error == null) {
              setFormMessageType("");
              setFormMessage("");
  
              toaster.push(<Notification type="info" header="Notification" closable>Banner Created Successfully</Notification>, {placement: "topEnd"});
  
              history.push("/cms/banners");
            } else {
              switch(response.data.error) {
                case "Platform Not Found":
                case "Unexpected Error":
                  setFormMessage("Unexpected Error");
                  setFormMessageType("error");
                break;
                case "Unauthorized":
                  dispatch({
                      type : "SET_LOGGED",
                      data : {
                          logged : false
                      }
                  })
                  localStorage.removeItem("jwt");
                  history.push("/");
                break;
              }
            }
          })
        }
      }
    
    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"cms-banners"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/cms/banners">Banners</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Banner</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Create Banner</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            <Form onSubmit={SubmitForm} className="form-box">
                                {
                                formMessage != "" && (
                                    <Message showIcon type={formMessageType}>
                                    {formMessage}
                                    </Message>
                                )
                                }
                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="title">
                                        <Form.ControlLabel>Banner Title</Form.ControlLabel>
                                        <Form.Control className={errors.title ? "errored" : ""} onChange={value => setForm({...form, title : value})} name="title" placeholder="Banner Title" />
                                    </Form.Group>
                                    <Form.Group controlId="page">
                                        <Form.ControlLabel>Banner Page</Form.ControlLabel>
                                        <InputPicker className={errors.page ? "errored" : ""} onChange={value => setForm({...form, page : value})}  size="md" placeholder="Page" style={{width : "100%"}} data={pages} />
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="action">
                                        <Form.ControlLabel>Action</Form.ControlLabel>
                                        <InputPicker className={errors.action ? "errored" : ""} onChange={value => setForm({...form, action : value})}  size="md" placeholder="Page" style={{width : "100%"}} data={actions} />
                                    </Form.Group>
                                    <Form.Group controlId="custom">
                                        <Form.ControlLabel>Action Value</Form.ControlLabel>
                                        {
                                            form.action == "game" ? (
                                                <>
                                                    <InputGroup style={{width : "100%"}}>
                                                        <InputGroup.Addon>/game/</InputGroup.Addon>
                                                        <Input onChange={value => setForm({...form, custom : value})} value={form.custom} className={errors.custom ? "errored" : ""}  placeholder="Game Id" />
                                                    </InputGroup>
                                                </>
                                            ) : form.action == "provider" ? (
                                                <>
                                                    <InputGroup>
                                                        <InputGroup.Addon>/provider/</InputGroup.Addon>
                                                        <Input onChange={value => setForm({...form, custom : value})} value={form.custom} className={errors.custom ? "errored" : ""} placeholder="Provider Id" />
                                                    </InputGroup>
                                                </>
                                            ) : (
                                                <Input className={errors.custom ? "errored" : ""} onChange={value => setForm({...form, custom : value})}  size="md" placeholder="Page" style={{width : "100%"}} disabled={form.action != "link" ? true : false} />
                                            )
                                        }
                                        
                                    </Form.Group>
                                </div>

                                <div className="form-row" style={{marginTop : "20px"}}>
                                    <Form.Group controlId="resolution">
                                        <Form.ControlLabel>Resolution</Form.ControlLabel>
                                        <InputPicker className={errors.action ? "errored" : ""} onChange={value => setForm({...form, resolution : value})}  size="md" placeholder="Resolution" style={{width : "100%"}} data={resolutions} />
                                    </Form.Group>
                                </div>
                               

                                <div className="id-group" style={{marginTop : "20px"}}>
                                    <div className="">
                                        <Form.ControlLabel>Banner Image</Form.ControlLabel>
                                        <small style={{display : "block", height : "10px", color : errors.image ? "red" : ""}}>File types allowed : png,jpg,gif,svg</small>
                                        <div className="upload-area">
                                            <Uploader onChange={(details) => toggleFileButton(details)} disabled={form.image != ""} onSuccess={(response) => checkUploadFile(response)} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "banner"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} listType="picture-text" />
                                        </div>
                                    </div>
                                    
                                </div>

                                <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                    <Button type="submit" style={{height : "100%"}} color="primary">Create Banner</Button>
                                </div>
                            </Form>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}