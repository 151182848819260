import {useState, useEffect} from "react";

import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button} from "rsuite";
import TagPicker from 'rsuite/TagPicker';
import {Link} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import axios from "axios";

import qs from "querystring";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const ToggleShop = (id) => {
        axios.post(window.__APP__.api+"/shops/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                // pass
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }
    const TogglePlayer = (id) => {
        axios.post(window.__APP__.api+"/players/toggle/"+id+"?platform="+platforms.platform.id, {type : 1}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                // pass
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        {
            rowData['type'] == 1 && (
                <Link to={rowData['type'] == "1" ? "/kshop/hierarchy/"+rowData['id'] : ""} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Hierarchy</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-sitemap"></i></IconButton>
                    </Whisper>
                </Link>
            )
        }
        <Link to={rowData['type'] == 1 ? "/kshop/"+rowData['id'] : "/kplayer/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        {
            rowData['type'] == 1 && (
                <Link to={"/kshop/edit/"+rowData['id']} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Edit</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-user-edit"></i></IconButton>
                    </Whisper>
                </Link>
            )
        }
        <Toggle defaultChecked={!rowData['status']} onChange={() => rowData['type'] == 1 ? ToggleShop(rowData['id']) : TogglePlayer(rowData['id'])} className="table_toggle" size="sm"></Toggle>
      </Table.Cell>
    );
};

export default function Partner() {

    const dispatch = useDispatch();
    const history = useHistory();

    const {id} = useParams();

    const platforms = useSelector(state => state.platforms);

    const [userTypes, setUserTypes] = useState([]);
    const [filters, setFilters] = useState({
        userTypes : []
    });
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);

    const GetUsers = () => {
        setUsers([]);
        axios.get(window.__APP__.api+"/kiosk/shop/hierarchy", {
            params : {
                operator : id,
                platform : platforms.platform.id,
                filter : {
                    types : filters.userTypes
                },
                page : page,
                limit : limit
            },
            headers : {
                "Authorization" : "Beaer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setUsers(response.data.users);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetUserTypes = () => {
        axios.get(window.__APP__.api+"/kiosk/shop/hierarchy/types?operator="+id+"&platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFilters({...filters, userTypes : response.data.default});
                setUserTypes(response.data.types);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetUserTypes();
        }
    }, [platforms.platform]);

    useEffect(() => {
        GetUsers();
    }, [filters, page, limit]);

    const setNewTypes = (types) => {
        setFilters({...filters, userTypes : types})
    }

    return (
        <>
            <div className="nav-overview">
                <div>
                    <Table
                        autoHeight
                        data={users}
                    >
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Full Name</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.Cell>{ rowData => <Link to={"/kplayer/"+rowData.id}>{rowData.username}</Link>}</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell>Player</Table.Cell>
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                            <Table.Cell dataKey="balance" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1}>
                            <Table.HeaderCell>Created At</Table.HeaderCell>
                            <Table.Cell dataKey="created" />
                        </Table.Column>

                        <Table.Column minWidth={120} flexGrow={1} align="left">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            pages={pages}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </div>  
            </div>
        </>
    )
}