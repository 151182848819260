import {useState, useEffect, useRef} from "react";

import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { Link } from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel, Uploader, Table } from 'rsuite';

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaUserAlt} from "react-icons/fa";
import { Form, Input, Button, Schema, Message, Nav, Tooltip, Whisper, IconButton } from 'rsuite';

import InputPicker from 'rsuite/InputPicker';

import axios from "axios";

import { useHistory, useParams } from "react-router";

import { Notification, toaster  } from 'rsuite';

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const UnExcludeShop = async () => {
        const response = await axios.post(window.__APP__.api+"/platform/jackpots/excluded/shops/remove/"+rowData["vId"]+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            dispatch({
                type : "SET_RANDOM_V",
                data : {
                    random : Math.random()
                }
            })
        }
    }

    return (
      <Table.Cell {...props} className="link-group">
        <Whisper
            placement={"top"}
            speaker={
                <Tooltip>Remove Shop's Exclusion</Tooltip>
            }
        >
            <IconButton style={{marginRight : "10px"}} onClick={UnExcludeShop} className="table-button no-icon"><i className="fas fa-times"></i></IconButton>
        </Whisper>
      </Table.Cell>
    );
};

export default function Partners() {

    const bars = useSelector(state => state.bars);
    const history = useHistory();

    const {id} = useParams();

    const dispatch = useDispatch();
    const platforms = useSelector(state => state.platforms);

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [excludedList, setExcludedList] = useState([]);
    const [excludedPlayers, setExcludedPlayers] = useState([]);
    const [excludedPlayer, setExcludedPlayer] = useState("");
    const [excludedId, setExcludedId] = useState(null);

    const [activeKey, setActiveKey] = useState("settings");

    const [formLoading, setFormLoading] = useState(false);
    const [shopJLoading, setJLoading] = useState(false);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");

    const [shops, setShops] = useState([]);
    const [errMessage, setErrMessage] = useState("");
    const [succMessage, setSuccMessage] = useState("");

    const [form, setForm] = useState({
      percentage : 0,
      threshold : 0,
      maxwin : 0,
      difficulty : 0
    });

    const [errors, setErrors] = useState({
        percentage : false,
        threshold : false,
        maxwin : false
    })
    const [formError, setFormError] = useState({})

    const GetJackpotSettings = () => {
        axios.get(window.__APP__.api+"/jackpots/settings/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setForm(response.data.jackpot);
            }
        });
    }

    const SaveJackpot = () => {
        setFormLoading(true);
        axios.post(window.__APP__.api+"/jackpots/update/"+id+"?platform="+platforms.platform.id, form, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setFormLoading(false);
            if(response.data.error == null) {
                history.push("/settings/jackpots");
            }
        });
    }

    useEffect(() => {
      if(platforms.platform.id >= 0) {
        GetJackpotSettings();
      }
    }, [platforms.platform]);

    const SubmitForm = () => {
      
    }

    const GetShops = async () => {
        axios.get(window.__APP__.api+"/platform/shops?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setShops(response.data.shops);
        });
    }
    
    const GetExcludedShops = async () => {
        try {

            const response = await axios.get(window.__APP__.api+"/platform/jackpots/excluded/shops/"+id+"?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            });

            setExcludedList(response.data.excluded)

        } catch(e) {
            console.log(e);
        }
    }

    const GetExcludedPlayers = async () => {
        try {

            const response = await axios.get(window.__APP__.api+"/platform/jackpots/excluded/players/"+id+"?platform="+platforms.platform.id, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            });

            setExcludedPlayers(response.data.excluded)

        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        GetShops()
    }, [])

    useEffect(() => {
        GetExcludedShops()
        GetExcludedPlayers()
    }, [succMessage, platforms.randomV])

    const AddExcludedShop = async () => {
        
        setErrMessage("");
        setSuccMessage("");

        if(excludedId == null) {
            return ;
        }

        setJLoading(true);

        const response = await axios.post(window.__APP__.api+"/platform/jackpots/excluded/shops/create/"+id+"?platform="+platforms.platform.id, {
            exId : excludedId
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setJLoading(false);

        if(response.data.error == null) {
            setSuccMessage("Shop excluded successfully");
        } else {
            setErrMessage(response.data.error)
        }
    }

    const AddExcludedPlayer = async () => {
        setErrMessage("");
        setSuccMessage("");

        if(excludedPlayer == null) {
            return ;
        }

        setJLoading(true);

        const response = await axios.post(window.__APP__.api+"/platform/jackpots/excluded/players/create/"+id+"?platform="+platforms.platform.id, {
            exId : excludedPlayer
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setJLoading(false);

        if(response.data.error == null) {
            setSuccMessage("Player excluded successfully");
        } else {
            setErrMessage(response.data.error)
        }
    }

    useEffect(() => {
        setSuccMessage("");
        setErrMessage("");
    }, [activeKey])

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"settings-jackpots"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item href="/settings/jackpots">Jackpots</Breadcrumb.Item>
                            <Breadcrumb.Item active>Jackpot Settings</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Jackpot Settings</h1>
                        </div>

                        <Panel style={{marginTop : "20px"}}>
                            {
                              formMessage != "" && (
                                <Message showIcon type={formMessageType}>
                                  {formMessage}
                                </Message>
                              )
                            }

                            <Nav appearance="subtle" activeKey={activeKey} onSelect={v => setActiveKey(v)}>
                                <Nav.Item eventKey="settings">General Settings</Nav.Item>
                                <Nav.Item eventKey="shops">Excluded Shops</Nav.Item>
                                <Nav.Item eventKey="players">Excluded Players</Nav.Item>
                            </Nav>
                            
                            {
                                activeKey == "settings" ? (
                                    <>
                                        <Form onSubmit={SubmitForm} className="form-box">
                                            <div className="form-row" style={{marginTop : "20px"}}>
                                                <Form.Group controlId="contribution">
                                                    <Form.ControlLabel>Contribution</Form.ControlLabel>
                                                    <Form.Control value={form.percentage} onChange={value => setForm({...form, percentage : value})} name="contribution" placeholder="Contribution" />
                                                </Form.Group>
                                            </div>

                                            <div className="form-row" style={{marginTop : "20px"}}>
                                                <Form.Group controlId="threshold">
                                                    <Form.ControlLabel>Threshold</Form.ControlLabel>
                                                    <Form.Control value={form.threshold} onChange={value => setForm({...form, threshold : value})} name="threshold" placeholder="Threshold" />
                                                </Form.Group>
                                            </div>

                                            <div className="form-row" style={{marginTop : "20px"}}>
                                                <Form.Group controlId="maxwin">
                                                    <Form.ControlLabel>Max Win</Form.ControlLabel>
                                                    <Form.Control value={form.maxwin} onChange={value => setForm({...form, maxwin : value})} name="maxwin" placeholder="Max Win" />
                                                </Form.Group>
                                            </div>

                                            <div className="form-row" style={{marginTop : "20px"}}>
                                                <Form.Group controlId="difficulty">
                                                    <Form.ControlLabel>Dofficulty</Form.ControlLabel>
                                                    <Form.Control value={form.difficulty} onChange={value => setForm({...form, difficulty : value})} name="difficulty" placeholder="Difficulty" />
                                                </Form.Group>
                                            </div>

                                            <div style={{textAlign: "right", marginTop : "20px", width : "90%", maxWidth : "1100px", marginRight : "auto", marginLeft : "auto"}}>
                                                <Button disabled={formLoading} type="submit" style={{height : "100%"}} onClick={SaveJackpot} color="primary">Save Jackpot</Button>
                                            </div>
                                        </Form>
                                    </>
                                ) : activeKey == "shops" ? (
                                    <div style={{marginTop : "20px"}}>
                                        <Panel header="Exclude Shop">
                                            <Form>
                                                {
                                                    errMessage != "" && (
                                                        <Message style={{marginBottom : "15px"}} type="error">{errMessage}</Message>
                                                    )
                                                }
                                                {
                                                    succMessage != "" && (
                                                        <Message style={{marginBottom : "15px"}} type="success">{succMessage}</Message>
                                                    )
                                                }

                                                <Form.Group>
                                                    <Form.ControlLabel>Shop</Form.ControlLabel>
                                                    <div className="form-control-wrap-box" style={{ display : "flex" }}>
                                                        <InputPicker onChange={v => setExcludedId(v)} style={{flex : 1}} data={shops}></InputPicker>
                                                        <Button disabled={shopJLoading} type="submit" style={{height : "100%", marginLeft : "10px"}} onClick={AddExcludedShop} color="primary">Exclude Jackpot</Button>
                                                    </div>
                                                </Form.Group>
                                            </Form>
                                        </Panel>

                                        <Panel style={{marginTop : "15px"}} header="Excluded Shops">
                                            <Table
                                                autoHeight
                                                data={excludedList}
                                                width={"100%"}
                                            >

                                                <Table.Column minWidth={80} flexGrow={1}>
                                                    <Table.HeaderCell>Shop #</Table.HeaderCell>
                                                    <Table.Cell dataKey="id" />
                                                </Table.Column>

                                                <Table.Column minWidth={180} flexGrow={1}>
                                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                                    <Table.Cell dataKey="username" />
                                                </Table.Column>

                                                <Table.Column minWidth={120} flexGrow={1} align="center">
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                    <ActionCell />
                                                </Table.Column>
                                            </Table>
                                        </Panel>
                                    </div>
                                ) : activeKey == "players" ? (
                                    <div style={{marginTop : "20px"}}>
                                        <Panel header="Exclude Player">
                                            <Form>
                                                {
                                                    errMessage != "" && (
                                                        <Message style={{marginBottom : "15px"}} type="error">{errMessage}</Message>
                                                    )
                                                }
                                                {
                                                    succMessage != "" && (
                                                        <Message style={{marginBottom : "15px"}} type="success">{succMessage}</Message>
                                                    )
                                                }

                                                <Form.Group>
                                                    <Form.ControlLabel>Player</Form.ControlLabel>
                                                    <div className="form-control-wrap-box" style={{ display : "flex", flexGrow : 1, width : "100%" }}>
                                                        <Form.Control onChange={v => setExcludedPlayer(v)} style={{display : "flex", flex : 1}} placeholder="Player ID"></Form.Control>
                                                        <Button disabled={shopJLoading} type="submit" style={{height : "100%", marginLeft : "10px"}} onClick={AddExcludedPlayer} color="primary">Exclude Jackpot</Button>
                                                    </div>
                                                </Form.Group>
                                            </Form>
                                        </Panel>

                                        <Panel style={{marginTop : "15px"}} header="Excluded Players">
                                            <Table
                                                autoHeight
                                                data={excludedPlayers}
                                                width={"100%"}
                                            >

                                                <Table.Column minWidth={80} flexGrow={1}>
                                                    <Table.HeaderCell>Player #</Table.HeaderCell>
                                                    <Table.Cell dataKey="id" />
                                                </Table.Column>

                                                <Table.Column minWidth={180} flexGrow={1}>
                                                    <Table.HeaderCell>Player</Table.HeaderCell>
                                                    <Table.Cell dataKey="username" />
                                                </Table.Column>

                                                <Table.Column minWidth={120} flexGrow={1} align="center">
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                    <ActionCell />
                                                </Table.Column>
                                            </Table>
                                        </Panel>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                            
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}