import {useState, useEffect} from "react";

import Navbar from "../../Navigation/Navbar";
import Sidebar from "../../Navigation/Sidebar";

import {useDispatch, useSelector} from "react-redux";

import { Breadcrumb, Panel } from 'rsuite';

import {Button, Input, InputPicker} from "rsuite";

import Transfer from "../../Transfer/Transfer";

import BonusesTable from "./BonusesTable";

import {AiOutlinePlusCircle} from "react-icons/ai";
import {FaFilter, FaRegQuestionCircle} from "react-icons/fa";

import {useHistory} from "react-router-dom";

import axios from "axios";

export default function Partners() {
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars"+(bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"bonuses-bonuses"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Overview</Breadcrumb.Item>
                            <Breadcrumb.Item active>Bonuses</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Bonuses</h1>
                        </div>

                        {/* <Panel className="filters-panel" bordered>
                            <div className="panel-head">
                                <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                                <h1>Filters</h1>
                            </div>

                            <div className="panel-body">
                                <Input value={player} onChange={setPlayer} style={{flexGrow : 1, flex : 1}} placeholder="Username" />
                                <InputPicker value={country} onChange={setCountry} placeholder="Country" style={{flexGrow : 2, flex : 1, marginLeft : "10px"}} data={countries} />
                            </div>
                        </Panel> */}

                        <Panel style={{marginTop : "20px"}}>
                            <BonusesTable></BonusesTable>
                        </Panel>
                    </div>
                </div>
            </div> 
        </>
    )
}